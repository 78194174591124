import { Pipe, PipeTransform } from 'angular-ts-decorators';

import { transformToNumber } from './transform-to-number';

@Pipe({
  name: 'statistics'
})
export class StatisticsPipe implements PipeTransform {
  /*@ngInject*/
  constructor(private $filter: ng.IFilterService) {}

  transform(input: any, category: string | boolean, hideNegative: boolean = true): string {
    return transformToNumber(this.$filter, input, category, hideNegative);
  }
}
