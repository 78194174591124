import { Injectable } from 'angular-ts-decorators';
import { Subject } from 'rxjs';

export interface IScatterChartPoint {
  id: any;
}

@Injectable('ScatterChartService')
export class ScatterChartService {
  private _pointSelected: Subject<IScatterChartPoint> = new Subject();

  selectPoint(point: IScatterChartPoint): void {
    this._pointSelected.next(point);
  }

  pointSelected() {
    return this._pointSelected.asObservable();
  }
}
