import { Component } from 'angular-ts-decorators';

const template = require('./page.html.haml');

import { CockpitsProfilesPage } from '../profiles/page';

@Component({
  selector: 'page-cockpits-esg',
  template
})
export class CockpitsEsgPage extends CockpitsProfilesPage {}
