import { Component, Inject, AfterViewInit, OnDestroy, ViewChild } from 'angular-ts-decorators';
import { Strategy, StrategyResource, categoriesForTable, TimeSerieCategory } from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/strategies/variables';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import merge = require('lodash/merge');

import './page.scss';
const template = require('./page.html.haml');

import {
  DatesSelector, dateAtMidnight, ISelectorDatesSelected,
  ITableSettingsGroup, TableSettingsController
} from '../../components';
import { COLORS } from '../../components/colors';
import { TimeSeriesStockChartComponent } from '../../time-series';

export interface ISelectedStrategy extends Strategy {
  color: string;
  hidden: boolean;
}

export interface IQualifier {
  currency: string;
  hedge_mode: 'none'|'spot';
  performance: string;
}

export const QUALIFIERS: IQualifier[] = [
  { currency: 'USD', hedge_mode: 'none', performance: '1_year' },
  { currency: 'USD', hedge_mode: 'spot', performance: '1_year' },
  { currency: 'local', hedge_mode: 'none', performance: '1_year' }
];

export const hiddenStrategies = (strategies: ISelectedStrategy[]) => {
  return strategies.filter(strategy => strategy.hidden === true);
};

const CATEGORIES = categoriesForTable([{
  key: TimeSerieCategory.return_pa,
  defaultActive: true
}, {
  key: TimeSerieCategory.cumulative_return,
  defaultActive: true
}, {
  key: TimeSerieCategory.max_drawdown,
  defaultActive: true
}, {
  key: TimeSerieCategory.volatility,
  defaultActive: true
}, {
  key: TimeSerieCategory.info_ratio,
  defaultActive: true
}]);

@Component({
  selector: 'page-strategies-comparator',
  template
})
export class StrategiesComparatorPage implements AfterViewInit, OnDestroy {
  private initialising = false;
  private paramsIds: number[];
  private strategyColors: any = {};
  private strategiesIds: number[] = [];
  private dataset: any[];
  private watchers: Function[] = [];

  @ViewChild(TimeSeriesStockChartComponent)
  public fixingsChart: TimeSeriesStockChartComponent;

  public datesSelector: DatesSelector = new DatesSelector();
  public maxStrategies = settings.MEMBERSHIP.defaults.strategies.max_comparable;
  public STATUSES = variables.STATUSES;
  public CATEGORIES = CATEGORIES;
  public PERFORMANCES: string[] = ['3_years', '1_year', 'year_to_date'];
  public QUALIFIERS: IQualifier[] = QUALIFIERS;
  public currentQualifier: IQualifier = QUALIFIERS[0];
  public fixingsSettings: any = {};
  public dates: ISelectorDatesSelected = {};
  public strategies: ISelectedStrategy[] = [];

  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController = new TableSettingsController('page-strategies-comparator');

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $route: ng.route.IRouteService,
    $rootScope: ng.IRootScopeService,
  ) {
    this.tableSettingsGroups = [{
      key: 'column',
      title: $translate.instant('timeSeries.categories.')
    }];

    CATEGORIES.forEach(category => {
      this.tableSettingsController.registerProperty({
        key: category.key,
        group: 'column',
        active: category.defaultActive,
        title: $translate.instant(`timeSeries.categories.${category.key}`)
      });
    });

    this.fixingsSettings  = {
      plots: [{
        colors: {},
        yScale: {
          comparisonMode: 'percent',
          baseHundred: true,
          stackMode: null
        },
        yAxis: { labels: {} },
        line: {},
        legend: {
          enabled: false
        },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {},
      messageLoading: $translate.instant('strategies.comparator.view.fixings.loading'),
      messageEmpty: $translate.instant('strategies.comparator.view.fixings.empty')
    };

    this.currentQualifier = this.QUALIFIERS[0];

    this.watchers.push($rootScope.$watchCollection(() => {
      return this.paramsIds;
    }, (_newValue, oldValue) => {
      // skip change when loading
      if (oldValue) {
        $route.updateParams({
          ids: this.paramsIds.join(',')
        });
      }
    }));

    this.watchers.push($rootScope.$on('$routeChangeSuccess', (_scope, route) => {
      this.routeChangeSuccess(route);
    }));
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  ngAfterViewInit() {
    this.$timeout(() => {
      this.init();
    });
  }

  init() {
    if (this.initialising) {
      return;
    }

    this.initialising = true;

    this.paramsIds = this.$routeParams.ids.split(',').map(id => parseInt(id, 10)) || [];

    if (this.paramsIds.length > this.maxStrategies) {
      this.paramsIds.splice(this.maxStrategies, this.paramsIds.length);

      const title = this.$translate.instant('strategies.comparator.controller.too_many_strategies', {
        count: this.maxStrategies
      });
      this.toaster.warning(title);
    }

    this.strategiesIds = this.strategies.map(strategy => strategy.id);

    const missingIds = this.paramsIds.filter(value => this.strategiesIds.indexOf(value) === -1);

    if (missingIds.length > 0) {
      this.fixingsChart.loading();

      this.strategyResource.comparator({
        'ids[]': missingIds,
        currency: this.currentQualifier.currency,
        hedge_mode: this.currentQualifier.hedge_mode
      }).$promise.then(strategies => {
        this.strategies = [...this.strategies, ...strategies] as any;
        this.initSuccess(this.strategies);
      }, () => this.initError());
    }
    else {
      this.initSuccess(this.strategies);
    }
  }

  initSuccess(strategies: ISelectedStrategy[]) {
    this.initialising = false;

    this.dataset = strategies.map(strategy => {
      if (!this.strategyColors[strategy.id]) {
        this.strategyColors[strategy.id] = COLORS[Object.keys(this.strategyColors).length];
      }
      strategy.color = this.strategyColors[strategy.id];
      this.fixingsSettings.plots[0].colors[strategy.id] = {
        null: strategy.color
      };

      return merge(strategy.fixings_time_serie, {
        owner_id: strategy.id
      });
    });

    if (this.paramsIds && this.strategies.length !== this.paramsIds.length) {
      this.paramsIds = this.paramsIds.filter(value => this.strategiesIds.indexOf(value) > -1);
    }

    this.$timeout(() => {
      this.refreshChart();
    });
  }

  initError() {
    this.initialising = false;

    this.$translate('strategies.comparator.controller.load.error').then(title => {
      this.toaster.error(title);
    });

    return this.$location.path('/strategies/all');
  }

  refreshChart() {
    let min;
    let max;

    this.strategies.forEach(strategy => {
      if (!min || (strategy.start_date && min > strategy.start_date)) {
        min = strategy.start_date;
      }

      if (!max || (strategy.end_date && max < strategy.end_date)) {
        max = strategy.end_date;
      }
    });

    this.dates.min = dateAtMidnight(min);
    this.dates.max = dateAtMidnight(max);

    this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
    this.datesSelector.dates({
      max: this.dates.max
    });

    this.fixingsChart.dataset = this.dataset;
    this.fixingsChart.refresh();
  }

  changeQualifier(qualifier) {
    // reload data
    this.strategies = [];
    this.currentQualifier = qualifier;

    this.dataset = [];
    this.init();
  }

  graphAdded(selectedStrategy?: ISelectedStrategy) {
    if (selectedStrategy && selectedStrategy.id) {
      return !selectedStrategy.hidden;
    }
    else {
      return this.hiddenSeries().length === 0;
    }
  }

  toggleGraph(selectedStrategy: ISelectedStrategy) {
    if (selectedStrategy && selectedStrategy.id) {
      selectedStrategy.hidden = !selectedStrategy.hidden;
    }
    else {
      if (this.graphAdded()) {
        this.strategies.forEach(strategy => strategy.hidden = true);
      }
      else {
        this.strategies.forEach(strategy => strategy.hidden = false);
      }
    }
    this.fixingsChart.showHideSeries(this.hiddenSeries());
  }

  hiddenSeries(): number[] {
    return hiddenStrategies(this.strategies).map(strategy => strategy.id);
  }

  routeChangeSuccess(route: any) {
    if (route.$$route.segment.indexOf('strategies.comparator') !== -1) {
      this.init();
    }
  }

  strategyAdded(element: ISelectedStrategy): boolean {
    return this.paramsIds.indexOf(element.id) !== -1;
  }

  addStrategy(element: ISelectedStrategy): boolean {
    if (typeof element === 'undefined' || element === null) {
      return false;
    }

    const added = this.strategyAdded(element);
    if (!added) {
      this.paramsIds.push(element.id);
    }

    return !added;
  }

  removeStrategy(element: ISelectedStrategy): void {
    const pos = this.strategies.indexOf(element);
    this.strategies.splice(pos, 1);
    this.dataset.splice(pos, 1);
    this.paramsIds.splice(this.paramsIds.indexOf(element.id), 1);
  }
}
