import { Component, Input, Output, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { StrategyMatrix } from '@quantizr/front-model';
import { variables as settings, SettingsMatrix } from '@quantizr/front-model/dist/settings/variables';

import './component.scss';
const template = require('./component.html.haml');

@Component({
  selector: 'strategies-matrix',
  template
})
export class StrategiesMatrixComponent implements OnChanges {
  @Input()
  values: StrategyMatrix;
  @Input()
  alphaBeta: 'alpha'|'beta';
  @Input()
  ngDisabled?: boolean;
  @Input()
  title?: string;

  @Output()
  onChange: (data: {$event: StrategyMatrix}) => void;

  public disabled: boolean = false;
  public matrix: SettingsMatrix = {
    asset_classes: [],
    strategy_types: []
  };

  ngOnInit() {
    this.disabled = this.ngDisabled || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('alphaBeta' in changes) {
      const alphaBeta = changes.alphaBeta.currentValue;
      if (alphaBeta) {
        this.matrix = settings.MATRIX[alphaBeta];
      }
    }
  }

  matrixAssetsChecked(strategyType: string) {
    if (!this.values) {
      return false;
    }

    const checked = Object.keys(this.values || {}).filter(assetClass => {
      return this.values[assetClass][strategyType];
    }).length;
    return checked === this.matrix.asset_classes.length;
  }

  matrixStrategiesChecked(assetClass: string) {
    if (!this.values) {
      return false;
    }

    const checked = Object.keys(this.values[assetClass] || {}).filter(strategyType => {
      return this.values[assetClass][strategyType];
    }).length;
    return checked === this.matrix.strategy_types.length;
  }

  matrixChecked() {
    if (!this.matrix) {
      return false;
    }

    let checked = true;
    this.matrix.asset_classes.forEach(assetClass => {
      if (!this.matrixStrategiesChecked(assetClass)) {
        checked = false;
      }
    });

    return checked;
  }

  reverseMatrix(assetClass: string, strategyType: string, checked: boolean) {
    this.values[assetClass] = this.values[assetClass] || {};
    this.values[assetClass][strategyType] = !checked;
  }

  updateMatrixAssets(strategyType: string) {
    if (this.disabled) {
      return;
    }

    const checked = this.matrixAssetsChecked(strategyType);
    this.matrix.asset_classes.forEach(assetClass => {
      this.reverseMatrix(assetClass, strategyType, checked);
    });

    this.onChange({
      $event: this.values
    });
  }

  updateMatrixStrategies(assetClass: string) {
    if (this.disabled) {
      return;
    }

    const checked = this.matrixStrategiesChecked(assetClass);
    this.matrix.strategy_types.forEach(strategyType => {
      this.reverseMatrix(assetClass, strategyType, checked);
    });

    this.onChange({
      $event: this.values
    });
  }

  updateMatrix(assetClass: string, strategyType: string) {
    if (this.disabled) {
      return;
    }

    const checked = assetClass in this.values ? this.values[assetClass][strategyType] : false;
    this.reverseMatrix(assetClass, strategyType, checked);

    this.onChange({
      $event: this.values
    });
  }

  /**
   * Toggle the whole matrix.
   */
  toggleMatrixChecked() {
    if (this.disabled) {
      return;
    }

    const checked = !this.matrixChecked();

    this.values = {};
    this.matrix.asset_classes.forEach(assetClass => {
      this.values[assetClass] = {};
      this.matrix.strategy_types.forEach(strategyType => {
        this.values[assetClass][strategyType] = checked;
      });
    });

    this.onChange({
      $event: this.values
    });
  }
}
