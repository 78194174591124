import { Injectable } from 'angular-ts-decorators';
import { ReplaySubject } from 'rxjs';
import merge = require('lodash/merge');

import { IScenarioField } from '../../simulations/scenarios';

@Injectable('VersionsViewsService')
export class VersionsViewsService {
  private _$shiftSettings: ReplaySubject<any> = new ReplaySubject(1);
  private _$shiftValues: ReplaySubject<any> = new ReplaySubject(1);
  private _$fieldsConfig: ReplaySubject<IScenarioField|null> = new ReplaySubject(1);

  public clear(): void {
    this._$shiftSettings.next(null);
    this._$shiftValues.next(null);
    this._$fieldsConfig.next(null);
  }

  public shiftSettings(shiftSettings: any) {
    this._$shiftSettings.next(merge({}, shiftSettings));
  }

  public $shiftSettings() {
    return this._$shiftSettings.asObservable();
  }

  public shiftValues(shiftValues: any) {
    this._$shiftValues.next(merge({}, shiftValues));
  }

  public $shiftValues(){
    return this._$shiftValues.asObservable();
  }

  public fieldsConfig(fieldsConfig: any) {
    this._$fieldsConfig.next(merge({}, fieldsConfig));
  }

  public $fieldsConfig() {
    return this._$fieldsConfig.asObservable();
  }
}
