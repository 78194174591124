import { Component, Inject } from 'angular-ts-decorators';
import { IStrategyList, StrategyListResource } from '@quantizr/front-model';

const template = require('./page.html.haml');

import { DialogController } from '../../dialog/controller';
import { StrategyListsService } from '../service';
import { formHasErrors } from '../../forms/has-errors';

@Component({
  selector: 'page-customlists-new',
  template
})
export class CustomlistsNewPage extends DialogController {
  public form: ng.IFormController;
  public saving = 0;
  public errorMessages: any = null;
  public strategyList: IStrategyList;

  /*@ngInject*/
  constructor(
    @Inject('StrategyListResource')
    strategylistResource: StrategyListResource,
    @Inject('StrategyListsService')
    private strategyListsService: StrategyListsService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $scope: ng.IScope
  ) {
    super($scope);
    this.strategyList = new strategylistResource({
      name: ''
    });
  }

  save(): ng.IPromise<void> {
    this.saving = 1;

    return this.strategyList.$save().then(() => {
      this.saveSuccess();
    }, (error) => {
      this.saveError(error);
    });
  }

  saveSuccess() {
    this.strategyListsService.reset();

    this.saving = 0;
    this.confirm();

    return this.$translate('customlists.new.controller.success', {
      name: this.strategyList.name
    }).then(title => {
      this.toaster.success(title);
    });
  }

  saveError(error) {
    this.saving = 2;
    this.errorMessages = error.data;
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.form, this.errorMessages);
  }
}
