interface IUibDateParser {
  apply: (value: any) => void;
  index: number;
  key: string;
  matcher: string;
}

export const bracketsUpperLowerChar = (text: string) => {
  return text.split('').reduce((previous, current) => {
    return previous + '[' + current.toLocaleUpperCase() + current.toLocaleLowerCase() + ']';
  }, '');
};

export const transformBetween = (text: string, delimiter: string, charTransform: (text: string) => string) => {
  return text.split(delimiter).map(value => charTransform(value)).join(delimiter);
};

export const capitalizeFirstLetter = (text: string) => {
  return text[0].toLocaleUpperCase() + text.substring(1).toLocaleLowerCase();
};

export const config = (uibDateParser: any) => {
  const format = 'dd-MMM-yyyy';
  // Triggers creation of the parser
  uibDateParser.parse('01-Jan-2000', format);

  // Override parser to handle lower cases
  const parser = uibDateParser.parsers[format];
  const months: IUibDateParser = parser.map[1]; // MMM
  const regex = '(' + [
    parser.map[0].matcher,
    transformBetween(months.matcher, '|', bracketsUpperLowerChar),
    parser.map[2].matcher
  ].join(')-(') + ')';

  const apply = months.apply;
  months.apply = function(value: string) {
    apply.call(this, capitalizeFirstLetter(value));
  };

  parser.regex = new RegExp('^' + regex + '$');
};
