import { Component, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import { MessageResource, IMessage, MyUser } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { MyUserService } from '../../my-user';
import { DialogController } from '../../dialog/controller';

export const MESSAGE_TYPES = ['contact', 'complain', 'demo', 'quotation', 'partnership'];

@Component({
  selector: 'page-messages-new',
  template
})
export class MessagesNewPage extends DialogController implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public COUNTRY_CODES = settings.COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES = settings.SHORTLISTED_COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES_STRING = settings.SHORTLISTED_COUNTRY_CODES_STRING;

  public currentUser: MyUser|null = null;

  public errorMessages: any = null;
  public messageForm: ng.IFormController;
  public message: IMessage;
  public to: string = '';
  public messageSent = false;
  public saving = 0;

  /*@ngInject*/
  constructor(
    @Inject('MessageResource')
    private messageResource: MessageResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
    private toaster: toaster.IToasterService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    private $window: ng.IWindowService,
    $scope: ng.IScope
  ) {
    super($scope);

    this.subscriptions.push(myUserService.$user().subscribe(user => {
      this.currentUser = user;
      this.init();
    }));
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    this.message = new this.messageResource({
      message_type: MESSAGE_TYPES[0]
    }) as any;

    const path = this.$location.path();
    MESSAGE_TYPES.forEach(messageType => {
      if (path.indexOf(messageType) !== -1) {
        this.message.message_type = messageType;
      }
    });

    if (this.data) {
      if (this.data.message) {
        this.message = new this.messageResource(this.data.message) as any;
        if (this.message.strategy && this.message.strategy.company) {
          this.to = this.message.strategy.company.name;
        }
      }
    }

    if (!this.to) {
      this.$translate('messages.new.controller.init.our_team').then(title => this.to = title);
    }
  }

  sendMessage() {
    this.saving = 1;

    return this.message.$save().then(response => this.sendMessageSuccess(response), (error) => {
      this.saving = 2;
      this.errorMessages = error.data;
    });
  }

  back() {
    this.$window.history.back();
  }

  sendMessageSuccess(message?) {
    this.errorMessages = null;
    this.saving = 0;
    this.message = this.message || message;

    this.$translate('messages.new.controller.send.success').then(title => {
      this.toaster.success(title);
    });

    // within a dialog
    if (this.isDialog) {
      this.closeThisDialog('');
    }
    else {
      this.messageForm.$submitted = false;
      this.messageSent = true;
      this.init();
    }
  }
}
