import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import {
  CockpitProfile, CockpitPortfolio,
  GroupedTimeSerie,
  TimeSerieWindow,
  TimeSerieCategory
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { windowDateFormat } from '../show/page';
import {
  ITableGroupedHeader,
  ITableGroupedGroup
} from '../../../components/table-grouped/component';

@Component({
  selector: 'cockpits-perf-contribution-currencies',
  template
})
export class CockpitsPerfContributionCurrenciesComponent implements OnChanges {
  @Input()
  public profile: CockpitProfile;
  @Input()
  public portfolio: CockpitPortfolio;
  @Input()
  public currencies: GroupedTimeSerie[] = [];
  @Input()
  public window: TimeSerieWindow;
  @Input()
  public limitColumns;

  public headers: ITableGroupedHeader[] = [];
  public groups: ITableGroupedGroup[] = [];

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $filter: ng.IFilterService,
    private ngDialog: ng.dialog.IDialogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes || 'currencies' in changes) {
      this.loadHeaders();
      this.updateGroups();
    }
    if ('window' in changes) {
      this.loadHeaders();
    }
  }

  limit(values: any[]) {
    return Math.min(values.length, this.limitColumns || Number.MAX_SAFE_INTEGER);
  }

  loadHeaders() {
    // first column, no name
    this.headers = [{
      label: '',
      colspan: 2
    }];

    const currency = this.currencies.length ? this.currencies[0] : null;
    if (currency) {
      const data = currency.categories.perf_contrib_perf_contributions_serie_local_currency!.data;
      const limit = this.limit(data);
      for (let i = data.length - limit; i < data.length; i++) {
        const date = data[i][0];
        this.headers.push({
          label: this.$filter('date')(date, windowDateFormat(this.window)),
          colspan: 1
        });
      }
    }
  }

  openCurrencyDialog(currency: GroupedTimeSerie) {
    this.ngDialog.open({
      template: '<page-cockpits-perf-contribution-details></page-cockpits-perf-contribution-details>',
      className: 'ngdialog-theme-default ngdialog-theme-large',
      plain: true,
      data: {
        portfolio: this.portfolio,
        currency: currency.currency,
        timeseries: Object.keys(currency.categories).map(key => {
          return {
            data: currency.categories[key].data
          };
        }),
        window: this.window,
        title: currency.currency
      }
    });
  }

  updateGroups() {
    this.groups = this.currencies.map(currency => {
      const group = this.$translate.instant(`timeSeries.categories.${
        TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency
      }`);
      const timeserie =
        currency.categories.perf_contrib_perf_contributions_serie_portfolio_currency;
      const values = timeserie ? timeserie.data.map(val => {
        return {
          value: val[1],
          isPercentage: true
        };
      }) : [];

      const subgroup = this.$translate.instant(`timeSeries.categories.${
        TimeSerieCategory.perf_contrib_perf_contributions_serie_local_currency
      }`);
      const subtimeserie = currency.categories.perf_contrib_perf_contributions_serie_local_currency;
      const subvalues = subtimeserie!.data.map(val => {
        return {
          value: val[1],
          isPercentage: true
        };
      });
      const limit = this.limit(timeserie ? timeserie.data : subtimeserie!.data);

      const children = [{
        name: '',
        open: true,
        values: [],
        children: [{
          name: `${currency.currency} ${subgroup}`,
          values: subvalues.slice(subvalues.length - limit),
          click: () => {
            this.openCurrencyDialog(currency);
          }
        }]
      }];

      return {
        name: `${currency.currency} ${group}`,
        open: true,
        values: values.slice(values.length - limit),
        children
      };
    });
  }
}
