import { Component } from 'angular-ts-decorators';
const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';

@Component({
  selector: 'page-simulations-widgets-edit',
  template
})
export class SimulationsWidgetsEditPage extends DialogController {}
