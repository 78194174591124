import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { LinkResource, Strategy, StrategyBenchmark, Link } from '@quantizr/front-model';
import { variables as timeSeries } from '@quantizr/front-model/dist/time-series/variables';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { COLOR as BENCHMARK_COLOR } from '../../../benchmarks';
import { dateAtMidnight, DatesSelector, ISelectorDatesSelected } from '../../../components';
import { COLORS } from '../../../components/colors';
import { CustomSearchRequestsService } from '../../../custom-search';
import { StrategiesBenchmarksService } from '../../benchmarks/service';
import { StrategiesViewsService } from '../../views/service';

@Component({
  selector: 'page-strategies-show-benchmark',
  template
})
export class StrategiesShowBenchmarkPage implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private namespace = 'page-strategies-show-benchmark';

  public CORRELATIONS_PERIODS = timeSeries.Correlations.PERIODS;
  public BENCHMARK_COLOR = BENCHMARK_COLOR;
  public TIME_SERIES_COLOR = COLORS;
  public strategy: Strategy;
  public benchmark: StrategyBenchmark;
  public benchmarks: StrategyBenchmark[] = [];
  public perfMonthToMonth: Link[];

  // All graphs
  public datesSelector = new DatesSelector();
  public dates: ISelectorDatesSelected = {};

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    private strategiesBenchmarksService: StrategiesBenchmarksService,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('LinkResource')
    private linkResource: LinkResource,
    private $timeout
  ) {
    this.subscriptions.push(strategiesBenchmarksService.$benchmarks().subscribe(benchmarks => {
      this.benchmarks = benchmarks;

      $timeout(() => {
        if (benchmarks && benchmarks.length && !this.benchmark) {
          // select first benchmark by default
          this.benchmark = this.benchmarks[0];
          this.onSelectedBenchmark(this.benchmark);
        }
      });
    }));

    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.init();
      }
    }));
  }

  ngOnDestroy() {
    this.customSearchRequestsService.cancelAll();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    (this.strategy as any).color = COLORS[0];

    if (this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }

    if (this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    this.$timeout(() => {
      this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
      this.datesSelector.dates({
        max: this.dates.max
      });
    });
  }

  // *** Benchmarks

  onSelectedBenchmark(benchmark) {
    this.strategiesBenchmarksService.select(benchmark);
    return this.refreshPerfMonthToMonth();
  }

  refreshPerfMonthToMonth() {
    if (!this.strategy || !this.benchmark) {
      this.perfMonthToMonth = [];
      return;
    }

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    const request = this.linkResource.perfMonthToMonth({
      strategy_from_id: this.strategy.id,
      strategy_to_id: this.benchmark.id
    });

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise.then(response => {
      this.perfMonthToMonth = response;
    }).then(() => {
      this.customSearchRequestsService.remove(request, this.namespace);
    });
  }
}
