import {
  Component, Input, Inject,
  OnDestroy, OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { TimeSerieResource, TimeSerie } from '@quantizr/front-model';
import 'anychart/dist/js/anychart-stock.min';

import './component.scss';
const template = require('./component.html.haml');

import { BaseChartComponent, BaseChartService, statisticToPercent } from '../../components';
import { COLORS } from '../../components/colors';
import { CustomSearchRequestsService } from '../../custom-search/service';
import { dateAtMidnight, DatesSelector, ISelectorDatesSelected } from '../../components/dates-selector/model';
import { tooltipStyle } from '../../components/base-chart/component';

export interface IStockChartRequestParams {
  'ids[]': number[];
  type: string;
  qualifier?: string;
  start_date?: string|Date;
  end_date?: string|Date;
  'windows[]'?: string[];
  'categories[]'?: string[];
  out_performance?: boolean;
}

export interface IStockChartTimeSerie extends TimeSerie {
  color: string;
  index: number;
  data: any[];
}

export const crosshairLabel = (value: any): string => {
  const date = new Date(value);
  return date.toLocaleDateString('en-au', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

let components: number = 1;

export const WINDOWS_SORT: {[key: string]: string} = {
  'start date': 'AAA',
  'live date': 'AAB',
  'year to date': 'AAC',
  daily : 'AAD',
  '1 week': 'ABA',
  '1 month': 'ACA',
  '3 months': 'ACB',
  '6 months': 'ACC',
  '1 year': 'ADA',
  '2 years': 'ADB',
  '3 years': 'ADC',
  '5 years': 'ADD',
  '10 years': 'ADE'
};

@Component({
  selector: 'time-series-stock-chart',
  template
})
export class TimeSeriesStockChartComponent extends BaseChartComponent<anychart.charts.Stock>
  implements OnChanges, OnDestroy {
  private namespace: string = `TimeSeriesStockChartComponent-${++components}`;
  private subscriptions: Subscription[] = [];
  private timeSeries: any[] = [];
  private selectedDates: ISelectorDatesSelected;

  @Input()
  private dates: any;
  @Input()
  private datesSelector: DatesSelector;
  @Input()
  private rangeSplineArea: {
    data: any;
    name: string;
  };
  @Input()
  scroller: any;
  @Input()
  public requestParams: IStockChartRequestParams|undefined;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('TimeSerieResource')
    private timeSerieResource: TimeSerieResource,
    private $rootScope: ng.IRootScopeService
  ) {
    super(baseChartService);
    this.initChart();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customSearchRequestsService.cancel(undefined, this.namespace);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('datesSelector' in changes) {
      const datesSelector = changes.datesSelector.currentValue as DatesSelector;
      if (datesSelector) {
        // update the range when the user changes the dates from the date selector
        this.subscriptions.push(this.datesSelector.$dates().subscribe((dates) => {
          if (this.chart) {
            this.selectedDates = dates;
            this.chart.selectRange(dates.min!, dates.max);
            this.refreshComparisonMode();
          }
        }));
      }
    }
    if ('scroller' in changes) {
      const scroller = changes.scroller.currentValue;
      if (scroller) {
        this.chart.scroller().enabled(true);
        this.chart.scroller().removeAllSeries();
        this.chart.scroller().line(
          anychart.data.table().addData(scroller).mapAs({
            value: 1
          })
        );
      }
      else {
        this.chart.scroller().enabled(false);
      }
    }
    if ('settings' in changes) {
      const settings = changes.settings.currentValue;
      if (settings) {
        this.updateSettings(settings);
      }
    }
  }

  public initChart() {
    this.chart = anychart.stock();

    this.chart.background().fill('transparent');

    this.chart.listen('selectedrangechangefinish', (event) => this.selectedRangeChangeFinish(event));
    // set container id for the chart
    this.chart.crosshair().enabled(true).yLabel(true).xLabel(true);
    this.chart.crosshair().xLabel().format(function() {
      return crosshairLabel(this.value);
    });

    this.chart.plot(0).xGrid().enabled(true);
    this.chart.plot(0).yGrid().enabled(true);

    this.chart.plot(0).xMinorGrid().enabled(true);
    this.chart.plot(0).yMinorGrid().enabled(true);
  }

  public refresh() {
    if (!this.chart) {
      return;
    }

    this.loading();

    if (
      this.requestParams &&
      this.requestParams['ids[]'] &&
      this.requestParams['ids[]'].length
    ) {
      this._query();
    }
    else {
      this.refreshTimeSeries(this.dataset);
    }
  }

  public _query() {
    if (!this.chart) {
      return;
    }

    if (
      !this.requestParams ||
      !this.requestParams['ids[]'] ||
      this.requestParams['ids[]'].length === 0
    ) {
      return this.refreshTimeSeries([]);
    }

    if (this.settings.messageLoading) {
      this.chart.plot(0).noData().label().text(this.settings.messageLoading);
    }

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    const request = this.timeSerieResource.query(this.requestParams);

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise
      .then(response => {
        this.refreshTimeSeries(response);
      }, () => {
        this.refreshTimeSeries([], true);
      })
      .then(() => {
        this.customSearchRequestsService.remove(request, this.namespace);
      });
  }

  private refreshComparisonMode() {
    const plot = this.chart.plot(0);
    const scale = plot.yScale() as anychart.scales.Linear;
    if (scale.comparisonMode() === 'none') {
      return;
    }

    let fullStartDate = this.dates.minDate;

    this.timeSeries.forEach((timeserie) => {
      if (
        fullStartDate < new Date(timeserie.start_date) &&
        new Date(timeserie.start_date) < this.dates.maxDate
      ) {
        fullStartDate = new Date(timeserie.start_date);
      }
    });

    if (fullStartDate > this.dates.minDate) {
      scale.compareWith(fullStartDate);
    }
    else {
      scale.compareWith('firstVisible');
    }
  }

  refreshTimeSeries(newTimeseries: TimeSerie[], isCancelled: boolean = false) {
    this.chart.plot(0).removeAllSeries();
    this.refreshTimeSeriesColor(newTimeseries as IStockChartTimeSerie[]);

    if (this.rangeSplineArea) {
      this.chart.plot(0)
        .rangeSplineArea(
          anychart.data.table().addData(this.rangeSplineArea.data).mapAs({
            low: 1,
            high: 2
          })
        )
        .name(this.rangeSplineArea.name)
        .legendItem()
        .text(this.rangeSplineArea.name);
    }

    // create fixing plot on the chart
    this.timeSeries.forEach(timeSerie => {
      this.addTimeSerieToPlot(timeSerie);
    });

    this.refreshComparisonMode();

    // do not display empty if request was cancelled
    if (!isCancelled && this.settings && this.settings.messageEmpty) {
      this.chart.plot(0).noData().label().text(this.settings.messageEmpty);
    }
    // initiate chart drawing
    if (this.selectedDates) {
      this.chart.selectRange(this.selectedDates.min!, this.selectedDates.max);
    }

    this.onRefreshedDone();
  }

  public showHideSeries(hiddenSeries: number[]) {
    this.timeSeries.forEach(timeserie => {
      let enabled = true;
      const serie = this.chart.plot(0).getSeriesAt(timeserie.index);

      if (serie !== null) {
        hiddenSeries.forEach(hiddenSerieId => {
          if (hiddenSerieId === timeserie.owner_id) {
            enabled = false;
          }
        });

        serie.enabled(enabled);
      }
    });
  }

  private selectedRangeChangeFinish(event) {
    let minDate = dateAtMidnight(event.firstSelected);
    let maxDate = dateAtMidnight(event.lastSelected);

    if (this.dates && this.dates.min && minDate < this.dates.min) {
      minDate = this.dates.min;
    }
    if (this.dates && this.dates.max && maxDate > this.dates.max) {
      maxDate = this.dates.max;
    }

    if (this.datesSelector) {
      this.datesSelector.dates({
        min: minDate,
        max: maxDate
      });
    }

    this.$rootScope.$apply();
  }

  private addTimeSerieToPlot(timeserie: IStockChartTimeSerie) {
    // create data tables on loaded data
    const datatable = anychart.data.table();
    const plot = this.chart.plot(0);
    const strokeColor = timeserie.color;
    const fillColor = anychart.color.lighten(strokeColor, 0.8);
    let element;

    datatable.addData(timeserie.data);

    const data = datatable.mapAs({value: 1});

    if (this.settings.plots[0].area) {
      element = plot.area(data);
      element.fill(fillColor);
      element.stroke({
        color: strokeColor,
        thickness: this.lineThickness()
      });
    }
    else if (this.settings.plots[0].column) {
      element = plot.column(data);
      element.fill(fillColor);
      timeserie.index = element.getIndex();
    }
    else {
      element = plot.line(data);
      element.stroke({
        color: strokeColor,
        thickness: this.lineThickness()
      });
      timeserie.index = element.getIndex();
    }

    if (timeserie.name) {
      element.name(timeserie.name).legendItem().text(timeserie.name);
    }
  }

  private refreshTimeSeriesColor(newTimeseries: IStockChartTimeSerie[]) {
    this.timeSeries = [];
    newTimeseries = newTimeseries.sort((a, b) => {
      return (WINDOWS_SORT[a.name!] || a.name!) > (WINDOWS_SORT[b.name!] || b.name!) ? 1 : -1;
    });

    const colors = this.settings.plots[0].colors;
    for (let i = 0; i < newTimeseries.length; i++) {
      const timeserie = newTimeseries[i];
      const window = timeserie.window || 'null';
      const color = colors && colors[timeserie.owner_id!] ? colors[timeserie.owner_id!][window] : null;
      if (!timeserie.color) {
        timeserie.color = color || COLORS[i];
      }
      this.timeSeries.push(timeserie);
    }
  }

  /**
   * Display loading.
   */
  public loading() {
    if (!this.chart) {
      return;
    }

    if (this.settings && this.settings.messageLoading) {
      const label = this.chart.plot(0).noData().label();
      label.enabled(true);
      label.text(this.settings.messageLoading);
    }
  }

  lineThickness() {
    const line = this.settings.plots[0].line;
    return line && 'thickness' in line ? line.thickness : 3;
  }

  updateSettings(settings: any) {
    if (settings.credits !== false) {
      this.chart.credits()
        .text('Source: Quantilia')
        .alt('Source: Quantilia')
        .url('')
        .logoSrc('');
    }
    else {
      this.chart.credits().enabled(false);
    }

    if (settings.messageLoading) {
      this.loading();
    }

    if (settings.grouping) {
      this.chart.grouping().maxVisiblePoints(settings.grouping.maxVisiblePoints);
    }

    const plot = this.chart.plot(0);

    const plotSettings = settings.plots && settings.plots.length ? settings.plots[0] : null;
    if (plotSettings) {
      const legendSettings = plotSettings.legend;
      if (legendSettings) {
        plot.legend().enabled(legendSettings.enabled);
        plot.legend().title(false).align('left');

        if ('fontSize' in legendSettings) {
          plot.legend().fontSize(legendSettings.fontSize);
        }

        if ('align' in legendSettings) {
          plot.legend().align(legendSettings.align);
        }
      }

      if (
        plotSettings.annotations &&
        plotSettings.annotations[0].verticalLine &&
        'xAnchor' in plotSettings.annotations[0].verticalLine
      ) {
        plot.annotations()
          .verticalLine({
            xAnchor: plotSettings.annotations[0].verticalLine.xAnchor
          })
          .stroke('black', 2)
          .allowEdit(false);
      }

      if (plotSettings.yAxis && plotSettings.yAxis.labels.format) {
        plot.yAxis().labels().format(plotSettings.yAxis.labels.format);
      }
    }

    const yScaleSettings = plotSettings ? plotSettings.yScale : null;
    if (yScaleSettings) {
      const scale = plot.yScale() as anychart.scales.Linear;
      if (yScaleSettings.comparisonMode) {
        scale.comparisonMode(yScaleSettings.comparisonMode);

        if (
          yScaleSettings.baseHundred === true &&
          yScaleSettings.comparisonMode === 'percent'
        ) {
          this.chart.crosshair().yLabel().format(function() {
            return this.value + 100;
          });
          plot.yAxis().labels().format(function() {
            return this.value + 100;
          });
        }
      }
      if (yScaleSettings.isPercentage) {
        this.chart.crosshair().yLabel().format(function() {
          return statisticToPercent(this.value);
        });
        plot.yAxis().labels().format(function() {
          return statisticToPercent(this.value);
        });
      }

      if (yScaleSettings.stackMode) {
        scale.stackMode(yScaleSettings.stackMode);

        if (!!yScaleSettings.minimum && !!yScaleSettings.maximum) {
          scale.minimum(yScaleSettings.minimum);
          scale.maximum(yScaleSettings.maximum);
        }
      }
    }

    const tooltipSettings = settings.tooltip;
    if (tooltipSettings) {
      this.chart.tooltip().background().fill(tooltipStyle.background.fill).stroke(tooltipStyle.background.stroke);
      this.chart.tooltip().padding(tooltipStyle.padding);

      if (tooltipSettings.enabled !== undefined) {
        this.chart.tooltip().enabled(tooltipSettings.enabled);
      }

      if (typeof tooltipSettings.format !== 'undefined') {
        this.chart.tooltip().format(tooltipSettings.format);
      }
      else if (yScaleSettings) {
        if (yScaleSettings.comparisonMode !== undefined) {
          this.chart.tooltip().format(function() {
            if (yScaleSettings.comparisonMode === 'percent') {
              return `${this.seriesName}: ${anychart.format.number(this.valuePercentChange + 100, 2, '.', '')}`;
            }
            else if (yScaleSettings.comparisonMode === 'value') {
              return `${this.seriesName}: ${anychart.format.number(this.valueChange, 2, '.', '')}`;
            }
            else {
              return `${this.seriesName}: ${anychart.format.number(this.value, 2, '.', '')}`;
            }
          });
        }
        if (yScaleSettings.isPercentage) {
          this.chart.tooltip().format(function() {
            return `${this.seriesName}: ${statisticToPercent(this.value)}`;
          });
        }
      }
    }
  }
}
