import { NgModule } from 'angular-ts-decorators';

import { PdfEngineComponent } from './engine/component';

@NgModule({
  id: 'PDF',
  declarations: [
    PdfEngineComponent
  ]
})
export class PDFModule {}

export default (PDFModule as NgModule).module!.name;
