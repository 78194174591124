import { Component } from 'angular-ts-decorators';
import { variables as dynamicSelections } from '@quantizr/front-model/dist/dynamic-selections/variables';

import './component.scss';
const template = require('./component.html.haml');

import { VersionsEditAbstractComponent } from '../abstract-component';

@Component({
  selector: 'versions-edit-filters',
  template
})
export class VersionsEditFiltersComponent extends VersionsEditAbstractComponent {
  public DYNAMIC_SELECTION_TYPES = dynamicSelections.DYNAMIC_SELECTION_TYPES;
  public INDICATORS = dynamicSelections.INDICATORS;
  public OPERATORS = dynamicSelections.OPERATORS;
  public SORTINGS = dynamicSelections.SORTINGS;
  public FREQUENCY_UNITS = dynamicSelections.FREQUENCY_UNITS;

  public asyncSelected = '';

  addDynamicSelection() {
    this.version.dynamic_selections_attributes!.push({
      dynamic_selection_type: this.DYNAMIC_SELECTION_TYPES.RANK_SELECTION,
      indicator: this.INDICATORS.PERFORMANCE,
      operator: this.OPERATORS.EQUAL,
      sorting: this.SORTINGS.ASCENDING,
      window_frequency: {
        value: 120,
        unit: this.FREQUENCY_UNITS.BD
      }
    });
  }

  removeDynamicSelection(dynamicSelection, index) {
    if (dynamicSelection.id) {
      dynamicSelection._destroy = 1;
    }
    // just added, not created on server-side
    else {
      this.version.dynamic_selections_attributes!.splice(index, 1);
    }
  }
}
