import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { StrategyResource, Strategy } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

const template = require('./page.html.haml');

import { typeFromError } from '../../../type';
import { StrategiesViewsService } from '../../../views/service';

@Component({
  selector: 'page-strategies-show-versions-composition',
  template
})
export class StrategiesShowVersionsCompositionPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public errors: any = null;
  public baseStrategy: Strategy;
  public strategy: Strategy;

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    $routeParams: ng.route.IRouteParamsService,
    private $translate: ng.translate.ITranslateService
  ) {
    const id = $routeParams.compositionStrategyId;
    this.init(id);

    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy) {
        this.baseStrategy = strategy;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init(id: number) {
    this.strategyResource.get({id}).$promise.then(response => {
      this.loadSuccess(response);
    }, error => this.loadError(error));
  }

  loadSuccess(response) {
    this.strategy = response;
  }

  loadError(error) {
    const type = typeFromError(error);

    // used in strategies/strategy_versions/composition.html
    this.$translate(`strategies.show.controller.load.error.${type}`).then(title => {
      this.errors = {
        load: title
      };
    });
  }
}
