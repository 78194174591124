import { NgModule } from 'angular-ts-decorators';
import DashboardResourceFactory from '@quantizr/front-model/dist/dashboards/resource';
import DashboardViewResourceFactory from '@quantizr/front-model/dist/dashboards/views/resource';

import { ComponentsModule } from '../components';
import { PanelsModule } from '../panels';
import { VendorModule } from '../vendor';

import { DashboardsShowPage } from './show/page';
import { DashboardsSidebarFiltersComponent } from './sidebar-filters/component';

@NgModule({
  id: 'Dashboards',
  imports: [
    ComponentsModule,
    PanelsModule,
    VendorModule
  ],
  declarations: [
    DashboardsShowPage,
    DashboardsSidebarFiltersComponent
  ],
  providers: [
    {
      provide: 'DashboardResource',
      useFactory: ($resource) => DashboardResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'DashboardViewResource',
      useFactory: ($resource) => DashboardViewResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class DashboardsModule {}

export default (DashboardsModule as NgModule).module!.name;
