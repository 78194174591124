import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { MyUser } from '@quantizr/front-model';
import { Subscription } from 'rxjs';
import './component.scss';
const template = require('./component.html.haml');

import { MyUserService } from '../../my-user';
import { EnvService } from '../../env';

@Component({
  selector: 'panels-header',
  template,
  transclude: true
})
export class PanelsHeaderComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public CMS_URL: string;
  public ADMIN_URL: string;
  public currentUser: MyUser|null = null;

  /*@ngInject*/
  constructor(
    @Inject('EnvService')
    envService: EnvService,
    @Inject('MyUserService')
    private myUserService: MyUserService,
  ) {
    this.CMS_URL = envService.apiUrl('www');
    this.ADMIN_URL = envService.apiUrl('admin');

    this.subscriptions.push(myUserService.$user().subscribe(user => this.currentUser = user));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public logout() {
    return this.myUserService.logout();
  }
}
