import merge = require('lodash/merge');

import * as translationsEnUs from './i18n-en_US';
import { IEnv } from '../env';

const defaultLocale = 'en_US';

const translations = {
  en_US: translationsEnUs
};

export const mergeTranslations = (locale: string) => {
  return Object.keys(translations[locale]).reduce((previous, current) => {
    return merge(previous, translations[locale][current]);
  }, {});
};

export const loadLocale = ($translateProvider: ng.translate.ITranslateProvider, locale: string) => {
  $translateProvider.translations(locale, mergeTranslations(locale));
};

export default function(
  Config: IEnv,
  $translateProvider: ng.translate.ITranslateProvider
) {
  if (Config.ENV !== 'production') {
    $translateProvider.useMissingTranslationHandlerLog();
  }
  $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
  loadLocale($translateProvider, 'en_US');
  $translateProvider.preferredLanguage(defaultLocale);
  $translateProvider.useLocalStorage();
}
