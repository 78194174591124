import { StrategyGroupedDetail } from '@quantizr/front-model';

export type weightType = 'short'|'long'|'total';

/**
 * TODO: fill this
 * @param values
 * @param maxCount
 */
export const sumWeightFor = <T extends StrategyGroupedDetail>(
  values?: T[],
  type: weightType = 'total',
  maxCount: number = 0
) => {
  let total: number = 0.0;

  for (let i = 0; values && i < values.length && (!maxCount || i < maxCount); i++) {
    total += values[i].weight[type];
  }

  return total;
};

/**
 * TODO: fill this
 * @param values
 */
export const sumAbsWeightFor = <T extends StrategyGroupedDetail>(
  values?: T[],
  type: weightType = 'total'
) => {
  let total: number = 0.0;

  for (let i = 0; values && i < values.length; i++) {
    total += Math.abs(values[i].weight[type]);
  }

  return total;
};
