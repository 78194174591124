import { Component, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import {
  CockpitCustomlistResource, CockpitCustomlistStrategy, CockpitCustomlistIssuer
} from '@quantizr/front-model';
import * as angular from 'angular';

import './page.scss';
const template = require('./page.html.haml');

import { ISankeyData, simpleOrderKeys } from '../../components/sankey/component';
import { COLORS } from '../../components/colors';
import { CustomSearchRequestsService } from '../../custom-search';
import { CORRELATION_COLORS } from '../../strategies/correlation-matrix/component';

export interface ICockpitStrategyDisplay extends CockpitCustomlistStrategy {
  color?: string;
}

@Component({
  selector: 'page-cockpits-buylists',
  template
})
export class CockpitsBuylistsPage implements OnInit, OnDestroy {
  public strategies: ICockpitStrategyDisplay[] = [];
  public issuers: CockpitCustomlistIssuer[] = [];
  public sankeyData: ISankeyData;

  public SANKEY_SOURCE_COLORS = COLORS;
  public SANKEY_TARGET_COLORS = [
    CORRELATION_COLORS.HIGHEST, // buylist
    CORRELATION_COLORS.NEUTRAL, // neutral
    CORRELATION_COLORS.LOWEST // blacklist
  ];

  /*@ngInject*/
  constructor(
    @Inject('CockpitCustomlistResource')
    private cockpitCustomlistResource: CockpitCustomlistResource,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService
  ) {}

  ngOnInit() {
    return Promise.all([
      this.loadStrategies(),
      this.loadIssuers(),
      this.loadSankey()
    ]);
  }

  ngOnDestroy() {
    this.customSearchRequestsService.cancelAll();
  }

  loadStrategies() {
    const request = this.cockpitCustomlistResource.strategies();
    this.customSearchRequestsService.add(request);
    return request.$promise
      .then(response => {
        this.strategies = response;
        for (let i = 0; i < this.strategies.length; i++) {
          this.strategies[i].color = COLORS[i];
        }
      })
      .then(() => {
        this.customSearchRequestsService.remove(request);
      });
  }

  loadIssuers() {
    const request = this.cockpitCustomlistResource.issuers();
    this.customSearchRequestsService.add(request);
    return request.$promise
      .then(response => {
        this.issuers = response;
      })
      .then(() => {
        this.customSearchRequestsService.remove(request);
      });
  }

  loadSankey() {
    const request = this.cockpitCustomlistResource.sankey();
    this.customSearchRequestsService.add(request);
    return request.$promise
      .then(response => {
        this.sankeyData = angular.fromJson(angular.toJson(response));
      })
      .then(() => {
        this.customSearchRequestsService.remove(request);
      });
  }

  orderSankeyData(values, isSource: boolean) {
    if (isSource) {
      return simpleOrderKeys(values, isSource);
    }
    return ['buylist', 'neutral', 'blacklist'];
  }
}
