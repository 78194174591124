import { Component, Inject, OnInit, OnDestroy, ViewChild } from 'angular-ts-decorators';
import {
  Simulation, SimulationResource,
  SimulationWidget, SimulationWidgetResource
} from '@quantizr/front-model';
import { Subscription } from 'rxjs';
import merge = require('lodash/merge');

import './page.scss';
const template = require('./page.html.haml');

import { COLOR as BENCHMARK_COLOR } from '../../benchmarks';
import { dateAtMidnight, DatesSelector } from '../../components';
import { COLORS } from '../../components/colors';
import { ScatterChartService } from '../../components/scatter-chart/service';
import { TimeSeriesStockChartComponent, IStockChartRequestParams } from '../../time-series';

@Component({
  selector: 'page-simulations-result',
  template
})
export class SimulationsResultPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private chartTimeout: ng.IPromise<any>;

  @ViewChild('fixings')
  public fixingsChart: TimeSeriesStockChartComponent;
  @ViewChild('outperformances')
  public outperformancesChart: TimeSeriesStockChartComponent;

  public errors: any = null;
  public simulationId: number;
  public simulation: Simulation;
  public selectedShift: any = null;
  public originalShift: any = null;

  public datesSelector: DatesSelector;
  public dates: any = {};
  public fixingsSettings: any;
  public fixingsRequestParams: IStockChartRequestParams;
  public outPerformanceSettings: any;
  public outPerformanceRequestParams: IStockChartRequestParams;

  /*@ngInject*/
  constructor(
    @Inject('SimulationResource')
    private simulationResource: SimulationResource,
    @Inject('SimulationWidgetResource')
    private simulationWidgetResource: SimulationWidgetResource,
    @Inject('ScatterChartService')
    scatterChartService: ScatterChartService,
    private $location: ng.ILocationService,
    $routeParams: ng.route.IRouteParamsService,
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    private ngDialog: ng.dialog.IDialogService
  ) {
    this.simulationId = $routeParams.id;

    const ctrl = this;

    this.fixingsSettings = {
      plots: [{
        colors: {},
        yScale: {
          comparisonMode: 'none',
          baseHundred: false
        },
        yAxis: { labels: {} },
        line: {},
        legend: {
          enabled: false
        }
      }],
      tooltip: {
        format() {
          return ctrl.formatFixingsTooltip(this.value, this.seriesName, this.low, this.high);
        }
      },
      messageLoading: this.$translate.instant('views.loading'),
      messageEmpty: this.$translate.instant('views.empty')
    };

    this.outPerformanceSettings = {
      plots: [{
        yScale: {
          comparisonMode: 'value',
          baseHundred: false,
          stackMode: null
        },
        yAxis: { labels: {} },
        area: {},
        legend: { enabled: false }
      }],
      tooltip: {
        enabled: true
      }
    };

    this.datesSelector = new DatesSelector();

    this.subscriptions.push(scatterChartService.pointSelected().subscribe(point => {
      this.selectShift(point);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnInit() {
    return this.simulationResource.get({
      id: this.simulationId
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  loadSuccess(response) {
    this.simulation = response;

    if (this.simulation.version_attributes.start_date) {
      this.dates.min = dateAtMidnight(
        this.simulation.version_attributes.start_date
      );
    }

    if (this.simulation.version_attributes.end_date) {
      this.dates.max = dateAtMidnight(
        this.simulation.version_attributes.end_date
      );
    }

    this.$timeout(() => {
      this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
    });

    if (this.simulation.shifts) {
      this.simulation.shifts.forEach((currentShift: any) => {
        const keys = Object.keys(currentShift.input_values);

        if (keys.length === 0 && !this.originalShift) {
          currentShift.fill = COLORS[0];
          currentShift.color = COLORS[0];

          this.fixingsSettings.plots[0].colors[currentShift.id] = { null: currentShift.color };
          this.originalShift = currentShift;
        }
        else {
          currentShift.color = BENCHMARK_COLOR;
          this.fixingsSettings.plots[0].colors[currentShift.id] = {
            null: BENCHMARK_COLOR
          };

          if (!this.selectedShift) {
            this.selectedShift = currentShift;
          }
        }
      });
    }

    if (this.originalShift) {
      this.fixingsRequestParams = {
        qualifier: 'fixings',
        type: 'Shift',
        'ids[]': [this.originalShift.id, this.selectedShift.id],
        start_date: this.simulation.version_attributes.start_date,
        end_date: this.simulation.version_attributes.end_date
      };
      this.outPerformanceRequestParams = {
        qualifier: 'fixings',
        type: 'Shift',
        'ids[]': [this.originalShift.id, this.selectedShift.id],
        start_date: this.simulation.version_attributes.start_date,
        end_date: this.simulation.version_attributes.end_date,
        out_performance: true
      };

      this.refreshCharts();
    }
  }

  loadError(_error?: any) {
    this.$location.path('/simulations');
  }

  formatFixingsTooltip(value?: number, seriesName?: any, low?: number, high?: number): string {
    if (!!high && !!low) {
      return `Min: ${low.toFixed(2)}\nMax: ${high.toFixed(2)}`;
    }
    else if (!!value && !!seriesName && !!this.originalShift) {
      if (seriesName === this.originalShift.id) {
        return `${this.$translate.instant('simulations.result.view.original_shift')}: ${value.toFixed(2)}`;
      }
      else {
        return `${this.selectedShift.name}: ${value.toFixed(2)}`;
      }
    }
    return '';
  }

  selectShift(point) {
    const shiftId = point.id;
    this.selectedShift = (this.simulation.shifts || []).find(shift => shift.id === shiftId);

    if (!this.selectedShift || this.fixingsRequestParams['ids[]'][0] === shiftId) {
      return;
    }

    this.fixingsRequestParams['ids[]'] = [this.fixingsRequestParams['ids[]'][0], shiftId];
    this.outPerformanceRequestParams['ids[]'] = this.fixingsRequestParams['ids[]'];

    this.goToAnchor();

    this.refreshCharts();
  }

  refreshCharts() {
    this.chartTimeout = this.$timeout(() => {
      // @note: special case for angularjs to refresh ViewChild
      (this as any)._updateViewChildren();
      this.fixingsChart.requestParams = this.fixingsRequestParams;
      this.fixingsChart.refresh();
      this.outperformancesChart.requestParams = this.outPerformanceRequestParams;
      this.outperformancesChart.refresh();
    });
  }

  goToAnchor() {
    const perf = $('.performances');

    $('#content').animate({
      scrollTop: perf.offset()!.top
    }, 2000);
  }

  // === Widgets

  addWidget() {
    return this.editWidget({} as any, -1);
  }

  editWidget(widget: SimulationWidget, index: number = -1) {
    return this.ngDialog.open({
      template: '<page-simulations-widgets-edit></page-simulations-widgets-edit>',
      plain: true,
      className: 'ngdialog-theme-default ngdialog-theme-large',
      data: {
        widget: merge({
          simulation_id: this.simulation.id
        }, widget),
        simulation: this.simulation
      },
      closeByEscape: false,
      closeByNavigation: true,
      closeByDocument: false
    }).closePromise.then(data => {
      if (data.value && typeof data.value.widget !== 'undefined') {
        this.editWidgetSuccess(index, data.value.widget);
      }
    });
  }

  editWidgetSuccess(index: number, widget: SimulationWidget) {
    if (index === -1) {
      this.simulation.widgets!.push(widget);
    }
    else {
      this.simulation.widgets!.splice(index, 1, widget);
    }
  }

  deleteWidget(widget: SimulationWidget, index) {
    return this.ngDialog.openConfirm({
      template: '<page-simulations-widgets-delete-confirm></page-simulations-widgets-delete-confirm>',
      plain: true
    })
    .then(() => {
      this.deleteWidgetConfirmed(widget, index);
    });
  }

  deleteWidgetConfirmed(widget: SimulationWidget, index: number = -1) {
    this.simulationWidgetResource.remove(widget, () => {
      this.deleteWidgetSuccess(index);
    });
  }

  deleteWidgetSuccess(index: number) {
    this.simulation.widgets!.splice(index, 1);
  }
}
