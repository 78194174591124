import { NgModule } from 'angular-ts-decorators';
import { VendorModule } from '../vendor';

import { JobModel, factory as JobModelFactory } from './model';
import { factory as JobPollerFactory } from './poller';

export const ACTIONS = {
  REFRESH_STATS: 'refresh',
  CREATE_STATS: 'create'
};

export {
  JobModel
};

@NgModule({
  id: 'Jobs',
  imports: [
    VendorModule
  ],
  providers: [{
    provide: 'JobsPoller',
    useFactory: JobPollerFactory,
    deps: ['$timeout', '$injector']
  }, {
    provide: 'JobsModel',
    useFactory: JobModelFactory,
    deps: ['JobsPoller', '$translate', 'toaster']
  }]
})
export class JobsModule {}

export default (JobsModule as NgModule).module!.name;
