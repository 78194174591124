// force loading jQuery before angular
import 'jquery';

import { platformBrowserDynamic } from 'angular-ts-decorators';
import AppModule from './main';

// TODO: enable strictDi in un-minified version
platformBrowserDynamic().bootstrapModule(AppModule, {
  strictDi: false
});
