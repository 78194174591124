import { Injectable } from 'angular-ts-decorators';
import { Subject } from 'rxjs';

@Injectable('BaseChartService')
export class BaseChartService {
  private _$refreshed: Subject<string> = new Subject();

  public refreshed(id?: string) {
    this._$refreshed.next(id);
  }

  public $refreshed() {
    return this._$refreshed.asObservable();
  }
}
