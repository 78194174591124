import { AnalyticsService } from './service';

export default function(
  $rootScope: ng.IRootScopeService,
  analyticsService: AnalyticsService
) {
  $rootScope.$on('$routeChangeSuccess', () => {
    analyticsService.visit();
  });
}
