import { Component, Input, OnInit } from 'angular-ts-decorators';
import {
  CockpitProfile,
  CockpitPortfolio,
  CockpitProfileStrategy
} from '@quantizr/front-model';

const template = require('./component.html.haml');

import { IStrategiesCorrelationMatrixSettings } from '../../../strategies/correlation-matrix/component';

@Component({
  selector: 'cockpits-profiles-strategies-correlation-matrix',
  template
})
export class CockpitsProfilesStrategiesCorrelationMartrixComponent implements OnInit {
  @Input()
  public profile: CockpitProfile;
  @Input()
  public strategies?: CockpitProfileStrategy[] =   [];

  public matrixSettings: IStrategiesCorrelationMatrixSettings = {};
  public selectedPortfolio: CockpitPortfolio|null = null;

  ngOnInit() {
    this.initGroups();
  }

  initGroups() {
    if (this.profile) {
      let position: number = 0;
      if (this.profile && this.profile.portfolios) {
        this.selectedPortfolio = this.profile.portfolios[0] || null;
      }

      (this.profile.grouped_keywords || []).forEach((group: any) => {
        position = this._initGroup(group, group.color, position);
      });
    }
  }

  private _initGroup(group: any, color: string, position: number) {
    let newPos = position + 1;

    this.matrixSettings[group.id] = {color, position};

    (group.children || []).forEach(child => {
      newPos = this._initGroup(child, color, newPos);
    });

    (group.strategies || []).forEach(child => {
      newPos = this._initGroup(child, color, newPos);
    });
    return newPos;
  }
}
