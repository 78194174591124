import { Component, Input, AfterViewInit } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

import { VersionsEditAbstractComponent } from '../abstract-component';

@Component({
  selector: 'versions-edit-calculation',
  template
})
export class VersionsEditCalculationComponent extends VersionsEditAbstractComponent implements AfterViewInit {
  @Input()
  public forms: any = {};
  @Input()
  public subpanel: string;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.subpanel = this.subpanel || 'weights';
  }

  toggleSubpanel(subpanel: string) {
    this.subpanel = subpanel;
  }
}
