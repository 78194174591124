import { Component, Inject, Input, OnDestroy, ViewChild, SimpleChanges, OnChanges } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy, StrategyBenchmark, LinkResource } from '@quantizr/front-model';
import merge = require('lodash/merge');

const template = require('./component.html.haml');

import { dateAtMidnight, DatesSelector } from '../../../components';
import { CustomSearchRequestsService } from '../../../custom-search';
import { TimeSeriesStockChartComponent } from '../../../time-series';
import { StrategiesBenchmarksService } from '../../benchmarks/service';
import { StrategiesViewsService } from '../../views/service';

let components = 0;

@Component({
  selector: 'strategies-links-outperformances',
  template
})
export class StrategiesLinksOutperformancesComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private namespace: string = `strategies-links-outperformances-${++components}`;
  private chartTimeout: ng.IPromise<any>;
  private benchmark: StrategyBenchmark|null = null;
  private dataset: any[];

  @ViewChild(TimeSeriesStockChartComponent)
  public chart: TimeSeriesStockChartComponent;

  @Input()
  public datesSelector: DatesSelector;
  @Input('=?')
  public scroller: any;
  @Input('=?')
  chartSettings: any;

  public strategy: Strategy;
  public settings: any = {};
  public dates: any = {};

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    strategiesBenchmarksService: StrategiesBenchmarksService,
    @Inject('LinkResource')
    private linkResource: LinkResource,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    private $timeout: ng.ITimeoutService
  ) {
    this.settings = {
      plots: [{
        yScale: {
          comparisonMode: 'value',
          baseHundred: false,
          stackMode: null
        },
        yAxis: { labels: {} },
        area: {},
        legend: { enabled: false },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {}
    };

    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.onStrategyChanged();
      }
    }));

    this.subscriptions.push(strategiesBenchmarksService.$selected().subscribe(benchmark => {
      this.benchmark = benchmark;
      this.onBenchmarkSelected();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.customSearchRequestsService.remove(undefined, this.namespace);
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('chartSettings' in changes) {
      const settings = changes.chartSettings.currentValue || {};
      merge(this.settings, settings);
    }
  }

  onStrategyChanged() {
    if (this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }

    if (this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    if (this.strategy.live_date) {
      this.settings.plots[0].annotations[0].verticalLine = {
        xAnchor: this.strategy.live_date
      };
    }

    if (this.benchmark !== null) {
      this.onBenchmarkSelected();
    }
  }

  async onBenchmarkSelected() {
    if (!this.strategy) {
      return;
    }

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    if (this.benchmark) {
      const request = this.benchmarkRequest(this.benchmark);

      this.customSearchRequestsService.add(request, this.namespace);

      try {
        const response: any = await request.$promise;
        this.dataset = response.dataset;
        this.refreshCharts();
      }
      catch (err) {}
      finally {
        this.customSearchRequestsService.remove(request, this.namespace);
      }
    }
    else {
      this.dataset = [];
      this.refreshCharts();
    }
  }

  benchmarkRequest(benchmark: StrategyBenchmark) {
    return this.linkResource.queryBy({
      strategy_from_id: this.strategy.id,
      strategy_to_id: benchmark.id,
      min_date: this.strategy.start_date,
      max_date: this.strategy.end_date,
    });
  }

  refreshCharts() {
    this.chartTimeout = this.$timeout(() => {
      this.chart.dataset = this.dataset;
      this.chart.refresh();
    });
  }
}
