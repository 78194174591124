import { ReplaySubject } from 'rxjs';
import merge = require('lodash/merge');

import { ITableSettingsProperty, ITableSettingsPropertyDisplay } from '../table-settings-property/model';

export const tableSettingsStorageKey = '_q_tbs';

export const getConfigFromStorage = (): ITableSettingsData => {
  try {
    return JSON.parse(`${localStorage.getItem(tableSettingsStorageKey)}`) || {};
  }
  catch (err) {
    return {};
  }
};

export const setConfigInStorage = (data: ITableSettingsData) => {
  localStorage.setItem(tableSettingsStorageKey, JSON.stringify(data));
};

export interface ITableSettingsData {
  [key: string]: ITableSettingsProperty[];
}

export interface ITableSettingsProperties {
  [key: string]: {
    default: ITableSettingsProperty;
    property: ITableSettingsPropertyDisplay;
  };
}

/**
 * @internal
 */
export class TableSettingsController {
  private registeredProperties: ITableSettingsProperties = {};
  private _properties: ITableSettingsProperty[]|undefined = undefined;
  private _$properties: ReplaySubject<ITableSettingsProperty[]> = new ReplaySubject(1);

  constructor(private key: string) {}

  load() {
    const data = getConfigFromStorage();
    this._properties = data[this.key] || [];

    // add new properties
    this.properties.forEach(property => {
      const prop = this._properties!.find(val => val.key === property.key);
      if (!prop) {
        this._properties!.push({
          active: property.active,
          key: property.key,
          group: property.group
        });
      }
    });

    // update list in view
    for (let i = this._properties!.length - 1; i >= 0; i--) {
      const property = this._properties![i];
      if (property.key in this.registeredProperties) {
        this.registeredProperties[property.key].property.active = property.active;
      }
      else {
        // remove missing properties
        this._properties!.splice(i, 1);
      }
    }

    this.save();
  }

  save() {
    const data = getConfigFromStorage();
    data[this.key] = this._properties || [];
    setConfigInStorage(data);
    this._$properties.next(this._properties);
  }

  get properties() {
    return Object.keys(this.registeredProperties).map(key => {
      return this.registeredProperties[key].property;
    });
  }

  $properties() {
    return this._$properties.asObservable();
  }

  registerProperty(property: ITableSettingsPropertyDisplay) {
    if (!(property.key in this.registeredProperties)) {
      this.registeredProperties[property.key] = {
        default: merge({}, property),
        property: merge({}, property)
      };
    }
  }

  unregisterProperty(key: string) {
    delete this.registeredProperties[key];
  }

  resetProperties() {
    this._properties = Object.keys(this.registeredProperties).map(key => {
      const property = this.registeredProperties[key].default;
      this.registeredProperties[key].property.active = property.active;

      return merge({}, {
        active: property.active,
        key: property.key,
        group: property.group
      });
    });
  }

  toggleProperty(property: ITableSettingsProperty) {
    const prop = (this._properties || []).find(val => val.key === property.key);
    if (prop) {
      prop.active = !prop.active;
    }
  }
}
