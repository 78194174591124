import { Component, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import {
  StrategyResource, IStrategy, Strategy,
  StrategyVersionAttribute
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { VersionsEditAbstractComponent } from '../abstract-component';
import { LOADING_STATES, CustomSearchRequestsService } from '../../../custom-search';
import { VersionsViewsService } from '../../views/service';

let components = 0;

@Component({
  selector: 'versions-edit-strategies',
  template
})
export class VersionsEditStrategiesComponent extends VersionsEditAbstractComponent implements OnInit, OnDestroy {
  private namespace = `versions-edit-strategies-${++components}`;
  private criteria: any = {};

  public name = '';
  public results: Strategy[] = [];
  public loadingState = LOADING_STATES.LOADING;
  public page: number = 0;
  public PAGINATION_SIZE = 10;
  public searchVolumes: any = {};
  public fieldsConfig: any;

  /*@ngInject*/
  constructor(
    @Inject('VersionsViewsService')
    versionsViewsService: VersionsViewsService,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    $element: ng.IAugmentedJQuery
  ) {
    super(versionsViewsService, $element);

    this.criteria = {
      hide_protected: true,
      text: undefined,
      page: undefined,
      per_page: undefined
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customSearchRequestsService.cancelAll();
  }

  ngOnInit() {
    this.search();
  }

  isSelected(strategy: Strategy) {
    const strategyVersion = this.findStrategyVersionBy(strategy);

    if (strategyVersion !== null) {
      return (strategyVersion as any)._destroy !== 1;
    }
    return false;
  }

  select(strategy?: Strategy) {
    if (!strategy || !strategy.id) {
      return;
    }

    const strategyVersion = this.findStrategyVersionBy(strategy);

    if (strategyVersion !== null) {
      delete (strategyVersion as any)._destroy;
    }
    else {
      const newStrategyVersion: StrategyVersionAttribute = {
        strategy,
        strategy_id: strategy.id,
        weight: 0,
        cap: this.version.cap !== null && this.version.cap != undefined ? undefined : 100,
        floor: this.version.floor !== null && this.version.floor != undefined ? undefined : 0
      };
      this.version.strategy_versions_attributes!.push(newStrategyVersion);
    }
  }

  unselect(attribute: StrategyVersionAttribute, index: number) {
    if (attribute.id) {
      (attribute as any)._destroy = 1;
    }
    else {
      this.version.strategy_versions_attributes!.splice(index, 1);
    }
  }

  search() {
    this.criteria.text = this.name;
    this.criteria.per_page = this.PAGINATION_SIZE;

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    const request = this.strategyResource.search(this.criteria);
    this.loadingState = LOADING_STATES.LOADING;

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise.then((response) => {
      this.searchVolumes = response.volumes;
      this.query();
    }, () => {
      this.loadingState = LOADING_STATES.IDLE;
    })
    .then(() => {
      this.customSearchRequestsService.remove(request);
    });
  }

  query() {
    this.criteria.text = this.name;
    this.criteria.page = this.page;
    this.criteria.per_page = this.PAGINATION_SIZE;

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    this.loadingState = LOADING_STATES.LOADING;
    const request = this.strategyResource.query(this.criteria);

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise
      .catch(() => [])
      .then((strategies: IStrategy[]) => {
        this.results = strategies.filter(version => version.id !== this.version.strategy_id);
        this.loadingState = LOADING_STATES.IDLE;
        this.customSearchRequestsService.remove(request);
      });
  }

  private findStrategyVersionBy(strategy: Strategy) {
    if (!this.version) {
      return null;
    }

    const strategyVersions = this.version.strategy_versions_attributes!;

    for (const strategyVersion of strategyVersions) {
      if (strategyVersion.strategy) {
        if (strategyVersion.strategy.id === strategy.id) {
          return strategyVersion;
        }
      }
      else if (strategyVersion.id === strategy.id) {
        return strategyVersion;
      }
    }

    return null;
  }
}
