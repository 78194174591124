import { Input, Inject, OnDestroy, OnChanges, SimpleChanges } from 'angular-ts-decorators';

import { BaseChartService } from './service';

export const tooltipStyle = {
  background: {
    fill: '#4e86ec 0.8',
    stroke: '#676767'
  },
  padding: '15px'
};

export abstract class BaseChartComponent<T extends anychart.core.Chart> implements OnDestroy, OnChanges {
  private loadingTimeout: any;

  protected container?: anychart.graphics.vector.Stage;

  @Input('@')
  protected containerId: string;
  @Input()
  public settings: any;
  @Input('<?')
  public dataset: any[] = [];

  public chart: T;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    private baseChartService: BaseChartService
  ) {}

  ngOnDestroy() {
    this.removeChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('dataset' in changes) {
      const dataset = changes.dataset.currentValue;
      if (dataset) {
        this.refresh();
      }
    }

    if ('containerId' in changes) {
      this.drawChart().catch(() => {});
    }
  }

  /**
   * Draw the chart. To be called if new chart is created
   */
  drawChart() {
    return new Promise<void>((resolve, reject) => {
      if (this.containerId) {
        this.loadingTimeout = setTimeout(() => {
          this.setContainerId(this.containerId);
          resolve();
        }, 100);
      }
      else {
        reject();
      }
    });
  }

  /**
   * Set the container of the chart
   * @param containerId The container id
   */
  setContainerId(containerId: string) {
    if (!this.chart || this.container) {
      return;
    }

    this.container = anychart.graphics.create(containerId);
    this.chart.container(this.container);
    this.chart.draw();
  }

  /**
   * Notify refresh of the chart is finished.
   */
  onRefreshedDone() {
    this.baseChartService.refreshed(this.containerId);
  }

  /**
   * Call before creating the chart again.
   */
  removeChart() {
    if (this.chart) {
      clearTimeout(this.loadingTimeout);
      this.chart.removeAllListeners();
      (this.chart as any).dispose();
    }
    if (this.container) {
      this.container.dispose();
      this.container = undefined;
    }
  }

  /**
   * Refresh the chart data.
   */
  abstract refresh(): void;

  /**
   * Init the chart
   */
  abstract initChart(): void;
}
