import { Component, Input, OnChanges, SimpleChanges, Inject, OnInit } from 'angular-ts-decorators';
import {
  CockpitProfile, CockpitProfileStrategy,
  CockpitAxisResource, CockpitAxis,
  CockpitProfileAxisResource, CockpitProfileAxis,
  TimeSerieWindow,
  StrategyStrategyTimeSerie
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { strategyValues, groupValues, windowDateFormat } from '../show/page';
import {
  columnChartDataset, IColumnChartColumnData, IColumnChartLineData
} from '../../../components/column-chart/component';

@Component({
  selector: 'cockpits-perf-contribution-chart',
  template
})
export class CockpitsPerfContributionChartComponent implements OnInit, OnChanges {
  private profileAxes: CockpitProfileAxis[] = [];

  @Input()
  public profile: CockpitProfile;
  @Input()
  public timeSeries: StrategyStrategyTimeSerie[] = [];
  @Input()
  public window: TimeSerieWindow;

  public axes: CockpitAxis[]|null = null;
  public axis: CockpitAxis|null = null;

  public chartDataset: columnChartDataset[] = [];
  public chartSettings = {
    chart: {
      barGroupsPadding: 0,
      legend: {
        align: 'left',
        enabled: true,
        margin: 5
      },
      tooltip: {
        format() {
          return `${this.seriesName}: ${this.value.toFixed(2)}%`;
        }
      },
      xAxis: {
        enabled: true,
        labels: {
          format() {}
        }
      },
      yMinorGrid: {
        enabled: true
      },
      yScale: {
        stackMode: 'value'
      }
    }
  };

  /*@ngInject*/
  constructor(
    @Inject('CockpitAxisResource')
    private cockpitAxisResource: CockpitAxisResource,
    @Inject('CockpitProfileAxisResource')
    private cockpitProfileAxisResource: CockpitProfileAxisResource,
    private $translate: ng.translate.ITranslateService,
    $filter: ng.IFilterService
  ) {
    const ctrl = this;
    this.chartSettings.chart.xAxis.labels.format = function() {
      return $filter('date')(this.value, windowDateFormat(ctrl.window));
    };
  }

  ngOnInit() {
    return this.loadAxes().then(() => {
      this.updateAxis(this.axes![0]);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes || 'timeSeries' in changes) {
      this.updateDatasets();
    }
  }

  loadAxes() {
    return this.cockpitAxisResource.queryBy({}).$promise.then(response => this.axes = response.axes);
  }

  updateAxis(axis: CockpitAxis) {
    this.axis = axis;
    return this.cockpitProfileAxisResource.query({
      profile_id: this.profile.id,
      id: axis.id
    }).$promise.then(response => {
      this.profileAxes = response;
      this.updateDatasets();
    });
  }

  groupStrategies(strategies: CockpitProfileStrategy[] = []) {
    const values = strategies.map(strategy => {
      return {
        values: strategyValues(this.timeSeries, strategy)
      };
    });

    return groupValues(values);
  }

  updateDatasets() {
    if (!this.profileAxes.length) {
      return;
    }

    const dates = this.timeSeries.length ? this.timeSeries[0].data.map(val => val[0]) : null;
    let minDate: Date|null = null;
    this.chartDataset = [];

    this.profileAxes.forEach(axis => {
      const values = this.groupStrategies(axis.strategies);
      const dataset: IColumnChartColumnData[] = [];
      if (dates) {
        for (let i = 0; i < dates.length; i++) {
          const date = dates[i];
          if (minDate) {
            minDate = new Date(Math.min(minDate.getTime(), new Date(date).getTime()));
          }
          else {
            minDate = new Date(date);
          }
          dataset.push({
            x: date,
            value: values[i] * 100
          });
        }
      }
      this.chartDataset.push({
        column: dataset,
        name: axis.keyword.name
      });
    });

    // add sum
    if (dates) {
      const sum = groupValues(this.chartDataset, 'column');
      const dataset: IColumnChartLineData[] = [];
      for (let i = 0; i < dates.length; i++) {
        dataset.push({
          x: dates[i],
          value: sum[i]
        });
      }
      this.chartDataset.push({
        line: dataset,
        name: this.$translate.instant('cockpits.perfContribution.chart.sum'),
        stroke: {
          color: '#e50400',
          thickness: 3
        },
        legend: {
          type: 'circle'
        }
      });
    }
  }
}
