export interface IView {
  iconClass: string;
  priority: number;
  enabled: boolean;
}

export const VIEWS: {
  [key: string]: IView
} = {
  performances: {
    iconClass: 'fas fa-chart-line',
    priority: 1,
    enabled: true
  },
  holdings: {
    iconClass: 'icon-holdings',
    priority: 2,
    enabled: true
  },
  esg: {
    iconClass: 'fas fa-star',
    priority: 3,
    enabled: true
  },
  weights: {
    iconClass: 'fas fa-balance-scale',
    priority: 4,
    enabled: true
  },
  strategies: {
    iconClass: 'fas fa-chart-pie',
    priority: 5,
    enabled: true
  },
  benchmark: {
    iconClass: 'icon-chart-line',
    priority: 6,
    enabled: true
  },
  factors: {
    iconClass: 'icon-factors',
    priority: 7,
    enabled: true
  },
  about: {
    iconClass: 'fas fa-info',
    priority: 8,
    enabled: true
  },
};
