import { Component } from 'angular-ts-decorators';

const template = require('./page.html.haml');

import { DialogController } from '../../../../../dialog/controller';

@Component({
  selector: 'page-cockpits-profiles-portfolios-delete-confirm',
  template
})
export class CockpitsProfilesPortfoliosDeleteConfirmPage extends DialogController {}
