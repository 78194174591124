import { Component, Inject, Input, OnInit, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { StrategyVersionCorrelationResource, Strategy, StrategyVersionCorrelation } from '@quantizr/front-model';
import { variables as timeSeries } from '@quantizr/front-model/dist/time-series/variables';
const Rainbow = require('rainbowvis.js');
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { hexToRgbValues } from '../../colors/hex-to-rgb';
import { brighterThan } from '../../colors/brightness';

export const CORRELATION_COLORS = {
  LOWEST: '#e50400', // red
  NEUTRAL: '#ffffff',
  HIGHEST: '#4058a4' // blue
};

const rainbow = new Rainbow();
rainbow.setSpectrum(
  CORRELATION_COLORS.LOWEST,
  CORRELATION_COLORS.NEUTRAL,
  CORRELATION_COLORS.HIGHEST
);
rainbow.setNumberRange(-1, 1);

export const bgColor = (value: number, sameValue: boolean = false) => {
  if (!value || sameValue) {
    return CORRELATION_COLORS.NEUTRAL;
  }

  return `#${rainbow.colourAt(value)}`;
};

// breakpoint between black and white text color.
// increase if some light background still get white color
// decrease if some dark background still get dark color
const brightnessThreshold = 180;

export const color = (hexColor: string) => {
  const rgb = hexToRgbValues(hexColor);
  if (rgb && !brighterThan(rgb.r, rgb.g, rgb.b, brightnessThreshold)) {
    return '#ffffff';
  }
  return '#676767'; // body color
};

export interface IStrategiesCorrelationMatrixSettings {
  [id: number]: {
    color: string;
    position: number;
  };
}

@Component({
  selector: 'strategies-correlation-matrix',
  template
})
export class StrategiesCorrelationMatrixComponent implements OnInit, OnChanges {
  @Input()
  settings: IStrategiesCorrelationMatrixSettings = {};
  @Input()
  public hideFullButton = false;
  @Input()
  strategy: Strategy;
  @Input()
  disablePeriods = false;
  @Input()
  showPeriodStart = true;
  @Input()
  matrices: StrategyVersionCorrelation[] = [];
  @Input()
  searchParams?: any = {};

  public PERIODS = timeSeries.Correlations.PERIODS.slice();
  public period = '3_months';

  public bgColor = bgColor;
  public color = color;

  /*@ngInject*/
  constructor(
    @Inject('StrategyVersionCorrelationResource')
    private strategyVersionCorrelationResource: StrategyVersionCorrelationResource,
    private ngDialog: ng.dialog.IDialogService,
    private $rootScope: ng.IRootScopeService
  ) {
    // we don't want to display live_date here
    this.PERIODS.splice(timeSeries.Correlations.PERIODS.indexOf('live_date'), 1);
  }

  ngOnInit() {
    if (this.strategy && this.matrices.length === 0) {
      this.refreshMatrix();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('searchParams' in changes) {
      if (!(changes.searchParams.isFirstChange())) {
        this.refreshMatrix();
      }
    }
    if ('strategy' in changes) {
      if (!(changes.strategy.isFirstChange())) {
        this.refreshMatrix();
      }
    }
  }

  refreshMatrix() {
    const params = merge({
      strategy_id: this.strategy.id
    }, this.searchParams || {});

    return this.strategyVersionCorrelationResource.query(params).$promise.then(values => {
      this.refreshMatrixSuccess(values);
    });
  }

  refreshMatrixSuccess(versions: StrategyVersionCorrelation[]) {
    this.matrices = versions.sort((v1, v2) => {
      const v1Settings = this.settings[v1.strategy.id];
      const v2Settings = this.settings[v2.strategy.id];
      if (v1Settings && v2Settings) {
        return v1Settings.position - v2Settings.position;
      }
      else if (!v1Settings) {
        return 1;
      }
      else if (!v2Settings) {
        return -1;
      }
      return 0;
    });
  }

  sameMatrix(matrix1?: StrategyVersionCorrelation, matrix2?: StrategyVersionCorrelation) {
    if (matrix1 && matrix2) {
      return matrix1.strategy.id === matrix2.strategy.id;
    }

    return false;
  }

  hasNullValues() {
    for (const matrix of this.matrices) {
      for (const key in matrix.correlations) {
        if (matrix.correlations[key][this.period] === null) {
          return true;
        }
      }
    }

    return false;
  }

  displayFull() {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.settings = this.settings;
    newScope.strategy = this.strategy;
    newScope.matrices = this.matrices;
    this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <strategies-correlation-matrix
          hide-full-button="true" strategy="strategy" settings="settings" matrices="matrices"
        ></strategies-correlation-matrix>
      `,
      plain: true,
      scope: newScope
    });
  }
}
