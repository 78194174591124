import { Injectable } from 'angular-ts-decorators';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable('TableSettingsService')
export class TableSettingsService {
  private _$enabled: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor() {
    // enabled by default
    this.enable();
  }

  /**
   * Enable the settings controllers to show/hide the properties
   */
  public enable() {
    this._$enabled.next(true);
  }

  /**
   * Disable the settings controllers to NOT show/hide the properties.
   * Make sure you re-enable when not needed anymore
   */
  public disable() {
    this._$enabled.next(false);
  }

  public $enabled() {
    return this._$enabled.asObservable();
  }

  public enabled() {
    return this.$enabled().pipe(take(1)).toPromise();
  }
}
