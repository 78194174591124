import { Component, Inject, Input, Output, OnDestroy } from 'angular-ts-decorators';
import { Strategy, StrategyResource, StrategyBenchmark } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './component.scss';
const template = require('./component.html.haml');

let components: number = 1;

import { COLOR as BENCHMARK_COLOR } from '../../../benchmarks';
import { COLORS } from '../../../components/colors';
import { CustomSearchRequestsService } from '../../../custom-search';
import { StrategiesViewsService } from '../../views/service';
import { StrategiesBenchmarksService } from '../../benchmarks/service';

export interface IStrategyDisplay extends Strategy {
  color?: string;
}

@Component({
  selector: 'strategies-benchmarks-comparator',
  template
})
export class StrategiesBenchmarksComparatorComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private namespace: string = `StrategiesBenchmarksComparatorComponent${++components}`;
  private benchmark: StrategyBenchmark|null = null;

  @Input()
  performances: string[];
  @Input()
  performance: string;

  @Output()
  onRefresh: () => void;

  public strategies: IStrategyDisplay[];
  public strategy: Strategy;

  /*@ngInject*/
  constructor(
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    strategiesBenchmarksService: StrategiesBenchmarksService,
    private $timeout: ng.ITimeoutService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.reloadStrategies();
      }
    }));

    this.subscriptions.push(strategiesBenchmarksService.$selected().subscribe(benchmark => {
      this.benchmark = benchmark;
      this.reloadStrategies();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.customSearchRequestsService.remove(undefined, this.namespace);
  }

  reloadStrategies() {
    this.strategies = [];

    if (!this.strategy || !this.benchmark) {
      return;
    }

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    const request = this.strategyResource.comparator({
      'ids[]': [
        this.strategy.id,
        this.benchmark.id
      ]
    });

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise
      .then(strategies => {
        this.strategies = strategies;

        this.strategies.forEach(strategy => {
          if (strategy.id === this.strategy.id) {
            strategy.color = COLORS[0];
          }
          else {
            strategy.color = BENCHMARK_COLOR;
          }
        });

        this.$timeout(() => {
          this.onRefresh();
        });
      })
      .catch(() => {})
      .then(() => {
        this.customSearchRequestsService.remove(request, this.namespace);
      });
  }
}
