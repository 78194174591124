import { Component, Input, SimpleChanges, Inject } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
import 'anychart/dist/js/anychart-radar.min';

import './component.scss';
const template = require('./component.html.haml');

import { IStrategyFactorDisplay } from '../page';
import { BaseChartComponent, BaseChartService } from '../../../../components';

@Component({
  selector: 'strategies-show-factors-betas',
  template
})
export class StrategiesShowFactorsBetasComponent extends BaseChartComponent<anychart.charts.Radar> {
  @Input()
  public strategy: Strategy;
  @Input()
  public factorsAnalysis: IStrategyFactorDisplay[];

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService,
    private $translate: ng.translate.ITranslateService
  ) {
    super(baseChartService);
    this.initChart();
  }

  initChart() {
    this.chart = anychart.radar();

    this.chart.credits().enabled(false);
    this.chart.xAxis().labels().fontSize(14);
    this.chart.tooltip().enabled(false);
    this.chart.labels().enabled(false);

    this.setContainerId('radar-container');
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('factorsAnalysis' in changes && changes.factorsAnalysis.currentValue) {
      this.refresh();
    }
  }

  refresh() {
    const radarData: Array<{
      x: string;
      value: number;
    }> = [];

    this.factorsAnalysis.forEach(factorAnalysis => {
      const name = this.$translate.instant(
        `strategies.factor_analysis.factors.${factorAnalysis.factor_type}`
      );

      if (factorAnalysis.factor_type !== 'alpha') {
        const x = `${name}\n (${factorAnalysis.analysis.beta.toFixed(2)})`;

        radarData.push({
          x,
          value: Math.abs(factorAnalysis.analysis.beta)
        });
      }
    });

    this.chart.area(radarData);

    this.onRefreshedDone();
  }
}
