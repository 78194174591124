import { Component } from 'angular-ts-decorators';

const template = require('./component.html.haml');

import { PanelsSidebarChild } from '../../panels';

@Component({
  selector: 'treemap-sidebar-filters',
  template
})
export class TreemapSidebarFiltersComponent extends PanelsSidebarChild {}
