import { Injectable, Inject } from 'angular-ts-decorators';
import { Subject } from 'rxjs';

import { VersionsViewsService } from '../../versions/views/service';

@Injectable('SimulationsViewsService')
export class SimulationsViewsService {
  private _$saving: Subject<{saving: boolean; error: any}|null> = new Subject();

  /*@ngInject*/
  constructor(
    @Inject('VersionsViewsService')
    private versionsViewsService: VersionsViewsService
  ) {}

  saving(saving: boolean, error?: any): void {
    this._$saving.next({
      saving,
      error
    });
  }

  $saving() {
    return this._$saving.asObservable();
  }

  clear(): void {
    this._$saving.next(null);
    this.versionsViewsService.clear();
  }
}
