const LocalStorageModule = require('angular-local-storage');
const ngRoute = require('angular-route');
const ngResource = require('angular-resource');
const ngSanitize = require('angular-sanitize');
const ngCookies = require('angular-cookies');
const ngFileSaver = require('angular-file-saver');
const ngFileUpload = require('ng-file-upload');
const duScroll = require('angular-scroll');
const ngThumbnails = require('angular-thumbnails');
import 'jquery-migrate';
import 'angular-route-segment';
import 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'angular-ui-sortable';
import 'AngularDevise';
import 'animate.css';
import '@jh3y/whirl/dist/whirl.css';

// --- anychart
const anychart = require('anychart/dist/js/anychart-core.min');
import 'anychart/dist/js/anychart-annotations.min';
import 'anychart/dist/js/anychart-ui.min';
import 'anychart/dist/css/anychart-ui.css';
import 'anychart/dist/fonts/css/anychart-font.css';

// --- bootstrap
import 'bootstrap';
const uiBootstrap = require('angular-ui-bootstrap');
import './bootstrap.scss';
import './bootstrap-angular.scss';
import './bootstrap-extras.scss';

// --- jquery-ui
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
// import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/themes/base/sortable.css';

// --- dialog
const ngDialog = require('ng-dialog');
import 'ng-dialog/css/ngDialog.css';
import 'ng-dialog/css/ngDialog-theme-default.css';
import './ngdialog.scss';

// --- cropper
import 'ui-cropper';
// unminified version throws error during webpack optimization
import 'ui-cropper/compile/minified/ui-cropper.css';

// --- select
const uiSelect = require('ui-select');
import 'ui-select/dist/select.css';
import './ui-select.scss';

// --- slider
import 'angularjs-slider';
import 'angularjs-slider/dist/rzslider.css';
import './rzslider.scss';

// --- chosen
import 'chosen-js';
import 'chosen-js/chosen.css';
import 'angular-chosen-localytics';
import 'angular-chosen-localytics/chosen-spinner.css';
import './chosen/chosen.scss';

// --- intl tel input
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import 'international-phone-number/releases/international-phone-number';
import 'intl-tel-input/build/css/intlTelInput.css';
import './intl-tel-input.scss';

// --- tags-input
import 'ng-tags-input/build/ng-tags-input';
import 'ng-tags-input/build/ng-tags-input.css';
import './tags-input.scss';

// --- toaster
const ngToaster = require('angularjs-toaster/index');
import 'angularjs-toaster/toaster.css';

// --- fonts
import './fontawesome.scss';
import './fonthello/fonthello.scss';
import './gotham/gotham.scss';

// --- recaptcha
import 'angularjs-google-recaptcha-v3/release/angularjs-google-recaptcha-v3';

// TODO: needed?
// disable mousewheel on a input number field when in focus
// (to prevent Cromium browsers change the value when scrolling)
$('form').on('focus', 'input[type=number]', () => {
  $(this).on('mousewheel.disableScroll', event => {
    event.preventDefault();
  });
});
$('form').on('blur', 'input[type=number]', () => {
  $(this).off('mousewheel.disableScroll');
});

import { NgModule } from 'angular-ts-decorators';

@NgModule({
  id: 'Vendor',
  imports: [
    LocalStorageModule,
    ngRoute,
    ngResource,
    ngSanitize,
    ngDialog,
    ngCookies,
    ngFileSaver,
    ngFileUpload,
    ngThumbnails,
    ngToaster,
    uiBootstrap,
    uiSelect,
    duScroll,
    'Devise',
    'ngTagsInput',
    'route-segment',
    'view-segment',
    'pascalprecht.translate',
    'localytics.directives',
    'ui.sortable',
    'uiCropper',
    'rzModule',
    'internationalPhoneNumber',
    'fayzaan.gRecaptcha.v3'
  ]
})
export class VendorModule {
  static run($templateCache: ng.ITemplateCacheService, gRecaptcha) {
    'ngInject';
    anychart.licenseKey('quantilia-7f653855-16e0d45c');
    gRecaptcha.initialize({ key: '6LfA88obAAAAAB-sC6WEi5REx5EHZ-5mDoQSmTPm' });

    $templateCache.put(
      'ui_bootstrap/pagination/pagination.html',
      require('./ui-bootstrap/pagination/pagination.html')
    );
  }
}

export default (VendorModule as NgModule).module!.name;
