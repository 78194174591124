import { Component } from 'angular-ts-decorators';
import { variables as versions } from '@quantizr/front-model/dist/versions/variables';

import './component.scss';
const template = require('./component.html.haml');

import { VersionsEditAbstractComponent } from '../abstract-component';

@Component({
  selector: 'versions-edit-weights',
  template
})
export class VersionsEditWeightsComponent extends VersionsEditAbstractComponent {
  public ALLOCATION_TYPES = versions.ALLOCATION_TYPES;
  public REBALANCING_TYPES = versions.REBALANCING_TYPES;
  public MIN_RETURN_TYPES = versions.MIN_RETURN_TYPES;

  getTotal(fieldName) {
    if (!this.version) {
      return 0;
    }

    if ((fieldName in this.version) && this.version[fieldName] !== null) {
      return this.version[fieldName] *
        this.version.strategy_versions_attributes!.length;
    }
    else {
      return this.version.strategy_versions_attributes!.reduce((previous, strategyVersion) => {
        if ((strategyVersion as any)._destroy !== 1) {
          return previous + strategyVersion[fieldName];
        }
        return previous;
      }, 0);
    }
  }

  showCapFloor() {
    const nbDynamicSelections = this.version.dynamic_selections_attributes!.reduce((nbDs, ds) => {
      return nbDs + ((ds as any)._destroy === 1 ? 0 : 1);
    }, 0);
    if (this.version.live_strategies_only || nbDynamicSelections > 0) {
      return false;
    }
    return (this.version.allocation_type === this.ALLOCATION_TYPES.INVERSE_VOLATILITY ||
            this.version.allocation_type === this.ALLOCATION_TYPES.MINIMUM_VOLATILITY);
  }

  toggleCapFloor(type: string, defaultValue: number) {
    if (this.version[type] !== null) {
      this.version.strategy_versions_attributes!.forEach(strategyVersion => {
        strategyVersion[type] = this.version[type];
      });
      this.version[type] = null;
    }
    else {
      this.version[type] = defaultValue;
      this.version.strategy_versions_attributes!.forEach(strategyVersion => {
        strategyVersion[type] = null;
      });
    }
  }

  autoStartDateChanged() {
    if (
      (!this.fieldsConfig || !this.fieldsConfig.start_date) &&
      this.version.auto_start_date === true
    ) {
      this.version.start_date = undefined;
    }
  }

  minReturnTypeChanged() {
    if (this.version.min_return_type !== this.MIN_RETURN_TYPES.MIN_EXPLICIT) {
      this.version.min_return_value = 0;
    }
  }
}
