import { Component, Input, SimpleChanges } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
import 'anychart/dist/js/anychart-cartesian.min';

import './component.scss';
const template = require('./component.html.haml');

import { IStrategyFactorDisplay } from '../page';
import { statisticToPercent } from '../../../../components';
import { columnChartDataset } from '../../../../components/column-chart/component';

@Component({
  selector: 'strategies-show-factors-performance',
  template
})
export class StrategiesShowFactorsPerformanceComponent {
  @Input()
  public strategy: Strategy;
  @Input()
  public factorsAnalysis: IStrategyFactorDisplay[];

  public chartDataset: columnChartDataset[] = [];
  public chartSettings = {
    chart: {
      labels: {
        enabled: true,
        position: 'center',
        anchor: 'center',
        fontColor: '#ffffff',
        format() {
          return statisticToPercent(this.value);
        }
      },
      tooltip: {
        title: false,
        separator: {
          enabled: false
        },
        format() {
          return `${this.seriesName}:${statisticToPercent(this.value)}`;
        }
      },
      xAxis: {
        enabled: false
      },
      yAxis: {
        labels: {
          format() {
            return statisticToPercent(this.value);
          }
        }
      },
      yMinorGrid: {
        enabled: false
      },
      yScale: {
        stackMode: 'value'
      }
    }
  };

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('factorsAnalysis' in changes && changes.factorsAnalysis.currentValue) {
      this.updateDataset();
    }
  }

  updateDataset() {
    this.chartDataset = this.factorsAnalysis.map(factorAnalysis => {
      const dataset = [{
        x: this.strategy.name || '',
        value: factorAnalysis.analysis.perf_attribution,
        fill: `${factorAnalysis.color} 0.8`,
        stroke: factorAnalysis.color
      }];
      const name = this.$translate.instant(
        `strategies.factor_analysis.factors.${factorAnalysis.factor_type}`
      );

      return {
        name,
        column: dataset
      };
    });
  }
}
