import { NgModule } from 'angular-ts-decorators';

import { VendorModule } from '../vendor';

import { BaseChartComponent } from './base-chart/component';
import { BaseChartService } from './base-chart/service';
import { ButtonStatesComponent } from './button-states/component';
import { DatepickerLocaldateDirective } from './datepicker-localdate/directive';
import { DatesSelectorComponent } from './dates-selector/component';
import { DatesSelectorButtonComponent } from './dates-selector/button/component';
import { DatesSelectorConfirmPage } from './dates-selector/confirm/page';
import { DatesSelector } from './dates-selector/model';
import { DatesSelectorService } from './dates-selector/service';
import { ColumnChartComponent } from './column-chart/component';
import { DropdownPersistDirective } from './dropdown-persist/directive';
import { FileUploaderComponent } from './file-uploader/component';
import { FlatdocComponent } from './flatdoc/component';
import { FocusOnDirective } from './focus-on/directive';
import { FormErrorsComponent } from './form-errors/component';
import { GaugeChartComponent } from './gauge-chart/component';
import { HistogramSliderComponent } from './histogram-slider/component';
import { HorizontalPagingComponent } from './horizontal-paging/component';
import { LoadingComponent } from './loading/component';
import { LocationStartsWithPipe } from './location-starts-with/filter';
import { MapChartComponent } from './map-chart/component';
import { PerfMonthToMonthComponent } from './perf-month-to-month/component';
import { PieChartComponent } from './pie-chart/component';
import { ProgressBarComponent } from './progress-bar/component';
import { SankeyComponent } from './sankey/component';
import { ScatterChartComponent } from './scatter-chart/component';
import { ScatterChartService } from './scatter-chart/service';
import { StartsWithPipe } from './starts-with/filter';
import { StatisticsPipe } from './statistics/filter';
import { SynchronizeScrollComponent } from './synchronize-scroll/directive';
import { TableGroupedComponent } from './table-grouped/component';
import { TableGroupedService } from './table-grouped/service';
import { TableSettingsComponent } from './table-settings/component';
import { TableSettingsService } from './table-settings/service';
import { TableSettingsPropertyDirective } from './table-settings-property/directive';

export * from './dates-selector/model';
export { statisticToPercent } from './statistics';
export { ITableSettingsGroup } from './table-settings/component';
export { TableSettingsController } from './table-settings/controller';
export {
  BaseChartComponent,
  BaseChartService,
  ColumnChartComponent,
  GaugeChartComponent,
  MapChartComponent,
  PieChartComponent,
  ScatterChartComponent, ScatterChartService,
  DatesSelector,
  DatesSelectorService,
  TableGroupedService,
  TableSettingsService
};

export const BUTTON_STATES = {
  IDLE: 'idle',
  PROGRESS: 'progress',
  DONE: 'done',
  ERROR: 'error'
};

export const ANIMATIONS_DURATION = 500;

@NgModule({
  id: 'Components',
  imports: [
    VendorModule
  ],
  declarations: [
    ButtonStatesComponent,
    DatepickerLocaldateDirective,
    DatesSelectorComponent,
    DatesSelectorButtonComponent,
    DatesSelectorConfirmPage,
    ColumnChartComponent,
    DropdownPersistDirective,
    FileUploaderComponent,
    FlatdocComponent,
    FocusOnDirective,
    FormErrorsComponent,
    GaugeChartComponent,
    HistogramSliderComponent,
    HorizontalPagingComponent,
    LoadingComponent,
    LocationStartsWithPipe,
    MapChartComponent,
    PerfMonthToMonthComponent,
    PieChartComponent,
    ProgressBarComponent,
    SankeyComponent,
    ScatterChartComponent,
    StartsWithPipe,
    StatisticsPipe,
    SynchronizeScrollComponent,
    TableGroupedComponent,
    TableSettingsComponent,
    TableSettingsPropertyDirective
  ],
  providers: [
    BaseChartService,
    DatesSelectorService,
    ScatterChartService,
    TableGroupedService,
    TableSettingsService
  ]
})
export class ComponentsModule {}

export default (ComponentsModule as NgModule).module!.name;
