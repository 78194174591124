import { ReplaySubject } from 'rxjs';

export const dateWithoutYears = (date: Date, years: number): Date => {
  let _date = date;
  if (date) {
    _date = new Date(date.getTime());
    _date.setFullYear(date.getFullYear() - years);
  }
  return _date;
};

export const dateWithoutMonths = (date: Date, months: number): Date => {
  let _date = date;
  if (date) {
    _date = new Date(date.getTime());
    _date.setMonth(date.getMonth() - months);
  }
  return _date;
};

export const firstDateOfYear = (date: Date): Date => {
  let _date = date;
  if (date) {
    _date = new Date(date.getTime());
    _date.setMonth(0);
    _date.setDate(1);
  }
  return _date;
};

export const dateAtMidnight = (date?: any): Date => {
  const _date = date ? new Date(date) : new Date();
  _date.setHours(0, 0, 0, 0);
  return _date;
};

export interface ISelectorDatesSelected {
  min?: Date;
  max?: Date;
}

export class DatesSelector {
  private _dates: ISelectorDatesSelected = {};
  private _$defaultPeriod: ReplaySubject<ISelectorDatesSelected> = new ReplaySubject(1);
  private _$dates: ReplaySubject<ISelectorDatesSelected> = new ReplaySubject(1);

  /**
   * Maximum period of the selector
   */
  defaultPeriod(maxDate?: Date, minDate?: Date): void {
    if (typeof maxDate === 'undefined') {
      maxDate = new Date();
    }
    // default period is 3 years
    if (typeof minDate === 'undefined') {
      minDate = dateWithoutYears(maxDate, 3);
    }

    this._$defaultPeriod.next({
      min: minDate,
      max: maxDate
    });
  }

  $defaultPeriod() {
    return this._$defaultPeriod.asObservable();
  }

  /**
   * Dates selected by the user
   */
  dates(dates: any): void {
    if (typeof dates === 'undefined') {
      dates = {};
    }

    if (typeof dates.max === 'undefined') {
      dates.max = new Date();
    }

    if (typeof dates.min === 'undefined') {
      dates.min = dateWithoutYears(dates.max, 3);
    }

    this._dates.min = dates.min ? new Date(dates.min - dates.min.getTimezoneOffset() * 60000) : undefined;
    this._dates.max = dates.max ? new Date(dates.max - dates.max.getTimezoneOffset() * 60000) : undefined;

    this._$dates.next(this._dates);
  }

  $dates() {
    return this._$dates.asObservable();
  }
}
