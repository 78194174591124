import {
  Portfolio as BasePortfolio, Strategy as BaseStrategy,
  StrategyResource,
  TimeSerie as BaseTimeSerie
} from '@quantizr/front-model';

export interface ITimeSerie extends BaseTimeSerie {
  color: string;
  data: any[];
}

export interface IStrategy extends BaseStrategy {
  color: string;
}

export interface IPortfolio extends BasePortfolio {
  strategies: IStrategy[];
}

export interface IComposition {
  real_weights: ITimeSerie[];
  perf_attributions: ITimeSerie[];
}

// 'StrategiesCompositionModel'
export class StrategiesCompositionModel {
  private weights: ITimeSerie[];
  private perfAttributions: ITimeSerie[];

  constructor(
    private strategyResource: StrategyResource,
    private $translate: ng.translate.ITranslateService,
    private portfolio: IPortfolio
  ) {}

  init() {
    return this.strategyResource.get({
      id: this.portfolio.id,
      min_date: this.portfolio.start_date,
      max_date: this.portfolio.end_date,
      action: 'compositions'
    }).$promise.then(response => this.initSuccess(response as any));
  }

  initSuccess(response: IComposition): IComposition {
    this.weights = response.real_weights;
    this.perfAttributions = response.perf_attributions;

    this.weights.forEach(timeserie => {
      this.portfolio.strategies.forEach(strategy => {
        if (timeserie.owner_id === strategy.id) {
          timeserie.color = strategy.color;
        }
      });
    });

    this.perfAttributions.forEach(timeserie => {
      this.portfolio.strategies.forEach(strategy => {
        if (timeserie.owner_id === strategy.id) {
          timeserie.color = strategy.color;
        }
      });
    });

    return response;
  }

  perfDataset(groupBy: string): any[] {
    return this.buildDataset(groupBy, this.perfAttributions);
  }

  dataset(groupBy: string): any[] {
    return this.buildDataset(groupBy, this.weights);
  }

  private buildDataset(groupBy: string, timeseries: ITimeSerie[]): any[] {
    const dataset: any[] = [];
    const timeseriesByGroup: {
      [group: string]: any;
    } = {};

    timeseries.forEach(timeserie => {
      // create data tables on loaded data
      this.portfolio.strategies.forEach(strategy => {
        if (timeserie.owner_id === strategy.id) {
          const group = strategy[groupBy];

          if (!timeseriesByGroup[group]) {
            timeseriesByGroup[group] = {
              id: group,
              name: group,
              data: {}
            };

            if (groupBy === 'asset_classes' || groupBy === 'strategy_types') {
              timeseriesByGroup[group].name =
                this.$translate.instant(['strategies', groupBy, group].join('.'));
            }
          }

          timeserie.data.forEach(value => {
            if (!timeseriesByGroup[group].data[value[0]]) {
              timeseriesByGroup[group].data[value[0]] = value[1];
            }
            else {
              timeseriesByGroup[group].data[value[0]] += value[1];
            }
          });
        }
      });
    });

    Object.keys(timeseriesByGroup).forEach(group => {
      const timeserie = timeseriesByGroup[group];
      const data: any[] = [];
      Object.keys(timeserie.data).forEach(key => {
        data.push([key, timeserie.data[key]]);
      });
      timeserie.data = data;
      dataset.push(timeserie);
    });

    return dataset;
  }
}
