import { Component, Inject, OnInit, OnDestroy, ViewChild } from 'angular-ts-decorators';
import {
  GroupField,
  Strategy, StrategyResource,
  StrategyBenchmark,
  Portfolio,
  StrategyIssuerResource,
  StrategyGrouped,
  StrategyFactorResource
} from '@quantizr/front-model';
import { findById as findBenchmarkById } from '@quantizr/front-model/dist/strategies/benchmarks';
import { of, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import cloneDeep = require('lodash/cloneDeep');

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesCompositionModel, IComposition } from '../composition/model';
import { StrategiesViewsService } from '../views/service';
import { StrategiesBenchmarksService } from '../benchmarks/service';
import { typeFromStrategy, typeFromError, strategyType } from '../type';
import { COLOR as BENCHMARK_COLOR } from '../../benchmarks';
import {
  BaseChartService, ISelectorDatesSelected, DatesSelector, TableSettingsService
} from '../../components';
import { COLORS } from '../../components/colors';
import { SECTION_TYPES } from '../show/holdings/page';
import { GROUPS } from '../show/holdings/general/page';
import { COUNTRY_ISSUER_TYPES } from '../show/holdings/general/page';
import { IStrategyFactorDisplay } from '../show/factors/page';
import { PdfEngineComponent } from '../../pdf/engine/component';

@Component({
  selector: 'page-strategies-pdf',
  template
})
export class StrategiesPdfPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @ViewChild(PdfEngineComponent)
  pdfEngine: PdfEngineComponent;

  public COLORS = COLORS;
  public BENCHMARK_COLOR = BENCHMARK_COLOR;

  public benchmarksComparatorRefreshed = false;

  public datesSelector = new DatesSelector();
  public timePeriod: ISelectorDatesSelected = {};

  public fixingsSettings: any = {};
  public volatilitySettings: any = {};
  public weightsSettings: any = {};

  public errors;
  public TYPE: strategyType|'' = '';
  public benchmark: StrategyBenchmark;
  public strategy: Strategy;
  public strategyId: number;
  public composition: IComposition;
  public compositionModel: StrategiesCompositionModel;

  public holdingsData: StrategyGrouped|undefined = undefined;
  public holdingsCountryData: StrategyGrouped|undefined = undefined;
  public HOLDINGS_SECTION_TYPES = SECTION_TYPES;

  public factorsAnalysis?: IStrategyFactorDisplay[];

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource,
    @Inject('StrategyFactorResource')
    private strategyFactorResource: StrategyFactorResource,
    @Inject('StrategiesViewsService')
    private strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesCompositionModel')
    private CompositionModel,
    @Inject('StrategiesBenchmarksService')
    private strategiesBenchmarksService: StrategiesBenchmarksService,
    @Inject('BaseChartService')
    private baseChartService: BaseChartService,
    @Inject('TableSettingsService')
    private tableSettingsService: TableSettingsService,
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    private $location: ng.ILocationService,
    $routeParams: ng.route.IRouteParamsService
  ) {
    this.strategyId = $routeParams.id;

    const plotSettings = {
      legend: {
        align: 'right',
        enabled: true,
        fontSize: '9px'
      },
      line: {
        thickness: 1
      }
    };
    this.fixingsSettings = {
      credits: true,
      plots: [cloneDeep(plotSettings)]
    };
    this.fixingsSettings.plots[0].legend.enabled = false;
    this.volatilitySettings = {
      credits: true,
      plots: [cloneDeep(plotSettings)]
    };
    this.weightsSettings = {
      credits: true,
      plots: [cloneDeep(plotSettings)]
    };
    this.weightsSettings.plots[0].legend.enabled = false;

    this.selectDates();
    this.tableSettingsService.disable();

    this.subscriptions.push(strategiesBenchmarksService.$benchmarks().subscribe(benchmarks => {
      this.selectBenchmark(benchmarks);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.tableSettingsService.enable();
  }

  ngOnInit() {
    return this.strategyResource.get({
      id: this.strategyId,
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  selectDates() {
    const path = this.$location.search();
    this.timePeriod.min = 'date.min' in path ? new Date(path['date.min']) : undefined;
    this.timePeriod.max = 'date.max' in path ? new Date(path['date.max']) : undefined;

    this.datesSelector.defaultPeriod(this.timePeriod.max, this.timePeriod.min);
    this.datesSelector.dates({
      max: this.timePeriod.max,
      min: this.timePeriod.min
    });
  }

  onChartRefreshed(id) {
    return this.baseChartService.$refreshed().pipe(
      filter(value => value === id),
      take(1)
    ).toPromise();
  }

  selectBenchmark(benchmarks: StrategyBenchmark[]) {
    const path = this.$location.search();
    const benchmarkId = 'benchmarkId' in path ? parseInt(path.benchmarkId, 10) : -1;

    if (benchmarks.length > 0) {
      this.benchmark = findBenchmarkById(benchmarks, benchmarkId) || benchmarks[0];
    }
    else {
      this.benchmark = { id: 0, link_id: 0 };
    }
    this.strategiesBenchmarksService.select(this.benchmark);
  }

  public benchmarksRefreshed() {
    this.benchmarksComparatorRefreshed = true;
  }

  onBenchmarksComparatorRefreshed() {
    return of(this.benchmarksComparatorRefreshed).pipe(
      filter((value) => value === true),
      take(1)
    ).toPromise();
  }

  async loadSuccess(response: Strategy) {
    this.strategy = response;
    this.strategiesViewsService.strategy(this.strategy);

    this.TYPE = typeFromStrategy(this.strategy);

    await Promise.all([
      this.loadIssuer(),
      this.loadCountryData(),
      this.loadFactors(),
      this.initPortfolio(),
      this.onChartRefreshed('fixings-container'),
      this.onChartRefreshed('volatility-container'),
      this.onBenchmarksComparatorRefreshed()
    ]);

    this.$timeout(() => {
      this.pdfEngine.loaded(this.strategy.name);
    }, 100);
  }

  public async initPortfolio() {
    if (
      this.TYPE !== 'portfolio' ||
      (this.strategy as Portfolio).strategies === undefined ||
      (this.strategy as Portfolio).strategies!.length === 0
    ) {
      return;
    }

    this.compositionModel = new this.CompositionModel(this.strategy);

    await Promise.all([
      this.compositionModel.init().then(composition => this.composition = composition),
      this.onChartRefreshed('weights-container')
    ]);
  }

  loadIssuer() {
    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      'fields[]': GROUPS
    }).$promise.then(
      response => this.holdingsData = response,
      () => this.holdingsData = undefined
    );
  }

  loadCountryData() {
    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      'fields[]': [GroupField.economical_zone],
      'issuer_type[]': COUNTRY_ISSUER_TYPES
    }).$promise.then(
      response => this.holdingsCountryData = response,
      () => this.holdingsCountryData = undefined
    );
  }

  loadFactors() {
    return this.strategyFactorResource.query({
      strategy_id: this.strategy.id
    }).$promise.then(response => {
      this.factorsAnalysis = response;
      for (let i = 0; i < this.factorsAnalysis.length; i++) {
        this.factorsAnalysis[i].color = COLORS[i];
      }
    },
    () => this.factorsAnalysis = []);
  }

  loadError(error) {
    this.TYPE = typeFromError(error);

    this.$translate(
      `strategies.pdf.controller.load.error.${(this.TYPE || '')}`
    ).then(translation => {
      this.errors = {
        load: translation
      };
    });
  }
}
