import { Component } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';

const template = require('./page.html.haml');

import { StrategiesEditChildPage } from '../child';

@Component({
  selector: 'page-strategies-edit-fixings',
  template
})
export class StrategiesEditFixingsPage extends StrategiesEditChildPage {
  public file: any = null;

  /*@ngInject*/
  constructor(
    private Upload,
    private toaster: toaster.IToasterService,
    private $translate: ng.translate.ITranslateService,
    $location: ng.ILocationService,
    ngDialog: ng.dialog.IDialogService,
    $timeout: ng.ITimeoutService,
    $element: ng.IAugmentedJQuery,
    $scope: ng.IScope
  ) {
    super($location, ngDialog, $timeout, $element, $scope);
  }

  public async save() {
    if (this.strategy.id) {
      await this.uploadFile();
    }
    else {
      await this.saveStrategy();
      await this.uploadFile();
    }
  }

  upload(file) {
    this.file = file;
  }

  saveStrategy() {
    return (this.strategy as any).$update().then(strategy => {
      this.strategy = strategy as Strategy;
    });
  }

  async uploadFile() {
    this.errorMessages = null;

    if (!this.file) {
      return;
    }

    return this.Upload.upload({
      url: '/strategies/' + this.strategy.id + '/time_serie_files?format=json',
      method: 'POST',
      data: {
        file: this.file
      }
    }).then(response => this.uploadFileSuccess(response), error => this.saveError(error));
  }

  uploadFileSuccess(response) {
    this.saving(false);

    this.$translate('strategies.edit.controller.save.success', {
      added: response.data.statistics.added,
      invalidRows: (
        response.data.statistics.extracted_rows - response.data.statistics.filtered_rows
      )
    }).then(title => this.toaster.success(title));
  }
}
