import { Component, OnInit, OnDestroy, Inject } from 'angular-ts-decorators';
import { StrategiesByKeyword, StrategiesByKeywordResource } from '@quantizr/front-model';
import 'anychart/dist/js/anychart-treemap.min';

import './page.scss';
const template = require('./page.html.haml');

import { KEYWORDS_CATEGORIES } from '../categories';
import { RED_TO_GREEN } from '../../components/base-chart/colors';
import { WINDOWS } from '../windows';

export const labelFormat = (name: string, value: number) => {
  return `<span style="font-weight:bold">${name}</span>
    <br>${value.toFixed(2)}%`;
};

export const tooltipFormat = (valueLabel: string, value: number, sizeLabel: string, size: number) => {
  return `${valueLabel}: ${value.toFixed(2)}%
${sizeLabel}: ${size.toFixed(2)}%`;
};

export const colorRangeLabelFormat = (range: {start: number, end: number}, {startLabel, endLabel}) => {
  if (isFinite(range.start + range.end)) {
    return `${range.start} — ${range.end}`;
  }
  else if (isFinite(range.start)) {
    return `${startLabel} ${range.start}`;
  }
  else {
    return `${endLabel} ${range.end}`;
  }
};

@Component({
  selector: 'page-treemap-show',
  template
})
export class TreemapShowPage implements OnInit, OnDestroy {
  public WINDOWS = WINDOWS;
  public LABELS = KEYWORDS_CATEGORIES;

  public SIZES = ['volatility'];
  public selectedSize: string;
  public COLORS = ['cumulative_return'];
  public selectedColor: string;

  public searchParams = {
    tag_category: KEYWORDS_CATEGORIES[0],
    window: WINDOWS[0]
  };

  public chart: anychart.charts.TreeMap;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesByKeywordResource')
    private strategiesByKeywordResource: StrategiesByKeywordResource,
    private $translate: ng.translate.ITranslateService
  ) {
    this.selectedSize = this.SIZES[0];
    this.selectedColor = this.COLORS[0];
  }

  ngOnInit() {
    this.initChart();
    this.refreshTree();
  }

  ngOnDestroy() {
    (this.chart as any).dispose();
  }

  updateWindow(window: string) {
    this.searchParams.window = window;
    this.refreshTree();
  }

  refreshTree() {
    this.searchParams['category[]'] = [this.selectedSize, this.selectedColor];
    this.strategiesByKeywordResource.query(this.searchParams).$promise.then(response => {
      this.refreshTreeSuccess(response);
    });
  }

  refreshTreeSuccess(response: StrategiesByKeyword[]) {
    const data = [{
      children: response
    }];

    this.chart.data(data);
    // initiate drawing the chart
    this.chart.container('treemap');
    this.chart.draw();
  }

  initChart() {
    const customColorScale = anychart.scales.ordinalColor();
    customColorScale.ranges([
      { less: -5.5 },
      { from: -5.5, to: -4.5 },
      { from: -4.5, to: -3.5 },
      { from: -3.5, to: -2.5 },
      { from: -2.5, to: -1.5 },
      { from: -1.5, to: -0.5 },
      { from: -0.5, to: 0.5 },
      { from: 0.5, to: 1.5 },
      { from: 1.5, to: 2.5 },
      { from: 2.5, to: 3.5 },
      { from: 3.5, to: 4.5 },
      { from: 4.5, to: 5.5 },
      { greater: 5.5 }
    ]);
    customColorScale.colors(RED_TO_GREEN);

    // create a chart and set the data
    this.chart = anychart.treeMap([], 'as-tree');

    this.chart.credits().enabled(false);
    this.chart.labels().useHtml(true);
    this.chart.labels().format(function() {
      return labelFormat(this.name, this.value);
    });
    this.chart.colorScale(customColorScale);

    const ctrl = this;
    this.chart.tooltip().format(function() {
      return tooltipFormat(
        ctrl.$translate.instant(`treemap.categories.${ctrl.selectedColor}`),
        this.value,
        ctrl.$translate.instant(`treemap.categories.${ctrl.selectedSize}`),
        this.size
      );
    });

    const colorRange = this.chart.colorRange();
    colorRange.enabled(true).padding([0, 0, 17, 0]).length('90%');
    colorRange.ticks().enabled(true).stroke('3 #ffffff').position('center').length(7);
    colorRange.colorLineSize(5);
    colorRange.marker().size(5);
    colorRange.labels().fontSize(10).padding(2, 0, 0, 0);

    colorRange.labels().format(function() {
      return colorRangeLabelFormat(this.colorRange, {
        startLabel: ctrl.$translate.instant('treemap.show.controller.colorRange.start'),
        endLabel: ctrl.$translate.instant('treemap.show.controller.colorRange.end')
      });
    });
  }
}
