import { Injectable, Inject } from 'angular-ts-decorators';
import { ReplaySubject, Subscription } from 'rxjs';
import { Keyword, KeywordResource } from '@quantizr/front-model';

import { MyUserService } from '../my-user/service';

@Injectable('KeywordsService')
export class KeywordsService {
  private subscriptions: Subscription[] = [];
  private _$keywords: ReplaySubject<Keyword[]|null> = new ReplaySubject(1);

  /*@ngInject*/
  constructor(
    @Inject('KeywordResource')
    private keywordResource: KeywordResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      if (user) {
        this.reset();
      }
    }));
  }

  public destroy() {
    this._$keywords.next(null);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public $keywords() {
    return this._$keywords.asObservable();
  }

  public reset() {
    return this.keywordResource.query({}).$promise.then(keywords => {
      this._$keywords.next(keywords);
      return keywords as Keyword[];
    }, () => {
      this._$keywords.next([]);
      return [] as Keyword[];
    });
  }
}
