import { NgModule } from 'angular-ts-decorators';
import BenchmarkResourceFactory from '@quantizr/front-model/dist/benchmarks/resource';

import { VendorModule } from '../vendor';

export const COLOR = '#4e86ec';

@NgModule({
  id: 'Benchmarks',
  imports: [
    VendorModule
  ],
  providers: [
    {
      provide: 'BenchmarkResource',
      useFactory: ($resource) => BenchmarkResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class BenchmarksModule {}

export default (BenchmarksModule as NgModule).module!.name;
