import { Component, Input, AfterViewInit } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
const template = require('./component.html.haml');

import { badge } from '../badge';

@Component({
  selector: 'strategies-status',
  template
})
export class StrategiesStatusComponent implements AfterViewInit {
  @Input()
  public strategy: Strategy;

  public badgeClass: string|undefined;

  ngAfterViewInit() {
    this.badgeClass = badge(this.strategy);
  }
}
