import { Component, Input } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

@Component({
  selector: 'strategies-historical-events',
  template
})
export class StrategiesHistoricalEventsComponent {
  @Input()
  historicalEvents?: any;
}
