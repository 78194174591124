import { NgModule } from 'angular-ts-decorators';
import MyUserResourceFactory from '@quantizr/front-model/dist/my-user/resource';

import { UsersModule } from '../users';
import { VendorModule } from '../vendor';

import { MyUserService } from './service';

import {
  default as UnauthorizedRequestInterceptorFactory,
  UnauthorizedRequestInterceptor
} from './unauthorized-interceptor';
import { isAuthenticatedFilter } from './is-authenticated/filter';
import { EnvModule } from '../env';

import { MyUserPasswordEditPage } from './password/edit/page';
import { MyUserPasswordResetPage } from './password/reset/page';
import { MyUserPoliciesShowDirective } from './policies/show/directive';
import { MyUserShowPage } from './show/page';
import { MyUserSigninPage } from './signin/page';
import { MyUserSignupPage } from './signup/page';
import { MyUserInvitationPage } from './invitation/page';

export * from './is-authenticated';

export {
  MyUserService
};

@NgModule({
  id: 'MyUser',
  imports: [
    UsersModule,
    VendorModule,
    EnvModule
  ],
  declarations: [
    MyUserPasswordEditPage,
    MyUserPasswordResetPage,
    MyUserPoliciesShowDirective,
    MyUserShowPage,
    MyUserSigninPage,
    MyUserSignupPage,
    MyUserInvitationPage
  ],
  providers: [
    MyUserService,
    {
      provide: 'MyUserResource',
      useFactory: ($resource) => MyUserResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: UnauthorizedRequestInterceptor,
      useFactory: ($q, $window, localStorageService) => {
        return UnauthorizedRequestInterceptorFactory($q, $window, localStorageService);
      },
      deps: [
        '$q', '$window', 'localStorageService'
      ]
    }
  ]
})
export class MyUserModule {
  /*@ngInject*/
  static config($httpProvider: ng.IHttpProvider) {
    ($httpProvider.defaults as any).useXDomain = true;
    $httpProvider.defaults.withCredentials = true;
    delete $httpProvider.defaults.headers!.common['X-Requested-With'];
    $httpProvider.interceptors.push(UnauthorizedRequestInterceptor);
  }
}

export default (MyUserModule as NgModule).module!
  .filter('isAuthenticated', ['MyUserService', isAuthenticatedFilter])
  .name;
