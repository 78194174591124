import { NgModule } from 'angular-ts-decorators';

import { MyUserModule } from '../my-user';

import { ErrorsIndexPage } from './index/page';

@NgModule({
  id: 'Errors',
  imports: [
    MyUserModule
  ],
  declarations: [
    ErrorsIndexPage
  ]
})
export class ErrorsModule {}

export default (ErrorsModule as NgModule).module!.name;
