import { Component } from 'angular-ts-decorators';

const template = require('./page.html.haml');

import { DialogController } from '../../../../dialog/controller';

@Component({
  selector: 'page-cockpits-profiles-delete-confirm',
  template
})
export class CockpitsProfilesDeleteConfirmPage extends DialogController {}
