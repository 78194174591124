import { OnDestroy } from 'angular-ts-decorators';

export abstract class DialogController implements OnDestroy {
  private scope: ng.dialog.IDialogConfirmScope;

  protected watchers: Array<() => void> = [];

  public id: string;

  /**
   * Data passed to the dialog using {data: ...}
   */
  public data: any;

  /**
   * Check this to know wether we are in a dialog or not.
   */
  public isDialog: boolean = false;

  /*@ngInject*/
  constructor($scope: ng.IScope) {
    this.scope = $scope.$parent as ng.dialog.IDialogConfirmScope;
    this.isDialog = 'closeThisDialog' in this.scope;
    this.data = this.scope.ngDialogData || {};
    this.id = this.scope.ngDialogId;

    // TODO: still needed?
    // check out http://stackoverflow.com/questions/37181017/angular-select-blinking for workaround
    this.watchers.push($scope.$on('ngDialog.opened', () => {
      $(':focus').blur();
    }));
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  /**
   * @see {@link ng.dialog.IDialogConfirmScope.closeThisDialog}
   */
  public closeThisDialog(value?: any) {
    this.scope.closeThisDialog(value);
  }

  /**
   * @see {@link ng.dialog.IDialogConfirmScope.confirm}
   */
  public confirm(value?: any) {
    this.scope.confirm(value);
  }
}
