import { NgModule } from 'angular-ts-decorators';
import { variables as raven } from '@quantizr/front-model/dist/raven/variables';

import { EnvModule, IEnv, EnvService } from '../env';
import { VendorModule } from '../vendor';
import { AnalyticsModule } from '../analytics';

import angularPromiseConfig from './angular-promise';
import { default as ApiInterceptorFactory, ApiInterceptor } from './api-http-interceptor';
import httpConfig from './http';
import localStorageConfig from './local-storage';
import ravenConfig from './raven';
import translateConfig from './translate';

@NgModule({
  id: 'Config',
  imports: [
    AnalyticsModule,
    EnvModule,
    VendorModule
  ],
  providers: [
    {
      provide: ApiInterceptor,
      useFactory: (envService: EnvService) => ApiInterceptorFactory(envService),
      deps: [
        'EnvService'
      ]
    }
  ]
})
export class ConfigModule {
  static config(
    ENV: IEnv,
    $translateProvider: ng.translate.ITranslateProvider,
    $qProvider: ng.IQProvider,
    $httpProvider: ng.IHttpProvider,
    localStorageServiceProvider: ng.local.storage.ILocalStorageServiceProvider
  ) {
    'ngInject';
    angularPromiseConfig($qProvider);
    httpConfig($httpProvider, [
      ApiInterceptor
    ]);
    localStorageConfig(localStorageServiceProvider);
    ravenConfig(ENV, raven);
    translateConfig(ENV, $translateProvider);
  }
}

export default (ConfigModule as NgModule).module!.name;
