import { Component, Input, OnDestroy, Inject, OnChanges, Output, SimpleChanges } from 'angular-ts-decorators';
import { CockpitProfile } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './component.scss';
const template = require('./component.html.haml');

import { CockpitsViewsService } from '../../views/service';
import { JobModel, ACTIONS as JOB_ACTIONS } from '../../../jobs';

const translateKey = 'cockpits.profiles.reload.controller';

@Component({
  selector: 'cockpits-profiles-reload',
  template
})
export class CockpitsProfilesReloadComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private updateJob: JobModel|null = null;

  public visible = false;

  @Input()
  profile: CockpitProfile;
  @Output()
  onReload: () => void;

  /*@ngInject*/
  constructor(
    @Inject('JobsModel')
    private Job,
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnDestroy() {
    this.stopJob();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes) {
      if (this.updateJob) {
        this.updateJob.destroy();
        this.updateJob = null;
      }
      // reload job every time profile changes
      this.initJob();
    }
  }

  reload() {
    this.cockpitsViewsService.loadCockpit();
    this.visible = false;
    if (this.updateJob) {
      // stop any ongoing update requests
      this.updateJob.clear();
    }

    this.initJob();
  }

  initJob() {
    if (!this.updateJob) {
      this.updateJob = new this.Job({
        id: this.profile.id,
        type: 'profile',
        action: JOB_ACTIONS.REFRESH_STATS,
        loading: {
          title: this.$translate.instant(`${translateKey}.loading`)
        },
        loaded: {
          title: this.$translate.instant(`${translateKey}.loaded`),
          action: `<a class="underline d-inline-block"><i class="fas fa-sync"></i>&nbsp;<span>
            ${this.$translate.instant(`${translateKey}.refresh`)}
          </span></a>`,
          onClick: () => {
            this.reload();
          }
        }
      });

      this.subscriptions.push(this.updateJob!.finished().subscribe(finished => {
        this.visible = finished;
      }));

      this.updateJob!.start();
    }
    // if reloading after already init, simple restart job to check for updates again
    else {
      this.updateJob.restart();
    }
  }

  stopJob() {
    if (this.updateJob) {
      this.updateJob.destroy();
      this.updateJob = null;
    }
  }
}
