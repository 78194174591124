import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { StrategyFactorResource, StrategyFactor, Strategy } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { COLORS } from '../../../components/colors';
import { StrategiesViewsService } from '../../views/service';

export interface IStrategyFactorDisplay extends StrategyFactor {
  color?: string;
}

@Component({
  selector: 'page-strategies-show-factors',
  template
})
export class StrategiesShowFactorsPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public strategy: Strategy;
  public factorsAnalysis?: IStrategyFactorDisplay[];

  /*@ngInject*/
  constructor(
    @Inject('StrategyFactorResource')
    private strategyFactorResource: StrategyFactorResource,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.init();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    return this.strategyFactorResource.query({
      strategy_id: this.strategy.id
    }).$promise.then(response => {
      this.factorsAnalysis = response;

      for (let i = 0; i < this.factorsAnalysis.length; i++) {
        this.factorsAnalysis[i].color = COLORS[i];
      }
    });
  }
}
