import {
  Component, Input, AfterViewInit, OnDestroy,
  OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import './component.scss';
// Hack to get flatdoc to work since it is not designed to work with webpack
// @see https://github.com/rstacruz/flatdoc/issues/98
(window as any).marked = require('marked');
import 'flatdoc';

import { ANIMATIONS_DURATION } from '../';

declare var Flatdoc: any;

@Component({
  selector: 'flatdoc',
  template: `
    <loading></loading>
    <div role="flatdoc">
      <div role="flatdoc-menu"></div>
      <div role="flatdoc-content"></div>
    </div>
  `
})
export class FlatdocComponent implements AfterViewInit, OnChanges, OnDestroy {
  private $root = $('#content');
  private $flatDocMenu;

  @Input()
  src: string;

  /*@ngInject*/
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $element: ng.IAugmentedJQuery
  ) {}

  ngAfterViewInit() {
    $(document).on('flatdoc:ready', this.flatdocReady.bind(this));
  }

  ngOnDestroy() {
    $(document).off('flatdoc:ready', this.flatdocReady);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('src' in changes) {
      const src = changes.src.currentValue;
      if (src) {
        Flatdoc.run({
          fetcher: Flatdoc.file(src)
        });
      }
    }
  }

  flatdocReady() {
    this.$element.find('loading').remove();

    this.$flatDocMenu = $('[role="flatdoc-menu"]');

    this.$element.find('a').each((_index: number, element: HTMLElement) => {
      $(element).on('click', (event: JQuery.Event) => {
        this.onClickLink(element, event);
      });
    });

    if (this.$location.search().link) {
      this.$flatDocMenu.find('a[href="' + this.$location.search().link + '"]').click();
    }
  }

  onClickLink(element: HTMLElement, event: JQuery.Event) {
    const href = element.getAttribute('href');
    if (!href || href.substring(0, 1) !== '#') {
      return;
    }

    this.$location.search('link', href);
    this.$rootScope.$apply();

    event.preventDefault();
    event.stopPropagation();

    this.$flatDocMenu.find('a.active').removeClass('active');
    $(element).addClass('active');

    this.$root.animate({
      scrollTop: $(href).offset()!.top - $('#-a').offset()!.top
    }, ANIMATIONS_DURATION);
  }
}
