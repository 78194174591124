import { Component, Input, Inject, ViewParent } from 'angular-ts-decorators';
import { variables as strategies } from '@quantizr/front-model/dist/strategies/variables';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import {
  StrategySuggestion, StrategyMatrix, StrategyFieldResource,
  Benchmark, BenchmarkResource,
  Company, CompanyResource,
  MyUser
} from '@quantizr/front-model';
import { Subscription } from 'rxjs';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { FILTERS, LOCAL_STORAGE_KEYS } from '../../';
import { StrategiesLocalStorageService } from '../../local-storage/service';
import { MyUserService } from '../../../my-user';
import { ISearchSort, StrategiesIndexPage } from '../page';

@Component({
  selector: 'strategies-index-search-filters',
  template
})
export class StrategiesIndexSearchFiltersComponent {
  private watchers: Function[] = [];
  private subscriptions: Subscription[] = [];
  private user: MyUser|null = null;

  @ViewParent('?^pageStrategiesIndex')
  indexPage: StrategiesIndexPage;

  @Input()
  criteriaVolumes: any;
  @Input()
  sort: ISearchSort;
  @Input()
  saving: number;

  public PAGINATION_SIZE = settings.PAGINATION_SIZE;
  public STRATEGY_INSTRUMENTS = strategies.INSTRUMENTS;
  public RETURN_TYPES = Object.keys(strategies.RETURN_TYPES).map(key => strategies.RETURN_TYPES[key]);
  public criteria: any = {};
  public benchmarks: Benchmark[] = [];
  public selectedBenchmark: Benchmark|null = null;
  public companies: Company[] = [];
  public currencies: Array<{value: string}> = [];

  // Track Record slider
  public liveTrackOptions: ng.RzSlider.RzOptions = {
    floor: settings.TRACK_RECORD.min,
    step: 1,
    showSelectionBar: true,
    showSelectionBarEnd: true,
    translate: value => {
      return `${value} month(s)${(value >= this.liveTrackOptions.ceil! ? '+' : '')}`;
    }
  };

  // Vol Budget slider
  public correlationOptions: ng.RzSlider.RzOptions = {
    floor: -100,
    ceil: 100,
    step: 10,
    translate: value => {
      return `${value}%`;
    }
  };

  // Vol Budget slider
  public volBudgetOptions: ng.RzSlider.RzOptions = {
    floor: settings.VOL_BUDGET.min,
    ceil: settings.VOL_BUDGET.max,
    step: 1,
    translate: value => {
      return value + (value >= this.volBudgetOptions.ceil! ? '+' : '');
    }
  };

  /*@ngInject*/
  constructor(
    @Inject('BenchmarkResource')
    private benchmarkResource: BenchmarkResource,
    @Inject('CompanyResource')
    private companyResource: CompanyResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('StrategiesLocalStorageService')
    private strategiesLocalStorageService: StrategiesLocalStorageService,
    @Inject('StrategyFieldResource')
    private strategyFieldResource: StrategyFieldResource,
    private $timeout: ng.ITimeoutService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $rootScope: ng.IRootScopeService
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      this.user = user;
    }));

    this.watchers.push($rootScope.$on('$routeChangeSuccess', (_event, route) => {
      // Called when changing filters or change from strategies to portfolios
      if (route && route.$$route.segment.indexOf('strategies.index') !== -1) {
        this.init();
      }
    }));
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    this._resetSearch();

    // make sure sliders are renderred correclty
    this.initFilters();
    this.initCompanies();
    this.initCurrencies();
    this.initBenchmarks();
  }

  initBenchmarks() {
    this.benchmarkResource.query(this._filteredCriteria()).$promise.then(response => {
      this.initBenchmarksSuccess(response);
    }, () => {
      this.initBenchmarksSuccess([]);
    });
  }

  initBenchmarksSuccess(response) {
    this.benchmarks = response;

    this.initSelectedBenchmark();

    this.indexPage.search(this.criteria);
  }

  initSelectedBenchmark() {
    this.selectedBenchmark = null;

    if (!!this.criteria.benchmark_id) {
      (this.benchmarks || []).forEach(benchmark => {
        if (benchmark.id === this.criteria.benchmark_id) {
          this.selectedBenchmark = benchmark;
        }
      });
    }

    if (this.benchmarks[0] && !this.selectedBenchmark) {
      this.selectedBenchmark = this.benchmarks[0];
      this.criteria.benchmark_id = this.selectedBenchmark.id;
    }
    else if (!this.selectedBenchmark) {
      this.selectedBenchmark = null;
      this.criteria.benchmark_id = null;
    }
  }

  initCompanies() {
    return this.companyResource.query(this._filteredCriteria()).$promise.then(response => {
      this.initCompaniesSuccess(response);
    }, () => this.initCompaniesError());
  }

  initCompaniesSuccess(response) {
    if (response.length > 1) {
      this.companies = [new this.companyResource({
        id: null,
        name: 'ALL'
      }), ...response];
    }
    else {
      this.initCompaniesError();
    }
  }

  initCompaniesError() {
    this.companies = [];
    this.criteria.company_ids = [];
  }

  initCurrencies() {
    this.strategyFieldResource.query(this._filteredCriteria()).$promise.then(response => {
      this.currencies = [];
      response.forEach(value => {
        this.currencies.push({
          value: value.currency
        });
      });
    }, () => {
      this.currencies = [];
    });
  }

  initFilters() {
    const type = this.indexPage && this.indexPage.strategiesType === 'strategies' ? 'Strategy' : 'Portfolio';

    // This is based on the strategy type
    this.liveTrackOptions.ceil = settings.TRACK_RECORD.max[type];

    // clear search before applying filters
    if (this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.CRITERIA)) {
      merge(
        this.criteria,
        this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.CRITERIA)
      );
    }

    switch (this.$routeParams.filter) {
      case FILTERS.ALL:
        this.criteria.type = type;
        break;
      case FILTERS.ME:
        this.criteria.type = type;
        this.criteria.user_id = this.user!.id;
        break;
      default:
        this.criteria.customlist_id = this.$routeParams.filter;
        break;
    }
  }

  search() {
    this.strategiesLocalStorageService.remove(LOCAL_STORAGE_KEYS.VOLUMES);
    this.strategiesLocalStorageService.set(LOCAL_STORAGE_KEYS.CRITERIA, this.criteria);

    this.indexPage.search(this.criteria);
  }

  clearSearch() {
    this._resetSearch();

    this.strategiesLocalStorageService.remove(LOCAL_STORAGE_KEYS.VOLUMES);
    this.strategiesLocalStorageService.remove(LOCAL_STORAGE_KEYS.CRITERIA);

    this.initFilters();
    this.initSelectedBenchmark();

    return this.indexPage.search(this.criteria);
  }

  refreshSlider() {
    this.$timeout(() => {
      this.$rootScope.$broadcast('rzSliderForceRender');
    });
  }

  matrixSize() {
    let matrixSize = 0;

    const matrix = this.criteria.matrix || {};
    Object.keys(matrix).forEach(alphaBeta => {
      Object.keys(matrix[alphaBeta]).forEach(assetClass => {
        Object.keys(matrix[alphaBeta][assetClass]).forEach(strategyType => {
          if (matrix[alphaBeta][assetClass][strategyType] === true) {
            matrixSize += 1;
          }
        });
      });
    });

    return matrixSize;
  }

  moreOptionsSelectedSize() {
    let optionsCount = 0;

    if (this.criteria.currency !== null) {
      optionsCount += 1;
    }

    if (this.criteria.hide_protected !== false) {
      optionsCount += 1;
    }

    if (this.criteria.return_type !== null) {
      optionsCount += 1;
    }

    if (this.criteria.instrument !== null) {
      optionsCount += 1;
    }

    return optionsCount;
  }

  suggestionChanged(suggestion: string) {
    this.criteria.text = suggestion;
  }

  suggestionSelected(suggestion: StrategySuggestion) {
    if (suggestion && suggestion.id) {
      (this.$location as any).$$search = {};
      this.$location.path('/strategy/' + suggestion.id);
    }

    return false;
  }

  companyChecked(element: Company) {
    if (element.id) {
      return this.criteria.company_ids.indexOf(element.id) > -1;
    }
    else {
      return this.criteria.company_ids.length === this.companies.length - 1;
    }
  }

  toggleCompany(element: Company) {
    if (element.id) {
      const index = this.criteria.company_ids.indexOf(element.id);
      if (index > -1) {
        this.criteria.company_ids.splice(index, 1);
      }
      else {
        this.criteria.company_ids.push(element.id);
      }
    }
    else {
      if (this.companyChecked(element)) {
        this.criteria.company_ids.splice(0, this.criteria.company_ids.length);
      }
      else {
        (this.companies || []).forEach(company => {
          if (company.id && !this.companyChecked(company)) {
            this.criteria.company_ids.push(company.id);
          }
        });
      }
    }
  }

  selectBenchmark() {
    this.criteria.benchmark_id =
      !!this.selectedBenchmark ? this.selectedBenchmark.id : null;
    this.criteria.benchmark_min = this.correlationOptions.floor;
    this.criteria.benchmark_max = this.correlationOptions.ceil;
  }

  updateKeywords(selected: string[]) {
    this.criteria.keywords = selected;
  }

  updateMatrix(matrix: StrategyMatrix, alphaBeta: 'alpha'|'beta') {
    this.criteria.matrix[alphaBeta] = matrix;
  }

  _resetSearch() {
    this.criteria = {
      benchmark_id: null,
      benchmark_min: -100,
      benchmark_max: 100,
      company_ids: [],
      currency: null,
      customlist_id: null,
      hide_protected: false,
      matrix: {
        alpha: {},
        beta: {}
      },
      return_type: null,
      instrument: null,
      text: '',
      track_record: settings.TRACK_RECORD.min,
      type: null,
      user_id: null,
      vol_budget_min: settings.VOL_BUDGET.min,
      vol_budget_max: settings.VOL_BUDGET.max
    };
  }

  _filteredCriteria() {
    return {
      customlist_id: this.criteria.customlist_id,
      type: this.criteria.type,
      user_id: this.criteria.user_id
    };
  }
}
