import cloneDeep = require('lodash/cloneDeep');

import * as scenarioTypes from './scenario-types';

export interface IScenarioFieldConfig {
  range: boolean;
  readonly: boolean;
}

export interface IScenarioField {
  [key: string]: IScenarioField|IScenarioFieldConfig;
}

export interface IScenarioConfig {
  multiple?: boolean;
  fields: IScenarioField;
}

const configs: {[key: string]: IScenarioConfig} = {};
Object.keys(scenarioTypes).forEach(scenarioType => {
  configs[scenarioType] = scenarioTypes[scenarioType];
});

export const scenarioConfigs = (type: string) => cloneDeep(configs[type]);
