import { Component, Input, OnInit, SimpleChanges } from 'angular-ts-decorators';
import { StrategyGroupedDetail } from '@quantizr/front-model';

const template = require('./component.html.haml');
import './component.scss';

import { sumWeightFor, weightType } from '../sum-weight';
import { SECTION_TYPES, LONG_SHORT_TYPES } from '../page';

const translateScope = 'strategies.show.holdings.groupBy';

interface  IWeight {
  short: number;
  long: number;
  long_short: number;
  total: number;
}

export const WEIGHT_DISPLAY_TYPES = {
  TOTAL: 'total',
  LONG_SHORT: 'long_short',
  LONG: 'long',
  SHORT: 'short'
};

@Component({
  selector: 'strategies-show-holdings-group-by',
  template
})
export class StrategiesShowHoldingsGroupByComponent implements OnInit {
  @Input()
  public data: StrategyGroupedDetail[];
  @Input()
  public groupType: string;
  @Input('<?')
  public progressBarClass: string = 'primary';
  @Input('<?')
  public orderBy: string = '-weight.total';
  @Input('<?')
  public weightDisplay: 'total'|'long_short'|undefined = undefined;
  @Input()
  public showControls = true;
  @Input()
  public baseValue = 1;
  @Input()
  public showAll: boolean = false;

  public maxCount: number = 5;
  public maxWeight: IWeight = { short: 0.0, long: 0.0, total: 0.0, long_short: 0.0 };

  public WEIGHT_DISPLAY_TYPES = WEIGHT_DISPLAY_TYPES;
  public LONG_SHORT_TYPES = LONG_SHORT_TYPES;

  /*@ngInject*/
  constructor(
    private ngDialog: ng.dialog.IDialogService,
    private $filter: ng.IFilterService,
    private $rootScope: ng.IRootScopeService,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnInit() {
    this.data = this.$filter('orderBy')(this.data, this.orderBy);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.data = this.$filter('orderBy')(this.data, this.orderBy);
      this.refreshWeights();
      this.refreshMaxWeight();
    }
  }

  refreshWeights() {
    this.data.forEach((row: StrategyGroupedDetail) => {
      ['total', 'short', 'long'].forEach(key => {
        if (key === 'total' && this.baseValue !== 1) {
          row.weight[key] = row.weight[key] / this.baseValue;
        }
      });
    });
  }

  refreshMaxWeight() {
    this.data.forEach((row: StrategyGroupedDetail) => {
      ['total', 'short', 'long'].forEach(key => {
        if (row.weight[key] > this.maxWeight[key]) {
          this.maxWeight[key] = row.weight[key];
        }
      });
    });

    if (this.maxWeight.long > this.maxWeight.short) {
      this.maxWeight.long_short = this.maxWeight.long;
    }
    else {
      this.maxWeight.long_short = this.maxWeight.short;
    }
  }

  displayInGroupTypeFormat(name) {
    if (this.groupType === SECTION_TYPES.DURATION) {
      return `${+name} - ${+name + 1}`;
    }
    else if (this.groupType === SECTION_TYPES.COUNTRY_CODE) {
      return this.$translate.instant(`countries.${name}.name`);
    }
    else if (name === '') {
      return this.$translate.instant(`${translateScope}.other`);
    }
    else {
      return this.$translate.instant(`issuers.${this.groupType}.${name}`);
    }
  }

  weightFor(constituent: StrategyGroupedDetail, type: weightType) {
    const value = constituent.weight[type] * 100;

    if (type === this.WEIGHT_DISPLAY_TYPES.TOTAL) {
      return value / this.maxWeight.total;
    }
    else {
      return Math.ceil(value /  this.maxWeight.long_short);
    }
  }

  sumDisplayWeight(type: weightType) {
    return sumWeightFor(this.data, type);
  }

  displayFull() {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.data = this.data;
    newScope.groupType = this.groupType;
    newScope.orderBy = this.orderBy;
    newScope.weightDisplay = this.weightDisplay;
    newScope.progressBarClass = this.progressBarClass;
    newScope.showAll = true;

    return this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <strategies-show-holdings-group-by class="col-12 col-lg-6 p-3 mb-3 border-right"
          data="data" group-type="groupType" weight-display="weightDisplay" show-all="showAll"
          progress-bar-class="progressBarClass" order-by="orderBy">
        </strategies-show-holdings-group-by>
      `,
      plain: true,
      scope: newScope
    });
  }

  showHide() {
    this.showAll = !this.showAll;
  }
}
