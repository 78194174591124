import { NgModule } from 'angular-ts-decorators';

import { IEnv } from './model';
import { EnvService } from './service';

// env variables file is generated with gulp
declare var env: {variables: IEnv};

// TOOD: this will not work in Angular, will need to use InjectionToken and a useFactory for ENV
let variables = {};
try {
  variables = env.variables;
}
catch (err) {
  variables = {
    configuration: {},
    ENV: 'development'
  };
}

export {
  IEnv,
  EnvService
};

@NgModule({
  id: 'Env',
  providers: [
    EnvService,
    {
      provide: 'ENV',
      useValue: variables
    }
  ]
})
export class EnvModule {}

export default (EnvModule as NgModule).module!.name;
