import { Component, Inject, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-pie.min';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { COLORS } from '../colors';
import { BaseChartComponent } from '../base-chart/component';
import { BaseChartService } from '../base-chart/service';

@Component({
  selector: 'pie-chart',
  template
})
export class PieChartComponent extends BaseChartComponent<anychart.charts.Pie> implements OnChanges {
  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService
  ) {
    super(baseChartService);
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('settings' in changes) {
      this.removeChart();
      this.initChart();
      this.drawChart().catch(() => {});
    }

    super.ngOnChanges(changes);
  }

  initChart() {
    const settings = merge({
      chart: {
        background: {
          fill: 'transparent'
        },
        type: 'pie',
        credits: false,
        noData: {
          label: {
            enabled: true
          }
        },
        // radius: '90%',
        innerRadius: '60%',
        labels: {
          enabled: false
        },
        // TODO: this is deprecated, replace
        outsideLabelsSpace: 90,
        legend: {
          enabled: false
        },
        title: {
          enabled: false
        },
        tooltip: {
          format() {
            return `${this.value.toFixed(2)}%`;
          }
        }
      }
    }, this.settings || {});

    this.chart = anychart.fromJson(settings);
  }

  public refresh() {
    if (!this.chart) {
      return;
    }

    this.dataset.forEach((d, i) => {
      if (!d.normal) {
        d.normal = { fill: COLORS[i]};
      }
    });

    this.chart.data(this.dataset);
    this.onRefreshedDone();
  }
}
