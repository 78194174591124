import { Component, AfterViewInit, OnDestroy } from 'angular-ts-decorators';
import * as angular from 'angular';
import './component.scss';
const template = require('./component.html.haml');

@Component({
  selector: 'panels-body',
  template,
  transclude: true
})
export class PanelsBodyComponent implements AfterViewInit, OnDestroy {
  private $window: ng.IAugmentedJQuery;
  private refreshInterval;

  /*@ngInject*/
  constructor(
    private $element: ng.IAugmentedJQuery,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    $window: ng.IWindowService
  ) {
    this.$window = angular.element($window);
  }

  ngOnDestroy() {
    this.$window.off('resize', this.refreshContentHeight.bind(this));
  }

  ngAfterViewInit() {
    this.$window.on('resize', this.refreshContentHeight.bind(this));

    // wait until header exists
    this.refreshInterval = setInterval(() => {
      if (this.refreshContentHeight()) {
        clearInterval(this.refreshInterval);
      }
    }, 100);

    // If body contains slider, make sure they are rendered properly when loaded
    this.$timeout(() => {
      this.$rootScope.$broadcast('rzSliderForceRender');
    });
  }

  public htmlMargin(): number {
    let top = parseInt($('html').css('padding-top'), 10);
    let bottom = parseInt($('html').css('padding-bottom'), 10);

    if (isNaN(top)) {
      top = 0;
    }
    if (isNaN(bottom)) {
      bottom = 0;
    }

    return top + bottom;
  }

  public refreshContentHeight(): boolean {
    const $header = $('panels-header');

    if ($header.length) {
      this.$element.find('.content-pane').height(
        window.innerHeight - $header.height()! - this.htmlMargin()
      );
      return true;
    }

    return false;
  }
}
