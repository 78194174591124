import { Inject, Input } from 'angular-ts-decorators';
import * as angular from 'angular';

import { BaseChartComponent } from '../component';
import { BaseChartService } from '../service';

const titleSize = 40;
const scrollerSize = 50;
const scrollerMargin = 10;
const scrollerHeight = scrollerSize + scrollerMargin;
const scrollerHandleHeight = scrollerSize + (scrollerMargin * 2);

const updateXScroller = (
  scroller: anychart.standalones.Scroller, height = 0, width = 0, hasTitle: boolean, hasYScroller: boolean
) => {
  const top = height - scrollerSize - (hasTitle ? titleSize : 0);
  const left = (hasYScroller ? scrollerHeight : scrollerMargin) + titleSize;
  width = width - (scrollerHandleHeight + titleSize) - scrollerMargin;
  height = scrollerSize;
  scroller.parentBounds(left, top, width, height);
};

const updateYScroller = (
  scroller: anychart.standalones.Scroller, height = 0, width = 0, hasTitle: boolean, hasXScroller: boolean
) => {
  const top = scrollerMargin;
  const left = hasTitle ? titleSize : 0;
  height = height - (hasXScroller ? scrollerHeight : scrollerMargin) - titleSize;
  scroller.parentBounds(left, top, width, height);
};

export interface IChartWithZoom extends anychart.core.Chart {
  xScale: any;
  yScale: any;
  xAxis: any;
  yAxis: any;
}

export abstract class BaseChartZoomComponent<T extends IChartWithZoom> extends BaseChartComponent<T> {
  private $window: ng.IAugmentedJQuery;
  private xScroller?: anychart.standalones.Scroller;
  private xScrollerBounds = {
    min: 0,
    max: 0
  };
  private xScaleBounds = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER
  };
  private yScroller?: anychart.standalones.Scroller;
  private yScrollerBounds = {
    min: 0,
    max: 0
  };
  private yScaleBounds = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER
  };

  @Input()
  public enableZoom = false;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService,
    $window: ng.IWindowService
  ) {
    super(baseChartService);
    this.$window = angular.element($window);
  }

  removeChart() {
    this.$window.off('resize', this.onResize);
    if (this.xScroller) {
      this.xScroller.removeAllListeners();
    }
    if (this.yScroller) {
      this.yScroller.removeAllListeners();
    }
    super.removeChart();
  }

  drawChart() {
    return super.drawChart().then(() => {
      if (this.enableZoom) {
        this.$window.on('resize', this.onResize.bind(this));
        this.createScrollers();
        setTimeout(() => {
          this.updateScrollers();
        }, 1000);
      }
    }).catch(() => {});
  }

  onRefreshedDone() {
    if (this.enableZoom) {
      // update min/max
      if (this.enableXScroller()) {
        const xScale = this.chart.xScale();
        this.xScrollerBounds = {
          min: xScale.minimum(),
          max: xScale.maximum()
        };
        this.xScaleBounds = {
          min: Math.max(this.xScrollerBounds.min, this.xScaleBounds.min),
          max: Math.min(this.xScrollerBounds.max, this.xScaleBounds.max)
        };

        // make sure title does not overlap with scrollers
        const xTitle = this.chart.xAxis().title();
        if (xTitle.enabled()) {
          xTitle.margin(scrollerMargin * 2, 0, 0, 0);
        }
      }

      if (this.enableYScroller()) {
        const yScale = this.chart.yScale();
        this.yScrollerBounds = {
          min: yScale.minimum(),
          max: yScale.maximum()
        };
        this.yScaleBounds = {
          min: Math.max(this.yScrollerBounds.min, this.yScaleBounds.min),
          max: Math.min(this.yScrollerBounds.max, this.yScaleBounds.max)
        };

        // make sure title does not overlap with scrollers
        const yTitle = this.chart.yAxis().title();
        if (yTitle.enabled()) {
          yTitle.margin(0, 0, scrollerMargin * 2, 0);
        }
      }

      this.updateScrollers();
      this.updateXScale();
      this.updateYScale();
    }

    super.onRefreshedDone();
  }

  onResize() {
    this.updateScrollers();
  }

  enableXScroller() {
    return !!this.chart.xScale().minimum;
  }

  enableXTitle() {
    return this.chart.xAxis().title().enabled();
  }

  enableYScroller() {
    return !!this.chart.yScale().minimum;
  }

  enableYTitle() {
    return this.chart.yAxis().title().enabled();
  }

  createScrollers() {
    if (this.xScroller || this.yScroller) {
      return;
    }

    if (this.enableXScroller()) {
      this.xScroller = anychart.standalones.scroller();
    }
    if (this.enableYScroller()) {
      this.yScroller = anychart.standalones.scroller();
      this.yScroller.orientation('left');
    }

    // draw scrollers
    this.updateScrollers();

    if (this.xScroller) {
      this.xScroller.container(this.container as any).draw();
      this.xScroller.listen('scrollerchange', (event: any) => {
        const distance = this.xScrollerBounds.max - this.xScrollerBounds.min;
        const min = this.xScrollerBounds.min + event.startRatio * distance;
        const max = this.xScrollerBounds.min + event.endRatio * distance;
        this.xScaleBounds = {
          min: Math.min(min, max),
          max: Math.max(min, max)
        };
        this.updateXScale();
      });
    }

    if (this.yScroller) {
      this.yScroller.container(this.container as any).draw();
      this.yScroller.listen('scrollerchange', (event: any) => {
        const distance = this.yScrollerBounds.max - this.yScrollerBounds.min;
        const min = this.yScrollerBounds.min + (1 - event.startRatio) * distance;
        const max = this.yScrollerBounds.min + (1 - event.endRatio) * distance;
        this.yScaleBounds = {
          min: Math.min(min, max),
          max: Math.max(min, max)
        };
        this.updateYScale();
      });
    }
  }

  updateXScale() {
    if (!this.enableXScroller()) {
      return;
    }
    const scale = this.chart.xScale();
    scale.minimum(this.xScaleBounds.min);
    scale.maximum(this.xScaleBounds.max);
  }

  updateYScale() {
    if (!this.enableYScroller()) {
      return;
    }
    const scale = this.chart.yScale();
    scale.minimum(this.yScaleBounds.min);
    scale.maximum(this.yScaleBounds.max);
  }

  updateScrollers() {
    const bounds: any = this.chart.getPixelBounds();

    if (this.xScroller) {
      updateXScroller(this.xScroller, bounds.height, bounds.width, this.enableXTitle(), this.enableYScroller());
    }
    if (this.yScroller) {
      updateYScroller(this.yScroller, bounds.height, 0, this.enableYTitle(), this.enableXScroller());
    }
  }
}
