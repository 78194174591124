import { Component, Input, Output, OnInit, OnDestroy } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

declare var FileReader: any;
declare var Image: any;

export const ACCEPT_TYPES = {
  image: 'image/*',
  pdf: 'application/pdf'
};

@Component({
  selector: 'file-uploader',
  template
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  private watchers: Function[] = [];

  @Input('@')
  public url: string;
  @Input()
  private ngModel: any;
  @Input('@')
  public attribute?: string;
  @Input('@')
  public deleteEnabled: boolean;
  @Input('@')
  public type: 'image'|'pdf';
  @Input('@')
  public crop: 'circle'|'square';

  @Output()
  onUpload: (data: {$event: any}) => void;

  // TODO: file not in scope when $watch removed
  public accept: string;
  public uploading: boolean = false;
  public uploadProgress: number = 0;
  public croppedImage: {
    file: any;
    data?: any;
    width: number;
    height: number;
  };

  /*@ngInject*/
  constructor(
    private Upload,
    private $http: ng.IHttpService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private $element: ng.IAugmentedJQuery,
    // TODO: remove watcher on file and replace by event-driven function
    private $scope: ng.IScope
  ) {}

  ngOnInit() {
    if (!this.type) {
      this.type = 'image';
    }

    this.accept = ACCEPT_TYPES[this.type];
    if (!this.accept) {
      console.error('Unrecognized type: ' + this.type);
    }
    if (this.type !== 'image' && this.crop) {
      console.error('Crop only available for \'image\' type');
    }

    if (this.crop) {
      this.watchers.push(this.$scope.$watch('file', () => {
        this.updateCroppedFileData(this.$scope.file);
      }));
    }

    this.init();
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  init() {
    this.$scope.file = null;
    this.croppedImage = {
      data: null,
      file: null,
      width: 0,
      height: 0
    };
    this.uploading = false;
  }

  get modelUrl(): string {
    if (this.ngModel) {
      if (this.attribute) {
        if (this.attribute in this.ngModel) {
          return this.ngModel[this.attribute].url;
        }
      }
      else {
        return this.ngModel.url;
      }
    }

    return '';
  }

  upload(file?: any) {
    if (file && !file.$error) {
      const params = {
        url: this.url,
        method: 'PUT',
        data: {}
      };
      const data = this.crop ? this.Upload.dataUrltoBlob(this.croppedImage.file, file.name) : file;

      if (this.attribute) {
        params.data[this.attribute] = data;
      }
      else {
        params.data = data;
      }

      this.uploading = true;

      this.Upload.upload(params).then(response => {
        this.uploadedFileSuccess(response);
      }, err => {
        this.responseError(err);
      }, (evt) => {
        this.uploadProgress = parseInt(`${(100.0 * evt.loaded / evt.total)}`, 10);
      });
    }
  }

  uploadedFileSuccess(response) {
    this.init();

    const data = this.attribute ? response.data[this.attribute] : response.data;
    this.onUpload({
      $event: data
    });

    this.$translate('components.file_uploader.directive.upload.success', {
      filename: this.crop ? response.config.data.file.name : response.config.file.name
    }).then(title => this.toaster.success(title));
  }

  deleteFile() {
    if (!this.deleteEnabled) {
      return;
    }

    const params = {};
    if (this.attribute) {
      params[this.attribute] = null;
    }

    return this.$http.put(this.url, params).then(response => {
      this.deleteFileSuccess(response);
    }, error => {
      this.responseError(error);
    });
  }

  deleteFileSuccess(response) {
    this.init();

    const data = this.attribute ? response.data[this.attribute] : response.data;
    this.onUpload({
      $event: data
    });

    this.$translate('components.file_uploader.directive.delete.success').then(title => {
      this.toaster.success(title);
    });
  }

  responseError(response) {
    this.uploading = false;

    if (response.status === 422) {
      const body = Object.keys(response.data).map(key => {
        return key + ': ' + response.data[key];
      }).join('<br>');

      this.$translate('components.file_uploader.directive.upload.error').then(title => {
        this.toaster.pop({
          type: 'error',
          title,
          body,
          bodyOutputType: 'trustedHtml'
        });
      });
    }
    else if (response.status === 500 || response.status === 403) {
      this.$translate('controller.errors.' + response.status).then(title => {
        this.toaster.error(title);
      });
    }
    else {
      this.$translate('controller.errors.generic').then(title => {
        this.toaster.error(title);
      });
    }
  }

  // Cropping

  updateCroppedImageSize(image) {
    const $crop = this.$element.find('.crop');
    if ($crop) {
      const width = $crop.width() || 1;
      const ratio = width / image.width;

      this.croppedImage.width = width;
      this.croppedImage.height = image.height * ratio;
    }
  }

  updateCroppedFileData(file?: any) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          this.updateCroppedImageSize(image);
          this.croppedImage.data = image.src;
          this.$scope.$digest();
        };
        image.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
    else {
      this.croppedImage.data = null;
      this.croppedImage.file = null;
    }
  }
}
