import { Injectable, Inject } from 'angular-ts-decorators';
import { ReplaySubject, Subscription } from 'rxjs';
import { StrategyList, StrategyListResource } from '@quantizr/front-model';

import { MyUserService } from '../my-user/service';

@Injectable('StrategyListsService')
export class StrategyListsService {
  private subscriptions: Subscription[] = [];
  private _$strategyLists: ReplaySubject<StrategyList[]|null> = new ReplaySubject(1);

  /*@ngInject*/
  constructor(
    @Inject('StrategyListResource')
    private customlistResource: StrategyListResource,
    @Inject('MyUserService')
    myUserService: MyUserService
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      if (user) {
        this.reset();
      }
    }));
  }

  public destroy() {
    this._$strategyLists.next(null);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public $strategyLists() {
    return this._$strategyLists.asObservable();
  }

  public reset(): ng.IPromise<StrategyList[]> {
    return this.customlistResource.query({}).$promise.then((strategyLists) => {
      this._$strategyLists.next(strategyLists);
      return strategyLists;
    }, () => {
      this._$strategyLists.next([]);
      return [];
    });
  }
}
