import { Component, Inject } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { StrategyResource, Strategy, MyUser, StrategySearchResponse } from '@quantizr/front-model';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import cloneDeep = require('lodash/cloneDeep');
import merge = require('lodash/merge');

import './page.scss';
const template = require('./page.html.haml');

import { FILTERS, LOCAL_STORAGE_KEYS } from '../';
import { StrategiesLocalStorageService } from '../local-storage/service';
import { typeFromPath, strategiesType } from '../type';
import { StrategyListsService } from '../../customlists';
import { LOADING_STATES, CustomSearchRequestsService } from '../../custom-search';
import { ITableSettingsGroup, TableSettingsController } from '../../components';
import { MyUserService } from '../../my-user';

const WINDOWS = ['1_month', '3_months', '6_months', '1_year', '2_years', '3_years'];

export interface ISearchSort {
  sort_asc: 'asc'|'desc';
  sort_timeline: string;
  sort_type: string;
  page: number;
}

@Component({
  selector: 'page-strategies-index',
  template
})
export class StrategiesIndexPage {
  private watchers: Function[] = [];
  private customlistsSubscription: Subscription|null = null;
  private user: MyUser|null = null;

  public FILTERS = FILTERS;
  public WINDOWS = WINDOWS;

  public title = '';
  public strategiesFilter: string;
  public strategiesType: strategiesType|null = null;
  public loadingState = LOADING_STATES.LOADING;
  public results: Strategy[] = [];
  public saving = 0;
  public selectedElements: number[] = [];
  public sort: ISearchSort = {
    sort_asc: 'desc',
    sort_timeline: '1_year',
    sort_type: 'priority',
    page: 1
  };
  public filters: any = {};
  public criteriaVolumes: any = {};
  public membership = settings.MEMBERSHIP.defaults;

  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController = new TableSettingsController('strategies-index-results');

  /*@ngInject*/
  constructor(
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('MyUserService')
    private myUserService: MyUserService,
    @Inject('StrategyListsService')
    private strategyListsService: StrategyListsService,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategiesLocalStorageService')
    private strategiesLocalStorageService: StrategiesLocalStorageService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    $rootScope: ng.IRootScopeService
  ) {
    this.strategiesFilter = $routeParams.filter;
    this.strategiesType = typeFromPath($location);

    this.tableSettingsGroups = [{
      key: 'column',
      title: $translate.instant('timeSeries.categories.')
    }];

    this.watchers.push($rootScope.$on('$routeChangeSuccess', (_event, route) => {
      // Called when changing filters or change from strategies to portfolios
      if (route && route.$$route.segment.indexOf('strategies.index') !== -1) {
        this.selectedElements.splice(0, this.selectedElements.length);
        this.initSuccess(this.user);
      }
    }));
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
    if (this.customlistsSubscription) {
      this.customlistsSubscription.unsubscribe();
    }
    this.customSearchRequestsService.cancelAll();
  }

  init() {
    if (this.customlistsSubscription) {
      this.customlistsSubscription.unsubscribe();
    }
    // Searching from the home page, we store the key in the url and apply it as a filter
    if (this.$routeParams.text) {
      this.strategiesLocalStorageService.set(LOCAL_STORAGE_KEYS.CRITERIA, {
        text: this.$routeParams.text
      });
      this.goToDefaultSearch();
    }
    // When no filter, we use the "all" filter
    else if (!this.strategiesFilter) {
      this.goToDefaultSearch();
    }
    else {
      this.myUserService.currentUser().then(user => this.initSuccess(user));
    }
  }

  initSuccess(user) {
    this.user = user;
    this.strategiesType = typeFromPath(this.$location);
    this.strategiesFilter = this.$routeParams.filter;
    this.sort = {
      sort_asc: 'desc',
      sort_timeline: '1_year',
      sort_type: 'priority',
      page: 1
    };

    if (this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.SORT)) {
      merge(this.sort, this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.SORT));
    }

    if (this.user) {
      this.initTitle();
    }
  }

  async initTitle() {
    if (this.$routeParams.filter === FILTERS.ME || this.$routeParams.filter === FILTERS.ALL) {
      this.$translate(
        `strategies.index.view${this.$location.path().replace(new RegExp('/', 'g'), '.')}`
      ).then(title => this.title = title);
    }
    else {
      this.customlistsSubscription = this.strategyListsService.$strategyLists().subscribe(
        strategyLists => {
          const currentCustomList = (strategyLists || []).filter((customlist) => {
            return customlist.id === parseInt(this.$routeParams.filter, 0);
          });
          if (currentCustomList.length === 0) {
            this.goToDefaultSearch();
          }
          else {
            this.title = currentCustomList[0].name!;
          }
        }
      );
    }
  }

  goToDefaultSearch() {
    this.goTo('/' + this.strategiesType + '/' + FILTERS.ALL);
  }

  goTo(path) {
    (this.$location as any).$$search = {};
    this.$location.path(path);
  }

  selected() {
    if (this.membership.versions) {
      return this.selectedElements.length === this.results.length ||
        this.selectedElements.length === this.membership.versions.max_strategies_count;
    }

    return false;
  }

  toggleSelect() {
    if (this.selected()) {
      this.selectedElements.splice(0, this.selectedElements.length);
    }
    else {
      this.results.forEach(value => {
        if (value['show?']) {
          this.selectedElements.push(value.id);
        }
      });
    }

    this.selectedElements.splice(
      this.membership.versions.max_strategies_count,
      this.selectedElements.length - this.membership.versions.max_strategies_count
    );
  }

  search(filters?: any) {
    if (!!filters) {
      this.filters = cloneDeep(filters);
      this.filters.company_ids = JSON.stringify(this.filters.company_ids);
      this.filters.keywords = JSON.stringify(this.filters.keywords);
      this.filters.search_type = 'standard';
    }

    if (this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.VOLUMES)) {
      this.criteriaVolumes = this.strategiesLocalStorageService.get(LOCAL_STORAGE_KEYS.VOLUMES);
      return this.query();
    }

    this.saving = 1;
    this.selectedElements = [];
    this.loadingState = LOADING_STATES.LOADING;
    this.sort.page = 1;

    const request = this.strategyResource.search(this.filters);

    this.customSearchRequestsService.cancel(undefined, 'search_strategies');
    this.customSearchRequestsService.add(request, 'search_strategies');

    return request.$promise.then((response) => {
      this.customSearchRequestsService.remove(request, 'search_strategies');
      this.searchSuccess(response);

      return response;
    }, () => {
      this.customSearchRequestsService.remove(request, 'search_strategies');
      this.searchError();

      return {};
    });
  }

  searchSuccess(response: StrategySearchResponse) {
    this.criteriaVolumes = response.volumes;
    this.saving = 0;
    this.strategiesLocalStorageService.set(LOCAL_STORAGE_KEYS.VOLUMES, this.criteriaVolumes);

    this.query();
  }

  searchError() {
    this.criteriaVolumes = {
      total_results: 0
    };
    this.strategiesLocalStorageService.set(LOCAL_STORAGE_KEYS.VOLUMES, this.criteriaVolumes);

    this.loadingState = LOADING_STATES.IDLE;
    this.results = [];
    this.saving = 2;
  }

  query() {
    this.strategiesLocalStorageService.set(LOCAL_STORAGE_KEYS.SORT, this.sort);

    const params = merge({ search_type: 'standard' }, this.sort, this.filters);
    const request = this.strategyResource.query(params);

    this.loadingState = LOADING_STATES.LOADING;

    this.customSearchRequestsService.cancel(undefined, 'query_strategies');
    this.customSearchRequestsService.add(request, 'query_strategies');

    return request.$promise.then((response) => {
      this.customSearchRequestsService.remove(request, 'query_strategies');
      this.loadingState = LOADING_STATES.IDLE;
      this.results = response;

      return this.results;
    }, () => {
      this.customSearchRequestsService.remove(request, 'query_strategies');
      this.loadingState = LOADING_STATES.IDLE;
      this.results = [];

      return this.results;
    });
  }
}
