import { isAuthenticated } from '../my-user';

export const isAuthenticatedResolve = {
  isAuthenticated: ['MyUserService', isAuthenticated]
};

export const config = (
  $routeProvider: ng.route.IRouteProvider,
  $routeSegmentProvider: ng.routeSegment.IRouteSegmentProvider
) => {
  $routeSegmentProvider
    // --- Cockpits
    .when('/cockpit/buylists', 'cockpits.buylists')
    .when('/cockpit/edit', 'cockpits.edit')
    .when('/cockpit/edit/profiles/:profileId', 'cockpits.editProfiles')
    .when('/cockpit/edit/profiles/:profileId/portfolios', 'cockpits.editProfiles.portfolios')
    .when('/cockpit/edit/profiles/:profileId/strategies', 'cockpits.editProfiles.strategies')
    .when('/cockpit/edit/strategies', 'cockpits.editStrategies')
    .when('/cockpit/esg', 'cockpits.esg')
    .when('/cockpit/esg/:profileId', 'cockpits.esg.show')
    .when('/cockpit/holdings', 'cockpits.holdings')
    .when('/cockpit/holdings/:profileId', 'cockpits.holdings.general')
    .when('/cockpit/holdings/:profileId/equity', 'cockpits.holdings.equity')
    .when('/cockpit/holdings/:profileId/fixedIncome', 'cockpits.holdings.fixedIncome')
    .when('/cockpit/holdings/:profileId/derivative', 'cockpits.holdings.derivative')
    .when('/cockpit/monitoring', 'cockpits.monitoring')
    .when('/cockpit/monitoring/:profileId', 'cockpits.monitoring.show')
    .when('/cockpit/perf-contribution', 'cockpits.perfContribution')
    .when('/cockpit/perf-contribution/:profileId', 'cockpits.perfContribution.show')
    .when('/cockpit/risk-contribution', 'cockpits.riskContribution')
    .when('/cockpit/risk-contribution/:profileId', 'cockpits.riskContribution.show')
    .when('/cockpit/weights', 'cockpits.weights')
    .when('/cockpit/weights/:profileId', 'cockpits.weights.show')
    .segment('cockpits', {
      template: '<page-cockpits-views></page-cockpits-views>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('axes', {
          template: '<div app-view-segment="2"></div>'
        })
          .within()
            .segment('edit', {
              template: '<page-cockpits-axes-edit></page-cockpits-axes-edit>',
              dependencies: ['profileId']
            })
            .segment('index', {
              template: '<page-cockpits-axes-index></page-cockpits-axes-index>'
            })
            .segment('new', {
              template: '<page-cockpits-axes-edit></page-cockpits-axes-edit>'
            })
          .up()
        .segment('buylists', {
          template: '<page-cockpits-buylists></page-cockpits-buylists>'
        })
        .segment('edit', {
          template: '<page-cockpits-edit></page-cockpits-edit>'
        })
        .segment('editProfiles', {
          template: '<div app-view-segment="2"></div>',
          dependencies: ['profileId']
        })
          .within()
            .segment('portfolios', {
              template: '<page-cockpits-edit-profiles-portfolios></page-cockpits-edit-profiles-portfolios>'
            })
            .segment('strategies', {
              default: true,
              template: '<page-cockpits-edit-profiles-strategies></page-cockpits-edit-profiles-strategies>'
            })
          .up()
        .segment('editStrategies', {
          template: '<page-cockpits-edit-strategies></page-cockpits-edit-strategies>'
        })
        .segment('esg', {
          template: '<page-cockpits-esg></page-cockpits-esg>'
        })
          .within()
            .segment('show', {
              template: '<page-cockpits-esg-show></page-cockpits-esg-show>',
              dependencies: ['profileId']
            })
          .up()
        .segment('holdings', {
          template: '<page-cockpits-holdings></page-cockpits-holdings>'
        })
          .within()
            .segment('general', {
              template: '<page-cockpits-holdings-general></page-cockpits-holdings-general>',
              dependencies: ['profileId']
            })
            .segment('fixedIncome', {
              template: '<page-cockpits-holdings-fixed-income></page-cockpits-holdings-fixed-income>',
              dependencies: ['profileId']
            })
            .segment('equity', {
              template: '<page-cockpits-holdings-equity></page-cockpits-holdings-equity>',
              dependencies: ['profileId']
            })
            .segment('derivative', {
              template: '<page-cockpits-holdings-derivative></page-cockpits-holdings-derivative>',
              dependencies: ['profileId']
            })
          .up()
        .segment('monitoring', {
          default: true,
          template: '<page-cockpits-monitoring></page-cockpits-monitoring>'
        })
          .within()
            .segment('show', {
              template: '<page-cockpits-monitoring-show></page-cockpits-monitoring-show>',
              dependencies: ['profileId']
            })
          .up()
        .segment('perfContribution', {
          template: '<page-cockpits-perf-contribution></page-cockpits-perf-contribution>',
        })
          .within()
            .segment('show', {
              template: '<page-cockpits-perf-contribution-show></page-cockpits-perf-contribution-show>',
              dependencies: ['profileId']
            })
          .up()
        .segment('riskContribution', {
          template: '<page-cockpits-risk-contribution></page-cockpits-risk-contribution>',
        })
          .within()
            .segment('show', {
              template: '<page-cockpits-risk-contribution-show></page-cockpits-risk-contribution-show>',
              dependencies: ['profileId']
            })
          .up()
        .segment('weights', {
          template: '<page-cockpits-weights></page-cockpits-weights>'
        })
          .within()
            .segment('show', {
              template: '<page-cockpits-weights-show></page-cockpits-weights-show>',
              dependencies: ['profileId']
            })
          .up()
      .up()

    // --- Errors
    .when('/401', 'error.index')
    .segment('error', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('index', {
          template: '<page-errors-index></page-errors-index>'
        })
      .up()

    // --- Glossary
    .when('/glossary', 'glossary.index')
    .segment('glossary', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('index', {
          template: '<page-glossary-index></page-glossary-index>',
          reloadOnSearch: false
        })
      .up()

    // --- Logged in users
    .when('/my', 'myUser.show')
    .segment('myUser', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('show', {
          template: '<page-my-user-show></page-my-user-show>'
        })
      .up()

    // --- Simulations
    .when('/simulations', 'simulations.index')
    .when('/simulations/new', 'simulations.new')
    .when('/simulation/:id', 'simulations.show')
    .when('/simulation/:id/result', 'simulations.result')
    .segment('simulations', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('index', {
          template: '<page-simulations-index></page-simulations-index>'
        })
        .segment('new', {
          template: '<page-simulations-new></page-simulations-new>'
        })
        .segment('show', {
          template: '<page-simulations-show></page-simulations-show>',
          dependencies: ['id']
        })
        .segment('result', {
          template: '<page-simulations-result></page-simulations-result>',
          dependencies: ['id']
        })
      .up()
      // --- Files
    .when('/files/:provider_config_id', 'files.index')
    .when('/file', 'files.show')
    .segment('files', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('index', {
          template: '<page-files-index></page-files-index>',
          dependencies: ['provider_config_id']
        })
        .segment('show', {
          template: '<page-files-show></page-files-show>',
          dependencies: ['id']
        })
      .up()

    // --- Strategies
    .when('/portfolios/new', 'strategies.new')
    .when('/portfolios/:filter', 'strategies.index')
    .when('/strategies/new', 'strategies.new')
    .when('/strategies/dashboard/:id', 'strategies.dashboards')
    .when('/strategies/:filter', 'strategies.index')
    .when('/comparator/:ids', 'strategies.comparator')
    .when('/customlists/:filter', 'strategies.index')
    .when('/strategy/:id/edit', 'strategies.edit')
    .when('/strategy/:id/edit/calculation', 'strategies.edit.calculation')
    .when('/strategy/:id/edit/fixings', 'strategies.edit.fixings')
    .when('/strategy/:id/edit/permissions', 'strategies.edit.permissions')
    .when('/strategy/:id/edit/settings', 'strategies.edit.settings')
    .when('/strategy/:id', 'strategies.show')
    .when('/strategy/:id/about', 'strategies.show.about')
    .when('/strategy/:id/benchmark', 'strategies.show.benchmark')
    .when('/strategy/:id/company', 'strategies.show.company')
    .when('/strategy/:id/factors', 'strategies.show.factors')
    .when('/strategy/:id/esg', 'strategies.show.esg')
    .when('/strategy/:id/holdings', 'strategies.show.holdings.general')
    .when('/strategy/:id/holdings/equity', 'strategies.show.holdings.equity')
    .when('/strategy/:id/holdings/fixedIncome', 'strategies.show.holdings.fixedIncome')
    .when('/strategy/:id/holdings/derivative', 'strategies.show.holdings.derivative')
    .when('/strategy/:id/performances', 'strategies.show.performances')
    .when('/strategy/:id/strategies', 'strategies.show.strategies')
    .when('/strategy/:id/strategies/:compositionStrategyId', 'strategies.show.strategies.composition')
    .when('/strategy/:id/weights', 'strategies.show.weights')
    .when('/strategy/:id/pdf', 'strategies.pdf')
    .segment('strategies', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('index', {
          default: true,
          template: '<page-strategies-index></page-strategies-index>'
        })
        .segment('dashboards', {
            template: '<page-dashboards-show></page-dashboards-show>',
            dependencies: ['id']
        })
        .segment('new', {
          template: '<page-strategies-new></page-strategies-new>'
        })
        .segment('comparator', {
          template: '<page-strategies-comparator></page-strategies-comparator>'
        })
        .segment('show', {
          template: '<page-strategies-show></page-strategies-show>',
          dependencies: ['id']
        })
          .within()
            .segment('about', {
              template: '<page-strategies-show-about></page-strategies-show-about>'
            })
            .segment('benchmark', {
              template: '<page-strategies-show-benchmark></page-strategies-show-benchmark>'
            })
            .segment('company', {
              template: '<page-strategies-show-company></page-strategies-show-company>'
            })
            .segment('factors', {
              template: '<page-strategies-show-factors></page-strategies-show-factors>'
            })
            .segment('holdings', {
              template: '<page-strategies-show-holdings></page-strategies-show-holdings>'
            })
            .within()
              .segment('general', {
                default: true,
                template: '<page-strategies-show-holdings-general></page-strategies-show-holdings-general>'
              })
              .segment('equity', {
                template: '<page-strategies-show-holdings-equity></page-strategies-show-holdings-equity>'
              })
              .segment('fixedIncome', {
                template: '<page-strategies-show-holdings-fixed-income></page-strategies-show-holdings-fixed-income>'
              })
              .segment('derivative', {
                template: '<page-strategies-show-holdings-derivative></page-strategies-show-holdings-derivative>'
              })
            .up()
            .segment('esg', {
              template: '<page-strategies-show-esg></page-strategies-show-esg>'
            })
            .segment('performances', {
              default: true,
              template: '<page-strategies-show-performance></page-strategies-show-performance>'
            })
            .segment('strategies', {
              template: '<div app-view-segment="3"></div>',
              dependencies: ['compositionStrategyId'] // reload views if id changes
            })
            .within()
              .segment('default', {
                default: true,
                template: '<page-strategies-show-versions></page-strategies-show-versions>'
              })
              .segment('composition', {
                template: '<page-strategies-show-versions-composition></page-strategies-show-versions-composition>'
              })
            .up()
            .segment('weights', {
              template: '<page-strategies-show-weights></page-strategies-show-weights>'
            })
          .up()
        .segment('pdf', {
          template: '<page-strategies-pdf></page-strategies-pdf>',
          dependencies: ['id']
        })
        .segment('edit', {
          template: '<page-strategies-edit></page-strategies-edit>',
          dependencies: ['id']
        })
        .within()
          .segment('settings', {
            default: true,
            template: '<page-strategies-edit-settings></page-strategies-edit-settings>'
          })
          .segment('calculation', {
            template: '<page-strategies-edit-calculation></page-strategies-edit-calculation>'
          })
          .segment('fixings', {
            template: '<page-strategies-edit-fixings></page-strategies-edit-fixings>'
          })
          .segment('permissions', {
            template: '<page-strategies-edit-permissions></page-strategies-edit-permissions>'
          })
        .up()
      .up()
    // --- Dashboards
    .when('/:module/dashboards', 'dashboard.show')
    .when('/:module/dashboard/:id', 'dashboard.show')
    .segment('dashboard', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('show', {
          template: '<page-dashboards-show></page-dashboards-show>',
          dependencies: ['id']
        })
      .up()

    // --- Treemap
    .when('/treemap', 'treemap.show')
    .segment('treemap', {
      template: '<div app-view-segment="1"></div>',
      resolve: isAuthenticatedResolve
    })
      .within()
        .segment('show', {
          template: '<page-treemap-show></page-treemap-show>'
        })
      .up()

    // --- Not logged in users
    .when('/users/password/edit', 'users.passwordEdit')
    .when('/users/password/reset', 'users.passwordReset')
    .when('/users/signin', 'users.signin')
    .when('/users/signup', 'users.signup')
    .when('/users/invitation', 'users.invitation')
    .segment('users', {
      template: '<div app-view-segment="1"></div>'
    })
      .within()
        .segment('passwordEdit', {
          template: '<page-my-user-password-edit></page-my-user-password-edit>'
        })
        .segment('passwordReset', {
          template: '<page-my-user-password-reset></page-my-user-password-reset>'
        })
        .segment('signin', {
          template: '<page-my-user-signin></page-my-user-signin>'
        })
        .segment('signup', {
          template: '<page-my-user-signup></page-my-user-signup>'
        })
        .segment('invitation', {
          template: '<page-my-user-invitation></page-my-user-invitation>'
        })
      .up();

  return $routeProvider.otherwise({
    redirectTo: '/market/dashboards'
  });
};
