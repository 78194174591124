import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-cartesian.min';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { tooltipStyle } from '../base-chart/component';
import { BaseChartZoomComponent } from '../base-chart/zoom/component';

export interface IColumnChartColumnData {
  x: string;
  value: number;
  fill?: string;
  stroke?: string|{
    color: string;
    thickness?: number;
  };
}

export interface IColumnChartLineData {
  x: string;
  value: number;
}

interface IColumnChartLegend {
  enabled?: boolean;
  type?: string;
}

interface IColumnChartColumn {
  name: string;
  legend?: IColumnChartLegend;
}

export interface IColumnChartColumnDataset extends IColumnChartColumn {
  column?: IColumnChartColumnData[];
}

export interface IColumnChartLineDataset extends IColumnChartColumn {
  line?: IColumnChartLineData[];
  stroke?: string|{
    color: string;
    thickness?: number;
  };
}

export type columnChartData = IColumnChartColumnData|IColumnChartLineData;
export type columnChartDataset = IColumnChartColumnDataset|IColumnChartLineDataset;

export const stepLabel = (min: number, max: number, step: number, current: number) => {
  return current === min ? `<${min}` : (current > max ? `>${max}` : `${current - step}-${current}`);
};

type valueFormat = 'percent'|'value';

const roundValue = (value: number|string) => {
  return typeof value === 'number' ? value.toFixed(2) : value;
};

const formatValue = (valueFormat: valueFormat, value: number|string) => {
  return valueFormat === 'percent' ? `${value}%` : `${value}`;
};

const customizeLegend = (legend: anychart.core.utils.LegendItemSettings, settings: IColumnChartLegend) => {
  if (typeof settings.enabled !== 'undefined') {
    legend.enabled(settings.enabled);
  }
  if (settings.type) {
    legend.iconType(settings.type);
  }
};

@Component({
  selector: 'column-chart',
  template
})
export class ColumnChartComponent extends BaseChartZoomComponent<anychart.charts.Cartesian> implements OnChanges {
  @Input()
  public dataset: columnChartData[]|columnChartDataset[] = [];
  @Input()
  public valueFormat: valueFormat = 'percent';
  @Input()
  tooltipPrefix = '';

  ngOnChanges(changes: SimpleChanges) {
    if ('settings' in changes) {
      this.removeChart();
      this.initChart();
      this.drawChart();
    }

    super.ngOnChanges(changes);
  }

  initChart() {
    const ctrl = this;
    const settings = merge({
      chart: {
        type: 'column',
        credits: {
          enabled: false
        },
        barGroupsPadding: 1,
        tooltip: {
          background: tooltipStyle.background,
          padding: tooltipStyle.padding,
          format() {
            return `${ctrl.tooltipPrefix}${formatValue(ctrl.valueFormat, roundValue(this.value))}`;
          }
        },
        xAxis: {
          enabled: true
        },
        xMinorGrid: {
          enabled: false
        },
        yAxis: {
          enabled: true,
          labels: {
            format() {
              return formatValue(ctrl.valueFormat, roundValue(this.value));
            }
          }
        },
        yMinorGrid: {
          enabled: false
        }
      }
    }, this.settings || {});
    this.chart = anychart.fromJson(settings);

    if (settings.chart.xAxis) {
      this.chart.xAxis().enabled(settings.chart.xAxis.enabled);

      if (settings.chart.xAxis.labels) {
        this.chart.xAxis().labels().format(settings.chart.xAxis.labels.format);
      }
    }

    if (settings.chart.xAxis) {
      this.chart.xAxis().enabled(settings.chart.xAxis.enabled);

      if (settings.chart.yAxis.labels) {
        this.chart.yAxis().labels().format(settings.chart.yAxis.labels.format);
      }
    }

    this.chart.xMinorGrid().enabled(settings.chart.xMinorGrid.enabled);
    this.chart.yMinorGrid().enabled(settings.chart.yMinorGrid.enabled);
  }

  refresh() {
    if (!this.chart) {
      return;
    }

    this.chart.removeAllSeries();

    // set boundaries on yScale
    // TODO: this is not the correct condition
    if (!this.chart.yMinorGrid().enabled()) {
      this.chart.yScale().minimum(0);

      if (this.valueFormat === 'percent') {
        this.chart.yScale().maximum(100);
      }
      else {
        const dataset = this.dataset as columnChartData[];
        this.chart.yScale().maximum(dataset.sort((a, b) => b.value - a.value)[0].value);
      }
    }

    if (this.chart.yScale().stackMode() !== 'none' && this.dataset.length) {
      const datasets = this.dataset as columnChartDataset[];

      // draw lines first
      datasets.map(val => val as IColumnChartLineDataset).filter(val => val.line).forEach(dataset => {
        const serie = this.chart.line(dataset.line).name(dataset.name);
        if (dataset.stroke) {
          serie.stroke(dataset.stroke);
        }
        if (dataset.legend) {
          customizeLegend(serie.legendItem(), dataset.legend);
        }
      });
      // draw columns
      datasets.map(val => val as IColumnChartColumnDataset).filter(val => val.column).forEach(dataset => {
        const serie = this.chart.column(dataset.column).name(dataset.name);
        if (dataset.legend) {
          customizeLegend(serie.legendItem(), dataset.legend);
        }
      });
    }
    else {
      this.chart.column(this.dataset);
    }

    this.onRefreshedDone();
  }
}
