import { Injectable, Inject } from 'angular-ts-decorators';
import { MyUser, MyUserResource } from '@quantizr/front-model';
import { ReplaySubject } from 'rxjs';
import merge = require('lodash/merge');
import * as angular from 'angular';

export const cookieIsLoggedIn = 'is_logged_in';

export interface ILoginMyUser extends MyUser, ng.devise.IAuthCredentials {
  password?: string;
  client: string;
  asset_classes?: string[];
  instruments?: string[];
  areas?: string[];
}

@Injectable('MyUserService')
export class MyUserService {
  private _$user: ReplaySubject<MyUser|null> = new ReplaySubject(1);

  private _user: MyUser|null = null;

  public get user(): MyUser|null {
    return this._user;
  }

  public set user(user: MyUser|null) {
    if (!this._user || !user || this._user.id !== user.id) {
      const isLoggedIn = !!user;
      this.localStorageService.set(cookieIsLoggedIn, isLoggedIn.toString());
      this._user = angular.fromJson(angular.toJson(user));
      this._$user.next(this._user);
    }
  }

  /*@ngInject*/
  constructor(
    public localStorageService: ng.local.storage.ILocalStorageService,
    public $location: ng.ILocationService,
    private $q: ng.IQService,
    private Auth: ng.devise.IAuthProvider<ILoginMyUser>,
    @Inject('MyUserResource')
    private myUserResource: MyUserResource
  ) {}

  /**
   * Retrieve the logged in user. Only executes the request once, will return the already fetched user otherwise.
   */
  currentUser(): angular.IPromise<MyUser|null> {
    const isLoggedIn = this.localStorageService.get(cookieIsLoggedIn);

    if (!!isLoggedIn && isLoggedIn !== 'false' && this.user) {
      const deferred = this.$q.defer();
      deferred.resolve(this.user);
      return deferred.promise;
    } else if (isLoggedIn === 'false' && !this.user) {
      const deferred = this.$q.defer();
      deferred.resolve(undefined);
      return deferred.promise;
    }

    return this.myUserResource.get().$promise
      .then((user) => {
        this.user = user;
      })
      .catch(() => this.user = null)
      .then(() => this.user);
  }

  $user() {
    return this._$user.asObservable();
  }

  /**
   * Redirectes to the signin page
   */
  requestLogin() {
    this.$location.path('/users/signin');
  }

  login(user: ILoginMyUser, config: any = {}) {
    return this.Auth.login(user, merge(config, {
      headers: {
        'X-HTTP-Method-Override': 'POST'
      }
    }, true));
  }

  logout() {
    return this.Auth.logout().then(() => {
      this.user = null;
      this.localStorageService.clearAll();
      return this.requestLogin();
    });
  }

  /**
   * Redirects to the signup page
   */
  requestSignup() {
    this.$location.path('/users/signup');
  }

  register(user: ILoginMyUser, config: any = {}) {
    return this.Auth.register(user, merge(config, {
      headers: {
        'X-HTTP-Method-Override': 'POST'
      }
    }, true));
  }
}
