import { Component, Input, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from 'angular-ts-decorators';
import { StrategyGrouped, StrategyGroupedDetail, GroupField } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { MapChartComponent } from '../../../../components';
import { COUNTRY_GROUPS } from '../general/page';
let mapCharts = 0;

@Component({
  selector: 'strategies-show-holdings-geography',
  template
})
export class StrategiesShowHoldingsGeographyComponent implements AfterViewInit, OnChanges {
  @ViewChild(MapChartComponent)
  public chart: MapChartComponent;

  @Input('<?')
  public dataset: StrategyGroupedDetail[] = [];
  @Input()
  public groupedData: StrategyGrouped;
  @Input()
  public showMap = true;
  @Input()
  public hideFullMapButton = false;
  @Input()
  public showControls = true;

  public SECTIONS = COUNTRY_GROUPS;

  public mapContainerId = `strategies-show-holdings-geography-map-chart-${++mapCharts}`;
  public maxCount = 10;
  public showAll = false;
  public section: GroupField = this.SECTIONS[0];
  public countries: Array<{
    id: string;
    name: string;
    weight: number;
  }> = [];

  /*@ngInject*/
  constructor(
    private ngDialog: ng.dialog.IDialogService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
  ) {}

  ngAfterViewInit() {
    this.updateMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('showMap' in changes) {
      this.updateMap();
    }

    if ('dataset' in changes) {
      const dataset = changes.dataset.currentValue.map((data: StrategyGroupedDetail) => {
        return {
          id: data.name,
          name: data.name,
          weight: data.weight.total
        };
      });
      this.countries = dataset;

      this.onChartReady().then(() => {
        this.chart.dataset = dataset;
        this.chart.refresh();
      });
    }
  }

  onChartReady() {
    if (this.chart) {
      return this.$timeout(() => {});
    }

    return this.$timeout(() => this.onChartReady());
  }

  updateMap() {
    if (this.showMap) {
      this.$timeout(() => {
        (this as any)._updateViewChildren();
      });
    }
  }

  displayFullMap() {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.dataset = this.dataset;
    newScope.groupedData = this.groupedData;

    return this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <strategies-show-holdings-geography class="in-dialog"
          hide-full-map-button="true" dataset="dataset" grouped-data="groupedData"
        ></strategies-show-holdings-geography>
      `,
      plain: true,
      scope: newScope
    });
  }

  showHide() {
    this.showAll = !this.showAll;
  }
}
