import { RED_TO_GREEN as COLORS } from '../../../components/base-chart/colors';

export const colorFor = (values: any, value: number) => {
  if (values.min === undefined || values.max === undefined) {
    setMinMax(values);
  }

  value = (value - values.min) / (values.max - values.min) * COLORS.length;

  if (value < 0) {
    return COLORS[COLORS.length - 1];
  }
  else if (value > (COLORS.length - 1)) {
    return COLORS[0];
  }
  else {
    return COLORS[COLORS.length - 1 - Math.ceil(value)];
  }
};

export const setMinMax = (values) => {
  let min = 999;
  let max = -999;

  for (const subValuesKey in values) {
    if (values.hasOwnProperty(subValuesKey)) {
      for (const key in values[subValuesKey]) {
        if (values[subValuesKey].hasOwnProperty(key)) {
          if (values[subValuesKey][key] != undefined && values[subValuesKey][key] > max) {
            max = values[subValuesKey][key];
          }
          if (values[subValuesKey][key] != undefined && values[subValuesKey][key] < min) {
            min = values[subValuesKey][key];
          }
        }
      }
    }
  }

  values.min = min;
  values.max = max;
};
