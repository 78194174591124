import {
  Component, Inject, Input, AfterViewInit, OnDestroy, ViewChild
} from 'angular-ts-decorators';
import {
  Simulation, SimulationIndicator, SimulationWidget,
  SimulationTimeSerie, SimulationTimeSerieResource
} from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/simulations/variables';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { CustomSearchRequestsService } from '../../../custom-search';
import { ScatterChartComponent, axisLabel, AxisType } from '../../../components/scatter-chart/component';
import { tooltipStyle } from '../../../components/base-chart/component';

export const titleFor = (
  $translate: ng.translate.ITranslateService,
  widget: SimulationWidget, type: 'x'|'y'
): string => {
  if (!widget) {
    return '';
  }
  else if (widget[type + '_type'] === 'indicator') {
    return [
      $translate.instant(
        'simulations.simulation_indicators.short_windows.' + widget[type + '_window']
      ),
      $translate.instant(
        'simulations.simulation_indicators.categories.' + widget[type + '_category']
      )
    ].join(' ');
  }
  else {
    const param = widget[type + '_parameter'].split('.');
    return param && param[param.length - 1] &&
      $translate.instant('simulations.shift_parameters.' + param[param.length - 1]);
  }
};

export const typeFor = (widget: SimulationWidget, type: 'x'|'y'): AxisType => {
  if (!widget) {
    return '';
  }
  else if (widget[type + '_type'] === 'indicator') {
    return 'float';
  }
  else {
    const param = widget[type + '_parameter'].split('.');
    return param && param[param.length - 1] &&
      variables.SHIFT_PARAMETERS_CONFIG[param[param.length - 1]].type;
  }
};

let components = 0;

@Component({
  selector: 'simulations-widgets-x-y-chart',
  template
})
export class SimulationsWidgetsXYChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ScatterChartComponent)
  public chart: ScatterChartComponent;

  @Input()
  simulation: Simulation;
  @Input()
  widget: SimulationWidget;

  private namespace = `simulations-widgets-x-y-chart-${++components}`;
  private chartTimeout: ng.IPromise<any>;

  public containerId: string;
  public dataset: any[];
  public xElement: SimulationIndicator;
  public yElement: SimulationIndicator;
  public settings: {
    chart: any
  } = {
    chart: {}
  };

  /*@ngInject*/
  constructor(
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    @Inject('SimulationTimeSerieResource')
    private simulationTimeSerieResource: SimulationTimeSerieResource,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnDestroy() {
    this.customSearchRequestsService.remove(undefined, this.namespace);
    this.$timeout.cancel(this.chartTimeout);
  }

  ngAfterViewInit() {
    this.xElement = this.simulation.simulation_indicators![0];
    this.yElement = this.simulation.simulation_indicators![1];
    const ctrl = this;
    this.settings = {
      chart: {
        tooltip: {
          useHtml: true,
          titleFormat() {
            const name = this.getData('name');
            const x = this.getData('x');
            const value = this.getData('value');
            return ctrl.tooltipTitleFormat(name, x, value);
          },
          format() {
            const values = this.getData('input_values');
            return ctrl.tooltipFormat(values);
          },
          padding: tooltipStyle.padding,
          background: tooltipStyle.background
        }
      }
    };

    if (this.widget) {
      this.containerId = 'xy-chart-' + this.widget.id;
      this.refreshGraph();
    }
    else {
      this.simulation.simulation_indicators!.forEach(simulationIndicator => {
        if (simulationIndicator.window === '1_year') {
          if (simulationIndicator.category === 'return_pa') {
            this.yElement = simulationIndicator;
          }
          else if (simulationIndicator.category === 'volatility') {
            this.xElement = simulationIndicator;
          }
        }
      });

      this.containerId = 'xy-chart-general';
      this.updateWidget(this.xElement, this.yElement);
    }
  }

  updateWidget(xElement: SimulationIndicator, yElement: SimulationIndicator) {
    this.widget = {
      id: null,
      simulation_id: null,
      chart: 'xy_chart',
      x_type: 'indicator',
      x_category: xElement.category,
      x_window: xElement.window,
      y_type: 'indicator',
      y_category: yElement.category,
      y_window: yElement.window
    } as any;
    this.refreshGraph();
  }

  async refreshGraph() {
    const queryParams = merge(this.widget, {
      simulation_id: this.simulation.id
    });
    const request = this.simulationTimeSerieResource.query(queryParams);

    this.customSearchRequestsService.add(request, this.namespace);

    if (this.widget.y_category === 'volatility') {
      this.settings.chart.yScale = {
        minimum: 0
      };
    }

    let response: SimulationTimeSerie[];
    try {
      response = await request.$promise;
    }
    catch (err) {
      response = [];
    }

    this.refreshGraphSuccess(response);
    this.customSearchRequestsService.remove(request, this.namespace);

    return response;
  }

  refreshGraphSuccess(response: SimulationTimeSerie[]) {
    this.chartTimeout = this.$timeout(() => {
      this.chart.dataset = response;
      this.chart.refresh();
    });
  }

  title() {
    return `${this.xTitle()} VS. ${this.yTitle()}`;
  }

  xTitle() {
    return titleFor(this.$translate, this.widget, 'x');
  }

  xAxisLabel = (value: any): string => {
    return axisLabel(value, typeFor(this.widget, 'x'));
  }

  yTitle() {
    return titleFor(this.$translate, this.widget, 'y');
  }

  yAxisLabel = (value: any): string => {
    return axisLabel(value, typeFor(this.widget, 'y'));
  }

  tooltipTitleFormat(name: string, x: any, value: any) {
    return `${name}\n${this.xTitle()}: ${this.xAxisLabel(x)} VS. ${this.yTitle()}: ${this.yAxisLabel(value)}`;
  }

  tooltipFormat(values: {[key: string]: any}) {
    const lines: string[] = [];

    Object.keys(this.simulation.shift_parameters || {}).forEach(path => {
      let value = values[path];

      const allocation = this.$translate.instant(
        'strategies.allocation_types.' +
        this.simulation.version_attributes.allocation_type
      );
      const keys = path.split('.');
      const key = keys[keys.length - 1];

      if (value === undefined || value === null) {
        value = this.simulation.current_version_shift_parameters[path];
      }

      if (value !== undefined && value !== null) {
        const title = this.$translate.instant('simulations.widgets.shift_parameters.' + key, {
          allocation: allocation.toLowerCase(),
          indicator: this.getIndicatorFor(path)
        });

        if (key === 'start_date') {
          value = axisLabel(value, 'date');
        }
        else if (!isNaN(Number(value))) {
          value = Number(value).toFixed(2);
        }

        lines.push(`${title}: <b>${value}</b>`);
      }
    });

    return lines.join('<br />');
  }

  private getIndicatorFor(key) {
    const keys = key.split('.');
    let dynamicSelectionType = '';

    if (keys.length > 1 && keys[0] === 'dynamic_selections_attributes') {
      this.simulation.version_attributes.dynamic_selections_attributes.forEach((dsa) => {
        if (dsa.id === keys[1]) {
          dynamicSelectionType =
            this.$translate.instant('strategies.indicators.' + dsa.indicator).toLowerCase();
        }
      });
    }
    return dynamicSelectionType;
  }
}
