import { Directive, Input, OnInit, OnDestroy } from 'angular-ts-decorators';
import * as angular from 'angular';

const scroll = ($targets: JQuery<HTMLElement>, $source, top: number) => {
  $targets.each((_index, $target) => {
    if ($target !== $source) {
      $target.scrollTop = top;
    }
  });
};

const init = ($targets: JQuery<HTMLElement>) => {
  $targets.on('scroll', function() {
    scroll($targets, this, angular.element(this).scrollTop()!);
  });
};

@Directive({
  selector: 'synchronize-scroll'
})
export class SynchronizeScrollComponent implements OnInit, OnDestroy {
  @Input('@')
  private synchronizeScroll: string;

  private timeout: ng.IPromise<void>;

  /*@ngInject*/
  constructor(
    private $element: JQuery<HTMLElement>,
    private $timeout: ng.ITimeoutService
  ) {}

  ngOnDestroy() {
    this.$timeout.cancel(this.timeout);
  }

  ngOnInit() {
    this.timeout = this.$timeout(() => {
      const $targets = this.$element.find(this.synchronizeScroll);
      init($targets);
    });
  }
}
