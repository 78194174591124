import { Component, Input, OnInit } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-linear-gauge.min';
import 'anychart/dist/js/anychart-table.min';

import './component.scss';
const template = require('./component.html.haml');

import { statisticToPercent } from '../../components';

export interface IPeerRanking {
  name: string;
  type: 'asset_class'|'risk_factor'|'tags_name';
  value: number;
  min: number;
  max: number;
  average: number;
  median: number;
}

export const rankTypesForValue = (value, rank): string[] => {
  if (value === rank.min) {
    return ['min'];
  }
  else if (value === rank.max) {
    return ['max'];
  }
  else {
    const ranks: string[] = [];
    const interval = (rank.max - rank.min) / 30;

    if (value + interval >= rank.average && value - interval  <= rank.average) {
      ranks.push('average');
    }

    if (value + interval >= rank.median && value - interval  <= rank.median) {
      ranks.push('median');
    }

    return ranks;
  }
};

const translateKey = 'strategies.peerRanking.controller';
const normalColor: string = '#2AD62A';
const warningColor: string = '#FFD700';
const dangerColor: string = '#D81E05';

@Component({
  selector: 'strategies-peer-ranking',
  template
})
export class StrategiesPeerRankingComponent implements OnInit {
  @Input()
  public peerRanking: IPeerRanking[];

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
  ) {}

  ngOnInit() {
    // Create simple gauge
    const layoutTable = this.drawGauges();
    layoutTable.container('peer-ranking-container');
    layoutTable.draw();
  }

  drawGauges() {
    const gauges: anychart.charts.LinearGauge[] = [];
    // Create table to place thermometers
    const layoutTable = anychart.standalones.table();

    layoutTable.hAlign('center').vAlign('center').cellBorder('', 0);

    for (let i = 0; i < this.peerRanking.length && i < 4; i ++) {
      gauges.push(this.drawGauge(this.peerRanking[i]));
    }

    // Put gauges into the layout table
    layoutTable.contents([gauges]);

    return layoutTable;
  }

  drawGauge(rank: IPeerRanking) {
    // create gauge
    const gauge = anychart.gauges.linear();
    const rightAxis = gauge.axis();
    const leftAxis = gauge.axis(1);
    const strategyMarker = gauge.marker(0);
    let rankTitle = `${this.$translate.instant(`${translateKey}.rankTypes.${rank.type}`)}: `;

    const ctrl = this;

    if (rank.type === 'asset_class') {
      rankTitle += this.$translate.instant(`strategies.asset_classes.${rank.name}`);
    }
    else if (rank.type === 'risk_factor') {
      rankTitle += this.$translate.instant(`strategies.strategy_types.${rank.name}`);
    }
    else {
      rankTitle += rank.name;
    }

    if (!!rank.value) {
      gauge.data([rank.value]).padding([10, 10, 10, 10]).tooltip(false);
    }

    // sets gauge Title
    gauge.title().enabled(true).text(rankTitle).padding([30, 0, 10, 0]).orientation('bottom')
      .fontColor('#4e86ec').fontSize(15);

    // sets scaleBars
    gauge.scaleBar(0).width('28%').from(rank.min).to(rank.average).fill({
      keys: [dangerColor, warningColor], angle: 90
    });

    gauge.scaleBar(1).width('28%').from(rank.average).to(rank.max).fill({
      keys: [warningColor, normalColor], angle: 90
    });

    // Set scale settings
    gauge.scale().minimum(rank.min).maximum(rank.max);
    (gauge.scale() as any).ticks().interval((rank.max - rank.min) / 30);

    // Set axis and axis settings
    leftAxis.width('0.5%').offset('-1%');
    leftAxis.orientation('left');
    leftAxis.labels().format(function() {
      const translatedRankTypes: string[] = rankTypesForValue(this.value, rank).map(
        (rankType) => {
          return ctrl.$translate.instant(`${translateKey}.${rankType}`);
        }
      );
      return translatedRankTypes.join(' / ');
    });

    rightAxis.width('0.5%').offset('28.5%');
    rightAxis.orientation('right');
    rightAxis.labels().format(function() {
      return rankTypesForValue(this.value, rank).map(rankType => statisticToPercent(rank[rankType])).join(' / ');
    });
    // Set marker point
    strategyMarker.color('#4e86ec').offset('24.5%').type('triangle-right');

    // Set label for marker point
    strategyMarker.labels().enabled(true).position('left-center').offsetX(0).anchor('right-center')
      .fontColor('#4e86ec').format(function() {
        return statisticToPercent(this.value);
      });
    strategyMarker.zIndex(1000);

    return gauge;
  }
}
