import { Component, Inject } from 'angular-ts-decorators';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';

import './page.scss';
const template = require('./page.html.haml');

import { EnvService } from '../../env';
import { UserInvitation, UserInvitationResource } from '@quantizr/front-model';
import { formHasErrors } from '../../forms/has-errors';
import {
  ASSET_CLASS_LIST, INSTRUMENT_LIST, AREA_LIST, ABOUT_US_LIST
} from '../../config/devise';

const INVITATION_REDIRECT_TIMEOUT = 3000;

@Component({
  selector: 'page-my-user-invitation',
  template
})
export class MyUserInvitationPage {
  public user: UserInvitation;
  public invitationForm: ng.IFormController;
  public errorMessages: any = null;
  public saving = 0;
  public token: string;
  public cmsUrl: string = '';
  public COUNTRY_CODES = settings.COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES = settings.SHORTLISTED_COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES_STRING = settings.SHORTLISTED_COUNTRY_CODES_STRING;
  public ASSET_CLASS_LIST: string[] = ASSET_CLASS_LIST;
  public INSTRUMENT_LIST: string[] = INSTRUMENT_LIST;
  public AREA_LIST: string[] = AREA_LIST;
  public ABOUT_US_LIST: string[] = ABOUT_US_LIST;

  /*@ngInject*/
  constructor(
    @Inject('EnvService')
    envService: EnvService,
    @Inject('UserInvitationResource')
    private userInvitationResource: UserInvitationResource,
    $location: ng.ILocationService,
    $routeParams: ng.route.IRouteParamsService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService
  ) {
    this.cmsUrl = envService.apiUrl('www');
    this.token = $routeParams.invitation_token;
    if (!this.token) {
      $location.path('/users/signup');
    }

    this.userInvitationResource.accept({
      invitation_token: this.token
    }).$promise.then((user) => {
      this.user = user;
    }, () => {
      $location.path('/users/signup');
    });
  }

  invitation() {
    this.saving = 1;

    return this.userInvitationResource.update({
      user: this.user
    } as any).$promise.then(() => {
      this.invitationSuccess();
    }, error => this.invitationError(error));
  }

  invitationSuccess() {
    this.saving = 0;

    return this.$translate('myUser.invitation.controller.success').then((title) => {
      this.toaster.success(title);
      this.$timeout(() => {
        this.$window.location.href = '/';
      }, INVITATION_REDIRECT_TIMEOUT);
    });
  }

  invitationError(error) {
    this.saving = 2;
    this.errorMessages = error.data.errors;
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.invitationForm, this.errorMessages);
  }
}
