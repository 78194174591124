import { Component, OnDestroy, Inject, AfterViewInit } from 'angular-ts-decorators';
import { CockpitProfile, CockpitPortfolio } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

const template = require('./page.html.haml');

import { CockpitsViewsService } from '../../views/service';

@Component({
  selector: 'page-cockpits-holdings-derivative',
  template
})
export class CockpitsHoldingsDerivativePage implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public profile: CockpitProfile|null = null;
  public portfolio: CockpitPortfolio|null = null;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.cockpitsViewsService.$cockpitProfile().subscribe(profile => {
      this.profile = profile;
    }));
    this.subscriptions.push(this.cockpitsViewsService.$cockpitPortfolio().subscribe(portfolio => {
      this.portfolio = portfolio;
    }));
  }
}
