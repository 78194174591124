import { Component, Inject, OnInit } from 'angular-ts-decorators';
import { MyUser as BaseMyUser, MyUserResource, User, UserResource } from '@quantizr/front-model';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import { variables as users } from '@quantizr/front-model/dist/users/variables';

import './page.scss';
const template = require('./page.html.haml');

import { MyUserService } from '../service';

export interface IMyUser extends BaseMyUser {
  current_password?: string;
  password?: string;
  password_confirmation?: string;
}

export const mapValue = (values: any): Array<{value: string}> => {
  return Object.keys(values).map(key => ({value: values[key]}));
};

@Component({
  selector: 'page-my-user-show',
  template
})
export class MyUserShowPage implements OnInit {
  public errorMessages: any = {};
  public user: IMyUser;
  public formUserSaving: number = 0;
  public formPasswordSaving: number = 0;

  public COUNTRY_CODES = settings.COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES = settings.SHORTLISTED_COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES_STRING = settings.SHORTLISTED_COUNTRY_CODES_STRING;
  public GRADES = mapValue(users.GRADES);
  public ROLES = mapValue(users.ROLES);

  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    private myUserService: MyUserService,
    @Inject('MyUserResource')
    private myUserResource: MyUserResource,
    @Inject('UserResource')
    private userResource: UserResource,
    private toaster: toaster.IToasterService,
    private $translate: ng.translate.ITranslateService,
    private $location: ng.ILocationService
  ) {}

  ngOnInit() {
    const path = this.$location.search();
    if ('error_code' in path) {
      const code = path.error_code;
      this.$translate(`myUser.errors.${code}`).then(error => {
        this.errorMessages[code] = error;
      });
    }

    return this.myUserResource.get().$promise.then(user => this.user = user);
  }

  public imageUploaded(image) {
    this.user.image = image;
    this.myUserService.user = this.user;
  }

  public updateUser(_form?: ng.IFormController) {
    this.formUserSaving = 1;

    return this.myUserResource.update(this.user).$promise.then(response => {
      this.updateUserSuccess(response);
    }, error => {
      this.formUserSaving = 2;
      this.errorMessages = error.data;
    });
  }

  updateUserSuccess(user) {
    this.formUserSaving = 0;
    this.errorMessages = {};

    if (user.warning_message) {
      this.toaster.warning(user.warning_message);
    }

    this.myUserService.user = user;
  }

  public updatePassword(form: ng.IFormController) {
    this.formPasswordSaving = 1;

    const user = this.user as User;

    return this.userResource.update({user}).$promise.then(() => {
      this.updatePasswordSuccess();
      form.$setPristine();
      form.$setUntouched();
    }, error => {
      this.formPasswordSaving = 2;
      this.errorMessages = error.data.errors;
    });
  }

  updatePasswordSuccess() {
    this.formPasswordSaving = 0;
    this.user.current_password = undefined;
    this.user.password = undefined;
    this.user.password_confirmation = undefined;
  }
}
