import { Component, Inject, Input, OnInit, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { CockpitProfileResource, CockpitProfile } from '@quantizr/front-model';

const template = require('./component.html.haml');

import { PROFILE_WINDOWS } from '../../';
import { DatesSelector, ISelectorDatesSelected } from '../../../components';

const COCKPIT_VOL_TYPES = ['avg_vol', 'min_vol', 'max_vol', 'lastest_vol'];

@Component({
  selector: 'cockpitsProfilesVolatilityBandWidth',
  template
})
export class CockpitsProfilesVolatilityBandWidthComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private selectedDates: ISelectorDatesSelected;

  @Input()
  profile: CockpitProfile;
  @Input()
  datesSelector: DatesSelector;

  public COCKPIT_VOL_TYPES = COCKPIT_VOL_TYPES;
  public WINDOWS = PROFILE_WINDOWS;
  public volatilityBandWidth?: any;
  public currentWindow?: string;

  /*@ngInject*/
  constructor(
    @Inject('CockpitProfileResource')
    private cockpitProfileResource: CockpitProfileResource
  ) {}

  ngOnInit() {
    if (this.datesSelector) {
     this.subscriptions.push(this.datesSelector.$dates().subscribe(dates => {
        this.selectedDates = dates;
        this.refresh();
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refresh() {
    this.currentWindow = PROFILE_WINDOWS[1];

    return this.cockpitProfileResource.statistics({
      id: this.profile.id,
      start_date: this.selectedDates.min!,
      end_date: this.selectedDates.max!
    }).$promise.then(response => {
      this.volatilityBandWidth = response;
    }, () => {
      this.volatilityBandWidth = {};
    });
  }

  hasNullValues() {
    if (!this.volatilityBandWidth || !this.currentWindow) {
      return false;
    }

    for (const typeKey of Object.keys(this.volatilityBandWidth[this.currentWindow])) {
      const type = this.volatilityBandWidth[this.currentWindow][typeKey];
      for (const valueKey of Object.keys(type)) {
        const value = type[valueKey];
        if (value === null) {
          return true;
        }
      }
    }

    return false;
  }
}
