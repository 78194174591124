import { Component } from 'angular-ts-decorators';
const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';

@Component({
  selector: 'page-simulations-edit-windows',
  template
})
export class SimulationsEditWindowsPage extends DialogController {}
