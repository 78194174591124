import { Component, Input, Inject } from 'angular-ts-decorators';
import { Cockpit, CockpitProfile, CockpitProfileResource } from '@quantizr/front-model';
import { CockpitsViewsService } from '../../views/service';

import './component.scss';
const template = require('./component.html.haml');

@Component({
  selector: 'cockpits-profiles-navbar',
  template
})
export class CockpitsProfilesNavbarComponent {
  @Input('@')
  public view: string;
  @Input()
  public cockpit: Cockpit;
  @Input()
  public profile: CockpitProfile;
  @Input()
  public editEnabled = false;

  /*@ngInject*/
  constructor(
    @Inject('CockpitProfileResource')
    private cockpitProfileResource: CockpitProfileResource,
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    private ngDialog: ng.dialog.IDialogService,
    private $location: ng.ILocationService
  ) {}

  goTo(path: string) {
    (this.$location as any).$$search = {};
    this.$location.path(path);
  }

  addProfile() {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-profiles-new></page-cockpits-profiles-new>',
      plain: true
    }).then(data => {
      this.cockpitsViewsService.loadCockpit();
      this.goTo(`/cockpit/edit/profiles/${data.profile.id}/${this.view}`);
    });
  }

  editProfile(profile: CockpitProfile) {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-profiles-edit></page-cockpits-profiles-edit>',
      plain: true,
      data: profile
    }).then(() => this.cockpitsViewsService.loadCockpit());
  }

  deleteProfile(profile: CockpitProfile) {
    return this.ngDialog.openConfirm({
      template:
        '<page-cockpits-profiles-delete-confirm></page-cockpits-profiles-delete-confirm>',
      plain: true,
      data: { profile }
    }).then(() => {
      return this.cockpitProfileResource.delete({
        id: profile.id
      }).$promise;
    }).then(() => this.cockpitsViewsService.loadCockpit());
  }
}
