import { Component, OnDestroy, Inject, AfterViewInit } from 'angular-ts-decorators';
import { CockpitProfile, CockpitPortfolio, StrategyResource, Strategy } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

const template = require('./page.html.haml');

import { CockpitsViewsService } from '../../views/service';

@Component({
  selector: 'page-cockpits-weights-show',
  template
})
export class CockpitsWeightsShowPage implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public profile: CockpitProfile|null = null;
  public portfolio: CockpitPortfolio|null = null;
  public strategy: Strategy|null = null;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.cockpitsViewsService.$cockpitProfile().subscribe(profile => {
      this.profile = profile;
    }));
    this.subscriptions.push(this.cockpitsViewsService.$cockpitPortfolio().subscribe(portfolio => {
      this.changePortfolio(portfolio);
    }));
  }

  changePortfolio(portfolio: CockpitPortfolio|null) {
    this.portfolio = portfolio;
    this.strategy = null;

    if (portfolio && portfolio.id) {
      this.strategyResource.get({
        id: portfolio.id
      }).$promise.then(strategy => this.strategy = strategy);
    }
  }
}
