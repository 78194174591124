import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from 'angular-ts-decorators';
import { categoriesForTable, TimeSerieCategory } from '@quantizr/front-model';
import { Subscription } from 'rxjs';
import cloneDeep = require('lodash/cloneDeep');

const template = require('./component.html.haml');

import { ITableSettingsGroup, TableSettingsController } from '../../../../components';

export const CATEGORIES = categoriesForTable([{
  key: TimeSerieCategory.return_pa,
  defaultActive: true
}, {
  key: TimeSerieCategory.cumulative_return,
  defaultActive: true
}, {
  key: TimeSerieCategory.max_drawdown,
  defaultActive: true
}, {
  key: TimeSerieCategory.volatility,
  defaultActive: true
}, {
  key: TimeSerieCategory.info_ratio,
  defaultActive: true
}, {
  key: TimeSerieCategory.calmar_ratio,
  defaultActive: true
}]);

export const WINDOWS = [{
  key: 'live_date',
  defaultActive: true
}, {
  key: 'start_date',
  defaultActive: true
}, {
  key: '3_years',
  defaultActive: true
}, {
  key: '1_year',
  defaultActive: true
}, {
  key: 'year_to_date',
  defaultActive: true
}];

@Component({
  selector: 'strategies-show-performance-overall',
  template
})
export class StrategiesShowPerformanceOverallComponent implements OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  liveDate: any;
  @Input()
  statistics: any;

  public CATEGORIES = CATEGORIES;
  public WINDOWS = cloneDeep(WINDOWS);
  public HIGHLIGHTED_WINDOW = 'year_to_date';

  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController = new TableSettingsController('strategies-show-performance-overall');

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService
  ) {
    this.tableSettingsGroups = [{
      key: 'row',
      title: $translate.instant('timeSeries.categories.')
    }, {
      key: 'column',
      title: $translate.instant('timeSeries.windows.')
    }];

    WINDOWS.forEach(window => {
      this.tableSettingsController.registerProperty({
        key: window.key,
        group: 'column',
        active: window.defaultActive,
        title: $translate.instant(`timeSeries.windows.${window.key}`)
      });
    });

    CATEGORIES.forEach(category => {
      this.tableSettingsController.registerProperty({
        key: category.key,
        group: 'row',
        active: category.defaultActive,
        title: $translate.instant(`timeSeries.categories.${category.key}`)
      });
    });

    // remove rows from table to keep css display consistent
    this.subscriptions.push(this.tableSettingsController.$properties().subscribe(properties => {
      CATEGORIES.forEach(category => {
        const property = properties.find(value => value.key === category.key && value.group === 'row');
        if (property) {
          category.displayed = property.active!;
        }
      });
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('liveDate' in changes) {
      this.updateLiveDateProperty(changes.liveDate.currentValue);
    }
  }

  updateLiveDateProperty(liveDate: any) {
    this.WINDOWS = cloneDeep(WINDOWS);

    if (liveDate) {
      const window = this.WINDOWS[0];
      this.tableSettingsController.registerProperty({
        key: window.key,
        group: 'column',
        active: window.defaultActive,
        title: this.$translate.instant(`timeSeries.windows.${window.key}`)
      });
    }
    else {
      const windows = this.WINDOWS.splice(0, 1);
      this.tableSettingsController.unregisterProperty(windows[0].key);
    }
  }
}
