import { Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Cockpit, CockpitProfile } from '@quantizr/front-model';

import { CockpitsViewsService } from '../views/service';

export abstract class CockpitsProfilesPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public cockpit: Cockpit|null = null;
  public profile: CockpitProfile|null = null;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService
  ) {
    this.subscriptions.push(cockpitsViewsService.$cockpit().subscribe(cockpit => this.cockpit = cockpit));
    this.subscriptions.push(cockpitsViewsService.$cockpitProfile().subscribe(profile => this.profile = profile));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.cockpitsViewsService.redirectToProfile(this.$location, this.$routeParams);
  }
}
