export const DASHBOARDS_TREE = {
  valquant: {
    'Valquant Library': ['38a59054-a626-416d-ba9b-a902639f0857']
  },
  gmi: {
    Allocation: ['d9b3044a-533d-4151-89c2-24d11418bf4c'],
    'GMI Library': ['51235166-0f31-44ee-b295-b3e4b76591cf']
  },
  market: {
    'Risk Indicators': ['2bab9e38-8c08-49b6-b00f-05c0c440c69f'],
    Correlations: ['90771b8e-46aa-4667-866f-9786de0eb5a9']
  },
  'la-4eme-ligne': {
    'Score FRANCE': ['f497e99c-85eb-4151-b9d4-c9d35fd014f5']
  },
  strategies: {
    'CIBC Indices': [
      '7a621905-3984-4e38-b1be-49a9795f9017'
    ],
    'Single stocks': [
      '53fe519d-fa15-403b-853b-41a1008c598b', // Screener
      'eef17c0e-cee0-42d3-954a-13c747a4022d'  // Equity Factors
    ],
    ETFs: [
      'de61e52f-4d92-47bb-a2de-d8c55a9cc636' // Screener
    ],
    Funds: [
      '320a2c2e-1baf-4728-98ce-a8fe61d639b4'
    ],
    Indices: [
      '44ccbfa4-fa21-4d83-9fbe-435d2ab692f8'
    ]
  },
  'reporting-demo': {
    'Reporting FO': ['c237ae08-8a38-49b9-ad85-b07bc6fc8ed6', 'd7c57c85-5117-4677-a170-6c44e2107c1f',
    'd617cf83-d50f-4fb5-a32f-e9f625301a07', 'b963e357-7e8c-4232-b54c-575851b09885',
    'ca7c2488-f0b6-41bd-a822-d9f21e95128f', 'e61f66f5-e211-4bf3-90cd-faee7b49e499',
    '972456ce-69cc-4c50-a86e-22f2e84b3dff', 'ba47caa6-c91f-41b0-bc41-a8c41f4ee545'],
    Performance: ['90b138ff-19e3-4347-af0c-2d9f8e8f2c42'],
    'Portfolio positions': ['bf18beca-4398-480f-9351-49e48e741f9e'],
    ESG: ['11823a64-90c2-435c-857f-895930cc01d9'],
    'Transparisation fonds ouverts': ['65320cef-676f-4f4a-a97e-f066fce8dd06'],
    Sensibilités: ['427a62a2-2a95-467c-8d3d-7a99225b61d4'],
    'Etude des OPC': ['e9f52806-24a5-452e-b681-245dd19b4dd3'],
    'Fonds sous jacents': ['ad8113d5-6262-4688-83df-3ab92546d51d'],
    'Transparisation (évolution)': ['bb4bb9ec-7f74-423e-89c6-4662bd0e26e8'],
    'Transparisation (positions)': ['fc12d8f7-845f-4e5c-b24b-f6fb82f5842c'],
    'Suivi de la collecte': ['adc1b7f8-313c-43c7-b2e1-4e39219a9b55']
  },
  'reporting-demo-pe': {
  },
  'shareholders-disclosures-demo': {
    'Suivi des seuils': ['dbed18bc-2a34-41f1-8122-e03c318f59c8'],
    'Reporting OPA': ['9c4c99c9-0ccc-4600-aa16-98660bafedae'],
    'Données dépositaires': ['ae3c08f2-fcc3-42ed-a796-22a301628a8c']
  },
  'reporting-a2': {
    Performance: ['247b63bb-1d05-493d-934b-1eed3aa3dfb0'],
    'Portfolio positions': ['b7a684ef-e9a5-4552-81d6-9e242a40529d'],
    Lookthrough: ['c06b3670-a864-41eb-9526-a4aefff59f7a'],
    ESG: ['8030ed17-50d6-4f64-9162-ddbc6ede8233'],
    SFDR: ['77fd89b7-48fa-41f4-a402-618fd00d3a4b']
  },
  'reporting-a3': {
    'Positions et transparisation': ['d6226ab6-e364-44a9-8c64-8a71195b42c3'],
    'Actif global & Niveau comptable': ['7d0efc2f-273f-4e69-961e-1ef82c570392'],
    'Fonds dédiés': ['2c9bdb20-95fe-4106-8a1e-04b9fd7fd19c'],
    'Produits dérivés': ['9a722846-c7a4-4a68-8ee9-dcadfca70d59'],
    'Transparisation des fonds ouverts': ['fd744f5e-4b0e-491e-8261-947d1c208ec4'],
    'Suivi de l\'allocation d\'actifs': ['8e0468da-c524-42f0-baad-e3cdb12e223c'],
    'Analyse de performance': ['0c076b24-6fba-4f5c-b667-40852ed1abc8'],
    'Indicateurs et ratios': ['5d3a3696-666d-4511-9d30-9c0ce9aaf72e'],
    'Suivi des fichiers': ['50019656-1aa9-4990-9bd9-47c4e6ce23b8'],
    'Indicateurs de risque': ['d83def09-399d-434b-943b-251fc8f62233']
  },
  'reporting-extra-financier': {
    'Notation ESG': ['76289fca-cc39-4315-a89b-6987579d0b31'], // ESG msci
    'Empreinte carbone': ['da96d87f-e1f7-4b52-9273-c0011137157b'], // Carbone msci
    'Exclusion Urgewald': ['f01cbfb9-7586-4395-9d22-fd98c2e53937'], // Urgewald
    'Notation ESG (Moodys)': ['3294d603-ee59-44ea-8ac4-7401feac096e'], // ESG
    'Empreinte carbone (Moodys)': ['2ec8056c-d650-4487-9fe2-954ea0f7c158'] // Carbone
  },
  'reporting-a5': {
    Cockpit: ['8ac51aa9-4438-4724-8926-6e329e1db9de'],
    'Actifs cote': ['e74bf170-0280-46d1-ac45-59ac3fa8cc32'],
    'Actifs non cote': ['b63415bd-96bd-45af-acd0-eba43eeee78f'],
    'Performances et risques': ['3867b1a7-8788-4250-92b5-7b8cc48f86ff'],
    'Emprunts & Frais': ['dacf1b51-5c9b-4ed2-ab61-bdf2b24f278f'],
    'Positions et transactions': ['8129ae13-5a86-427d-8e92-34bc18d252be'],
    Consolidation: ['09833cd8-7016-4e4b-b507-33a6d4fcc779'],
    Reporting: ['ab1334a0-456f-4b70-866f-d818e1aec5ed',
                '5c23a6b5-ae95-4c08-9edf-5a2aa2a6a9cb',
                'f1f0dc91-fae5-4ac8-9d23-7c914b3bad43',
                'c51e02ce-bf08-46f8-b9eb-12c8066b993d',
                'b850a209-c8e1-49fb-aa9b-695fc870f4e5',
                'eac4d254-5a47-43e4-9c39-12c65514be3a'
    ]
  },
  'reporting-a6': {
    'Vue générale': ['45fb26b4-3977-4131-93cf-0dad266f7ce0'],
    'Actifs cotés': ['eb041a95-c6b4-4aae-b904-0b71e3a20c70'],
    'Actifs non cotés': ['93c48671-635e-481f-9cb1-0493b00ac0ec'],
    Performances: ['793132ad-89ca-4653-90ae-d464e0768adc'],
    Emprunts: ['665c449e-70fc-4b69-976c-ceec00d7bffc'],
    Positions: ['d677ce66-fc56-4abb-990f-b4ea445c2d01'],
    'Reporting mensuel': ['b806767b-80d1-41e9-a5d9-5ca4ed0f0820'],
    'Reporting mensuel (accounts)': ['0c9b554c-d9bd-45ed-8143-1cce3e9460af'],
    'Reporting mensuel (positions)': ['73c8ab2d-2f7b-4dc7-b1cc-a5167cb66c76'],
    'Reporting mensuel (warning)': ['d80c1518-93dd-40c4-9cbd-af50f9400287'],
  },
  'reporting-b1': {
    'Bibliothèque de documents': ['00787e28-9318-427f-9905-9ec83e0efa06'],
  },
  'reporting-c8': {
    OPCVM: ['2f0fd53f-5a1e-4033-bb91-26e57947533f'],
    Reporting: ['8a1228ef-a852-4c8a-9374-a3b5fe169861'],
    Performance: ['227aaa74-748f-4912-b0d8-f422a534deac'],
    'Stress test': ['05f4b988-a4aa-4351-abea-b1f47abb3143'],
    'Actifs non cotes': ['fdb6b413-e62e-4c8d-a9c1-5492fa8b9e3d'],
    'Situation des placements': ['46664376-b95f-4d2e-a4aa-89705b354637']
  },
  'reporting-l1': {
    'Vue générale': ['24e9d43a-1fb9-49f8-9347-29a97c84ba10'],
    'Actifs cotés': ['7d81dc90-fbf1-45b3-8c91-e22ce993550d'],
    'Actifs non cotés': ['b245e5cc-4ad5-4e36-b020-063263467410'],
    Performance: ['1163d762-f85f-4a19-b2c7-c0b8c2c5d947'],
    Positions: ['88120175-1ba6-429b-acba-f7ee86dbb8f1'],
    Transactions: ['dbb700eb-a626-4593-bd90-cbed8d34b2a0']
  },
  'reporting-n1': {
    'Reporting platform': ['ab3de702-6d9f-4443-860c-7b5a6cd9c060'],
    'Private equity': ['5bdaca3d-85a2-45c5-bfc3-1eaa82da1213'],
    'Liquidity Planning Tool': ['46b0264d-e8e2-448c-a01f-3f2b5217f0bc'],
    'Equity portfolio': ['4d286e1f-6b4a-45fb-80f8-eef1a596c1d0']
  },
  'reporting-s1': {
    'Transparisation (positions)': ['7ba8643d-4cd7-4f4e-bef3-dbf882ea7e21'],
    'Transparisation (évolution)': ['3b4e6db1-1994-44c8-8ab9-c765ecef8881'],
    'Fonds sous-jacents': ['766fbb66-166d-4e86-a744-8c39c8448cc3'],
    'EPT/EMT': ['6e29849d-9679-436b-8194-750636c00701']
  },
  customlists: {
    'Peer analysis': ['a1e26dea-9af2-4eb0-aaf9-26a45b4f54b6'],
    Correlations: ['9bac261f-c0cf-4aef-a0f4-aae60c494ccd']
  },
  portfolioDescription: {
    'Indicator and ratios': ['6c114aa8-028a-4c7a-9b74-d3b37ce16aa7'],
    Positions: [
      '8930e6db-7b67-4eee-bc57-f9baade93173', // General
      '2680671a-1316-4436-8e48-51517ab1e0be', // Loomthrough
      '3654d0d1-a989-47cc-a4ac-65f02f4c9ebf' // ESG
    ],
    'News and events': ['55ea94c1-c1f5-4a25-907c-ed432d24faa2'],
    Guidelines: ['7156a627-cb53-4799-9cc3-faf5f3eb1d22', 'a87e8fa0-6cd1-48f5-9b66-227ad99e60cb']
  },
  portfolioAnalysis: {
    'Performance Analysis': ['50dec0b4-7a34-457d-8cfd-4af84069033d'],
    'Performance attribution': ['8c2954ae-46b5-4b99-a733-460b39dc8d04'],
    'Risk analysis': ['9a12bfcd-8711-4a0d-9561-1bc3df4d4d14'],
    'Factor analysis': [
      '7293b2e0-f0ed-4b6e-a698-bcb341edc1d3', // Sub portfolios
      '9f7e2a05-956a-4115-a414-de732a35ab0b', // Risk factors
      '339409cb-66e1-4b3a-af2a-bf0105c12ce9' // Equity factor exposures
    ]
  },
  'internal-monitoring': {
    Clients: [
      '81340b06-3e29-423d-8416-72196c463383', // Level 0
      'ee650074-b02e-4e14-b31c-07bc208efe80', // Level 1
      '208d7e09-80fa-4a51-ba27-018ddf10cb37' // Level 2
    ],
    Compositions: [
      'd8ab7574-1cd8-4abb-8a98-8d73c9a2ad73'
    ],
    Screener: [
      '975f4d4d-2a2c-46a6-87d5-a82b69e034f6', // Screener
      '7dac72cc-3bb2-4aea-b789-6121fd1a4e31' // Strategies
    ],
    TimeSerieFiles: ['b5b9fbd3-d385-4d74-b1fc-1270a5702ea6'],
    A2: ['6a7a4718-f731-4a58-b642-8321680fa418', '5a325123-209c-4730-8e14-381e088c1996'],
    A3: [
      '2a5c2c91-83e6-4342-84ac-bcedc65a7d47', '2f694690-a387-4c18-8262-b0ac249f2739',
      '4cada654-9ddf-477f-a121-463434a6a65c', 'e644f6dc-7d4d-4cba-a144-1e240ae28ac5',
      '3d3cb48a-09dc-4165-8103-13143b46f6ac', 'f68d6fa0-b8d3-42bd-aadf-18d17e7ecdc1',
      'dcc2a643-c645-4937-a69d-d255812c5570'],
    A5: ['1e37202d-d657-47d0-aa3b-5e520d769e25', '41e89b26-ebf0-46ed-974c-4fcb14680894'],
    A6: ['6c752311-4ef4-4c7a-8c15-d91068873222', '55323045-c8c9-47c6-94cc-8271dd0529f2'],
    B1: ['c0cf03d1-be44-49c5-aa81-1b65341f117e'],
    C6: ['be2451f9-6e95-414f-9e8a-707470f5eeb1', 'd1c2a4fb-bfd6-40d3-aa6a-6929b21d0e8c'],
    C8: ['245f4208-8398-46b0-9a7c-6a7bd1a2cb9e'],
    C9: ['4b39b55f-f95e-4c69-8fd8-a3e5e60c5015'],
    G3: ['1420651c-7d9c-4d18-84c5-d6374d815757'],
    L1: [
      '074cb088-4b11-4d52-8f39-fce472863bca', '8eff0570-bac4-473e-9292-4cde9f953d2a',
      'e0e9375a-8da3-4b03-91a5-90c9985df81b'],
    M1: ['08ef6630-6591-42ef-92bf-8e77d722b568'],
    N1: ['4e563116-d268-4c5a-8856-0624ba47c0b4', 'fa9fbc6d-59b1-428d-8ab8-914fc0e9d07d'],
    S1: [
      '01034769-b323-425a-bbbc-c08fb4df156b',
      'e5c577c7-eec3-42ef-b710-803875090d44',
      '583dfed7-a4e3-4971-b7a3-66cb0e2f008b'
    ],
    Events: ['de9678d0-cf4c-4341-8445-89a76b5b7f44'], // Events
    gate: ['5e68d7fa-9db0-42b5-9fd8-57dac163e971', '5aed081b-62f5-4351-b172-732153227c57'] // Gate statistics
  },
  realEstate: {
    bilan: ['b54a4926-beb2-4cd6-b2bf-c226635475d2'],
    cdc: ['5148d24a-4ca7-47ba-82ed-d83ccd749548']
  },
  'monitoring-b1': {
    'Suivi de la collecte': ['2cea682b-6e67-4953-92d4-8a74f9ff5c21']
  },
  'monitoring-c9': {
    'Collecte de fichiers TPT': ['9adae6b8-5b58-460a-bc60-023d6d35856f']
  },
  'monitoring-g2': {
    'Suivi de la collecte': ['c556d77d-977a-4f4a-b966-12ca2747b067']
  },
  'monitoring-g3': {
    'Suivi de la collecte': ['8fcb41dc-22c0-49bb-8290-306a68061ccf']
  },
  'monitoring-m1': {
    'Suivi de la collecte': ['f132521b-c402-4a40-9ca2-056b118eaa95'],
    'Files monitoring': ['24a0388a-9090-4ca4-a0b4-debbe049f107'],
    PDF: ['0a5d3e56-88a1-41af-a26c-11b97605f695']
  },
  preProd: {
    A2: ['5cbac503-d449-4e7a-9b6c-d5a293d12b34'],
    A3: [
      '8b3690a5-9f8e-44b5-89b4-7f3887302cbb', 'e45f5e43-9417-4390-8eb2-99b8f70cde0b',
      'a2bed3f7-49ac-433e-b554-2dca11065f60', 'e0ce2c42-0047-44b3-a3aa-82d9f9cb751c',
      '236b8116-1955-4bf1-9a61-f5b34d3c1436', '52d05d03-11b5-4840-9bed-40801448be48',
      '3ee367e5-2b6f-420b-86dc-c4e8a14ab056', '9f5c9117-e3c1-4913-bff0-1d872d469931',
      '2a371335-093f-421e-86db-b71f696a9570', 'b753d55e-3ac4-4673-b206-265ae25ca6e9',
      'e5c2b54d-ecc7-476f-a0d3-1800052d7c56', '6b1335df-0862-4a4e-b46b-494cf952f303'
    ],
    A5: [
      'dbaef058-706e-4171-b005-745b213dc0f6', 'ef946da2-e4f4-46f3-9f41-65e9e15d618b',
      'de465be1-aa1b-4de2-a494-c8496cd3d796', '441e8c95-0e76-47bb-9a3b-9fdbfa84ab20',
      '1616aefd-371a-43b8-a297-4a8714494ffa', '863acb94-6420-4c34-a2b1-60cda0cb1e69',
      'bc252a30-b42c-4959-835d-59fa0f87de7d', 'f9674b09-31dc-432b-9df8-5e2806049510',
      '834774e3-e560-4ae9-8856-813c3dba89b7', '9b5cc45e-969a-4dbc-9b8c-4423f3730162',
      '6ef1494a-1199-4ec9-84eb-ab6265f53588'
    ],
    A6: [
      '12373c3e-23a2-43a0-aacf-7503911ab9f1',
      '5345e785-688c-42c1-b249-48a7a518cf73',
      '0f9f57a0-6fba-4724-a94a-b876281d7ad7',
      '6a0d98d6-2679-41be-ae4d-fbfd0dbb2d52',
      '0a1f1bc0-588d-44ec-b355-0c75b5ddf0bd',
      'ec261b3f-85f5-4a32-af06-66a0086bd217',
      '1056ff7d-9c25-44c4-aad1-1f18292fef70',
      '83c760f2-509a-42d1-b2ec-764ba8f4396d',
      '3bb10ebd-470e-4be4-af1c-0ef7826d2651',
      'e9f0bd48-6eaa-458e-9e39-c9608aabbbb2',
      '309f5716-f22a-44cb-91f1-8a5deda472c2'
    ],
    B1: ['2c77aa13-b1d9-4fbe-beef-0c1ec347f753'],
    C8: [
      'a8db07d1-e839-4edb-90ad-15a56bdc0014',
      '38718be0-d2e6-4617-9387-1e95a3fb4088',
      '040cd752-325e-4c68-9f48-35677bc752e3',
      '5ef0d563-a792-44de-9e6d-4e7ece7ba48c'
    ],
    C9: ['ea8d0fb7-1ca1-4ede-9488-c5a61d87eeb8'],
    D1: ['bd5ce6f9-30b0-4a19-9f9b-7653457daa5f', 'b428b4b8-7c58-4d8a-b366-712b7b19fb22'],
    G2: ['c54328ef-f141-4469-8f67-5cc254978fd2', '77e797e3-7f78-4f05-844e-d161d4753282'],
    G3: ['96dba0a4-5a1e-4e14-a4bd-c38e04e5c654'],
    L1: [
      '4172bdb4-2cc7-464c-aaee-7b614a851bd2',
      '34f22d9b-3558-4dd8-8f90-17e564ad6833',
      '467302dc-182e-40cf-b551-626d7f486ea7',
      'ffe8037f-8b25-43ee-ad3d-785afe1b83af',
      'c3050d2d-e511-412a-8a12-53a318e6653b',
      '885acb98-021c-4776-9e0a-912a90163dc3'
    ],
    N1: [
      '63d85e2b-44ed-4d84-ba44-72c6172a8bbe', '2fa824ba-69db-4907-9515-77375fdc35d7',
      'a5fe48f6-f1a1-4431-bc13-ea9efeb53d6b', '496d5766-a4f2-43e2-91a6-b3363a445080'
    ],
    S1: ['b3124ee2-a098-4dc2-8c00-7f30310d1d50', '05b27fe3-4104-4d45-a424-11953f5edbbc',
      'e3a464e8-4e94-4d55-8209-46e0a8cc2afe']
  },
  'demo-a8': {'Data input': ['0ce052aa-61ee-4d43-96ee-dcaf3f7dbfaa']},
  'demo-c11': {
    Positions: ['b473e7d7-8eb8-4c45-8a48-ce524d9033a2'],
    Transparisation: ['12122512-09bd-49ce-86f2-a7ba38e4a4ff'],
    'Collecte de fichiers TPT': ['eb2a3a9f-328a-47c9-90c4-2688d8fef197'],
    Corrélations: ['9ea3d382-65fb-4667-967c-a2c204bf1f69'],
    ESG: ['c3cc0a9b-3d4f-4ff5-bcf7-77c62406dba1'],
    'Empreinte Carbone': ['44c29d2e-1710-4f1a-acac-c55e4673ff89'],
    'Exclusion urgewald': ['aea89697-0c84-4c23-ad77-b69e05b7e9cd']
  },
  'demo-reporting': {
    Cockpit: ['f0e561c4-7f68-4815-b7ee-ab728ece376d'],
    Performance: ['cee515f1-b780-4a38-b899-a57cad161d38'],
    'Actifs cotés': ['7ed5b6c6-695a-4233-b259-63cce7c8cb87'],
    'Actifs non cotés': ['25f66a43-e536-4123-b146-176034c70ff8'],
    Emprunts: ['6525db25-1f91-456a-a1fc-2c5f555b828e'],
    Positions: ['1ed9183c-f306-4fde-b0ee-16973b0a2276']
  },
  'demo-s2': {
    Positions: ['1e837ddf-cdb1-46a2-bdb2-7d64000ef12d'],
    Transparisation: ['1b423990-9b0c-4c8e-b861-f759b3d71f98'],
    'Collecte de fichiers TPT': ['95ec0d07-6fb2-4a36-a4bf-11962663600e'],
    Corrélations: ['096b684e-b590-43ce-a64d-a6d57b2a5209'],
    ESG: ['2c868830-1242-401d-a813-0fdbaf0c4d11'],
    'Empreinte Carbone': ['e1b4d39d-130c-41e9-a7d4-23d44b820a1d'],
    'Exclusion urgewald': ['c13f0c83-e16f-48a8-b0f7-b818b9be2da1'],
    'Suivi de l allocation d actif': ['a5a453cc-a9b1-4fef-bdba-614ff6fcd925'],
    'Transparisation des fonds ouverts': ['d46c2ff0-7e75-4e0c-baeb-c907ac97a324'],
    'Etude des OPC': ['5859f6f8-eb58-45dd-800c-d38ff97af67b'],
    'Analyse de performance': ['b6b272c2-899f-423e-9dd7-3cd2964de62d'],
    'Performance attrubution': ['7a911f92-d52c-4a4c-aa03-07614071de4e'],
    'Etude liquidité': ['5573e77f-d0eb-4249-b2a4-23067cf88150']
  },
  'demo-specific': {
    A4: ['4f8e76b0-e149-4e74-876e-1338c706cee5'],
    A7: ['2c7c4850-d679-4dd8-bf03-1c0f3dd2026f'],
    A8: ['e0cf12f6-de0d-4c38-b0c4-e154d08faffb', 'e735cc9b-f4d6-4ec9-96d9-3ede925ee0d7',
      'f52168ed-9916-4f2d-9d57-34d7af954ef0'],
    B2: ['f14c8667-64a8-4e86-83c9-f92d7090f925'],
    C5: ['45395951-54a1-4dda-9aef-ec9d8d690600'],
    C7: ['f3a60e3a-57d1-4bb3-b48e-9df0273e01ee', '4a836c79-cab8-4360-9855-1e6fbd8d818b',
      'e0c248c8-91af-4d97-bb79-6665202f0039', '854a15c7-9aba-4177-8418-10faae38181d',
      '011ac915-f05e-4577-be4d-71f69a65f8b6', '61600a2d-d3a6-4102-b1b4-a48c1d88286f',
      'd6ceb322-f009-4844-a60f-824a7f0d0ac3', 'ce89c28e-5109-4ff9-a764-74194c8c2eb3'],
    C8: ['068d69f1-4ad7-4359-9fb0-1b7881f8dc7e', '15e61aba-0e9b-4dd0-8fe8-e4cd84805bb6',
      '82812453-3f97-4fd7-94ce-979b3005acc3'],
    C10: ['f623f15a-86cc-439a-bb4e-67a3e4a88056', '7c6954f4-d9c2-446a-b89c-ccb394974d44'],
    D1: ['30bf9a2f-0679-41c6-a4bc-518cd95b6b43', '7a131f3e-9d30-4a28-ac80-65baaa9fa7d9',
      'e432f8e1-9fdb-4df6-ba53-7f131be77a17'],
    E1: ['707aa677-4fc6-4c92-bf43-544e19d125d4', '14d77bbf-6ed0-4305-972f-82bafbeffa6f',
      'eadd3b72-8a8f-4103-b07f-8a027a84d1ef', '057e5e4a-7d93-4a07-a4bc-79f1ca67e3ac',
      '9392ecaa-728a-4e97-8138-ef76449a1e07', '5dced819-a8fc-4631-92f5-94ba4fffa173',
      '50c9f495-d05f-4dcf-955f-b5d38c655ab0', '171f4a82-971c-4c6c-a94c-b1ba29f12212',
      'ae57637b-5874-492c-b2f4-106a06fec3f9', '120cf389-1da7-4f22-969d-6ed669b20d10'],
    E2: ['253dfa8f-9811-489d-bc0b-58f5d575247b', '5008eb8d-13e4-48d2-ae72-2b66773f0641',
      '13a48b85-7f94-49c8-8892-32facb64847e', '93c310b0-f6c0-47ec-a011-e76b6cf8d34e',
      '5c200366-17ca-4484-815b-d78ccae67a55'],
    G2: ['72d7de27-5b61-4978-8a2c-6edd20b0176c', '861d4041-d04f-4967-825d-7a5a284d565c',
      '4b86dc18-fda1-45a0-b0b3-85f58d2e3822', '4b14f9ee-01f0-46bb-b494-e55063be6fee',
      'c9922318-36a6-47e2-b7bd-3ce93e6fec2e'],
    G3: ['737d1810-8db3-4e46-94e7-5c587fdd3600', '527b9bb2-9b6f-4771-b98c-bcf419b97d3a'],
    M1: ['dd973ee6-7d17-4509-946b-ef302b163ff2', 'f491d250-1e6a-4f05-a44a-789ffec587d5'],
    S1: ['d6378d64-01f6-4f73-948e-0cf5a88d5b55', 'fecb7b31-d1f6-4138-8402-3e96e3565932']
  },
  'demo-en': {
    Overview: ['9d17735a-0319-4990-a1e7-b9be28c3e4ae'],
    'Reporting platform': ['516cbb43-13dc-4f0b-b96e-9e85bd7d2956'],
    Performance: ['620d7438-0d72-4441-a23d-3a2927b326d5'],
    'Performance attribution': ['fe89a233-faf1-4b23-9b59-58c964fa948e'],
    'Listed assets': ['6ce615fa-d942-40e9-9503-4146e12584f7'],
    Lookthrough: ['38f72d8a-ae09-4375-af75-a74dbeb8e098'],
    'Illiquid assets': ['9253a78c-6ab2-411a-a553-97e438e2131d'],
    'Private equity': ['6758aaeb-832f-49f5-9fa0-0ac467ee17c0'],
    'Liquidity Planning Tool': ['603b550b-f15c-4165-b9e5-85729ea7c31a'],
    Loans: ['57e2875f-9e48-49e6-90b4-4c90c509c279'],
    Position: ['d8152d37-7f32-42d8-b750-a5d7c1df8f53'],
    'Notation ESG': ['43445fe8-505f-4e5d-9916-87f4e7cad94c'],
    'Carbon foot print': ['7d5dcfc5-78b5-454d-b965-d032fb23af93']
  }
};

export const isInDashboardTree = (dashboardId: String) => {
  return Object.keys(DASHBOARDS_TREE).some(mainSection => {
    return Object.keys(DASHBOARDS_TREE[mainSection]).some(subSection => {
      return DASHBOARDS_TREE[mainSection][subSection].some(e => e === dashboardId);
    });
  });
};

export const dashboardSubSection = (dashboardId: String) => {
  let section: string | undefined;

  Object.keys(DASHBOARDS_TREE).forEach(mainSection => {
    Object.keys(DASHBOARDS_TREE[mainSection]).forEach(subSection => {
      const isDasboardSection = DASHBOARDS_TREE[mainSection][subSection].some(e => e === dashboardId);

      if (isDasboardSection) {
        section = subSection;
      }
    });
  });

  return section;
};
