import { Component, OnDestroy, Inject, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { variables as versions } from '@quantizr/front-model/dist/versions/variables';
import { Strategy } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { dateAtMidnight, DatesSelector, ISelectorDatesSelected } from '../../../components';
import { StrategiesViewsService } from '../../views/service';
import { StrategiesCompositionModel, IComposition } from '../../composition/model';

const GROUPS = ['name', 'company_name', 'asset_classes', 'strategy_types'];

@Component({
  selector: 'page-strategies-show-weights',
  template
})
export class StrategiesShowWeightsPage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  public ALLOCATION_TYPES = versions.ALLOCATION_TYPES;

  public GROUPS = GROUPS;
  public group = GROUPS[0];
  public compositionModel: StrategiesCompositionModel;
  public composition: IComposition;

  public datesSelector = new DatesSelector();
  public dates: ISelectorDatesSelected = {};

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesCompositionModel')
    private CompositionModel,
    private $timeout: ng.ITimeoutService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.updateStrategy(strategy);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.updateStrategy(strategy);
    }
  }

  updateStrategy(strategy: Strategy) {
    if (!strategy) {
      return;
    }

    this.strategy = strategy;

    if (this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }
    if (this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    this.$timeout(() => {
      this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
      this.datesSelector.dates({
        max: this.dates.max
      });
    });

    this.initCompositions();
  }

  initCompositions() {
    this.compositionModel = new this.CompositionModel(this.strategy);
    this.compositionModel.init().then(composition => {
      this.composition = composition;
    });
  }

  changeGroup(group: string) {
    this.group = group;
  }
}
