import { Component, OnInit, Inject } from 'angular-ts-decorators';
import { Simulation, SimulationResource } from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/simulations/variables';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';

import './page.scss';
const template = require('./page.html.haml');

import { LOADING_STATES } from '../../custom-search';

@Component({
  selector: 'page-simulations-index',
  template
})
export class SimulationsIndexPage implements OnInit {
  public LOADING_STATES = LOADING_STATES;
  public PAGINATION_SIZE = settings.PAGINATION_SIZE;

  public loadingState = LOADING_STATES.LOADING;
  public page: number = 1;
  public totalResults: number = 0;
  public simulations: Simulation[] = [];

  /*@ngInject*/
  constructor(
    @Inject('SimulationResource')
    private simulationResource: SimulationResource,
    private $location: ng.ILocationService,
    private ngDialog: ng.dialog.IDialogService
  ) {}

  ngOnInit() {
    this.query();
  }

  query() {
    this.loadingState = LOADING_STATES.LOADING;

    return this.simulationResource.queryBy({
      page: this.page
    }).$promise.then(response => {
      this.querySuccess(response.simulations, response.total_results);
    }, () => {
      this.querySuccess([], 0);
    });
  }

  querySuccess(simulations: Simulation[], totalResults?: number) {
    this.loadingState = LOADING_STATES.IDLE;
    if (typeof totalResults !== 'undefined') {
      this.totalResults = totalResults;
    }
    this.simulations = simulations || [];
  }

  remove(simulation: Simulation) {
    return this.ngDialog.openConfirm({
      template: '<page-simulations-delete-confirm></page-simulations-delete-confirm>',
      plain: true
    }).then(() => {
      this.removeConfirmed(simulation);
    });
  }

  removeConfirmed(simulation: Simulation) {
    this.simulationResource.remove({
      id: simulation.id
    }, () => {
      this.query();
    }, () => {
      // TODO: display error
    });
  }

  open(simulation: Simulation) {
    (this.$location as any).$$search = {};
    if (simulation.status === variables.STATUSES.COMPLETED) {
      this.$location.path('/simulation/' + simulation.id + '/result');
    }
    else {
      this.$location.path('/simulation/' + simulation.id);
    }
  }
}
