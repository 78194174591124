import { Component } from 'angular-ts-decorators';

const template = require('./component.html.haml');

import { VersionsEditAbstractComponent } from '../abstract-component';

@Component({
  selector: 'versions-edit-fees',
  template
})
export class VersionsEditFeesComponent extends VersionsEditAbstractComponent {
  managementFeesInludedChanged() {
    if (this.version && (this.version as any).management_fees_included) {
      this.version.management_fees = 0;
    }
  }
}
