import { NgModule } from 'angular-ts-decorators';
import UserResourceFactory from '@quantizr/front-model/dist/users/resource';
import UserInvitationResourceFactory from '@quantizr/front-model/dist/users/invitations/resource';

import { VendorModule } from '../vendor';

@NgModule({
  id: 'Users',
  imports: [
    VendorModule
  ],
  providers: [{
    provide: 'UserResource',
    useFactory: ($resource) => UserResourceFactory($resource),
    deps: [
      '$resource'
    ]
  }, {
    provide: 'UserInvitationResource',
    useFactory: ($resource) => UserInvitationResourceFactory($resource),
    deps: [
      '$resource'
    ]
  }]
})
export class UsersModule {}

export default (UsersModule as NgModule).module!.name;
