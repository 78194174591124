import { Component, Inject } from 'angular-ts-decorators';
import { CockpitAxisResource, CockpitAxis } from '@quantizr/front-model';

const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';
import { formHasErrors } from '../../../forms/has-errors';

@Component({
  selector: 'page-cockpits-axes-edit',
  template
})
export class CockpitsAxesEditPage extends DialogController {
  public form: ng.IFormController;
  public saving = 0;
  public errorMessages: any = null;
  public axis: CockpitAxis;

  /*@ngInject*/
  constructor(
    @Inject('CockpitAxisResource')
    private cockpitAxisResource: CockpitAxisResource,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $scope: ng.IScope
  ) {
    super($scope);
    this.axis = this.data || {};
  }

  save() {
    this.saving = 1;

    return this.cockpitAxisResource.update(this.axis).$promise.then(() => {
      this.saveSuccess();
    }, error => this.saveError(error));
  }

  saveSuccess() {
    this.saving = 0;
    this.confirm();

    return this.$translate('cockpits.axes.edit.controller.success', {
      name: this.axis.name
    }).then(title => this.toaster.success(title));
  }

  saveError(error) {
    this.saving = 2;
    this.errorMessages = error.data;
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.form, this.errorMessages);
  }
}
