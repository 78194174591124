import { Component, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Simulation, SimulationResource } from '@quantizr/front-model';
import { variables as simulations } from '@quantizr/front-model/dist/simulations/variables';

import './page.scss';
const template = require('./page.html.haml');

import { scenarioConfigs } from '../scenarios';
import { JobModel, ACTIONS as JOB_ACTIONS } from '../../jobs';
import { VersionsViewsService } from '../../versions';
import { SimulationsViewsService } from '../views/service';

@Component({
  selector: 'page-simulations-show',
  template
})
export class SimulationsShowPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private _jobInit = false;
  private _updateJob: JobModel|null = null;

  public STATUSES = simulations.STATUSES;
  public simulationId;
  public simulation: Simulation;

  /*@ngInject*/
  constructor(
    @Inject('JobsModel')
    private Job,
    @Inject('SimulationResource')
    private simulationResource: SimulationResource,
    @Inject('SimulationsViewsService')
    private simulationsViewsService: SimulationsViewsService,
    @Inject('VersionsViewsService')
    private versionsViewsService: VersionsViewsService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    $routeParams: ng.route.IRouteParamsService
  ) {
    this.simulationId = $routeParams.id;
    versionsViewsService.fieldsConfig(scenarioConfigs('readonly').fields);
  }

  ngOnDestroy() {
    this.stopJob();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.simulationsViewsService.clear();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (!this.simulationId) {
      return;
    }

    return this.simulationResource.get({
      id: this.simulationId
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  loadSuccess(response: Simulation) {
    this.simulation = response;
    this.versionsViewsService.shiftValues(this.simulation.shift_parameters);
    if (this.simulation.status === simulations.STATUSES.PENDING) {
      this.initJob();
    }
  }

  loadError(_error?: any) {
    this.$location.path('/simulations');
  }

  initJob() {
    // Check if strategy needs to be refreshed
    if (!this._jobInit) {
      this._updateJob = new this.Job({
        id: this.simulationId,
        type: 'simulation',
        action: JOB_ACTIONS.CREATE_STATS,
        loading: {
          title: this.$translate.instant('simulations.show.controller.loading')
        },
        loaded: {
          title: this.$translate.instant('simulations.show.controller.loaded'),
          action: `<i class="far fa-eye"></i>&nbsp;<a class="underline"><span>
            ${this.$translate.instant('simulations.show.view.see_result')}
          </span></a>`,
          onClick: () => {
            this.seeResult();
          }
        }
      });

      this.subscriptions.push(this._updateJob!.finished().subscribe(finished => {
        if (finished) {
          this.init();
        }
      }));

      this._updateJob!.start();
    }
    // If reloading after already init, simple restart job to check for updates again
    else if (this._updateJob) {
      this._updateJob.restart();
    }

    this._jobInit = true;
  }

  stopJob() {
    if (this._updateJob) {
      this._updateJob.destroy();
      this._updateJob = null;
    }
  }

  seeResult() {
    (this.$location as any).$$search = {};
    this.$location.path('/simulation/' + this.simulation.id + '/result');
  }
}
