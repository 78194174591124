import { Component, Inject, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import {
  CockpitProfile,
  CockpitProfilePortfolio, CockpitProfilePortfolioResource, CockpitProfileGroupedKeywords,
  CockpitProfileVersionResource, CockpitProfileVersion,
  CockpitProfileStrategy
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import {
  ITableGroupedHeader,
  ITableGroupedGroup,
  ITableGroupedValue
} from '../../../components/table-grouped/component';

export interface ICockpitProfileVersionPortfolio extends CockpitProfileVersion {
  portfolio?: CockpitProfilePortfolio;
}

type weightDisplay = 'percent'|'diff';

const strategyVersionModel = (strategy: CockpitProfileStrategy, version: CockpitProfileVersion) => {
  return version.strategy_versions_attributes!.find(value => value.strategy_id === strategy.id!);
};

export const versionWeight = (
  version: CockpitProfileVersion,
  property: 'weight'|'real_weight',
  group?: CockpitProfileGroupedKeywords,
  strategy?: CockpitProfileStrategy
): number => {
  if (strategy) {
    const model = strategyVersionModel(strategy, version);
    return model ? model[property] as number : 0;
  }
  else if (group) {
    if (group.strategies) {
      return group.strategies.reduce((previous, current) => {
        return previous + versionWeight(version, property, undefined, current);
      }, 0);
    }
    else if (group.children) {
      return group.children.reduce((previous, current) => {
        return previous + versionWeight(version, property, current);
      }, 0);
    }
  }
  return 0;
};

@Component({
  selector: 'cockpits-profiles-strategies-listing',
  template
})
export class CockpitsProfilesStrategiesListingComponent implements OnChanges {
  private portfolios: CockpitProfilePortfolio[] = [];
  private versions: ICockpitProfileVersionPortfolio[] = [];

  @Input()
  profile: CockpitProfile;
  @Input()
  versionDate: Date;

  public weightDisplay: weightDisplay = 'percent';

  public headers: ITableGroupedHeader[] = [];
  public groups: ITableGroupedGroup[] = [];

  /*@ngInject*/
  constructor(
    @Inject('CockpitProfilePortfolioResource')
    private cockpitProfilePortfolioResource: CockpitProfilePortfolioResource,
    @Inject('CockpitProfileVersionResource')
    private cockpitProfileVersionResource: CockpitProfileVersionResource,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes) {
      this.loadPortfolios();
    }
    if ('versionDate' in changes) {
      const versionDate = changes.versionDate.currentValue;
      if (versionDate) {
        this.loadVersions();
      }
    }
  }

  updateWeightDisplay(weightDisplay: weightDisplay) {
    this.weightDisplay = weightDisplay;
    this.updateGroups();
  }

  loadHeaders() {
    return this.$translate([
      'cockpits.profiles.strategiesListing.isin',
      'cockpits.profiles.strategiesListing.name'
    ]).then(values => {
      this.headers = Object.keys(values).map(value => {
        return {
          label: values[value],
          colspan: 1
        };
      });
    });
  }

  loadPortfolios() {
    return this.cockpitProfilePortfolioResource.query({
      profile_id: this.profile!.id
    }).$promise.then(portfolios => {
      this.portfolios = portfolios;
      this.portfolios.forEach(portfolio => portfolio.profile_id = this.profile!.id);
      this.updateGroups();
    });
  }

  loadVersions() {
    return this.cockpitProfileVersionResource.query({
      profile_id: this.profile!.id,
      start_date: this.versionDate
    }).$promise
      .then(versions => {
        this.versions = versions;
        this.versions.forEach(version => {
          const portfolio = this.portfolios.find(value => value.id === version.strategy_id);
          version.portfolio = portfolio;
        });
      })
      .then(() => this.loadHeaders())
      .then(() => {
        this.versions.forEach(version => {
          this.headers.push({
            label: version.portfolio!.name,
            colspan: 2
          });
        });
        this.updateGroups();
      });
  }

  weight(
    version: CockpitProfileVersion, group?: CockpitProfileGroupedKeywords, strategy?: CockpitProfileStrategy
  ) {
    return versionWeight(version, 'weight', group, strategy);
  }

  realWeight(
    version: CockpitProfileVersion, group?: CockpitProfileGroupedKeywords, strategy?: CockpitProfileStrategy
  ) {
    const realWeight = versionWeight(version, 'real_weight', group, strategy);
    if (this.weightDisplay === 'percent') {
      return realWeight;
    }
    else {
      const weight = versionWeight(version, 'weight', group, strategy);
      return realWeight - weight;
    }
  }

  groupValues(group?: CockpitProfileGroupedKeywords, strategy?: CockpitProfileStrategy) {
    const values: ITableGroupedValue[] = [];
    this.versions.forEach(version => {
      values.push({
        value: this.weight(version, group, strategy) / 100,
        isPercentage: true
      });
      values.push({
        value: this.realWeight(version, group, strategy) / 100,
        isPercentage: true
      });
    });
    return values;
  }

  updateGroups() {
    this.groups = (this.profile.grouped_keywords || []).map(group => {
      const color = (group as any).color;

      const children = (group.children || []).map(subgroup => {
        const strategies = (subgroup.strategies || []).map(strategy => {
          let href = '';
          if (strategy.type === 'Strategy' || strategy.type === 'Portfolio') {
            href = `#!/strategy/${strategy.id}`;
          }
          return {
            id: strategy.isin || '',
            href,
            name: strategy.name || '',
            values: this.groupValues(undefined, strategy)
          };
        });

        return {
          id: subgroup.id,
          name: subgroup.name,
          open: (subgroup as any).open || false,
          values: this.groupValues(subgroup),
          children: strategies
        };
      });

      return {
        id: group.id,
        name: group.name,
        open: (group as any).open || false,
        color,
        bgColor: color,
        values: this.groupValues(group),
        children
      };
    });
  }
}
