import { Injectable } from 'angular-ts-decorators';

@Injectable('CustomSearchRequestsService')
export class CustomSearchRequestsService {
  private _id = 0;
  private _requests: Array<{
    key: string | number;
    request: ng.resource.IResource<any>|ng.resource.IResourceArray<any>;
  }> = [];

  public add(
    request: ng.resource.IResource<any>|ng.resource.IResourceArray<any>,
    key?: string | number
  ): CustomSearchRequestsService {
    this._requests.push({
      key: key || ++this._id,
      request
    });
    return this;
  }

  public remove(
    request?: ng.resource.IResource<any>|ng.resource.IResourceArray<any>,
    key?: string | number
  ): CustomSearchRequestsService {
    for (let i = this._requests.length - 1; i >= 0; i--) {
      if ((key && this._requests[i].key === key) || this._requests[i].request === request) {
        this._requests.splice(i, 1);
        break;
      }
    }
    return this;
  }

  public contains(
    request?: ng.resource.IResource<any>|ng.resource.IResourceArray<any>,
    key?: string | number
  ): boolean {
    for (let i = this._requests.length - 1; i >= 0; i--) {
      if ((key && this._requests[i].key === key) || this._requests[i].request === request) {
        return true;
      }
    }
    return false;
  }

  public cancel(
    request?: ng.resource.IResource<any>|ng.resource.IResourceArray<any>,
    key?: string | number
  ): CustomSearchRequestsService {
    for (let i = this._requests.length - 1; i >= 0; i--) {
      if ((key && this._requests[i].key === key) || this._requests[i].request === request) {
        request = request || this._requests[i].request;
        this._requests.splice(i, 1);
        request.$cancelRequest();
        break;
      }
    }
    return this;
  }

  public cancelAll(): CustomSearchRequestsService {
    while (this._requests.length) {
      try {
        const request = this._requests.pop()!.request;
        request && request.$cancelRequest();
      }
      catch (err) {}
    }
    this._requests = [];
    return this;
  }

  public get size(): number {
    return this._requests.length;
  }
}
