import {
  Component, Input, Output, AfterViewInit, OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import './component.scss';

const template = require('./component.html.haml');

import { BUTTON_STATES, ANIMATIONS_DURATION } from '../';

export const progressState = (progress: number, state?: string): {state: string, idle: boolean} => {
  if (progress === 0) {
    if (state === BUTTON_STATES.PROGRESS) {
      return {
        state: BUTTON_STATES.DONE,
        idle: true
      };
    }
    else {
      return {
        state: BUTTON_STATES.IDLE,
        idle: false
      };
    }
  }
  else if (progress === 2) {
    if (state === BUTTON_STATES.PROGRESS) {
      return {
        state: BUTTON_STATES.ERROR,
        idle: true
      };
    }
    else {
      return {
        state: BUTTON_STATES.IDLE,
        idle: false
      };
    }
  }

  // progress typically 1
  return {
    state: BUTTON_STATES.PROGRESS,
    idle: false
  };
};

@Component({
  selector: 'button-states',
  template
})
export class ButtonStatesComponent implements AfterViewInit, OnChanges {
  @Output()
  onClick: () => void;

  @Input('@?')
  public buttonClass: string;
  @Input('@?')
  public buttonIcon: string;
  @Input()
  public inProgress: number;
  @Input()
  public form: ng.IFormController;
  @Input('@')
  public idleLabel: string;
  @Input('@')
  public progressLabel: string;
  @Input('@')
  public doneLabel: string;
  @Input('@')
  public errorLabel: string;
  @Input('@')
  public isFullBlock: boolean;

  public STATES = BUTTON_STATES;
  public state: string = BUTTON_STATES.IDLE;

  /*@ngInject*/
  constructor(
    public $timeout: ng.ITimeoutService
  ) {}

  ngAfterViewInit() {
    if (!this.buttonClass) {
      this.buttonClass = 'btn-primary';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inProgress' in changes) {
      const value = changes.inProgress.currentValue;
      this.updateState(value);
    }
  }

  submit() {
    this.onClick();
  }

  updateState(progress: number) {
    const updateState = progressState(progress, this.state);
    this.state = updateState.state;
    if (updateState.idle) {
      this.$timeout(() => {
        this.state = BUTTON_STATES.IDLE;
      }, ANIMATIONS_DURATION * 2);
    }
  }
}
