import { NgModule } from 'angular-ts-decorators';

import './app.scss';
import { config as configRoutes } from './routes';
import { config as configUibDateParser } from './uib-date-parser';
import { config as configUibTooltip } from './uib-tooltip';

import { BenchmarksModule } from '../benchmarks';
import { CockpitsModule } from '../cockpits';
import { ConfigModule } from '../config';
import { DashboardsModule } from '../dashboards';
import { ErrorsModule } from '../errors';
import { FilesModule } from '../files';
import { GlossaryModule } from '../glossary';
import { KeywordsModule } from '../keywords';
import { MyUserModule } from '../my-user';
import { SimulationsModule } from '../simulations';
import { StrategiesModule } from '../strategies';
import { TreemapModule } from '../treemap';
import { VendorModule } from '../vendor';

@NgModule({
  id: 'App',
  imports: [
    BenchmarksModule,
    CockpitsModule,
    ConfigModule,
    DashboardsModule,
    ErrorsModule,
    FilesModule,
    GlossaryModule,
    KeywordsModule,
    MyUserModule,
    SimulationsModule,
    StrategiesModule,
    TreemapModule,
    VendorModule
  ]
})
export class AppModule {
  static config(
    $routeProvider: ng.route.IRouteProvider,
    $routeSegmentProvider: ng.routeSegment.IRouteSegmentProvider,
    $uibTooltipProvider: ng.ui.bootstrap.ITooltipProvider
  ) {
    'ngInject';
    configRoutes($routeProvider, $routeSegmentProvider);
    configUibTooltip($uibTooltipProvider);
  }

  static run(uibDateParser: any) {
    'ngInject';
    configUibDateParser(uibDateParser);
  }
}

export default (AppModule as NgModule).module!.name;
