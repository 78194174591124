import { Component } from 'angular-ts-decorators';

import './page.scss';
const template = require('./page.html.haml');

import { ILoginMyUser } from '../../service';
import { CLIENT_APPLICATION } from '../../../config/devise';

@Component({
  selector: 'page-my-user-password-reset',
  template
})
export class MyUserPasswordResetPage {
  public saving = 0;
  public errorMessages: any = null;
  public success: boolean = false;
  public email: string = '';

  /*@ngInject*/
  constructor(
    private Auth: ng.devise.IAuthProvider<ILoginMyUser>
  ) {}

  submit() {
    this.errorMessages = null;
    this.saving = 1;

    return this.Auth.sendResetPasswordInstructions({
      email: this.email,
      client: CLIENT_APPLICATION
    }).then(() => this.updateSuccess(), error => this.updateError(error));
  }

  updateSuccess() {
    this.saving = 0;
    this.success = true;
  }

  updateError(error) {
    this.saving = 2;
    this.errorMessages = error.data.errors;
  }
}
