import { Injectable } from 'angular-ts-decorators';
import { Subject } from 'rxjs';
import cloneDeep = require('lodash/cloneDeep');

export interface ITableGroupedServiceOpen {
  [id: string]: boolean|undefined;
}

@Injectable('TableGroupedService')
export class TableGroupedService {
  private _open: ITableGroupedServiceOpen = {};
  private _$open: Subject<ITableGroupedServiceOpen> = new Subject();

  public set open(open: ITableGroupedServiceOpen) {
    this._open = open;
    this._$open.next(open);
  }

  public get open() {
    return cloneDeep(this._open);
  }

  public $open() {
    return this._$open.asObservable();
  }
}
