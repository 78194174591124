import { OnDestroy, Inject } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { CockpitProfile, CockpitProfileResource, Cockpit } from '@quantizr/front-model';

import { formHasErrors } from '../../../forms/has-errors';
import { formOnDirty } from '../../../forms/on-dirty';
import { CockpitsViewsService } from '../../views/service';

export abstract class CockpitsEditProfilesChildPage implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private watchers: Function[] = [];

  public saving = 0;
  public errors: any = null;
  public errorMessages: any = {};
  public forms: {
    form?: ng.IFormController
  } = {};

  public cockpit: Cockpit|null = null;
  public profile: CockpitProfile|null = null;
  public profileId: number|undefined;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    @Inject('CockpitProfileResource')
    protected cockpitProfileResource: CockpitProfileResource,
    protected $location: ng.ILocationService,
    protected ngDialog: ng.dialog.IDialogService,
    protected $translate: ng.translate.ITranslateService,
    protected $q: ng.IQService,
    private toaster: toaster.IToasterService,
    $routeParams: ng.route.IRouteParamsService,
    $rootScope: ng.IRootScopeService
  ) {
    this.profileId = $routeParams.profileId;
    this.init();

    this.subscriptions.push(cockpitsViewsService.$cockpit().subscribe(cockpit => this.cockpit = cockpit));
    this.watchers.push($rootScope.$on('$locationChangeStart', event => this.locationChangeStart(event)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.watchers.forEach(watcher => watcher());
  }

  /**
   * Load the profile
   */
  init() {
    this.errors = {};
    this.profile = null;
    this.saving = 0;

    return this.cockpitProfileResource.get({
      id: this.profileId,
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  loadSuccess(profile: CockpitProfile) {
    this.profile = profile;
  }

  loadError(_error: any) {
    this.$translate('cockpits.profiles.edit.load.error').then(value => {
      this.errors = {
        load: value
      };
    });
  }

  canSave(): boolean {
    return this.forms.form!.$valid === true;
  }

  public async save() {
    this.forms.form!.$submitted = true;

    if (!this.canSave()) {
      return this.saveError({
        data: {}
      });
    }

    this.saving = 1;

    await this.saveProfile();
  }

  /**
   * Main save action
   */
  abstract saveProfile();

  saveSuccess(profile?: CockpitProfile) {
    this.errorMessages = null;
    if (!this.profile!.id && profile) {
      this.profile = profile;
    }
    this.forms.form!.$dirty = false;

    this.$translate('cockpits.profiles.edit.controller.success', {
      name: this.profile!.name
    }).then(title => this.toaster.success(title));

    this.cockpitsViewsService.loadCockpit(() => {
      this.saving = 0;
      this.$location.path(`/cockpit/monitoring/${this.profile!.id}`);
    });
  }

  async saveError(error: {data: any}) {
    this.errorMessages = error.data;
    this.saving = 2;
  }

  hasError(
    fields: string|string[],
    form: ng.IFormController = this.forms.form!,
    errors: any = this.errorMessages
  ) {
    return formHasErrors(fields, form, errors);
  }

  locationChangeStart(event) {
    const next = this.$location.path();
    this.confirmOnDirty(() => {
      event.preventDefault();
      return this.cancelConfirm();
    }).then(() => {
      this.$location.path(next);
    });
  }

  cancel() {
    return this.confirmOnDirty(() => {
      return this.cancelConfirm();
    }).then(() => {
      this.init();
    });
  }

  cancelConfirm() {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-cancel-confirm></page-cockpits-cancel-confirm>',
      plain: true,
      className: 'ngdialog-theme-default'
    });
  }

  confirmOnDirty(confirm) {
    return formOnDirty(this.$q, (this.forms || {}).form, confirm);
  }
}
