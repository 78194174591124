import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import {
  MyUser, DashboardView, DashboardResource, DashboardViewResource
} from '@quantizr/front-model';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import { Subscription } from 'rxjs';

import { MyUserService } from '../../my-user';
import { DASHBOARDS_TREE } from '../../panels/sidebar/constants';

import { isValid, PolicyType } from '../../my-user/policies/is-valid';

import './page.scss';
const template = require('./page.html.haml');

@Component({
  selector: 'page-dashboards-show',
  template
})
export class DashboardsShowPage implements OnDestroy {
  public PAGINATION_SIZE = settings.PAGINATION_SIZE;

  public totalResults: number = 0;
  public dashboardViews: DashboardView[] = [];
  public selectedDashboardView: DashboardView;
  public dashboardId;
  public ticket: String|undefined = undefined;
  public viz: any = undefined;
  public workbook: any = undefined;
  public user: MyUser|null = null;
  public disableSheetSwitch: Boolean = true;
  private watchers: Function[] = [];
  private subscriptions: Subscription[] = [];

  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('DashboardViewResource')
    private dashboardViewResource: DashboardViewResource,
    @Inject('DashboardResource')
    private dashboardResource: DashboardResource,
    public $route: ng.route.IRouteService,
    public $routeParams: ng.route.IRouteParamsService,
    public $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    public $rootScope: ng.IRootScopeService
  ) {
    this.subscriptions.push(
      myUserService.$user().subscribe((user) => this.updateDashboards(user))
    );

    this.watchers.push(
      $rootScope.$on('$routeChangeSuccess', (_scope, route) => {
        this.routeChangeSuccess(route);
      })
    );
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  routeChangeSuccess(_route: any) {
    if (!this.disableSheetSwitch && this.$routeParams.id === this.dashboardId) {
      this.selectedDashboardView =
        this.dashboardViews.find(e => e.name === this.$routeParams.dashboardView) || this.dashboardViews[0];

      this.workbook = this.viz.getWorkbook();
      this.workbook.activateSheetAsync(this.selectedDashboardView.name);
    }
    else if (this.$routeParams.id === this.dashboardId && this.ticket && this.ticket !== '-1') {
      this.query();
    }
  }

  updateDashboards(user) {
    this.user = user;
    if (!user) {
      return;
    }

    if (!!this.$routeParams.id && user!.dashboards!.length > 0) {
      user.dashboards.forEach(dashboard => {
        if (dashboard.id === this.$routeParams.id) {
          this.dashboardId = dashboard.id;
        }
      });
    }

    if (this.dashboardId) {
        this.query();
    }
    else {
      this.redirectToDefaultUrl(user);
    }
  }

  private redirectToDefaultUrl(user) {
    if (!!user.default_url && user.default_url !== '' &&
        this.$window.location !== user.default_url) {
      this.$window.location.hash = user.default_url;
      return;
    }

    if (isValid(user.policies, 'files', 'index' as PolicyType) &&
             user.provider_configs.length > 0) {
      this.$window.location.hash = `#!/files/${user.provider_configs[0].id}`;
      return;
    }

    if (this.hasDashboardFor('market')) {
      this.$window.location.hash = `#!/market/dashboard/${this.hasDashboardFor('market')}`;
      return;
    }

    if (this.hasDashboardFor('la-4eme-ligne')) {
      this.$window.location.hash =
        `#!/la-4eme-ligne/dashboard/${this.hasDashboardFor('la-4eme-ligne')}`;
      return;
    }

    if (isValid(user.policies, 'strategies', 'index' as PolicyType)) {
      this.$window.location.hash = '#!/strategies/all';
      return;
    }

    this.$window.location.hash = '#!/my';
    return;
  }

  query() {
    return this.dashboardViewResource.queryBy({
      dashboard_id: this.dashboardId
    }).$promise.then(response => {
      this.querySuccess(response.views);
    }, () => {
      this.querySuccess([]);
    });
  }

  querySuccess(dashboardsViews: DashboardView[]) {
    this.dashboardViews = dashboardsViews || [];

    this.dashboardViews.forEach((dashboardView) => {
      (dashboardView as any).path = `${this.$window.location.hash.split('?', 1)[0]}?` +
        `dashboardView=${encodeURIComponent(dashboardView.name as string)}`;
    });

    if (this.dashboardViews.length > 0) {
      this.selectedDashboardView =
        this.dashboardViews.find(e => e.name === this.$routeParams.dashboardView) || this.dashboardViews[0];

      return this.dashboardResource.trustedTicket().$promise.then(response => {
        this.initViz(response.ticket);
      }, () => {
        this.ticket = '-1';
      });
    }
    else {
      return this.ticket = '-1';
    }
  }

  initViz(ticket: String) {
    if (this.viz) {
      this.viz.dispose();
    }

    this.ticket = ticket;
    const vizDiv = document.getElementById('viz');
    let vizURL = 'https://dashboard.quantilia.com/trusted/' +
                   `${this.ticket}/views/${this.selectedDashboardView!.contentUrl}?`;
    let calculation_start_date: Date = new Date();
    let benchmark_calculation_start_date: Date|undefined = new Date();
    let strategy_name: String = 'Select a name';

    if (this.user && this.user.default_strategy) {
      if (!!this.user.default_strategy.calculation_start_date) {
        calculation_start_date = this.user.default_strategy.calculation_start_date;
      }

      if (!!this.user.default_strategy.name) {
        strategy_name = this.user.default_strategy.name;
      }

      vizURL += `Selected Strategy Id=${this.user.default_strategy.id}&` +
       `Selected Date=${calculation_start_date}&Base Date=${this.user.default_strategy.start_date}`;

      if (this.user.default_strategy.benchmark &&
          this.user.default_strategy.benchmark.calculation_start_date) {
        benchmark_calculation_start_date =
          this.user.default_strategy.benchmark.calculation_start_date;
        vizURL += `&Selected Benchmark Id=${this.user.default_strategy.benchmark.id}&` +
         `Selected Benchmark Date=${benchmark_calculation_start_date}`;
      }
      else {
        benchmark_calculation_start_date = calculation_start_date;
        vizURL +=
          `&Selected Benchmark Id=0&Selected Benchmark Date=${benchmark_calculation_start_date}`;
      }

      if (this.user.default_customlist) {
        vizURL += `&Selected Customlist Id=${this.user.default_customlist.id}`;
      }
    }

    this.viz = new this.$window.tableau.Viz(vizDiv, vizURL, {
      StrategyName: strategy_name,
      CalculationStartDate: calculation_start_date,
      BenchmarkCalculationStartDate: benchmark_calculation_start_date,
      hideToolbar: false,
      hideTabs: true,
      width: '100%',
      height: '1700px',
      onFirstInteractive: () => {
        this.disableSheetSwitch = false;
        this.viz.addEventListener(
          this.$window.tableau.TableauEventName.TAB_SWITCH,
          (tabEvent) => {
              this.onTabSwitch(tabEvent);
          }
        );
      }
    });
  }

  private onTabSwitch(tabEvent) {
    this.$timeout(() => {
      const newDashboardView = this.dashboardViews.find(
        e => e.name === tabEvent.getNewSheetName()
      );
      if (!!newDashboardView) {
        this.$route.updateParams({
          dashboardView: tabEvent.getNewSheetName()
        });
        this.selectedDashboardView = newDashboardView;
      }
    });
  }

  private hasDashboardFor(mainCategory: string = 'market') {
    const foundKey = Object.keys(DASHBOARDS_TREE[mainCategory]).find(marketKey => {
      const dashboardId = DASHBOARDS_TREE[mainCategory][marketKey][0];

      if ((this.user as any).dashboards.some(e => e.id === dashboardId)) {
        return dashboardId;
      }
    });

    if (!!foundKey) {
      return DASHBOARDS_TREE[mainCategory][foundKey][0];
    }
    else {
      return false;
    }
  }
}
