import { Component } from 'angular-ts-decorators';

const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';

@Component({
  selector: 'page-cockpits-cancel-confirm',
  template
})
export class CockpitsCancelConfirmPage extends DialogController {}
