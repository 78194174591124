import { Component, Input, OnChanges, SimpleChanges, Inject } from 'angular-ts-decorators';
import { StrategyStock, StrategyStockResource, categoriesForTable } from '@quantizr/front-model';
import merge = require('lodash/merge');

const template = require('./component.html.haml');

import { ITableSettingsGroup, TableSettingsController } from '../../../../components';

type orderDir = 'asc'|'desc';

@Component({
  selector: 'strategies-show-holdings-sorted-table',
  template
})
export class StrategiesShowHoldingsSortedTableComponent implements OnChanges {
  @Input()
  strategyId: number;
  @Input('@')
  public key = '';
  @Input()
  public cTitle = '';
  @Input()
  public fixingsProperties: string[] = [];
  @Input()
  public longShort?: 'long'|'short' = 'long';
  @Input('@?')
  public orderBy?: string;
  @Input('@?')
  public orderDir: orderDir = 'desc';
  @Input()
  public reverse = false;
  @Input()
  public maxLines = 5;

  public CATEGORIES: any[] = [];
  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController?: TableSettingsController;
  public strategies: StrategyStock[] = [];

  /*@ngInject*/
  constructor(
    @Inject('StrategyStockResource')
    private strategyStockResource: StrategyStockResource,
    private $translate: ng.translate.ITranslateService
  ) {
    this.tableSettingsGroups = [{
      key: 'column',
      title: $translate.instant('timeSeries.categories.')
    }];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('key' in changes) {
      const key = changes.key.currentValue;
      if (key) {
        this.registerCommonTableProperties();
      }
    }

    if ('fixingsProperties' in changes) {
      const fixingsProperties = changes.fixingsProperties.currentValue;
      if (fixingsProperties) {
        this.registerTableProperties();
      }
    }

    if ('strategyId' in changes || 'longShort' in changes) {
      this.query();
    }
  }

  registerCommonTableProperties() {
    this.tableSettingsController = new TableSettingsController(
      `strategies-show-holdings-sorted-table-${this.key}`
    );
    this.tableSettingsController!.registerProperty({
      key: 'name',
      group: 'column',
      active: true,
      title: this.$translate.instant('strategies.show.holdings.sortedTable.name')
    });
    this.tableSettingsController!.registerProperty({
      key: 'weight',
      group: 'column',
      active: true,
      title: this.$translate.instant('strategies.show.holdings.sortedTable.weight')
    });
  }

  registerTableProperties() {
    this.CATEGORIES = categoriesForTable(this.fixingsProperties.map(property => {
      const [key] = property.split('.');
      return {
        property,
        key,
        defaultActive: true
      } as any;
    })).map((value: any) => {
      const [category, window] = value.property.split('.');
      const categoryTitle = this.$translate.instant(`timeSeries.categories.${category}`);
      const windowTitle = this.$translate.instant(`timeSeries.windowsShort.${window}`);
      return merge(value, {
        key: value.property,
        title: `${categoryTitle} ${windowTitle}`
      });
    });

    this.CATEGORIES.forEach(category => {
      this.tableSettingsController!.registerProperty({
        key: category.key,
        group: 'column',
        active: true,
        title: category.title
      });
    });
  }

  query() {
    let orderBy: any;
    if (this.orderBy) {
      const [category, window] = this.orderBy.split('.');
      orderBy = {category, window};
    }

    const fixings: any = {};
    (this.fixingsProperties || []).forEach(property => {
      const [category, window] = property.split('.');
      fixings[category] = fixings[category] || [];
      fixings[category].push(window);
    });

    return this.strategyStockResource.queryBy({
      strategy_id: this.strategyId,
      asc_desc: this.orderDir,
      order_by: orderBy,
      page: 1,
      per_page: this.maxLines,
      fixings,
      long_short: this.longShort
    }).$promise.then(response => {
      if (this.reverse) {
        this.strategies = response.reverse();
      }
      else {
        this.strategies = response;
      }
    });
  }

  finxingValue(strategy: StrategyStock, property: string) {
    try {
      const [category, window] = property.split('.');
      return strategy.fixings[category][window].value;
    }
    catch (err) {
      return null;
    }
  }
}
