import { EnvService } from '../env';

export const ApiInterceptor = 'ApiInterceptor';

export default function(envService: EnvService): ng.IHttpInterceptor {
  return {
    request(config: ng.IRequestConfig): ng.IRequestConfig {
      if (config.url.indexOf('/') === 0) {
        config.url = `${envService.apiUrl('api')}${config.url}`;
      }

      return config;
    }
  };
}
