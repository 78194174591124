import { Injectable } from 'angular-ts-decorators';

import { IEnv, subdomain as Subdomain } from './model';

@Injectable('EnvService')
export class EnvService {
  /*@ngInject*/
  constructor(private ENV: IEnv) {}

  public apiUrl(subdomain: Subdomain): string {
    try {
      const configuration = this.ENV.configuration;
      const sub = configuration.apiSubdomains[subdomain];
      const host = sub ? `${sub}.${configuration.apiBaseUrl}` : configuration.apiBaseUrl;
      return `${configuration.apiUrlProtocol}://${host}`;
    }
    catch (err) {
      return '';
    }
  }
}
