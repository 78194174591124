import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { IStrategiesCorrelationMatrixSettings } from '../../correlation-matrix/component';
import { StrategiesVersionsModel, IStrategyVersionVersion } from '../../versions/model';
import { StrategiesViewsService } from '../../views/service';

@Component({
  selector: 'page-strategies-show-versions',
  template
})
export class StrategiesShowVersionsPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public model: StrategiesVersionsModel;
  public strategy: Strategy;
  public strategyVersions: IStrategyVersionVersion[];
  public strategiesColors: IStrategiesCorrelationMatrixSettings;
  public strategies: Strategy[];
  public groupedData: any;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesVersionsModel')
    VersionsModel,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy) {
        this.strategy = strategy;
        this.model = new VersionsModel(this.strategy);
        this.init();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    return this.model.init().then(strategyVersions => this.initSuccess(strategyVersions));
  }

  initSuccess(strategyVersions: IStrategyVersionVersion[]) {
    this.strategyVersions = strategyVersions;
    this.strategiesColors = this.model.colors();
    this.strategies = this.model.strategies();
    this.groupedData = this.model.groupedData();
  }
}
