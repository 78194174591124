import { Directive, OnInit } from 'angular-ts-decorators';

export const toLocalDate = (value: Date, dateFilter: ng.IFilterDate) => {
  value.setMinutes(value.getMinutes() - value.getTimezoneOffset());
  return dateFilter(value, 'dd-MMM-yyyy');
};

@Directive({
  selector: 'datepicker-localdate',
  require: {
    ngModel: '?ngModel'
  },
  bindToController: true
})
export class DatepickerLocaldateDirective implements OnInit {
  ngModel: ng.INgModelController;

  /*@ngInject*/
  constructor(
    private $filter: ng.IFilterService
  ) {}

  ngOnInit() {
    this.ngModel.$parsers.push(viewValue => toLocalDate(viewValue, this.$filter('date')));
  }
}
