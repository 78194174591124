import { Component, Inject, OnInit } from 'angular-ts-decorators';
import { CockpitProfile } from '@quantizr/front-model';
import { take } from 'rxjs/operators';

const template = require('./page.html.haml');

import { CockpitsViewsService } from '../views/service';

@Component({
  selector: 'page-cockpits-edit',
  template
})
export class CockpitsEditPage implements OnInit {
  public profile: CockpitProfile|null = null;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService
  ) {}

  ngOnInit() {
    return this.cockpitsViewsService.$cockpitProfile().pipe(take(1)).toPromise().then(profile => {
      this.profile = profile;
    });
  }
}
