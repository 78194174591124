import { Component, Inject, OnInit } from 'angular-ts-decorators';

import './page.scss';
const template = require('./page.html.haml');

import { MyUserService, ILoginMyUser } from '../service';
import { CLIENT_APPLICATION } from '../../config/devise';
import { formHasErrors } from '../../forms/has-errors';

@Component({
  selector: 'page-my-user-signin',
  template
})
export class MyUserSigninPage implements OnInit {
  public user: ILoginMyUser = {
    client: CLIENT_APPLICATION
  };
  public loginForm: ng.IFormController;
  public errorMessage: any = null;
  public errorMessages: any = null;
  public noticeMessages: any = null;
  public saving = 0;

  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    private myUserService: MyUserService,
    private $window: ng.IWindowService,
    private $translate: ng.translate.ITranslateService,
    private $location: ng.ILocationService,
    private gRecaptcha: any
  ) {}

  async ngOnInit() {
    const path = this.$location.search();
    if ('error_code' in path) {
      return this.$translate(`myUser.errors.${path.error_code}`).then(error => {
        this.errorMessages = error;
      });
    }

    if ('notice' in path) {
      return this.$translate(`myUser.notices.${path.notice}`).then(notice => {
        this.noticeMessages = notice;
      });
    }
  }

  login() {
    this.saving = 1;
    return this.gRecaptcha.execute({ action: 'login' }).then(token => this.loginAfterRecaptcha(token));
  }

  loginAfterRecaptcha(token) {
    this.user['token'] = { login: token };
    return this.myUserService.login(this.user).then(response => {
      this.loginSuccess(response);
    }, error => this.loginError(error));
  }

  loginSuccess(user) {
    this.myUserService.user = user;

    this.saving = 0;
    if (this.myUserService.localStorageService.get('redirectAfterSignIn')) {
      const path = this.myUserService.localStorageService.get('redirectAfterSignIn') as any;

      this.myUserService.localStorageService.remove('redirectAfterSignIn');
      this.$window.location.hash = `#!${path}`;
      return;
    }
    if (user.redirect_after_login) {
      this.$window.location.href = user.redirect_after_login;
      return;
    }
    else if (this.$window.location.hash !== '#!') {
      if (user.dashboards && user.dashboards.length > 0) {
        this.$window.location.hash = `#!/dashboard/${user.dashboards[0].id}`;
      }
      else {
        this.$window.location.hash = '#!/strategies/all';
      }
      return;
    }

    this.$window.location.reload();
  }

  loginError(error) {
    this.myUserService.user = null;

    this.saving = 2;
    this.errorMessage = error.data.error;
    this.errorMessages = error.data.errors;
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.loginForm, this.errorMessages);
  }
}
