import { Component, Inject, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import {
  Constituent, StrategyIssuer, StrategyIssuerResource, StrategyIssuerQueryByResponse
} from '@quantizr/front-model';

const template = require('./component.html.haml');
import './component.scss';

import { ITableSettingsGroup, TableSettingsController } from '../../../../components';
import { transformToNumber } from '../../../../components/statistics/transform-to-number';
import { COUNTRY_ISSUER_TYPES } from '../general/page';
const ISSUERS_CATEGORIES = [
  'name',
  'weight',
  'sector',
  'country',
  'nbStrategies',
  'assetClasses'
];

type IFieldKey = 'strategy.name'|'weight'|
  'constituent.isin'|'constituent.instrument'|'constituent.currency'|
  'constituent.coupon'|'constituent.maturity'|'constituent.rating'|
  'constituent.derivative_type'|'constituent.derivative_strike'|
  'constituent.derivative_issuer.name'|'constituent.tranche'|'constituent.identification';

export interface IFieldDefinition {
  key: IFieldKey;
  defaultActive?: boolean;
  type?: 'translate'|'percent'|'date'|'number';
  isStatistics?: boolean;
}

const STRATEGIES_CATEGORIES: IFieldDefinition[] = [{
  key: 'constituent.isin',
  defaultActive: false
}, {
  key: 'weight',
  defaultActive: true,
  isStatistics: true,
  type: 'number'
}, {
  key: 'constituent.instrument',
  defaultActive: true,
  type: 'translate'
}, {
  key: 'constituent.currency',
  defaultActive: true
}, {
  key: 'constituent.maturity',
  defaultActive: false
}, {
  key: 'constituent.coupon',
  defaultActive: false,
  isStatistics: true,
  type: 'number'
}, {
  key: 'constituent.rating',
  defaultActive: false
}, {
  key: 'constituent.derivative_type',
  defaultActive: false,
  type: 'translate'
}, {
  key: 'constituent.derivative_strike',
  defaultActive: false
}, {
  key: 'constituent.derivative_issuer.name',
  defaultActive: false
}, {
  key: 'strategy.name',
  defaultActive: true
}, {
  key: 'constituent.tranche',
  defaultActive: false
}, {
  key: 'constituent.identification',
  defaultActive: false
}];

export interface IStrategyIssuer extends StrategyIssuer {
  nbStrategies?: number;
  assetClasses?: any;
  detail?: string|null;
}

@Component({
  selector: 'strategies-show-holdings-issuers',
  template
})
export class StrategiesShowHoldingsIssuersComponent implements OnChanges {
  @Input()
  public strategyId: number;
  @Input()
  public showPagination = true;

  public totalIssuers: number = 0;
  public issuers: IStrategyIssuer[];
  public LIMIT = 10;
  public page = 0;
  public STRATEGIES_CATEGORIES = STRATEGIES_CATEGORIES;

  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController = new TableSettingsController('strategies-show-holdings-issuers');

  /*@ngInject*/
  constructor(
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource,
    private $translate: ng.translate.ITranslateService,
    private $filter: ng.IFilterService
  ) {
    this.tableSettingsGroups = [{
      key: 'issuers',
      title: $translate.instant('strategies.show.holdings.issuers.controller.issuers')
    }, {
      key: 'constituents',
      title: $translate.instant('strategies.show.holdings.issuers.controller.constituents')
    }];

    ISSUERS_CATEGORIES.forEach(category => {
      this.tableSettingsController.registerProperty({
        key: `issuer.${category}`,
        group: 'issuers',
        active: true,
        title: $translate.instant(`strategies.show.holdings.issuers.issuer.${category}`)
      });
    });

    STRATEGIES_CATEGORIES.forEach(category => {
      this.tableSettingsController.registerProperty({
        key: category.key,
        group: 'constituents',
        active: category.defaultActive,
        title: $translate.instant(`strategies.show.holdings.issuers.strategies.${category.key}`)
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategyId' in changes) {
      this.page = 0;
      this.query();
    }
  }

  query() {
    return this.strategyIssuerResource.queryBy({
      strategy_id: this.strategyId,
      page: this.page,
      per_page: this.LIMIT
    }).$promise.then(response => {
      this.refreshIssuers(response);
    }, () => this.refreshIssuers({
      total_results: 0,
      issuers: []
    }));
  }

  refreshIssuers(response: StrategyIssuerQueryByResponse) {
    this.issuers = response.issuers;
    if (!!response.total_results) {
      this.totalIssuers = response.total_results;
    }
    this.issuers.forEach((issuer: IStrategyIssuer) => this.refreshIssuer(issuer));
  }

  refreshIssuer(issuer: IStrategyIssuer) {
    const assetClasses = {};
    const strategyIds = issuer.strategy_constituents.map(sc => sc.strategy.id);
    const uniqueStrategyIds = strategyIds.filter((x, i, a) => a.indexOf(x) === i);

    issuer.strategy_constituents.forEach(strategyConstituent => {
      const constituent: Constituent = strategyConstituent.constituent;

      if (constituent.asset_class) {
        if (!assetClasses[constituent.asset_class]) {
          assetClasses[constituent.asset_class] = 0.0;
        }
        assetClasses[constituent.asset_class] += strategyConstituent.weight;
      }
    });

    issuer.assetClasses = Object.keys(assetClasses).map(assetClass => {
      return { key: assetClass, weight: assetClasses[assetClass] };
    });
    issuer.nbStrategies = uniqueStrategyIds.length;
  }

  detail(issuer: IStrategyIssuer, assetClass: string) {
    if (!issuer.detail || issuer.detail !== assetClass) {
      issuer.detail = assetClass;
    }
    else {
      issuer.detail = null;
    }
  }

  displayContentFor(strategyConstituent: any, fieldDefinition: IFieldDefinition) {
    const splittedKey: string[] = fieldDefinition.key.split('.');
    let value = strategyConstituent;

    splittedKey.forEach(key => {
      if (value) {
        value = value[key];
      }
    });

    if (!value) {
      return '';
    }
    else if (fieldDefinition.type === 'translate') {
      return this.$translate.instant(`constituents.${splittedKey[1]}s.${value}`);
    }
    else if (fieldDefinition.type === 'number') {
      return transformToNumber(
        this.$filter, value, !!fieldDefinition.isStatistics, !fieldDefinition.isStatistics
      );
    }
    else {
      return value;
    }
  }

  isNotApplicableCountry(issuer) {
    let res = !!issuer.issuer_type;

    COUNTRY_ISSUER_TYPES.forEach(issuerType => {
      if (issuer.issuer_type === issuerType) {
        res = false;
      }
    });

    return res;
  }

  filterIssuerByAssetClass(issuer) {
    if (!issuer.detail) {
      return [];
    }
    return issuer.strategy_constituents.filter(sc => sc.constituent.asset_class === issuer.detail);
  }
}
