import { Component } from 'angular-ts-decorators';
const template = require('./page.html.haml');

import { DialogController } from '../../dialog/controller';

@Component({
  selector: 'page-strategies-user-permissions',
  template
})
export class StrategiesUserPermissionsConfirmPage extends DialogController {}
