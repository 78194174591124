import { Component, Inject, Input, OnInit } from 'angular-ts-decorators';
import {
  CockpitProfile, CockpitPortfolio,
  StrategyVersionResource, StrategyVersionGrouped, StrategyVersionGroupedDetail
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

export const groups = ['asset_class', 'provider_name', 'strategy_type'];

export interface IGroupedDataset {
  [key: string]: {
    dataset: any[];
  };
}

@Component({
  selector: 'cockpits-profiles-pie-charts',
  template
})
export class CockpitsProfilesPieChartsComponent implements OnInit {
  @Input()
  profile: CockpitProfile;

  public groupedData: IGroupedDataset|null = null;
  public selectedPortfolio: CockpitPortfolio|null = null;
  public selectedAllocation = groups[0];
  public ALLOCATIONS = groups;

  /*@ngInject*/
  constructor(
    @Inject('StrategyVersionResource')
    private versionResource: StrategyVersionResource,
    private $translate
  ) {}

  ngOnInit() {
    if (this.profile && this.profile.portfolios) {
      this.selectPortfolio(this.profile.portfolios[0]);
    }
  }

  selectPortfolio(portfolio: CockpitPortfolio) {
    this.selectedPortfolio = portfolio;
    this.groupedData = null;
    return this.versionResource.grouped({
      strategy_id: this.selectedPortfolio.id
    }).$promise.then(groupedData => this.initGroupedData(groupedData));
  }

  initGroupedData(groupedData: StrategyVersionGrouped) {
    this.groupedData = {};

    groups.forEach(group => {
      if (this.groupedData && groupedData[group]) {
        const dataset = groupedData[group].map((detail: StrategyVersionGroupedDetail) => {
          if (group === 'provider_name') {
            return { x: detail.name, value: detail.weight };
          }
          else {
            return {
              x: this.$translate.instant(['strategies', group, detail.name].join('.')),
              value: detail.weight
            };
          }
        });
        this.groupedData[group] = { dataset };
      }
    });
  }
}
