/**
 * Call confirm function when form is dirty
 * @param $q
 * @param form The form controller
 * @param onDirty Promise that will get called if form is dirty
 * @returns Promise that resolves whith value returned from confirm.
 */
export const formOnDirty = ($q: ng.IQService, form?: ng.IFormController, onDirty?: () => ng.IPromise<any>) => {
  if (form && form.$dirty && onDirty) {
    return onDirty().then(data => {
      form.$dirty = false;
      return data;
    });
  }

  const deferred = $q.defer<void>();
  deferred.resolve();
  return deferred.promise;
};
