import { Input, OnDestroy } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
import { Subject } from 'rxjs';

import { formHasErrors } from '../../forms/has-errors';

export const childViewId = 'page-strategies-edit-child';

export abstract class StrategiesEditChildPage implements OnDestroy {
  private _$saving: Subject<{saving: boolean; error: any}> = new Subject();

  private watchers: Function[] = [];

  @Input()
  public strategy: Strategy;
  @Input()
  public errorMessages: any = {};
  @Input()
  public forms: any = {};

  /*@ngInject*/
  constructor(
    private $location: ng.ILocationService,
    private ngDialog: ng.dialog.IDialogService,
    private $timeout: ng.ITimeoutService,
    protected $element: ng.IAugmentedJQuery,
    protected $rootScope: ng.IRootScopeService
  ) {
    this.$element.attr('id', childViewId);
    this.watchers.push($rootScope.$on('$locationChangeStart', event => this.locationChangeStart(event)));
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  canSave(): boolean {
    return this.forms.form.$valid === true;
  }

  public async save() {
    this.forms.form.$submitted = true;

    if (!this.canSave()) {
      return this.saveError({
        data: {}
      });
    }

    this.saving(true);

    await this.saveStrategy();
  }

  saveStrategy() {
    return (this.strategy as any).$update().then(response => {
      this.saveSuccess(response);
    }, error => this.saveError(error));
  }

  saveSuccess(strategy) {
    this.errorMessages = null;
    if (!this.strategy.id) {
      this.strategy = strategy;
    }
    this.forms.form.$dirty = false;
    this.saving(false);

    this.$timeout(() => {
      this.$location.path('/strategy/' + this.strategy.id);
    });
  }

  async saveError(error: {data: any}) {
    if (typeof error.data === 'object') {
      Object.keys(error.data).forEach((key) => {
        const newkey = key.replace('versions.', '');
        error.data[newkey] = error.data[key];
      });
    }

    this.errorMessages = error.data;
    this.saving(false, error.data);
  }

  hasError(
    fields: string|string[],
    form: ng.IFormController = this.forms.form,
    errors: any = this.errorMessages
  ) {
    return formHasErrors(fields, form, errors);
  }

  locationChangeStart(event) {
    if (this.forms && this.forms.form) {
      if (this.forms.form.$dirty) {
        const next = this.$location.path();
        event.preventDefault();

        return this.ngDialog.openConfirm({
          template: '<page-strategies-cancel-confirm></page-strategies-cancel-confirm>',
          plain: true,
          className: 'ngdialog-theme-default',
          data: {
            strategy: this.strategy
          }
        }).then(() => {
          this.forms.form.$dirty = false;
          this.$location.path(next);
        });
      }
    }

    return;
  }

  saving(saving: boolean, error?: any): void {
    this._$saving.next({
      saving,
      error
    });
  }

  $saving() {
    return this._$saving.asObservable();
  }
}
