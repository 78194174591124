import {
  Component, Inject, Input, OnDestroy,
  OnChanges, SimpleChanges,
  ViewChild
} from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy, StrategyBenchmark } from '@quantizr/front-model';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { dateAtMidnight, DatesSelector } from '../../components';
import { COLORS } from '../../components/colors';
import { COLOR as BENCHMARK_COLOR } from '../../benchmarks';
import { TimeSeriesStockChartComponent, IStockChartRequestParams } from '../../time-series';
import { StrategiesBenchmarksService } from '../benchmarks/service';

export interface IChartStrategy extends Strategy {
  color?: string;
}

export interface IChartStrategyBenchmark extends StrategyBenchmark {
  color?: string;
}

@Component({
  selector: 'strategies-fixings',
  template
})
export class StrategiesFixingsComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private chartTimeout: ng.IPromise<any>;
  private benchmark: IChartStrategyBenchmark|null = null;

  @ViewChild(TimeSeriesStockChartComponent)
  public chart: TimeSeriesStockChartComponent;

  @Input()
  public strategy: IChartStrategy;
  @Input()
  public datesSelector: DatesSelector;
  @Input('<?')
  public dates: any = {};
  @Input()
  public scroller: any;
  @Input()
  chartSettings: any;
  @Input('<?')
  benchmarkRequired = false;

  public requestParams: IStockChartRequestParams;
  public settings: any = {};

  /*@ngInject*/
  constructor(
    @Inject('StrategiesBenchmarksService')
    private BenchmarksService: StrategiesBenchmarksService,
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService
  ) {
    this.settings = {
      plots: [{
        colors: {},
        yScale: {
          comparisonMode: 'percent',
          baseHundred: true,
          stackMode: null
        },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {},
      messageLoading: this.$translate.instant('strategies.fixings.loading'),
      messageEmpty: this.$translate.instant('strategies.fixings.empty')
    };

    this.subscriptions.push(this.BenchmarksService.$selected().subscribe(benchmark => {
      this.benchmark = benchmark;
      this.onBenchmarkSelected();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('chartSettings' in changes) {
      const settings = changes.chartSettings.currentValue || {};
      merge(this.settings, settings);
    }

    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      if (strategy) {
        this.onStrategyChanged();
      }
    }
  }

  onStrategyChanged() {
    this.strategy.color = this.strategy.color || COLORS[0];

    if (!this.dates.min && this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }
    if (!this.dates.max && this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    this.settings.plots[0].colors[this.strategy.id] = {
      null: this.strategy.color
    };

    if (this.strategy.live_date) {
      this.settings.plots[0].annotations[0].verticalLine = {
        xAnchor: this.strategy.live_date
      };
    }

    this.requestParams = {
      'ids[]': [this.strategy.id],
      type: 'Strategy',
      qualifier: 'fixings',
      start_date: this.strategy.start_date,
      end_date: this.strategy.end_date
    };

    if (!this.benchmarkRequired) {
      this.refresh();
    }
    else if (this.benchmark !== null) {
      this.onBenchmarkSelected();
    }
  }

  onBenchmarkSelected() {
    if (!this.strategy) {
      return;
    }

    if (this.benchmark) {
      this.requestParams['ids[]'] = [
        this.strategy.id,
        this.benchmark.id
      ];

      this.benchmark.color = BENCHMARK_COLOR;
      this.settings.plots[0].colors[this.benchmark.id] = {
        null: this.benchmark.color
      };
    }
    else if (!this.benchmarkRequired) {
      this.requestParams['ids[]'] = [this.strategy.id];
    }
    else {
      return;
    }

    this.refresh();
  }

  refresh() {
    this.chartTimeout = this.$timeout(() => {
      this.chart.requestParams = this.requestParams;
      this.chart.refresh();
    });
  }
}
