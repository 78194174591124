import { Injectable } from 'angular-ts-decorators';
import * as angular from 'angular';

export const KEYS = {
  CRITERIA: 'strategies.criteria',
  SORT: 'strategies.sort',
  VOLUMES: 'strategies.volumes'
};

export interface IStrategyLocalStorage {
  path: string|null;
}

@Injectable('StrategiesLocalStorageService')
export class StrategiesLocalStorageService {
  /*@ngInject*/
  constructor(
    private localStorageService: ng.local.storage.ILocalStorageService,
    private $location: ng.ILocationService
  ) {}

  get<T extends IStrategyLocalStorage>(key: string): T|null {
    const savedCriteria = this.localStorageService.get<T>(key);
    const path = this.$location.path();

    if (!savedCriteria || !savedCriteria.path || savedCriteria.path !== path) {
      this.remove(key);

      return null;
    }

    return savedCriteria;
  }

  remove(key: string): void {
    this.localStorageService.remove(key);
  }

  set(key: string, attributes: any): void {
    this.localStorageService.set(key, angular.extend({}, attributes, {
      path: this.$location.path()
    }));
  }
}
