import { NgModule } from 'angular-ts-decorators';
import CockpitResourceFactory from '@quantizr/front-model/dist/cockpits/resource';
import CockpitAxisResourceFactory from '@quantizr/front-model/dist/cockpits/axes/resource';
import CockpitCustomlistResourceFactory from '@quantizr/front-model/dist/cockpits/customlists/resource';
import CockpitProfileResourceFactory from '@quantizr/front-model/dist/cockpits/profiles/resource';
import CockpitProfileAxisResourceFactory from '@quantizr/front-model/dist/cockpits/profiles/axes/resource';
import CockpitProfilePcaResourceFactory from '@quantizr/front-model/dist/cockpits/profiles/pca/resource';
import {
  default as CockpitProfilePortfolioResourceFactory
} from '@quantizr/front-model/dist/cockpits/profiles/portfolios/resource';
import CockpitProfileStrategyResourceFactory from '@quantizr/front-model/dist/cockpits/profiles/strategies/resource';
import {
  default as CockpitProfileRiskContributionResourceFactory
} from '@quantizr/front-model/dist/cockpits/profiles/risk-contribution/resource';
import {
  default as CockpitProfileVersionResourceFactory
} from '@quantizr/front-model/dist/cockpits/profiles/versions/resource';
import CockpitStrategyResourceFactory from '@quantizr/front-model/dist/cockpits/strategies/resource';

import { ComponentsModule } from '../components';
import { ForeignExchangeRatesModule } from '../foreign-exchange-rates';
import { MyUserModule } from '../my-user';
import { PanelsModule } from '../panels';
import { StrategiesModule } from '../strategies';
import { VendorModule } from '../vendor';

import { CockpitsViewsService } from './views/service';

import { CockpitsAxesDeleteConfirmPage } from './axes/delete/confirm/page';
import { CockpitsAxesEditPage } from './axes/edit/page';
import { CockpitsAxesNewPage } from './axes/new/page';
import { CockpitsBuylistsPage } from './buylists/page';
import { CockpitsCancelConfirmPage } from './cancel/confirm/page';
import { CockpitsEditProfilesPortfoliosPage } from './edit/profiles/portfolios/page';
import { CockpitsEditProfilesStrategiesPage } from './edit/profiles/strategies/page';
import { CockpitsEditStrategiesPage } from './edit/strategies/page';
import { CockpitsEditPage } from './edit/page';
import { CockpitsEsgShowPage } from './esg/show/page';
import { CockpitsEsgPage } from './esg/page';
import { CockpitsHeaderComponent } from './header/component';
import { CockpitsHoldingsDerivativePage } from './holdings/derivative/page';
import { CockpitsHoldingsEquityPage } from './holdings/equity/page';
import { CockpitsHoldingsFixedIncomePage } from './holdings/fixed-income/page';
import { CockpitsHoldingsGeneralPage } from './holdings/general/page';
import { CockpitsHoldingsPage } from './holdings/page';
import { CockpitsMonitoringShowPage } from './monitoring/show/page';
import { CockpitsMonitoringPage } from './monitoring/page';
import { CockpitsProfilesChartHistoryComponent } from './profiles/chart-history/component';
import { CockpitsProfilesDeleteConfirmPage } from './profiles/delete/confirm/page';
import { CockpitsProfilesEditPage } from './profiles/edit/page';
import { CockpitsProfilesNavbarComponent } from './profiles/navbar/component';
import { CockpitsProfilesNewPage } from './profiles/new/page';
import { CockpitsProfilesPieChartsComponent } from './profiles/pie-charts/component';
import { CockpitsProfilesPorfoliosEditPage } from './profiles/portfolios/edit/page';
import { CockpitsProfilesPortfoliosDeleteConfirmPage } from './profiles/portfolios/delete/confirm/page';
import { CockpitsProfilesPorfoliosNewPage } from './profiles/portfolios/new/page';
import { CockpitsProfilesReloadComponent } from './profiles/reload/component';
import {
  CockpitsProfilesStrategiesCorrelationMartrixComponent
} from './profiles/strategies-correlation-matrix/component';
import { CockpitsProfilesStrategiesListingComponent } from './profiles/strategies-listing/component';
import { CockpitsProfilesVolatilityBandWidthComponent } from './profiles/volatility-band-width/component';
import { CockpitsPerfContributionChartComponent } from './perf-contribution/chart/component';
import { CockpitsPerfContributionCurrenciesComponent } from './perf-contribution/currencies/component';
import { CockpitsPerfContributionGeneralComponent } from './perf-contribution/general/component';
import { CockpitsPerfContributionShowPage } from './perf-contribution/show/page';
import { CockpitsPerfContributionDetailsPage } from './perf-contribution/details/page';
import { CockpitsPerfContributionPage } from './perf-contribution/page';
import { CockpitsRiskContributionShowPage } from './risk-contribution/show/page';
import { CockpitsRiskContributionTableComponent } from './risk-contribution/table/component';
import { CockpitsRiskContributionPage } from './risk-contribution/page';
import { CockpitsSidebarFiltersComponent } from './sidebar-filters/component';
import { CockpitsTopNavbarComponent } from './top-navbar/component';
import { CockpitsVersionsResetConfirmPage } from './versions/reset/confirm/page';
import { CockpitsViewsPage } from './views/page';
import { CockpitsWeightsShowPage } from './weights/show/page';
import { CockpitsWeightsPage } from './weights/page';

export const QUALIFIERS = ['fixings', 'volatility'];
export const PROFILE_WINDOWS = ['6_months', '1_year', '3_years'];

@NgModule({
  id: 'Cockpits',
  imports: [
    ComponentsModule,
    ForeignExchangeRatesModule,
    MyUserModule,
    PanelsModule,
    StrategiesModule,
    VendorModule
  ],
  declarations: [
    CockpitsAxesDeleteConfirmPage,
    CockpitsAxesEditPage,
    CockpitsAxesNewPage,
    CockpitsBuylistsPage,
    CockpitsCancelConfirmPage,
    CockpitsEditProfilesPortfoliosPage,
    CockpitsEditProfilesStrategiesPage,
    CockpitsEditStrategiesPage,
    CockpitsEditPage,
    CockpitsEsgShowPage,
    CockpitsEsgPage,
    CockpitsHeaderComponent,
    CockpitsHoldingsDerivativePage,
    CockpitsHoldingsEquityPage,
    CockpitsHoldingsFixedIncomePage,
    CockpitsHoldingsGeneralPage,
    CockpitsHoldingsPage,
    CockpitsMonitoringShowPage,
    CockpitsMonitoringPage,
    CockpitsProfilesChartHistoryComponent,
    CockpitsProfilesDeleteConfirmPage,
    CockpitsProfilesEditPage,
    CockpitsProfilesNavbarComponent,
    CockpitsProfilesNewPage,
    CockpitsProfilesPieChartsComponent,
    CockpitsProfilesPorfoliosEditPage,
    CockpitsProfilesPortfoliosDeleteConfirmPage,
    CockpitsProfilesPorfoliosNewPage,
    CockpitsProfilesReloadComponent,
    CockpitsProfilesStrategiesCorrelationMartrixComponent,
    CockpitsProfilesStrategiesListingComponent,
    CockpitsProfilesVolatilityBandWidthComponent,
    CockpitsPerfContributionChartComponent,
    CockpitsPerfContributionCurrenciesComponent,
    CockpitsPerfContributionGeneralComponent,
    CockpitsPerfContributionShowPage,
    CockpitsPerfContributionDetailsPage,
    CockpitsPerfContributionPage,
    CockpitsRiskContributionShowPage,
    CockpitsRiskContributionTableComponent,
    CockpitsRiskContributionPage,
    CockpitsSidebarFiltersComponent,
    CockpitsTopNavbarComponent,
    CockpitsVersionsResetConfirmPage,
    CockpitsViewsPage,
    CockpitsWeightsShowPage,
    CockpitsWeightsPage
  ],
  providers: [
    CockpitsViewsService, {
      provide: 'CockpitResource',
      useFactory: ($resource) => CockpitResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitAxisResource',
      useFactory: ($resource) => CockpitAxisResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitCustomlistResource',
      useFactory: ($resource) => CockpitCustomlistResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfileResource',
      useFactory: ($resource) => CockpitProfileResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfileAxisResource',
      useFactory: ($resource) => CockpitProfileAxisResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfilePcaResource',
      useFactory: ($resource) => CockpitProfilePcaResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfilePortfolioResource',
      useFactory: ($resource) => CockpitProfilePortfolioResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfileStrategyResource',
      useFactory: ($resource) => CockpitProfileStrategyResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfileVersionResource',
      useFactory: ($resource) => CockpitProfileVersionResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitStrategyResource',
      useFactory: ($resource) => CockpitStrategyResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }, {
      provide: 'CockpitProfileRiskContributionResource',
      useFactory: ($resource) => CockpitProfileRiskContributionResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class CockpitsModule {}

export default (CockpitsModule as NgModule).module!.name;
