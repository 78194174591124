import { Component, AfterViewInit } from 'angular-ts-decorators';
import { PanelsSidebarChild } from '../../panels';
const template = require('./component.html.haml');

@Component({
  selector: 'simulations-sidebar-filters',
  template
})
export class SimulationsSidebarFiltersComponent extends PanelsSidebarChild implements AfterViewInit {
}
