import { Injectable } from 'angular-ts-decorators';
import { Subject } from 'rxjs';

import { ISelectorDatesSelected } from './model';

@Injectable('DatesSelectorService')
export class DatesSelectorService {
  private _$dates: Subject<ISelectorDatesSelected> = new Subject();

  public dates(dates: ISelectorDatesSelected) {
    this._$dates.next(dates);
  }

  public $dates() {
    return this._$dates.asObservable();
  }
}
