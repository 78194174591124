import { Component } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

// use this class to mark blocks in pdf page
export const blockClass = 'pdf-engine-block';
const bodyClass = 'pdf-page-body';

const newPage = ($header, $footer) => {
  const $page = $('<div class="pdf-page"></div>');
  $page.append($header.clone());
  $page.append(`<div class="${bodyClass}"></>`);
  $page.append($footer.clone());
  return $page;
};

@Component({
  selector: 'pdf-engine',
  template,
  transclude: {
    header: '?paneHeader',
    footer: '?paneFooter',
    body: '?paneBody'
  }
})
export class PdfEngineComponent {
  public loading = true;

  /*@ngInject*/
  constructor(
    private $element: ng.IAugmentedJQuery,
    private $timeout: ng.ITimeoutService
  ) {}

  public loaded(title = 'Quantilia') {
    document.title = title;
    this.generatePages();
    this.loading = false;
    this.$timeout(() => {
      (window).print();
    });
  }

  private generatePages() {
    // TODO: remove dummy element in html and get height depending on userAgent
    const pageHeight = this.$element.find(`.${bodyClass}`).outerHeight() || 0;
    const $pageHeader = this.$element.find('pane-header');
    const $pageFooter = this.$element.find('pane-footer');
    const $result = this.$element.find('.pdf-result');

    let pageTop = 0;
    let $page = newPage($pageHeader, $pageFooter);

    this.$element.find(`.${blockClass}`).each((_index, el) => {
      const $block = $(el);
      const height = $block.outerHeight() || 0;
      const top = $block.offset()!.top || 0;
      const bottom = top + height;

      // init new page
      if (bottom > pageTop + pageHeight) {
        pageTop = top;
        $result.append($page);
        $page = newPage($pageHeader, $pageFooter);
      }

      $block.clone().appendTo($page.find(`.${bodyClass}`));
    });

    $result.append($page);
  }
}
