import { Component, Inject, Input, OnDestroy, ViewChild, SimpleChanges, OnChanges } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy, StrategyBenchmark } from '@quantizr/front-model';
import merge = require('lodash/merge');

const template = require('./component.html.haml');

import { dateAtMidnight, DatesSelector } from '../../../components';
import { TimeSeriesStockChartComponent, IStockChartRequestParams } from '../../../time-series';
import { StrategiesBenchmarksService } from '../../benchmarks/service';
import { StrategiesViewsService } from '../../views/service';

@Component({
  selector: 'strategies-links-correlations',
  template
})
export class StrategiesLinksCorrelationsComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private chartTimeout: ng.IPromise<any>;
  private benchmark: StrategyBenchmark|null = null;

  @ViewChild(TimeSeriesStockChartComponent)
  chart: TimeSeriesStockChartComponent;

  @Input()
  public datesSelector: DatesSelector;
  @Input('=?')
  public scroller: any;
  @Input('=?')
  chartSettings: any;
  @Input('<?')
  benchmarkRequired = false;

  public strategy: Strategy;
  public settings: any = {};
  public dates: any = {};
  public requestParams: IStockChartRequestParams;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    strategiesBenchmarksService: StrategiesBenchmarksService,
    private $timeout: ng.ITimeoutService,
    $translate: ng.translate.ITranslateService
  ) {
    this.settings = {
      plots: [{
        yScale: {
          comparisonMode: 'none',
          baseHundred: false,
          stackMode: null
        },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {},
      messageLoading: $translate.instant('strategies.show.benchmark.view.correlations.loading'),
      messageEmpty: $translate.instant('strategies.show.benchmark.view.correlations.empty'),
    };

    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.onStrategyChanged();
      }
    }));

    this.subscriptions.push(strategiesBenchmarksService.$selected().subscribe(benchmark => {
      this.benchmark = benchmark;
      this.onBenchmarkSelected();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('chartSettings' in changes) {
      const settings = changes.chartSettings.currentValue || {};
      merge(this.settings, settings);
    }
  }

  onStrategyChanged() {
    if (this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }

    if (this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    if (this.strategy.live_date) {
      this.settings.plots[0].annotations[0].verticalLine = {
        xAnchor: this.strategy.live_date
      };
    }

    this.requestParams = {
      'ids[]': [],
      type: 'Link',
      qualifier: 'correlations',
      start_date: this.strategy.start_date,
      end_date: this.strategy.end_date,
      'windows[]': ['3_months', '6_months', '1_year', '2_years', '3_years']
    };

    if (!this.benchmarkRequired) {
      this.refreshCharts();
    }
    else if (this.benchmark !== null) {
      this.onBenchmarkSelected();
    }
  }

  onBenchmarkSelected() {
    if (!this.strategy) {
      return;
    }

    if (this.benchmark) {
      this.requestParams['ids[]'] = [
        this.benchmark.link_id
      ];
    }
    else if (!this.benchmarkRequired) {
      this.requestParams['ids[]'] = [];
    }
    else {
      return;
    }

    this.refreshCharts();
  }

  refreshCharts() {
    this.chartTimeout = this.$timeout(() => {
      this.chart.requestParams = this.requestParams;
      this.chart.refresh();
    });
  }
}
