import { Component } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

import { PanelsSidebarChild } from '../../panels';

import { FILTERS } from '../';
import { isValid } from '../../my-user/policies/is-valid';

@Component({
  selector: 'strategies-sidebar-filters',
  template
})
export class StrategiesSidebarFiltersComponent extends PanelsSidebarChild {
  public FILTERS = FILTERS;

  displaySection() {
    if (!this.currentUser || !this.dashboards) {
      return;
    }
    return !!Object.keys(this.dashboards).some(e => this.dashboards[e].length > 0) ||
      isValid(this.currentUser!.policies, 'strategies', 'index');
  }
}
