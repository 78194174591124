import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { variables as strategies } from '@quantizr/front-model/dist/strategies/variables';
import { StrategyProviderStrategyResource, Strategy } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesViewsService } from '../../views/service';

const WINDOWS = ['1_month', '3_months', '6_months', '1_year', '2_years', '3_years'];

@Component({
  selector: 'page-strategies-show-company',
  template
})
export class StrategiesShowCompanyPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public STATUSES = strategies.STATUSES;
  public WINDOWS = WINDOWS;
  public strategy: Strategy;
  public strategies: Strategy[] = [];
  public sortTimeline: string|null = null;

  /*@ngInject*/
  constructor(
    @Inject('StrategyProviderStrategyResource')
    private strategyProviderStrategyResource: StrategyProviderStrategyResource,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    private ngDialog: ng.dialog.IDialogService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
        this.refreshStrategies('1_year');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refreshStrategies(sortTimeline: string) {
    this.sortTimeline = sortTimeline;
    return this.strategyProviderStrategyResource.query({
      strategy_id: this.strategy.id,
      sort_timeline: sortTimeline
    }).$promise.then(response => this.refreshStrategiesSuccess(response), () => {
      this.refreshStrategiesSuccess([]);
    });
  }

  refreshStrategiesSuccess(response: Strategy[]) {
    this.strategies = response;
  }

  contactCompany(messageType: string) {
    const message = {
      message_type: messageType,
      subject: this.strategy.name,
      strategy_id: this.strategy.id,
      strategy: this.strategy
    };
    return this.ngDialog.open({
      template: '<page-messages-new></page-messages-new>',
      plain: true,
      className: 'ngdialog-theme-default ngdialog-theme-large',
      data: {
        message
      }
    });
  }
}
