import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { StrategyListResource, StrategyList, IStrategyList } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';
import { StrategyListsService } from '../../../customlists';

export type actionType = '.action_add'|'.action_create';

export interface ISelectableStrategyList extends StrategyList {
  disabled?: boolean;
}

@Component({
  selector: 'page-strategies-customlists-new',
  template
})
export class StrategiesCustomlistsNewPage extends DialogController implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private selectedElements: number[] = [];

  public strategyIds: number[];
  public addCreate: actionType = '.action_add';
  public saving = 0;
  public errorMessages: any = null;
  public strategyLists: StrategyList[] = [];
  public newStrategyList: IStrategyList;

  /*@ngInject*/
  constructor(
    @Inject('StrategyListResource')
    private strategyListResource: StrategyListResource,
    @Inject('StrategyListsService')
    private strategyListsService: StrategyListsService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $scope: ng.IScope
  ) {
    super($scope);

    this.strategyIds = this.data.strategyIds;
    const ignoreStrategyListIds = this.data.ignoreStrategyListIds as number[];

    this.newStrategyList = new strategyListResource({
      strategy_ids: this.strategyIds
    });
    this.subscriptions.push(strategyListsService.$strategyLists().subscribe(strategyLists => {
      this.strategyLists = (strategyLists || []).map((customlist: ISelectableStrategyList) => {
        customlist.disabled = ignoreStrategyListIds.indexOf(customlist.id) !== -1;
        return customlist;
      });

      if (!this.strategyLists.length) {
        this.addCreate = '.action_create';
      }
    }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selected(element: ISelectableStrategyList|ISelectableStrategyList[]) {
    if (element) {
      if (Array.isArray(element)) {
        return this.selectedElements.length === element.length;
      }
      else {
        return this.selectedElements.indexOf(element.id) !== -1;
      }
    }

    return false;
  }

  toggleSelect(element: ISelectableStrategyList|ISelectableStrategyList[]) {
    if (element) {
      if (Array.isArray(element)) {
        if (this.selected(element)) {
          this.selectedElements = [];
        }
        else {
          this.selectedElements = element.map(customlist => {
            return customlist.id;
          });
        }
      }
      else {
        if (!element.disabled) {
          const index = this.selectedElements.indexOf(element.id);
          if (index === -1) {
            this.selectedElements.push(element.id);
          }
          else {
            this.selectedElements.splice(index, 1);
          }
        }
      }
    }
  }

  save() {
    const strategyLists: Array<{
      id: any,
      strategy_ids: any[]
    }> = [];
    this.saving = 1;

    this.strategyLists.forEach(customlist => {
      if (this.selected(customlist)) {
        strategyLists.push({
          id: customlist.id,
          strategy_ids: this.strategyIds
        });
      }
    });

    let request: ng.IPromise<any>;

    switch (this.addCreate) {
      case '.action_create':
      request = this.newStrategyList.$save();
      break;
      default:
        request = this.strategyListResource.updateAll({
          strategy_lists: strategyLists
        }).$promise;
    }

    return request.then(() => this.saveSuccess(), error => this.saveError(error));
  }

  saveError(error) {
    this.saving = 2;
    this.errorMessages = error.data;
  }

  saveSuccess() {
    this.saving = 0;
    this.strategyListsService.reset();
    this.confirm();

    this.$translate('strategies.customlists.new.controller.success').then(title => {
      this.toaster.success(title);
    });
  }
}
