import { AfterViewInit, Component, Input, OnDestroy, ViewChild, SimpleChanges } from 'angular-ts-decorators';
import { StrategyGrouped, StrategyGroupedDetail } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { PieChartComponent } from '../../../../components';

@Component({
  selector: 'strategies-show-holdings-pie-chart',
  template
})
export class StrategiesShowHoldingsPieChartComponent implements OnDestroy, AfterViewInit {
  @ViewChild('asset-classes-pie')
  public assetClassPieChart: PieChartComponent;
  @ViewChild('currencies-pie')
  public currencyPieChart: PieChartComponent;
  @ViewChild('instruments-pie')
  public instrumentPieChart: PieChartComponent;

  @Input()
  public groupedData: StrategyGrouped;
  @Input()
  public groupType: string;
  @Input('<?')
  public orderBy: string = '-weight.total';

  private chartTimeout: ng.IPromise<any>;

  /*@ngInject*/
  constructor(
    private $filter: ng.IFilterService,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnDestroy() {
    this.$timeout.cancel(this.chartTimeout);
  }

  ngAfterViewInit() {
    this.refreshCharts();
  }

  ngOnInit() {
    this.reorderGroupedData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('groupedData' in changes) {
      this.reorderGroupedData();
      this.refreshCharts();
    }
  }

  reorderGroupedData() {
    ['asset_class', 'instrument', 'currency'].forEach(key => {
      if (!!this.groupedData[key]) {
        this.groupedData[key] = this.$filter('orderBy')(this.groupedData[key], this.orderBy);
      }
      else {
        this.groupedData[key] = [];
      }
    });
  }

  refreshCharts() {
    this.chartTimeout = this.$timeout(() => {
      [
        ['asset_class', this.assetClassPieChart], ['instrument', this.instrumentPieChart],
        ['currency', this.currencyPieChart]
      ].forEach((params: any[]) => {
        const key: string = params[0];
        const pieChart: PieChartComponent = params[1];

        pieChart.dataset = this.groupedData[key].map((c: StrategyGroupedDetail) => {
          return {
            x: this.$translate.instant(`issuers.${key}.${c.name}`),
            value: (c.weight.total * 100)
          };
        });
        pieChart.refresh();
      });
    });
  }
}
