import { Component, Inject, OnDestroy, OnChanges, SimpleChanges, Input } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  ConstituentInstrument, Strategy, StrategyIssuerResource, StrategyGrouped, GroupField,
  LongShortType, ConstituentInstrumentNumber, defaultWeightDisplay, filterClosingDate, categoryAbsValue
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { SECTION_TYPES, LONG_SHORT_TYPES } from '../page';
import { COLORS as SANKEY_COLORS, translateData } from '../../../sankey';
import { COLORS } from '../../../../components/colors';
import { StrategiesViewsService } from '../../../views/service';

export const GROUPS: GroupField[] = [
  GroupField.duration, GroupField.maturity, GroupField.sector
];

@Component({
  selector: 'page-strategies-show-holdings-fixed-income',
  template
})
export class StrategiesShowHoldingsFixedIncomePage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  public GROUPS = GROUPS;
  public SECTIONS = SECTION_TYPES;
  public LONG_SHORT_TYPES = LONG_SHORT_TYPES;
  public SANKEY_SOURCE_COLORS = COLORS;
  public SANKEY_TARGET_COLORS = SANKEY_COLORS;

  public selectedSection: string = GROUPS[0];
  public groupedData?: StrategyGrouped;
  public weightDisplay?: LongShortType = LongShortType.total;
  public closingDate?: Date;
  public totalCash: number = 1;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource,
    private $translate: ng.translate.ITranslateService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.updateStrategy(strategy);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.updateStrategy(strategy);
    }
  }

  updateStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.strategy) {
      this.groupedData = undefined;
      return;
    }

    this.weightDisplay = defaultWeightDisplay(!!this.strategy.is_long_short);
    this.totalCash = categoryAbsValue(this.strategy, ConstituentInstrumentNumber.bond);

    return this.query();
  }

  query() {
    this.groupedData = undefined;
    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      instrument: ConstituentInstrument.bond,
      'fields[]': [...this.GROUPS, ...[GroupField.rating, GroupField.country_code]],
      sankey_source: GroupField.country_code,
      sankey_dest: GroupField.rating
    }).$promise.then(
      response => this.updateGroups(response),
      () => this.updateGroups(undefined)
    );
  }

  updateGroups(groupedData?: StrategyGrouped) {
    this.groupedData = groupedData;
    this.closingDate = filterClosingDate(this.groupedData);

    if (!this.groupedData) {
      return;
    }

    this.groupedData.sankey = translateData(
      this.$translate, this.groupedData.sankey, GroupField.country_code, GroupField.rating
    );
  }
}
