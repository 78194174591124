import { Component, Input, Inject, OnInit, ViewParent } from 'angular-ts-decorators';
import { ISimulationWidget, Simulation, SimulationWidgetResource } from '@quantizr/front-model';
import { variables as widgets } from '@quantizr/front-model/dist/widgets/variables';

import './component.scss';
const template = require('./component.html.haml');

import { formHasErrors } from '../../../forms/has-errors';
import { DialogController } from '../../../dialog/controller';

@Component({
  selector: 'simulations-widgets-edit',
  template
})
export class SimulationsWidgetsEditComponent implements OnInit {
  @ViewParent('?^^pageSimulationsWidgetsEdit')
  dialogController?: DialogController;

  @Input()
  widget: ISimulationWidget;
  @Input()
  simulation: Simulation;

  public form: ng.IFormController;
  public errorMessages: any = null;
  public saving = 0;
  public editingYValues = false;
  public typesEnabled = false;
  public categories: string[] = [];
  public windows: any;
  public CHARTS = widgets.CHARTS;
  public X_TYPES = widgets.X_TYPES;
  public Y_TYPES = widgets.Y_TYPES;

  /*@ngInject*/
  constructor(
    @Inject('SimulationWidgetResource')
    private simulationWidgetResource: SimulationWidgetResource
  ) {}

  ngOnInit() {
    if (this.widget && this.simulation) {
      this.widget = new this.simulationWidgetResource(this.widget);
      this.widget.simulation_id = this.simulation.id;

      this.updateChart(this.widget.chart || widgets.CHARTS[0]);
    }
  }

  updateChart(chart) {
    this.widget.chart = chart;
    this.editingYValues = !this.hasXValues();

    this.categories = [];
    this.windows = {};

    if (this.widget.chart === this.CHARTS[0]) {
      this.simulation.simulation_indicators!.forEach(indicator => {
        if (this.categories.indexOf(indicator.category) === -1) {
          this.categories.push(indicator.category);
        }

        this.windows[indicator.category] = this.windows[indicator.category] || [];
        if (this.windows[indicator.category].indexOf(indicator.window) === -1) {
          this.windows[indicator.category].push(indicator.window);
        }
      });
      this.typesEnabled = true;
      this.widget.x_type = this.widget.x_type || widgets.X_TYPES[0];
      this.widget.y_type = this.widget.y_type || widgets.Y_TYPES[0];
    }
    else {
      this.categories.push('fixings');
      // default types to param and cannot change
      this.typesEnabled = false;
      this.widget.x_type = undefined;
      this.widget.y_type = widgets.Y_TYPES[0];
    }
  }

  hasXValues() {
    return !!this.widget && this.widget.chart === 'xy_chart';
  }

  editXValues() {
    if (this.hasXValues()) {
      this.editingYValues = false;
    }
  }

  editYValues() {
    this.form.xForm.$submitted = true;
    if (this.form.xForm.$valid) {
      this.editingYValues = true;
    }
  }

  save() {
    this.form.$submitted = true;
    this.form.yForm.$submitted = true;

    if (this.form.$valid === false) {
      return this.saveError({
        data: {}
      });
    }

    this.saving = 1;
    let request;
    if (this.widget.id) {
      request = this.simulationWidgetResource.update(this.widget).$promise;
    }
    else {
      request = this.widget.$save();
    }
    request.then(() => this.saveSuccess(), error => this.saveError(error));
  }

  saveSuccess() {
    this.saving = 0;
    this.errorMessages = null;
    this.close({
      widget: this.widget
    });
  }

  saveError(errors) {
    this.saving = 2;
    this.errorMessages = errors.data;
  }

  close(data?: any) {
    if (this.dialogController) {
      this.dialogController.closeThisDialog(data);
    }
  }

  hasError(form: ng.IFormController, fields: string|string[]) {
    return formHasErrors(fields, form, this.errorMessages);
  }
}
