import { NgModule } from 'angular-ts-decorators';
import StrategiesByKeywordResourceFactory from '@quantizr/front-model/dist/strategies-by-keywords/resource';

import { ComponentsModule } from '../components';
import { PanelsModule } from '../panels';
import { VendorModule } from '../vendor';

import { TreemapShowPage } from './show/page';
import { TreemapSidebarFiltersComponent } from './sidebar-filters/component';

@NgModule({
  id: 'Treemap',
  imports: [
    ComponentsModule,
    PanelsModule,
    VendorModule
  ],
  declarations: [
    TreemapShowPage,
    TreemapSidebarFiltersComponent
  ],
  providers: [
    {
      provide: 'StrategiesByKeywordResource',
      useFactory: ($resource) => StrategiesByKeywordResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class TreemapModule {}

export default (TreemapModule as NgModule).module!.name;
