import { Component, Inject, Input, Output, OnDestroy, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { StrategySuggestion, StrategyResource } from '@quantizr/front-model';
import './component.scss';
const template = require('./component.html.haml');

import { CustomSearchRequestsService } from '../../custom-search';

@Component({
  selector: 'strategies-suggestions',
  template
})
export class StrategiesSuggestionsComponent implements OnChanges, OnDestroy {
  @Input()
  value = '';
  @Input('<?')
  criteria: any = {
    hide_protected: true,
    text: undefined
  };
  @Input('@?')
  inputClass: string = '';
  @Input('@')
  placeholder: string;
  @Input('<?')
  strategiesToIgnore: any = [];
  @Input('<?')
  clearOnSelect: boolean = false;

  @Output()
  onChange: (data: {$event: string}) => void;
  @Output()
  onSelect: (data: {$event: StrategySuggestion}) => void;

  public ngModel: string;

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService
  ) {
    this.criteria = {
      hide_protected: true,
      text: undefined
    };
    this.inputClass = '';
    this.strategiesToIgnore = [];
  }

  ngOnDestroy() {
    this.customSearchRequestsService.cancelAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes) {
      this.ngModel = this.value;
    }
  }

  suggestions(query: string) {
    this.criteria.text = query;
    this.ngModel = query;

    this.customSearchRequestsService.cancel(undefined, 'suggestions');

    const request = this.strategyResource.suggestions(this.criteria);

    this.customSearchRequestsService.add(request, 'suggestions');

    return request.$promise.then((response) => {
      this.customSearchRequestsService.remove(request);
      return this.suggestionsSuccess(response);
    }, () => {
      this.customSearchRequestsService.remove(request);
      return [];
    });
  }

  suggestionsSuccess(strategies: StrategySuggestion[]): StrategySuggestion[] {
    return strategies.filter((strategy) => {
      for (const strat of this.strategiesToIgnore) {
        if (strat.strategy) {
          if (strat.strategy.id === strategy.id) {
            return false;
          }
        }
        else if (strat.id === strategy.id) {
          return false;
        }
      }
      return true;
    });
  }

  inputChange() {
    this.onChange({
      $event: this.ngModel
    });
  }

  select($item: StrategySuggestion) {
    if (this.clearOnSelect) {
      this.ngModel = '';
      this.inputChange();
    }

    this.onSelect({
      $event: $item
    });
  }
}
