import { Strategy } from '@quantizr/front-model';

export type strategiesType = 'strategies'|'portfolios'|'customlists';
export type strategyType = 'strategy'|'portfolio'|'customlist';

export const TYPES: {[key: string]: strategiesType} = {
  STRATEGY: 'strategies',
  PORTFOLIO: 'portfolios',
  CUSTOMLIST: 'customlists'
};

export const typeFromError = (error?: {data?: {record?: string}}): strategyType|'' => {
  if (error) {
    if ('data' in error && 'record' in error.data!) {
      return error.data!.record!.toLowerCase() as strategyType;
    }
  }

  return '';
};

export const typeFromPath = ($location: ng.ILocationService): strategiesType|null => {
  const path = $location.path();

  if (path.indexOf(TYPES.STRATEGY) !== -1) {
    return TYPES.STRATEGY;
  }
  else if (path.indexOf(TYPES.PORTFOLIO) !== -1) {
    return TYPES.PORTFOLIO;
  }
  else if (path.indexOf(TYPES.CUSTOMLIST) !== -1) {
    return TYPES.CUSTOMLIST;
  }

  return null;
};

export const typeFromStrategy = (strategy: Strategy): strategyType => {
  return strategy.type!.toLowerCase() as strategyType;
};
