import { Input, Inject, AfterViewInit, OnDestroy } from 'angular-ts-decorators';
import { StrategyVersion } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import { formHasErrors } from '../../forms/has-errors';
import { VersionsViewsService } from '../views/service';
import { IScenarioField } from '../../simulations/scenarios';

export abstract class VersionsEditAbstractComponent implements AfterViewInit, OnDestroy {
  protected subscriptions: Subscription[] = [];

  @Input()
  public version: StrategyVersion;
  @Input()
  public errorMessages: any;
  @Input()
  public form: ng.IFormController;

  public fieldsConfig: IScenarioField|null;

  /*@ngInject*/
  constructor(
    @Inject('VersionsViewsService')
    versionsViewsService: VersionsViewsService,
    private $element: ng.IAugmentedJQuery
  ) {
    this.subscriptions.push(versionsViewsService.$fieldsConfig().subscribe(fieldsConfig => {
      this.fieldsConfig = fieldsConfig;
    }));
  }

  ngAfterViewInit() {
    if (!this.form) {
      const input1 = this.$element.find('input, textarea, select').eq(0);
      this.form = input1.controller('form');
    }

    if (typeof this.errorMessages === 'undefined') {
      this.errorMessages = {};
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.form, this.errorMessages);
  }
}
