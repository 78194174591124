import { Injectable, Inject } from 'angular-ts-decorators';
import { Strategy, StrategyBenchmark, StrategyBenchmarkResource } from '@quantizr/front-model';
import { ReplaySubject, Subscription } from 'rxjs';

import { StrategiesViewsService } from '../views/service';

@Injectable('StrategiesBenchmarksService')
export class StrategiesBenchmarksService {
  private subscriptions: Subscription[] = [];
  private _$benchmarks: ReplaySubject<StrategyBenchmark[]> = new ReplaySubject(1);
  private _$selected: ReplaySubject<StrategyBenchmark|null>  = new ReplaySubject(1);
  private _selected: StrategyBenchmark|null = null;
  private _benchmarks: StrategyBenchmark[] = [];

  /*@ngInject*/
  constructor(
    @Inject('StrategyBenchmarkResource')
    private strategyBenchmarkResource: StrategyBenchmarkResource,
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      this.onStrategyChanged(strategy);
    }));
  }

  private init(strategy: Strategy) {
    return this.strategyBenchmarkResource.query({
      strategy_id: strategy.id
    }).$promise.then((benchmarks) => {
      this._benchmarks = benchmarks;
      this._$benchmarks.next(benchmarks);
    }, (_error) => {
      this.clear();
      this._$benchmarks.next([]);
    });
  }

  private clear() {
    this._benchmarks = [];
    this._$selected.next(null);
    this._selected = null;
  }

  private onStrategyChanged(strategy: Strategy|null) {
    if (strategy !== null) {
      this.init(strategy);
    }
    else {
      this.clear();
    }
  }

  public destroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.clear();
  }

  public benchmarks(): StrategyBenchmark[] {
    return this._benchmarks;
  }

  public $benchmarks() {
    return this._$benchmarks.asObservable();
  }

  public select(benchmark: StrategyBenchmark) {
    if (benchmark !== this._selected) {
      this._$selected.next(benchmark);
      this._selected = benchmark;
    }
  }

  public $selected() {
    return this._$selected.asObservable();
  }
}
