import { Component, Inject, OnDestroy, ViewChild } from 'angular-ts-decorators';
import {
  CockpitProfile,
  CockpitProfileRiskContribution, CockpitProfileRiskContributionResource,
  CockpitProfilePca, CockpitProfilePcaMapping, CockpitProfilePcaResource,
  CockpitProfileStrategy
} from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { CockpitsViewsService } from '../../views/service';
import { TimeSeriesStockChartComponent, IStockChartRequestParams } from '../../../time-series';

import { colorFor } from '../table/color-for';
import { IContributionTable, refreshGroupedTable } from './group-tables';

export const riskContributionTypes: String[] = ['volatility', 'var', 'es'];

export const maxAbsFor = (values: {[key: string]: number}) => {
  return Math.max.apply(Math, Object.keys(values || {}).map(key => Math.abs(values[key])));
};

@Component({
  selector: 'page-cockpits-risk-contribution-show',
  template
})
export class CockpitsRiskContributionShowPage implements OnDestroy {
  private chartTimeout: ng.IPromise<any>;
  private subscriptions: Subscription[] = [];

  @ViewChild(TimeSeriesStockChartComponent)
  public chart: TimeSeriesStockChartComponent;

  public tables: IContributionTable;
  public profile: CockpitProfile|null = null;
  public pca: CockpitProfilePca|null = null;
  public riskContribution: CockpitProfileRiskContribution|null = null;
  public strategies: CockpitProfileStrategy[] = [];
  public requestParams: IStockChartRequestParams;
  public riskContributionType: String = riskContributionTypes[0];
  public settings = {
    plots: [{
      colors: {},
      yScale: {
        comparisonMode: 'none',
        baseHundred: false,
        stackMode: null
      },
      yAxis: { labels: {} },
      line: {},
      legend: { enabled: true },
      messageLoading: '',
      messageEmpty: '',
      annotations: [{ verticalLine: {} }]
    }],
    tooltip: {}
  };

  public RISK_CONTRIBUTION_TYPES = riskContributionTypes;
  public colorFor = colorFor;
  public maxAbsFor = maxAbsFor;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    cockpitsViewsService: CockpitsViewsService,
    @Inject('CockpitProfilePcaResource')
    private pcaResource: CockpitProfilePcaResource,
    @Inject('CockpitProfileRiskContributionResource')
    private riskContributionResource: CockpitProfileRiskContributionResource,
    $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService
  ) {
    this.settings.plots[0].messageLoading = $translate.instant(
      'timeSeries.stockChart.loading'
    );
    this.settings.plots[0].messageEmpty = $translate.instant(
      'strategies.show.benchmark.view.fixings.empty'
    );

    this.subscriptions.push(
      cockpitsViewsService.$cockpitProfile().subscribe(profile => this.loadProfile(profile))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.$timeout.cancel(this.chartTimeout);
  }

  loadProfile(profile: CockpitProfile|null) {
    this.profile = profile;

    if (profile !== null) {
      this.loadPCA();
      this.loadRiskContribution('volatility');
    }
  }

  loadPCA() {
    this.pcaResource.get({
      profile_id: this.profile!.id
    }).$promise.then(response => this.refreshPCA(response));
  }

  loadRiskContribution(riskContributionType: string) {
    this.riskContributionType = riskContributionType;

    this.riskContributionResource.get({
      profile_id: this.profile!.id,
      id: this.riskContributionType
    }).$promise.then(response => {
      this.riskContribution = response;
      this.refreshTables();
    });
  }

  refreshPCA(pca: CockpitProfilePca) {
    this.pca = pca;

    this.refreshTables();
    this.chartTimeout = this.$timeout(() => {
      // @note: special case for angularjs to refresh ViewChild
      (this as any)._updateViewChildren();
      this.chart.requestParams = {
        'ids[]': pca.principal_components.map(pc => pc.id),
        type: 'PrincipalComponent',
        'categories[]': ['fixings']
      };
      this.chart.refresh();
    });
  }

  refreshTables() {
    if (!this.profile || !this.profile.portfolios) {
      return;
    }
    const riskContribution: CockpitProfileRiskContribution =
      this.riskContribution || {absolute: {}, relative: {}, entropy: {}};
    let weights: CockpitProfilePcaMapping = {};

    if (this.pca && this.pca.weights && this.pca.weights.strategies) {
      weights = this.pca.weights.strategies;
    }

    this.tables = refreshGroupedTable(
      riskContribution, weights, this.profile.portfolios, this.profile
    );
  }
}
