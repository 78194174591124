import { Simulation } from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/simulations/variables';

export const badge = (simulation: Simulation): string => {
  switch (simulation.status) {
    case variables.STATUSES.COMPLETED:
      return 'badge-success';
    case variables.STATUSES.ERROR:
      return 'badge-danger';
    default:
      return 'badge-secondary';
  }
};
