import { variables } from '@quantizr/front-model/dist/jobs/variables';
import { ReplaySubject } from 'rxjs';

export const TIMEOUT = 1000;
export const MAX_ATTEMPTS = 10;

export const factory = ($timeout: ng.ITimeoutService, $injector) => {
  return (id: string, type: string, action: string) => {
    return new JobPoller($timeout, $injector, id, type, action);
  };
};

export interface IJobPollerStatus {
  status: string;
  error?: string;
}

export interface IJobPollerResponse {
  status: string;
  error?: {
    message: string;
  };
}

// Jobs.Poller
export class JobPoller {
  private $http: ng.IHttpService;
  private _status: ReplaySubject<IJobPollerStatus> = new ReplaySubject(1);
  private pollTimeout: ng.IPromise<void>;

  public status() {
    return this._status.asObservable();
  }

  private _stopped: boolean = false;

  public get stopped(): boolean {
    return this._stopped;
  }

  private _attempts: number = 0;

  public get attempts(): number {
    return this._attempts;
  }

  constructor(
    public $timeout: ng.ITimeoutService,
    $injector,
    private id: string,
    private type: string,
    private action: string
  ) {
    this.$http = $injector.get('$http');
  }

  /**
   * Start the poller. Can be stopped using `stop()`
   */
  start(): JobPoller {
    if (!this._stopped) {
      this._poll();
    }
    return this;
  }

  /**
   * Cancel the poller. Can be resumed using `start()`
   */
  stop(): JobPoller {
    // TODO: cancel ongoing request
    // @see http://odetocode.com/blogs/scott/archive/2014/04/24/canceling-http-requests-in-angularjs.aspx
    this._stopped = true;
    return this;
  }

  /**
   * Destroy the poller. Can be called only once for cleanup when not used anymore.
   */
  destroy(): void {
    this.$timeout.cancel(this.pollTimeout);
    this.stop();
  }

  /**
   * @internal
   */
  _setStatus(status: IJobPollerStatus): void {
    this._status.next(status);
  }

  /**
   * @internal
   */
  _retry(): void {
    if (this._attempts > MAX_ATTEMPTS) {
      this._setStatus({status: variables.STATUSES.MAX_ATTEMPTS});
      this._stopped = true;
      // TODO: should we restart in 10xTIMEOUT?
      return;
    }

    this._attempts++;
    this._poll();
  }

  /**
   * @internal
   */
  _poll(): void {
    if (this._stopped) {
      return;
    }

    const url = ['/jobs', this.type, this.id, this.action + '?format=json'].join('/');

    this.$http.get<IJobPollerResponse>(url).then(response => {
      this._updateStatus(response.data);
    }, () => this._retry());
  }

  /**
   * @internal
   */
  _updateStatus(data?: IJobPollerResponse) {
    if (this._stopped) {
      return;
    }

    if (!data) {
      this._retry();
    }
    else {
      this._attempts = 0;
      const status = data.status;
      this._setStatus({
        status,
        error: data.error ? data.error.message : undefined
      });

      if (status !== variables.STATUSES.COMPLETED && status !== variables.STATUSES.FAILED) {
        this.pollTimeout = this.$timeout(() => this._poll(), TIMEOUT);
      }
    }
  }
}
