import { RavenConfig } from '@quantizr/front-model/dist/raven/variables';
import * as angular from 'angular';
const Raven = require('raven-js');
const RavenAngular = require('raven-js/plugins/angular');

import { IEnv } from '../env';

export default function(Config: IEnv, ravenConfig: RavenConfig) {
  if (ravenConfig.DSN) {
    Raven.config(ravenConfig.DSN, {
      environment: Config.ENV
    })
      .addPlugin(RavenAngular, angular)
      .install();
  }
}
