import { Component, Input, OnInit, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import {
  Strategy, categoriesForTable, isClosingDate, maxClosingDate, TimeSerieCategory
} from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/strategies/variables';

import './component.scss';
const template = require('./component.html.haml');

import { ITableSettingsGroup, TableSettingsController } from '../../components';

const CATEGORIES = categoriesForTable([{
  key: TimeSerieCategory.return_pa,
  defaultActive: true
}, {
  key: TimeSerieCategory.cumulative_return,
  defaultActive: true
}, {
  key: TimeSerieCategory.max_drawdown,
  defaultActive: true
}, {
  key: TimeSerieCategory.volatility,
  defaultActive: true
}, {
  key: TimeSerieCategory.info_ratio,
  defaultActive: true
}]);

@Component({
  selector: 'strategies-overall-performances',
  template
})
export class StrategiesOverallPerformancesComponent implements OnInit, OnChanges {
  // default sort by name
  private _orderKey = 'name';

  @Input()
  private strategies: Strategy[];
  @Input()
  public performances: string[] = [];
  @Input()
  public performance: string;

  public STRATEGY_STATUSES = variables.STATUSES;
  public CATEGORIES = CATEGORIES;

  public tableSettingsGroups: ITableSettingsGroup[] = [];
  public tableSettingsController = new TableSettingsController('strategies-overall-performances');

  public order: string;
  public reverse = false;
  public selectedPerformance: string;
  public closingDate: Date|null = null;

  /*@ngInject*/
  constructor(
    private $location: ng.ILocationService,
    $translate: ng.translate.ITranslateService
  ) {
    this.tableSettingsGroups = [{
      key: 'column',
      title: $translate.instant('timeSeries.categories.')
    }];

    CATEGORIES.forEach(category => {
      this.tableSettingsController.registerProperty({
        key: category.key,
        group: 'column',
        active: category.defaultActive,
        title: $translate.instant(`timeSeries.categories.${category.key}`)
      });
    });
  }

  ngOnInit() {
    if (!this.performance) {
      this.performance = this.performances[0];
    }

    this.selectedPerformance = this.performance;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategies' in changes) {
      this.closingDate = maxClosingDate(this.strategies);
    }

    if ('performance' in changes) {
      const performance = changes.performance.currentValue;
      if (performance) {
        this.order = this.orderKey(this._orderKey);
      }
    }
  }

  isClosingDate(strats?: Strategy|Strategy[]) {
    if (!this.closingDate) {
      return true;
    }
    if (!strats) {
      return false;
    }

    if (!Array.isArray(strats)) {
      strats = [strats];
    }
    return isClosingDate(strats, this.closingDate!);
  }

  orderKey(orderKey: string) {
    if (orderKey === 'name') {
      return orderKey;
    }

    return `statistics.${orderKey}["${this.performance}"]`;
  }

  orderPerf(orderKey: string) {
    this._orderKey = orderKey;
    this.reverse = this.order.match(orderKey) !== null ? !this.reverse : false;
    this.order = this.orderKey(this._orderKey);
  }

  perfOrder() {
    return this.reverse ?
      ['!!' + this.order, this.order] : [this.order, '!!' + this.order];
  }

  goToComparator() {
    (this.$location as any).$$search = {};
    this.$location.path('/comparator/' + this.strategies.map(e => e.id));
  }
}
