import { Component, Inject } from 'angular-ts-decorators';
import { CockpitProfile, CockpitPortfolio } from '@quantizr/front-model';
import { CockpitsViewsService } from '../views/service';
import { Subscription } from 'rxjs';

const template = require('./component.html.haml');

@Component({
  selector: 'cockpits-top-navbar',
  template
})
export class CockpitsTopNavbarComponent {
  private subscriptions: Subscription[] = [];
  public profile: CockpitProfile|null;
  public selectedPortfolio: CockpitPortfolio|null;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService
  ) {}

  ngAfterViewInit() {
    this.subscriptions.push(this.cockpitsViewsService.$cockpitProfile().subscribe(profile => {
      this.profile = profile;
      this.init();
    }));
  }

  init() {
    if (this.profile && this.profile.portfolios) {
      this.changePortfolio(this.profile.portfolios[0]);
    }
    else {
      this.changePortfolio(null);
    }
  }

  changePortfolio(portfolio: CockpitPortfolio|null) {
    if (!this.selectedPortfolio || !portfolio || this.selectedPortfolio.id !== portfolio.id) {
      this.cockpitsViewsService.cockpitPortfolio(portfolio);
      this.selectedPortfolio = portfolio;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
