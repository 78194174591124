import { NgModule } from 'angular-ts-decorators';
import StrategyBenchmarkResourceFactory from '@quantizr/front-model/dist/strategies/benchmarks/resource';
import StrategyConstituentResourceFactory from '@quantizr/front-model/dist/strategies/constituents/resource';
import StrategyEtfStaticDataResource from '@quantizr/front-model/dist/strategies/etf-static-data/resource';
import StrategyIssuerResourceFactory from '@quantizr/front-model/dist/strategies/issuers/resource';
import StrategyFactorResourceFactory from '@quantizr/front-model/dist/strategies/factors/resource';
import StrategyFieldResourceFactory from '@quantizr/front-model/dist/strategies/fields/resource';
import StrategyPortfolioResourceFactory from '@quantizr/front-model/dist/strategies/portfolios/resource';
import {
  default as StrategyProviderStrategyResourceFactory
} from '@quantizr/front-model/dist/strategies/provider-strategies/resource';
import {
  default as StrategyStockResourceFactory
} from '@quantizr/front-model/dist/strategies/stocks/resource';
import {
  default as StrategyStrategyTimeSerieResourceFactory
} from '@quantizr/front-model/dist/strategies/strategy-time-series/resource';
import {
  default as StrategyUserPermissionResourceFactory
} from '@quantizr/front-model/dist/strategies/user-permissions/resource';
import {
  default as StrategyVersionCorrelationResourceFactory
} from '@quantizr/front-model/dist/strategies/versions/correlations/resource';
import {
  default as StrategyVersionVersionResourceFactory
} from '@quantizr/front-model/dist/strategies/versions/strategy-versions/resource';
import StrategyVersionResourceFactory from '@quantizr/front-model/dist/strategies/versions/resource';
import StrategyResourceFactory from '@quantizr/front-model/dist/strategies/resource';

import { EnvModule } from '../env';
import { BenchmarksModule } from '../benchmarks';
import { CompaniesModule } from '../companies';
import { ComponentsModule } from '../components';
import { CustomlistsModule } from '../customlists';
import { CustomSearchModule } from '../custom-search';
import { JobsModule } from '../jobs';
import { KeywordsModule } from '../keywords';
import { LevelPermissionsModule } from '../level-permissions';
import { LinksModule } from '../links';
import { MessagesModule } from '../messages';
import { MyUserModule } from '../my-user';
import { PanelsModule } from '../panels';
import { PDFModule } from '../pdf';
import { TimeSeriesModule } from '../time-series';
import { VendorModule } from '../vendor';

import { StrategiesCompositionModel } from './composition/model';
import { StrategiesVersionsModel } from './versions/model';

import { StrategiesViewsService } from './views/service';
import { StrategiesBenchmarksService } from './benchmarks/service';
import {
  StrategiesLocalStorageService, KEYS as LOCAL_STORAGE_KEYS
} from './local-storage/service';

import { StrategiesBenchmarksComparatorComponent } from './benchmarks/comparator/component';
import { StrategiesCancelConfirmPage } from './cancel/confirm/page';
import { StrategiesComparatorPage } from './comparator/page';
import { StrategiesCorrelationMatrixComponent } from './correlation-matrix/component';
import { StrategiesCustomlistsDeleteConfirmPage } from './customlists/delete/confirm/page';
import { StrategiesCustomlistsNewPage } from './customlists/new/page';
import { StrategiesDeleteConfirmPage } from './delete/confirm/page';
import { StrategiesEditPage } from './edit/page';
import { StrategiesEditCalculationPage } from './edit/calculation/page';
import { StrategiesEditFixingsPage } from './edit/fixings/page';
import { StrategiesEditPermissionsPage } from './edit/permissions/page';
import { StrategiesEditSettingsPage } from './edit/settings/page';
import { StrategiesFixingsComponent } from './fixings/component';
import { StrategiesHistoricalEventsComponent } from './historical-events/component';
import { StrategiesIndexMultiActionsComponent } from './index/multi-actions/component';
import { StrategiesIndexResultsComponent } from './index/results/component';
import { StrategiesIndexSearchFiltersComponent } from './index/search-filters/component';
import { StrategiesIndexPage } from './index/page';
import { StrategiesKeywordsComponent } from './keywords/component';
import { StrategiesLinksCorrelationsComponent } from './links/correlations/component';
import { StrategiesLinksOutperformancesComponent } from './links/outperformances/component';
import { StrategiesMatrixComponent } from './matrix/component';
import { StrategiesNewPage } from './new/page';
import { StrategiesOverallPerformancesComponent } from './overall-performances/component';
import { StrategiesPdfPage } from './pdf/page';
import { StrategiesPdfFooterComponent } from './pdf/footer/component';
import { StrategiesPdfHeaderComponent } from './pdf/header/component';
import { StrategiesPeerRankingComponent } from './peer-ranking/component';
import { StrategiesPublishConfirmPage } from './publish/confirm/page';
import { StrategiesShowAboutPage } from './show/about/page';
import { StrategiesShowBenchmarkPage } from './show/benchmark/page';
import { StrategiesShowCompanyPage } from './show/company/page';
import { StrategiesShowFactorsBetasComponent } from './show/factors/betas/component';
import { StrategiesShowFactorsExposureComponent } from './show/factors/exposure/component';
import { StrategiesShowFactorsPerformanceComponent } from './show/factors/performance/component';
import { StrategiesShowFactorsPage } from './show/factors/page';
import { StrategiesShowHoldingsPage } from './show/holdings/page';
import { StrategiesShowHoldingsDerivativePage } from './show/holdings/derivative/page';
import { StrategiesShowHoldingsDistributionComponent } from './show/holdings/distribution/component';
import { StrategiesShowHoldingsFixedIncomePage } from './show/holdings/fixed-income/page';
import { StrategiesShowHoldingsEquityPage } from './show/holdings/equity/page';
import { StrategiesShowHoldingsGeneralPage } from './show/holdings/general/page';
import { StrategiesShowHoldingsGeographyComponent } from './show/holdings/geography/component';
import { StrategiesShowHoldingsGroupByComponent } from './show/holdings/group-by/component';
import { StrategiesShowHoldingsIssuersComponent } from './show/holdings/issuers/component';
import { StrategiesShowHoldingsPieChartComponent } from './show/holdings/pie-chart/component';
import { StrategiesShowHoldingsSortedTableComponent } from './show/holdings/sorted-table/component';
import { StrategiesShowHoldingsXYChartComponent } from './show/holdings/x-y-chart/component';
import { StrategiesShowEsgPage } from './show/esg/page';
import { StrategiesShowPerformancePage } from './show/performance/page';
import { StrategiesShowPerformanceMonthToMonthComponent } from './show/performance/month-to-month/component';
import { StrategiesShowPerformanceOverallComponent } from './show/performance/overall/component';
import { StrategiesShowVersionsCompositionPage } from './show/versions/composition/page';
import { StrategiesShowVersionsPage } from './show/versions/page';
import { StrategiesShowWeightsPage } from './show/weights/page';
import { StrategiesShowPage } from './show/page';
import { StrategiesSidebarFiltersComponent } from './sidebar-filters/component';
import { StrategiesStatusComponent } from './status/component';
import { StrategiesStrategiesDeleteConfirmPage } from './strategies/delete/confirm/page';
import { StrategiesStrategiesNewPage } from './strategies/new/page';
import { StrategiesSuggestionsComponent } from './suggestions/component';
import { StrategiesUserPermissionsConfirmPage } from './user-permissions/page';
import { StrategiesVersionsDeleteConfirmPage } from './versions/delete/confirm/page';
import { StrategiesVersionsPublishConfirmPage } from './versions/publish/confirm/page';
import { StrategiesVolatilityComponent } from './volatility/component';
import { StrategiesWeightsComponent } from './weights/component';

export const FILTERS = {
  ALL: 'all',
  ME: 'me'
};

export {
  LOCAL_STORAGE_KEYS
};

@NgModule({
  id: 'Strategies',
  imports: [
    BenchmarksModule,
    CompaniesModule,
    ComponentsModule,
    CustomlistsModule,
    CustomSearchModule,
    EnvModule,
    JobsModule,
    KeywordsModule,
    LevelPermissionsModule,
    LinksModule,
    MessagesModule,
    MyUserModule,
    PanelsModule,
    PDFModule,
    TimeSeriesModule,
    VendorModule
  ],
  declarations: [
    StrategiesBenchmarksComparatorComponent,
    StrategiesCancelConfirmPage,
    StrategiesComparatorPage,
    StrategiesCorrelationMatrixComponent,
    StrategiesCustomlistsDeleteConfirmPage,
    StrategiesCustomlistsNewPage,
    StrategiesDeleteConfirmPage,
    StrategiesEditPage,
    StrategiesEditCalculationPage,
    StrategiesEditFixingsPage,
    StrategiesEditPermissionsPage,
    StrategiesEditSettingsPage,
    StrategiesFixingsComponent,
    StrategiesHistoricalEventsComponent,
    StrategiesIndexMultiActionsComponent,
    StrategiesIndexResultsComponent,
    StrategiesIndexSearchFiltersComponent,
    StrategiesIndexPage,
    StrategiesKeywordsComponent,
    StrategiesLinksCorrelationsComponent,
    StrategiesLinksOutperformancesComponent,
    StrategiesMatrixComponent,
    StrategiesNewPage,
    StrategiesOverallPerformancesComponent,
    StrategiesPdfPage,
    StrategiesPdfFooterComponent,
    StrategiesPdfHeaderComponent,
    StrategiesPeerRankingComponent,
    StrategiesPublishConfirmPage,
    StrategiesShowAboutPage,
    StrategiesShowBenchmarkPage,
    StrategiesShowCompanyPage,
    StrategiesShowFactorsBetasComponent,
    StrategiesShowFactorsExposureComponent,
    StrategiesShowFactorsPerformanceComponent,
    StrategiesShowFactorsPage,
    StrategiesShowHoldingsPage,
    StrategiesShowHoldingsDerivativePage,
    StrategiesShowHoldingsDistributionComponent,
    StrategiesShowHoldingsFixedIncomePage,
    StrategiesShowHoldingsEquityPage,
    StrategiesShowHoldingsGeneralPage,
    StrategiesShowHoldingsGeographyComponent,
    StrategiesShowHoldingsGroupByComponent,
    StrategiesShowHoldingsIssuersComponent,
    StrategiesShowHoldingsPieChartComponent,
    StrategiesShowHoldingsSortedTableComponent,
    StrategiesShowHoldingsXYChartComponent,
    StrategiesShowEsgPage,
    StrategiesShowPerformancePage,
    StrategiesShowPerformanceMonthToMonthComponent,
    StrategiesShowPerformanceOverallComponent,
    StrategiesShowVersionsCompositionPage,
    StrategiesShowVersionsPage,
    StrategiesShowWeightsPage,
    StrategiesShowPage,
    StrategiesSidebarFiltersComponent,
    StrategiesStatusComponent,
    StrategiesStrategiesNewPage,
    StrategiesStrategiesDeleteConfirmPage,
    StrategiesSuggestionsComponent,
    StrategiesUserPermissionsConfirmPage,
    StrategiesVersionsDeleteConfirmPage,
    StrategiesVersionsPublishConfirmPage,
    StrategiesVolatilityComponent,
    StrategiesWeightsComponent
  ],
  providers: [
    StrategiesViewsService,
    StrategiesBenchmarksService,
    StrategiesLocalStorageService,
    {
      provide: 'StrategyResource',
      useFactory: ($resource) => StrategyResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyBenchmarkResource',
      useFactory: ($resource) => StrategyBenchmarkResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyConstituentResource',
      useFactory: ($resource) => StrategyConstituentResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyIssuerResource',
      useFactory: ($resource) => StrategyIssuerResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyFactorResource',
      useFactory: ($resource) => StrategyFactorResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyFieldResource',
      useFactory: ($resource) => StrategyFieldResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyPortfolioResource',
      useFactory: ($resource) => StrategyPortfolioResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'Strategies.EtfStaticData',
      useFactory: ($resource) => StrategyEtfStaticDataResource($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyProviderStrategyResource',
      useFactory: ($resource) => StrategyProviderStrategyResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyStockResource',
      useFactory: ($resource) => StrategyStockResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyStrategyTimeSerieResource',
      useFactory: ($resource) => StrategyStrategyTimeSerieResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyUserPermissionResource',
      useFactory: ($resource) => StrategyUserPermissionResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyVersionCorrelationResource',
      useFactory: ($resource) => StrategyVersionCorrelationResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyVersionVersionResource',
      useFactory: ($resource) => StrategyVersionVersionResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategyVersionResource',
      useFactory: ($resource) => StrategyVersionResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'StrategiesCompositionModel',
      useFactory: (StrategyResource, $translate) => {
        return (portfolio) => {
          return new StrategiesCompositionModel(StrategyResource, $translate, portfolio);
        };
      },
      deps: ['StrategyResource', '$translate']
    },
    {
      provide: 'StrategiesVersionsModel',
      useFactory: (StrategyVersionVersionResource, $translate) => {
        return (strategy) => {
          return new StrategiesVersionsModel(StrategyVersionVersionResource, $translate, strategy);
        };
      },
      deps: ['StrategyVersionVersionResource', '$translate']
    }
  ]
})
export class StrategiesModule {
  /*@ngInject*/
  static run($templateCache: ng.ITemplateCacheService) {
    $templateCache.put('strategies/suggestions/typeahead.html', require('./suggestions/typeahead.html.haml'));
  }
}

export default (StrategiesModule as NgModule).module!.name;
