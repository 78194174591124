import { Component } from 'angular-ts-decorators';

const template = require('./page.html.haml');

import { CockpitsProfilesPage } from '../profiles/page';

@Component({
  selector: 'page-cockpits-holdings',
  template
})
export class CockpitsHoldingsPage extends CockpitsProfilesPage {}
