import { Component, Input, OnChanges, SimpleChanges, Inject } from 'angular-ts-decorators';
import { StrategyStockResource, GroupedStrategyStock } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { IColumnChartColumnData } from '../../../../components/column-chart/component';

type groupType = 'weight'|'count';
const groupTypes: groupType[] = [
  'weight', 'count'
];
const outerValue = 999;

@Component({
  selector: 'strategies-show-holdings-distribution',
  template
})
export class StrategiesShowHoldingsDistributionComponent implements OnChanges {
  @Input()
  strategyId: number;
  @Input()
  public cTitle = '';
  @Input('@')
  public fixingsProperty = '';
  @Input()
  public longShort?: 'long'|'short' = 'long';
  @Input()
  min = -30;
  @Input()
  max = 30;
  @Input()
  step = 5;

  public distribution: IColumnChartColumnData[] = [];
  public settings = {
    chart: {
      barGroupsPadding: 0
    }
  };
  public groupTypes = groupTypes;
  public selectedType: groupType = 'weight';

  private group: GroupedStrategyStock;

  /*@ngInject*/
  constructor(
    @Inject('StrategyStockResource')
    private strategyStockResource: StrategyStockResource
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('strategyId' in changes || 'longShort' in changes) {
      this.query();
    }
  }

  query() {
    const [category, window] = this.fixingsProperty.split('.');

    return this.strategyStockResource.groupBy({
      strategy_id: this.strategyId,
      long_short: this.longShort,
      category: category as any,
      window: window as any,
      min: this.min,
      max: this.max,
      step: this.step
    }).$promise.then(response => {
      this.group = response;
      this.updateDistribution();
    });
  }

  updateDistribution() {
    const total = this.total();
    this.distribution = Object.keys(this.group).filter(key => {
      return typeof this.group[key] === 'object' && this.selectedType in this.group[key];
    }).sort((a, b) => +a - +b).map(key => {
      const x = this.label(key);
      const value = this.value(this.group[key][this.selectedType], total);

      return {
        x,
        value
      };
    });
  }

  total() {
    return Object.keys(this.group)
      .map(key => this.group[key][this.selectedType])
      .filter(val => val !== undefined)
      .reduce((prev, cur) => prev + cur, 0);
  }

  label(label: string) {
    const max = Math.abs(this.max);
    const min = Math.abs(this.min);
    return +label === outerValue ? `> ${max}` : (+label === -outerValue ? `< ${min}` : label);
  }

  value(value: number, total = 0) {
    return value > 0 ? value / total * 100 : 0;
  }
}
