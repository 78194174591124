import { Component, OnDestroy, Inject } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  CockpitProfile,
  CockpitPortfolio,
  CockpitProfileStrategy,
  CockpitProfileGroupedKeywords
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { QUALIFIERS } from '../..';
import { dateAtMidnight, DatesSelector, ISelectorDatesSelected } from '../../../components';
import { CockpitsViewsService } from '../../views/service';

@Component({
  selector: 'page-cockpits-monitoring-show',
  template
})
export class CockpitsMonitoringShowPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public QUALIFIERS = QUALIFIERS;
  public profile: CockpitProfile|null = null;
  public selectedPortfolio: CockpitPortfolio;
  public strategies: CockpitProfileStrategy[] = [];
  public datesSelector: DatesSelector;
  public dates: ISelectorDatesSelected = {};
  public maxDate?: Date;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    cockpitsViewsService: CockpitsViewsService,
    private $timeout: ng.ITimeoutService
  ) {
    this.datesSelector = new DatesSelector();
    this.subscriptions.push(this.datesSelector.$dates().subscribe(dates => {
      this.maxDate = dates.max;
    }));

    this.subscriptions.push(cockpitsViewsService.$cockpitProfile().subscribe(profile => {
      this.profile = profile;
      this.init();

      if (profile !== null) {
        this.loadStrategies(profile.grouped_keywords);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    if (this.profile && this.profile.portfolios) {
      this.changePortfolio(this.profile.portfolios[0]);
    }
  }

  changePortfolio(portfolio?: CockpitPortfolio) {
    if (portfolio) {
      this.selectedPortfolio = portfolio;

      if (this.selectedPortfolio.start_date) {
        this.dates.min = dateAtMidnight(this.selectedPortfolio.start_date);
      }

      if (this.selectedPortfolio.end_date) {
        this.dates.max = dateAtMidnight(this.selectedPortfolio.end_date);
      }

      this.$timeout(() => {
        const defaultMin = this.dates.max ? new Date(this.dates.max) : new Date();
        defaultMin.setFullYear(defaultMin.getFullYear() - 1);

        this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
        this.datesSelector.dates({
          max: this.dates.max,
          min: defaultMin
        });
      });
    }
  }

  loadStrategies(keywords?: CockpitProfileGroupedKeywords[]) {
    this.strategies = [];

    if (!keywords) {
      return;
    }

    keywords.forEach(group => {
      (group.children || []).forEach(child => {
        (child.strategies || []).forEach(strategy => this.strategies.push(strategy));
      });
    });
  }
}
