import { MyUserService } from '../service';

export const isAuthenticatedFilter = (myUserService: MyUserService) => {
  let isAuthenticated: boolean = !!myUserService.user;

  myUserService.$user().subscribe(user => {
    isAuthenticated = !!user;
  });

  const filter = (match: boolean): boolean => {
    return match === isAuthenticated;
  };

  (filter as any).$stateful = true;
  return filter;
};
