import { Injectable } from 'angular-ts-decorators';
import { ReplaySubject } from 'rxjs';

@Injectable('PanelsSidebarService')
export class PanelsSidebarService {
  private _$open: ReplaySubject<boolean> = new ReplaySubject(1);

  public $open() {
    return this._$open.asObservable();
  }

  public open(): void {
    this._$open.next(true);
  }

  public close(): void {
    this._$open.next(false);
  }
}
