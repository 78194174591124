import {
  Component, Input, AfterViewInit, OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import { variables } from '@quantizr/front-model/dist/level-permissions/variables';
import './component.scss';
const template = require('./component.html.haml');
import merge = require('lodash/merge');

@Component({
  selector: 'level-permissions-editor',
  template
})
export class LevelPermissionsEditorComponent implements AfterViewInit, OnChanges {
  private initialData = {};

  @Input()
  public ngModel: any = {};

  public LEVELS_DEFAULT = variables.LEVELS_DEFAULT;
  public levels: string[] = [];
  public sortableOptions: any = {};

  ngAfterViewInit() {
    this.sortableOptions = {
      connectWith: '.list-categories',
      'ui-floating': false // set list as vertical
    };

    Object.keys(variables.LEVELS).forEach((key) => {
      const level = variables.LEVELS[key];
      if (level !== variables.LEVELS_DEFAULT) {
        this.levels.push(level);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('ngModel' in changes) {
      const ngModel = changes.ngModel.currentValue;
      if (ngModel) {
        this.init();
      }
    }
  }

  init() {
    // make sure every permission is defined
    Object.keys(variables.LEVELS).forEach((key) => {
      const level = variables.LEVELS[key];

      if (!(level in this.ngModel)) {
        this.ngModel[level] = {
          permissions: []
        };
      }
    });

    // move all non-added categories to the default column
    Object.keys(variables.CATEGORIES).forEach((categoryKey) => {
      const category = variables.CATEGORIES[categoryKey];
      let added = false;

      Object.keys(variables.LEVELS).forEach((levelKey) => {
        const level = variables.LEVELS[levelKey];

        for (const permission of this.ngModel[level].permissions) {
          if (permission.category === category) {
            added = true;
            break;
          }
        }
      });

      if (!added) {
        this.ngModel[variables.LEVELS_DEFAULT].permissions.push({
          category
        });
      }
    });

    this.initialData = merge({}, this.ngModel);
  }

  moveAll(level) {
    Object.keys(variables.LEVELS).forEach((key) => {
      const current = variables.LEVELS[key];
      if (current !== level) {
        this.ngModel[current].permissions.forEach((permission) => {
          this.ngModel[level].permissions.push(permission);
        });
        this.ngModel[current].permissions = [];
      }
    });
  }

  reset() {
    this.ngModel = merge({}, this.initialData);
  }
}
