import { Component, Inject, OnInit, ViewChild, OnDestroy } from 'angular-ts-decorators';
import {
  StrategyStrategyTimeSerieResource, StrategyStrategyTimeSerie,
  TimeSerieCategory,
  ForeignExchangeRateResource, ForeignExchangeRate, TimeSerieWindow, TimeSerieResource, TimeSerie
} from '@quantizr/front-model';
import cloneDeep = require('lodash/cloneDeep');

const template = require('./page.html.haml');

import { dateAtMidnight, DatesSelector } from '../../../components';
import { CustomSearchRequestsService } from '../../../custom-search';
import { DialogController } from '../../../dialog/controller';
import { TimeSeriesStockChartComponent } from '../../../time-series';

const namespace = 'page-cockpits-perf-contribution-details';

@Component({
  selector: namespace,
  template
})
export class CockpitsPerfContributionDetailsPage
  extends DialogController implements OnInit, OnDestroy {
  @ViewChild('perf')
  public perfsChart: TimeSeriesStockChartComponent;
  @ViewChild('fixings')
  public fixingsChart: TimeSeriesStockChartComponent;
  @ViewChild('weights')
  public weightsChart: TimeSeriesStockChartComponent;
  @ViewChild('currency')
  public currencyChart: TimeSeriesStockChartComponent;

  public dates: any = {};
  public datesSelector: DatesSelector = new DatesSelector();

  public perfChartSettings: any;
  public fixingsChartSettings: any;
  public weightsChartSettings: any;
  public currencyChartSettings: any;

  /*@ngInject*/
  constructor(
    @Inject('StrategyStrategyTimeSerieResource')
    private strategyStrategyTimeSerieResource: StrategyStrategyTimeSerieResource,
    @Inject('ForeignExchangeRateResource')
    private foreignExchangeRateResource: ForeignExchangeRateResource,
    @Inject('TimeSerieResource')
    private timeSerieResource: TimeSerieResource,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    $scope: ng.IScope
  ) {
    super($scope);

    if (this.data.portfolio) {
      if (this.data.portfolio.start_date) {
        this.dates.min = dateAtMidnight(
          this.data.portfolio.start_date
        );
      }
      if (this.data.portfolio.end_date) {
        this.dates.max = dateAtMidnight(
          this.data.portfolio.end_date
        );
      }
    }

    const chartSettings = {
      plots: [{
        colors: {},
        yScale: {},
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: false },
        annotations: [{ verticalLine: {} }]
      }],
      messageLoading: this.$translate.instant('timeSeries.stockChart.loading'),
      messageEmpty: this.$translate.instant('timeSeries.stockChart.empty'),
      tooltip: {}
    };

    this.perfChartSettings = cloneDeep(chartSettings);
    this.fixingsChartSettings = cloneDeep(chartSettings);
    this.weightsChartSettings = cloneDeep(chartSettings);
    this.currencyChartSettings = cloneDeep(chartSettings);

    this.perfChartSettings.plots[0].yScale = {
      isPercentage: true
    };
    this.perfChartSettings.plots[0].legend.enabled = true;
    this.fixingsChartSettings.plots[0].yScale = {
      comparisonMode: 'percent',
      baseHundred: true
    };
    this.weightsChartSettings.plots[0].yScale = {
      isPercentage: true
    };
  }

  ngOnInit() {
    if (!this.data.portfolio) {
      return Promise.resolve();
    }

    this.$timeout(() => {
      (this as any)._updateViewChildren();
      this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
    });

    return Promise.all([
      this.loadPerfs(),
      this.loadFixings(),
      this.loadWeights(),
      this.loadCurrencies()
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customSearchRequestsService.cancel(undefined, namespace);
  }

  loadStrategyTimeSerie(categories: TimeSerieCategory[]) {
    const request = this.strategyStrategyTimeSerieResource.get({
      strategy_id: this.data.portfolio.id,
      id: this.data.strategy.id,
      'category[]': categories,
      'window[]': [this.data.window]
    } as any);
    this.customSearchRequestsService.add(request, namespace);
    return request.$promise.then(response => {
      this.customSearchRequestsService.remove(request, namespace);
      return response;
    });
  }

  loadStrategyTimeSeries(category: TimeSerieCategory, window?: TimeSerieWindow) {
    const request = this.strategyStrategyTimeSerieResource.queryBy({
      strategy_id: this.data.portfolio.id,
      strategy_to_id: this.data.strategy.id,
      'category[]': [category],
      'window[]': window ? [window] : undefined
    } as any);
    this.customSearchRequestsService.add(request, namespace);
    return request.$promise.then(response => {
      this.customSearchRequestsService.remove(request, namespace);
      return response;
    });
  }

  loadTimeSeries(category: TimeSerieCategory) {
    const request = this.timeSerieResource.query({
      'ids[]': [this.data.strategy.id],
      type: 'Strategy',
      'categories[]': [category],
      'windows[]': [this.data.window],
    });
    this.customSearchRequestsService.add(request, namespace);
    return request.$promise.then(response => {
      this.customSearchRequestsService.remove(request, namespace);
      return response;
    });
  }

  loadPerfs() {
    if (this.data.timeseries) {
      return new Promise<void>(resolve => {
        this.$timeout(() => {
          this.updatePerfs(this.data.timeseries);
          resolve();
        });
      });
    }

    return Promise.all([
      this.loadStrategyTimeSerie(
        [TimeSerieCategory.perf_contrib_perf_contributions_serie_local_currency]
      ),
      this.loadStrategyTimeSerie(
        [TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency]
      )
    ]).then(data => this.updatePerfs(data));
  }

  updatePerfs(timeseries: StrategyStrategyTimeSerie[]) {
    this.perfsChart.dataset = [{
      data: timeseries[0].data,
      name: this.$translate.instant(
        `cockpits.perfContribution.details.${
          TimeSerieCategory.perf_contrib_perf_contributions_serie_local_currency
        }`
      )
    }, {
      data: timeseries[1].data,
      name: this.$translate.instant(
        `cockpits.perfContribution.details.${
          TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency
        }`
      )
    }];
    this.perfsChart.refresh();
  }

  loadFixings() {
    if (!this.data.strategy) {
      return Promise.resolve();
    }

    return this.loadTimeSeries(TimeSerieCategory.fixings).then(data => this.updateFixings(data));
  }

  updateFixings(timeseries: TimeSerie[]) {
    const name = this.$translate.instant(
      `cockpits.perfContribution.details.${TimeSerieCategory.fixings}`
    );
    this.fixingsChart.dataset = [{
      data: timeseries[0].data,
      name
    }];
    this.fixingsChart.refresh();
  }

  loadWeights() {
    if (!this.data.strategy) {
      return Promise.resolve();
    }

    return this.loadStrategyTimeSeries(TimeSerieCategory.real_weights).then(
      data => this.updateWeights(data)
    );
  }

  updateWeights(timeseries: StrategyStrategyTimeSerie[]) {
    const name = this.$translate.instant(
      `cockpits.perfContribution.details.${TimeSerieCategory.real_weights}`
    );
    this.weightsChart.dataset = [{
      data: timeseries[0].data,
      name
    }];
    this.weightsChart.refresh();
  }

  loadCurrencies() {
    const request = this.foreignExchangeRateResource.get({
      currency_from: this.data.portfolio.currency,
      currency_to: this.data.currency ? this.data.currency : this.data.strategy.currency,
      window: this.data.window
    });
    this.customSearchRequestsService.add(request, namespace);
    return request.$promise.then(response => {
      this.customSearchRequestsService.remove(request, namespace);
      this.updateCurrency(response);
    });
  }

  updateCurrency(timeserie: ForeignExchangeRate) {
    this.currencyChart.dataset = [{
      data: timeserie.data,
      name: this.$translate.instant('cockpits.perfContribution.details.view.currency', {
        from: this.data.portfolio.currency,
        to: this.data.currency ? this.data.currency : this.data.strategy.currency
      })
    }];
    this.currencyChart.refresh();
  }
}
