import { Component, Input, Inject, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';

import './component.scss';
const template = require('./component.html.haml');

import { TableGroupedService, ITableGroupedServiceOpen } from './service';
import { hexToRgba } from '../../colors/hex-to-rgb';

export interface ITableGroupedHeader {
  /**
   * Display label
   */
  label: string;
  /**
   * How many columns the header takes
   */
  colspan: number;
}

export interface ITableGroupedValue {
  value?: number;
  text?: string;
  isPercentage?: boolean;
  bgColor?: string;
}

export interface ITableGroupedGroup {
  id?: string|number;
  name: string;
  values: ITableGroupedValue[];
  children?: ITableGroupedGroup[];
  /**
   * Set to false by default
   */
  open?: boolean;
  /**
   * Text color.
   */
  color?: string;
  /**
   * Background color. Leave empty for no background color
   */
  bgColor?: string;
  /**
   * If set, element can be clicked to navigate to href
   */
  href?: string;
  /**
   * If set, element can be clicked and will call this function
   */
  click?: Function;
}

const groupOpenId = (group: ITableGroupedGroup, level: string) => `${level}-${group.id}`;

export const getGroupOpen = (open: ITableGroupedServiceOpen, group: ITableGroupedGroup, level: string) => {
  if (group.id) {
    open[groupOpenId(group, level)] = group.open;
  }
  for (let i = 0; i < (group.children || []).length; i++) {
    getGroupOpen(open, group.children![i], `${level}-${i}`);
  }
};

export const setGroupOpen = (open: ITableGroupedServiceOpen, group: ITableGroupedGroup, level: string) => {
  if (group.id) {
    group.open = open[groupOpenId(group, level)];
  }
  for (let i = 0; i < (group.children || []).length; i++) {
    setGroupOpen(open, group.children![i], `${level}-${i}`);
  }
};

@Component({
  selector: 'table-grouped',
  transclude: {
    footer: '?paneFooter'
  },
  template
})
export class TableGroupedComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  public groups: ITableGroupedGroup[] = [];
  @Input()
  public headers: ITableGroupedHeader[] = [];
  @Input()
  public footers: ITableGroupedValue[] = [];
  @Input()
  public synchronizeOpenGroups = false;

  public rgba = hexToRgba;

  /*@ngInject*/
  constructor(
    @Inject('TableGroupedService')
    private tableGroupedService: TableGroupedService
  ) {
    this.subscriptions.push(this.tableGroupedService.$open().subscribe(open => {
      if (this.synchronizeOpenGroups) {
        this.onGroupsOpenChange(open);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  toggleGroupOpen(group: ITableGroupedGroup) {
    group.open = !group.open;
    const open = this.tableGroupedService.open;
    for (let i = 0; i < this.groups.length; i++) {
      getGroupOpen(open, this.groups[i], `${i}`);
    }
    this.tableGroupedService.open = open;
  }

  onGroupsOpenChange(open: ITableGroupedServiceOpen) {
    for (let i = 0; i < this.groups.length; i++) {
      setGroupOpen(open, this.groups[i], `${i}`);
    }
  }

  subgroupsVisible(group: ITableGroupedGroup) {
    // when group has only 1 child, make sure it has an id, otherwise should be hidden
    if (group.children && group.children.length === 1) {
      const child = group.children[0];
      return !!child.id;
    }
    return true;
  }
}
