import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import {
  CockpitProfile, CockpitPortfolio, CockpitProfileStrategy,
  StrategyStrategyTimeSerie,
  TimeSerieWindow
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { groupValues, strategyValues, windowDateFormat } from '../show/page';
import {
  ITableGroupedHeader,
  ITableGroupedGroup
} from '../../../components/table-grouped/component';

@Component({
  selector: 'cockpits-perf-contribution-general',
  template
})
export class CockpitsPerfContributionGeneralComponent implements OnChanges {
  @Input()
  public profile: CockpitProfile;
  @Input()
  public portfolio: CockpitPortfolio;
  @Input()
  public timeSeries: StrategyStrategyTimeSerie[] = [];
  @Input()
  public window: TimeSerieWindow;
  @Input()
  public limitColumns;

  public headers: ITableGroupedHeader[] = [];
  public groups: ITableGroupedGroup[] = [];

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $filter: ng.IFilterService,
    private ngDialog: ng.dialog.IDialogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes || 'timeSeries' in changes) {
      this.loadHeaders();
      this.updateGroups();
    }
    if ('window' in changes) {
      this.loadHeaders();
    }
  }

  limit(values: any[]) {
    return Math.min(values.length, this.limitColumns || Number.MAX_SAFE_INTEGER);
  }

  loadHeaders() {
    return this.$translate([
      'cockpits.perfContribution.general.isin',
      'cockpits.perfContribution.general.name',
      'cockpits.perfContribution.general.currency'
    ])
      .then(values => {
        this.headers = Object.keys(values).map(value => {
          return {
            label: values[value],
            colspan: 1
          };
        });
        const timeSerie = this.timeSeries.length ? this.timeSeries[0] : null;
        if (timeSerie) {
          const data = timeSerie.data;
          const limit = this.limit(data);
          for (let i = data.length - limit; i < data.length; i++) {
            const date = data[i][0];
            this.headers.push({
              label: this.$filter('date')(date, windowDateFormat(this.window)),
              colspan: 1
            });
          }
        }
      });
  }

  openStragyDialog(strategy: CockpitProfileStrategy) {
    this.ngDialog.open({
      template: '<page-cockpits-perf-contribution-details></page-cockpits-perf-contribution-details>',
      className: 'ngdialog-theme-default ngdialog-theme-large',
      plain: true,
      data: {
        portfolio: this.portfolio,
        strategy,
        window: this.window,
        title: strategy.name
      }
    });
  }

  strategyValues(strategy: CockpitProfileStrategy) {
    const values = strategyValues(this.timeSeries, strategy);
    const limit = this.limit(values);
    return values.map(val => {
      return {
        value: val,
        isPercentage: true
      };
    }).slice(values.length - limit);
  }

  groupValues(children: any[]) {
    const values = groupValues(children);
    const limit = this.limit(children[0].values);
    return values.map(val => {
      return {
        value: val,
        isPercentage: true
      };
    }).slice(values.length - limit);
  }

  updateGroups() {
    this.groups = (this.profile.grouped_keywords || []).map(group => {
      group.children = group.children || [];
      const color = (group as any).color;
      const children = group.children.map(subgroup => {
        subgroup.strategies = subgroup.strategies || [];
        const strategies = subgroup.strategies.map(strategy => {
          return {
            id: strategy.isin || '',
            name: strategy.name || '',
            values: this.strategyValues(strategy),
            click: () => {
              this.openStragyDialog(strategy);
            }
          };
        });

        return {
          id: subgroup.id,
          name: subgroup.name,
          open: (subgroup as any).open || false,
          values: this.groupValues(strategies),
          children: strategies
        };
      });

      return {
        id: group.id,
        name: group.name,
        open: (group as any).open || false,
        color,
        values: this.groupValues(children),
        children
      };
    });

    // add currency column
    for (let i = 0; i < (this.profile.grouped_keywords || []).length; i++) {
      const group = (this.profile.grouped_keywords || [])[i];
      this.groups[i].values.unshift({
        text: ''
      });
      for (let j = 0; j < group.children!.length; j++) {
        const subgroup = group.children![j];
        this.groups[i].children![j].values.unshift({
          text: ''
        });
        for (let k = 0; k < subgroup.strategies!.length; k++) {
          const strategy = subgroup.strategies![k];
          this.groups[i].children![j].children![k].values.unshift({
            text: strategy.currency || ''
          });
        }
      }
    }
  }
}
