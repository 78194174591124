export enum ErrorCode {
  timeout = 'timeout',
  sessionLimited = 'session_limited',
  expired = 'expired',
  changePassword = 'session.change_password'
}

export const redirectPath = (errorCode: ErrorCode) => {
  switch (errorCode) {
    case ErrorCode.changePassword:
      return '/my';
    default:
      return '/users/signin';
  }
};
