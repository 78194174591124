import { variables } from '@quantizr/front-model/dist/jobs/variables';
import { ReplaySubject, Subscription } from 'rxjs';
import merge = require('lodash/merge');

import { JobPoller, IJobPollerStatus } from './poller';

export interface IJobModelStatusOptions {
  title: string;
  onClick: () => void;
  action?: string;
  allowClose?: boolean;
}

export interface IJobModelOptions {
  id?: string;
  type?: string;
  action?: string;
  loaded?: IJobModelStatusOptions;
  loading?: IJobModelStatusOptions;
}

export const errorId = -1;

export const factory = (
  Poller,
  $translate: ng.translate.ITranslateService,
  toaster: toaster.IToasterService
) => {
  return (options?: IJobModelOptions) => {
    return new JobModel(Poller, $translate, toaster, options);
  };
};

let id = 0;

// Jobs.Model
export class JobModel {
  private subscriptions: Subscription[] = [];
  private _id: number = ++id;
  private loadingToast;
  private loadedToast;
  private _poller: JobPoller|null = null;

  private _finished: ReplaySubject<boolean> = new ReplaySubject(1);

  public finished() {
    return this._finished.asObservable();
  }

  private _options: IJobModelOptions = {};

  public get options(): IJobModelOptions {
    return this._options;
  }

  public set options(newOptions: IJobModelOptions) {
    merge(this._options, newOptions);
  }

  constructor(
    private poller,
    $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    options: IJobModelOptions = {}
  ) {
    this._options = merge({
      id: null,
      type: null,
      action: null,
      loading: {
        title: $translate.instant('job.model.loading.title'),
        allowClose: true,
        onClick: () => {
          return this._options.loading && this._options.loading.allowClose;
        }
      },
      loaded: {
        title: $translate.instant('job.model.loaded.title')
      }
    }, options);
  }

  clear(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    if (this._poller) {
      this._poller.destroy();
      this._poller = null;
    }

    if (this.loadingToast) {
      (this.toaster as any).clear(this.loadingToast);
      this.loadingToast = null;
    }
    if (this.loadedToast) {
      (this.toaster as any).clear(this.loadedToast);
      this.loadedToast = null;
    }
  }

  restart(): void {
    this.clear();
    this.start();
  }

  start(): number {
    if (this._poller !== null) {
      return errorId;
    }

    this._poller = new this.poller(this._options.id, this._options.type, this._options.action);
    this.subscriptions.push(this._poller!.status().subscribe(status => {
      this._onPollerStatusChanged(status);
    }));
    this._poller!.start();

    this._finished.next(false);

    return this._id;
  }

  stop(): number {
    // no need to stop if never started or progress
    if (!this._poller || !this.loadingToast) {
      return errorId;
    }

    this.clear();

    if (this._options.loaded) {
      let body = this._options.loaded.title;
      if (!!this._options.loaded.action) {
        body += `&nbsp;&nbsp;${this._options.loaded.action}`;
      }

      this.loadedToast = this.toaster.pop({
        type: 'success',
        body,
        bodyOutputType: 'trustedHtml',
        showCloseButton: this._options.loaded.allowClose || true,
        timeout: -1,
        clickHandler: () => {
          this._options.loaded!.onClick!();
          return true;
        }
      });
    }

    this._finished.next(true);

    return this._id;
  }

  error(error?: string): number {
    if (!this._poller) {
      return errorId;
    }

    this.clear();

    this.toaster.pop({
      type: 'error',
      body: error,
      bodyOutputType: 'trustedHtml'
    });

    return this._id;
  }

  id(): number {
    return this._id;
  }

  destroy(): void {
    this.clear();
  }

  /**
   * @internal
   */
  _progress(): void {
    if (this.loadingToast) {
      return;
    }

    if (this._options.loading) {
      this.loadingToast = this.toaster.pop({
        type: 'info',
        body: `<i class="fas fa-spinner rotate-infinite"></i>&nbsp;${this._options.loading.title}`,
        bodyOutputType: 'trustedHtml',
        showCloseButton: this._options.loading.allowClose || true,
        timeout: -1,
        clickHandler: () => this._options.loading!.onClick()
      });
    }
  }

  /**
   * @internal
   */
  _onPollerStatusChanged(status: IJobPollerStatus) {
    switch (status.status) {
      case variables.STATUSES.WORKING:
      case variables.STATUSES.PENDING:
        this._progress();
        break;
      case variables.STATUSES.COMPLETED:
        this.stop();
        break;
      case variables.STATUSES.FAILED:
        this.error(status.error);
        break;
      // TODO: display error?
      // case STATUSES.MAX_ATTEMPTS:
      //   break;
    }
  }
}
