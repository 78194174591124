import { Component, Inject } from 'angular-ts-decorators';
import {
  CockpitProfileResource,
  CockpitProfilePortfolio, CockpitProfilePortfolioResource,
  CockpitProfileVersion, CockpitProfileVersionResource,
  CockpitProfileStrategy, CockpitProfileStrategyResource
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { CockpitsEditProfilesChildPage } from '../child';
import { CockpitsViewsService } from '../../../views/service';

export interface ICockpitProfileVersionPortfolio extends CockpitProfileVersion {
  portfolio?: CockpitProfilePortfolio;
}

@Component({
  selector: 'page-cockpits-edit-profiles-portfolios',
  template
})
export class CockpitsEditProfilesPortfoliosPage extends CockpitsEditProfilesChildPage {
  public versions: ICockpitProfileVersionPortfolio[] = [];
  public strategies: CockpitProfileStrategy[];

  public versionDate: Date|null = null;
  public versionDates: Array<{
    label: string;
    value: Date|null;
  }> = [];

  private portfolios: CockpitProfilePortfolio[] = [];

  /*@ngInject*/
  constructor(
    @Inject('CockpitProfilePortfolioResource')
    private cockpitProfilePortfolioResource: CockpitProfilePortfolioResource,
    @Inject('CockpitProfileStrategyResource')
    private cockpitProfileStrategyResource: CockpitProfileStrategyResource,
    @Inject('CockpitProfileVersionResource')
    private cockpitProfileVersionResource: CockpitProfileVersionResource,
    private $filter: ng.IFilterService,
    // super dependencies
    @Inject('CockpitsViewsService')
    cockpitsViewsService: CockpitsViewsService,
    @Inject('CockpitProfileResource')
    cockpitProfileResource: CockpitProfileResource,
    $location: ng.ILocationService,
    ngDialog: ng.dialog.IDialogService,
    $translate: ng.translate.ITranslateService,
    $q: ng.IQService,
    toaster: toaster.IToasterService,
    $routeParams: ng.route.IRouteParamsService,
    $rootScope: ng.IRootScopeService
  ) {
    super(
      cockpitsViewsService, cockpitProfileResource, $location, ngDialog, $translate, $q, toaster,
      $routeParams, $rootScope
    );
  }

  init() {
    return super.init()
      .then(() => {
        this.updateVersionDates();

        return this.$q.all([
          this.loadPortfolios(),
          this.loadStrategies()
        ]);
      })
      .then(() => this.loadVersions());
  }

  updateVersionDates() {
    this.profile!.version_dates = this.profile!.version_dates || [];
    this.versionDates = this.profile!.version_dates!.filter(date => !!date).map(date => {
      return {
        label: this.$filter('date')(date, 'd-MMM-y'),
        value: date
      };
    });
    this.versionDates = [{
      label: this.$translate.instant('cockpits.edit.profiles.portfolios.controller.versionDateDefault'),
      value: null // add default date
    }, ...this.versionDates];
    this.versionDate = null;
  }

  saveProfile() {
    return this.cockpitProfileVersionResource.updateAll({
      profile_id: this.profile!.id,
      start_date: this.versionDate!,
      versions: this.versions
    }).$promise.then(() => this.saveSuccess(), error => this.saveError(error));
  }

  loadPortfolios() {
    return this.cockpitProfilePortfolioResource.query({
      profile_id: this.profile!.id
    }).$promise.then(portfolios => {
      this.portfolios = portfolios;
      this.portfolios.forEach(portfolio => portfolio.profile_id = this.profile!.id);
    });
  }

  addPortfolio() {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-profiles-portfolios-new></page-cockpits-profiles-portfolios-new>',
      plain: true,
      data: {
        profile: this.profile
      }
    }).then(() => this.init());
  }

  editPortfolio(portfolio: CockpitProfilePortfolio) {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-profiles-portfolios-edit></page-cockpits-profiles-portfolios-edit>',
      plain: true,
      data: portfolio
    }).then(() => this.init());
  }

  deletePortfolio(portfolio: CockpitProfilePortfolio) {
    return this.ngDialog.openConfirm({
      template:
        '<page-cockpits-profiles-portfolios-delete-confirm></page-cockpits-profiles-portfolios-delete-confirm>',
      plain: true,
      data: {portfolio}
    }).then(() => {
      return this.cockpitProfilePortfolioResource.delete({
        id: portfolio.id,
        profile_id: this.profile!.id
      }).$promise;
    }).then(() => this.init());
  }

  loadStrategies() {
    return this.cockpitProfileStrategyResource.query({
      profile_id: this.profile!.id
    }).$promise.then(response => this.strategies = response);
  }

  loadVersions() {
    return this.cockpitProfileVersionResource.query({
      profile_id: this.profile!.id,
      start_date: this.versionDate
    }).$promise.then(versions => {
      this.versions = versions;
      this.versions.forEach(version => {
        const portfolio = this.portfolios.find(value => value.id === version.strategy_id);
        version.portfolio = portfolio;
      });
    });
  }

  strategyVersionModel(strategy: CockpitProfileStrategy, version: CockpitProfileVersion) {
    let strategyVersion = version.strategy_versions_attributes!.find(value => value.strategy_id === strategy.id!);
    if (!strategyVersion) {
      strategyVersion = {
        strategy_id: strategy.id!,
        weight: 0
      };
      version.strategy_versions_attributes!.push(strategyVersion);
    }
    return strategyVersion;
  }

  addVersionDate() {
    return this.confirmOnDirty(() => {
      return this.ngDialog.openConfirm({
        template: '<page-cockpits-versions-reset-confirm></page-cockpits-versions-reset-confirm>',
        plain: true
      });
    }).then(() => {
      return this.ngDialog.openConfirm({
        template: '<page-dates-selector-confirm></page-dates-selector-confirm>',
        plain: true,
        data: {
          options: {
            showWeeks: false,
            maxDate: new Date()
          }
        }
      });
    }).then(date => {
      if (date) {
        this.profile!.version_dates!.push(date);
        this.updateVersionDates();
        this.versionDate = date;
        this.loadVersions();
      }
    });
  }

  versionDateChanged(originalVersionDate: Date) {
    return this.confirmOnDirty(() => {
      return this.ngDialog.openConfirm({
        template: '<page-cockpits-versions-reset-confirm></page-cockpits-versions-reset-confirm>',
        plain: true
      });
    }).then(() => {
      this.loadVersions();
    }).catch(() => {
      this.versionDate = originalVersionDate;
    });
  }

  getTotalWeight(version: ICockpitProfileVersionPortfolio) {
    return version.strategy_versions_attributes.reduce((previous, current) => {
      return previous + current.weight;
    }, 0);
  }
}
