import {
  Component, Inject, Input, OnInit, OnDestroy, OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { StrategyResource } from '@quantizr/front-model';
import cloneDeep = require('lodash/cloneDeep');

import './component.scss';
const template = require('./component.html.haml');

import { VersionsViewsService } from '../../../versions';
import { formHasErrors } from '../../../forms/has-errors';
import { ISimulationNew, ISimulationNewShift } from '../page';
import { IScenarioConfig, scenarioConfigs } from '../../scenarios';

@Component({
  selector: 'simulations-new-parameters',
  template
})
export class SimulationsNewParametersComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  simulation: ISimulationNew;
  @Input()
  errorMessages: any;
  @Input()
  form: ng.IFormController;
  @Input()
  active: boolean;

  public selectedShift: ISimulationNewShift|null = null;
  public config: IScenarioConfig;
  public shiftParametersCount = 0;

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('VersionsViewsService')
    private versionsViewsService: VersionsViewsService,
    private $translate: ng.translate.ITranslateService,
    private $element: ng.IAugmentedJQuery
  ) {
    this.subscriptions.push(versionsViewsService.$shiftValues().subscribe(values => {
      if (this.simulation && values) {
        this.simulation.shift_parameters = values;
        this.shiftParametersCount = Object.keys(values).length;
      }
    }));
  }

  ngOnInit() {
    this.errorMessages = this.errorMessages || {};

    if (!this.form) {
      const input = this.$element.find('input, textarea, select').eq(0);
      this.form = input && input.controller('form');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('active' in changes) {
      const active = changes.active.currentValue;
      if (active) {
        this.init();
      }
    }
  }

  init() {
    if (!this.simulation.strategy_id) {
      return;
    }

    if (!this.simulation.strategy) {
      // reset parameters
      delete this.simulation.shifts_attributes;
      delete this.simulation.shift_parameters;

      this.strategyResource.edit({
        id: this.simulation.strategy_id
      }).$promise.then(strategy => {
        if (strategy.draft_version) {
          if (strategy.draft_version.start_date) {
            strategy.draft_version.start_date =
            new Date(strategy.draft_version.start_date as string);
          }
          else {
            strategy.draft_version.start_date = new Date(strategy.start_date as string);
          }
        }
        this.simulation.strategy = strategy;

        this.config = scenarioConfigs(this.simulation.scenario_type!);
        this.versionsViewsService.fieldsConfig(this.config.fields);
        if (this.config.multiple) {
          this.initShifts();
        }
        else {
          this.initShiftParameters();
        }
      });
    }
  }

  initShifts() {
    this.simulation.shifts_attributes = [];
    this.addShift();
  }

  addShift() {
    const shift = {
      name: this.$translate.instant('simulations.new.parameters.controller.name', {
        index: this.simulation.shifts_attributes.length + 1
      }),
      version_attributes: cloneDeep(this.simulation.strategy!.draft_version)
    };
    this.simulation.shifts_attributes.push(shift);
    this.selectedShift = shift;
  }

  removeShift(shift: ISimulationNewShift, index?: number) {
    if (shift === this.selectedShift) {
      this.selectedShift = null;
    }

    if (typeof index === 'undefined') {
      index = this.simulation.shifts_attributes.indexOf(shift);
    }
    this.simulation.shifts_attributes.splice(index, 1);
  }

  editShift(shift: ISimulationNewShift) {
    this.selectedShift = shift;
  }

  validateShift() {
    this.selectedShift = null;
  }

  // Shift Parameters

  initShiftParameters() {
    const nbStrategies = this.simulation.strategy!.draft_version!.strategy_versions_attributes!.length;

    // TODO: each scenario should have a model that defines the settings and values

    // TODO: constant
    const REBALANCING_TYPES = [
      'no_rebalancing', 'daily', 'weekly', 'monthly',
      'quarterly', 'semi_annually'
    ];
    const shiftSettings = {
      cap: { floor: (100 / nbStrategies), cap: 100, type: 'float' },
      floor: { floor: 0, cap: (100 / nbStrategies), type: 'float' },
      nb_max_strategies: { floor: 1, cap: nbStrategies, type: 'integer' },
      min_return_value: { floor: 0, cap: 100, type: 'integer', step: 5 },
      window_frequency: { floor: 20, cap: 260, type: 'integer', step: 20 },
      management_fees: { floor: 0, cap: 5, type: 'float' },
      nb_returns: { floor: 20, cap: 260, type: 'integer', step: 20 },
      transaction_costs: { floor: 0, cap: 2, type: 'float', step: 0.5 },
      start_date: { type: 'date' },
      rebalancing_type: { type: 'list', options: REBALANCING_TYPES }
    };

    this.versionsViewsService.shiftSettings(shiftSettings);

    // values will be updated in the subscription below
    let values = {};
    if (this.simulation.scenario_type === 'transaction_costs') {
      values = {
        transaction_costs: {
          min: shiftSettings.transaction_costs.floor,
          max: shiftSettings.transaction_costs.cap,
          type: 'transaction_costs'
        }
      };
    }
    else if (this.simulation.scenario_type === 'rebalancing_frequency') {
      values = {
        rebalancing_type: {
          values: shiftSettings.rebalancing_type.options,
          type: 'rebalancing_type'
        }
      };
    }
    this.versionsViewsService.shiftValues(values);
  }

  hasError(form: ng.IFormController, fields: string[]) {
    return (form && formHasErrors(fields, form, this.errorMessages)) ||
      formHasErrors(fields, this.form);
  }
}
