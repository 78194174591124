import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';

import { CockpitsViewsService } from './service';

@Component({
  selector: 'page-cockpits-views',
  template: '<div app-view-segment="1"></div>'
})
export class CockpitsViewsPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    cockpitsViewsService: CockpitsViewsService,
    $location: ng.ILocationService
  ) {
    this.subscriptions.push(cockpitsViewsService.$cockpit().subscribe(cockpit => {
      if (!cockpit) {
        $location.path('/');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
