import { Component, Inject, OnDestroy, OnChanges, SimpleChanges, Input } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  ConstituentInstrument, Strategy, StrategyIssuerResource, StrategyGrouped, GroupField,
  LongShortType, ConstituentInstrumentNumber, defaultWeightDisplay, filterClosingDate, categoryAbsValue
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { SECTION_TYPES, LONG_SHORT_TYPES } from '../page';
import { COLORS as SANKEY_COLORS, translateData } from '../../../sankey';
import { COLORS } from '../../../../components/colors';
import { StrategiesViewsService } from '../../../views/service';
import { MyUserService } from '../../../../my-user';
import { isValid } from '../../../../my-user/policies/is-valid';

export const GROUPS: GroupField[] = [GroupField.country_code, GroupField.sector];

@Component({
  selector: 'page-strategies-show-holdings-equity',
  template
})
export class StrategiesShowHoldingsEquityPage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  public GROUPS = GROUPS;
  public SECTIONS = SECTION_TYPES;
  public LONG_SHORT_TYPES = LONG_SHORT_TYPES;
  public SANKEY_SOURCE_COLORS = COLORS;
  public SANKEY_TARGET_COLORS = SANKEY_COLORS;

  public selectedSection: string = GROUPS[0];
  public groupedData?: StrategyGrouped = undefined;
  public weightDisplay?: LongShortType = LongShortType.total;
  public closingDate?: Date;
  public totalEquity = 1;
  public longShort: 'long'|'short' = 'long';
  public displayStocks = false;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource,
    @Inject('MyUserService')
    private myUserService: MyUserService,
    private $translate: ng.translate.ITranslateService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.updateStrategy(strategy);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.updateStrategy(strategy);
    }
  }

  updateStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.strategy) {
      this.updateGroups(undefined);
      return;
    }

    this.weightDisplay = defaultWeightDisplay(!!this.strategy.is_long_short);
    this.totalEquity = categoryAbsValue(this.strategy, ConstituentInstrumentNumber.stock);
    this.updateDisplayStocks();

    return this.query();
  }

  async updateDisplayStocks() {
    const user = await this.myUserService.currentUser();
    this.displayStocks = user ? isValid(user.policies, `strategies/${this.strategy.id}/stocks`, 'show') : false;
  }

  query() {
    this.groupedData = undefined;

    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      'fields[]': this.GROUPS,
      instrument: ConstituentInstrument.stock,
      sankey_source: this.GROUPS[0],
      sankey_dest: this.GROUPS[1]
    }).$promise.then(
      response => this.updateGroups(response),
      () => this.updateGroups(undefined)
    );
  }

  updateGroups(groupedData?: StrategyGrouped) {
    this.groupedData = groupedData;
    this.closingDate = filterClosingDate(this.groupedData);

    if (!this.groupedData) {
      return;
    }

    this.groupedData.sankey = translateData(this.$translate, this.groupedData.sankey);
  }
}
