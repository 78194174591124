import { NgModule } from 'angular-ts-decorators';
import MessageResourceFactory from '@quantizr/front-model/dist/messages/resource';

import { MyUserModule } from '../my-user';
import { VendorModule } from '../vendor';

import { MessagesNewPage } from './new/page';

@NgModule({
  id: 'Messages',
  imports: [
    MyUserModule,
    VendorModule
  ],
  declarations: [
    MessagesNewPage
  ],
  providers: [
    {
      provide: 'MessageResource',
      useFactory: ($resource) => MessageResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class MessagesModule {}

export default (MessagesModule as NgModule).module!.name;
