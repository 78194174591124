import { Component, OnDestroy, Inject, OnInit } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  StrategyResource, StrategyVersionResource,
  Strategy, MyUser, Portfolio, StrategyBenchmark,
  categoryAbsValue, StrategyWeightKeys, ConstituentInstrumentNumber
} from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/strategies/variables';
import merge = require('lodash/merge');

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesBenchmarksService } from '../benchmarks/service';
import { typeFromStrategy, typeFromError, strategyType } from '../type';
import { StrategiesViewsService } from '../views/service';
import { ISelectorDatesSelected, DatesSelectorService } from '../../components';
import { JobModel, ACTIONS as JOB_ACTIONS } from '../../jobs';
import { MyUserService } from '../../my-user';
import { isValid } from '../../my-user/policies/is-valid';

const HOLDINGS_CATEGORIES = [
  ['equity', ConstituentInstrumentNumber.stock],
  ['fixedIncome', ConstituentInstrumentNumber.bond],
  ['derivative', ConstituentInstrumentNumber.derivative]
];

export const factorsEnabled = (strategy: any) => {
  return strategy.rsquared;
};

export const holdingsEnabled = (strategy: Strategy) => {
  return strategy.composition &&
    strategy.composition.closing_dates &&
    strategy.composition.closing_dates.length > 0;
};

@Component({
  selector: 'page-strategies-show',
  template
})
export class StrategiesShowPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private watchers: Function[] = [];
  private jobInit = false;
  private updateJob: JobModel|null = null;
  private selectedBenchmark: StrategyBenchmark|null = null;

  public user: MyUser|null = null;
  public views: any[] = [];
  public TYPE: strategyType|'' = '';
  public canReload = false;
  public errors: any = null;
  public saving = 1;
  public strategyId: number;
  public strategy: Strategy;
  public benchmarks: StrategyBenchmark[];
  public STATUSES = variables.STATUSES;

  // TODO: if only 1 dates selector can be active at a time,
  // refactor to use a service
  public timePeriod: ISelectorDatesSelected = {};

  /*@ngInject*/
  constructor(
    @Inject('JobsModel')
    private Job,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategyVersionResource')
    private strategyVersionResource: StrategyVersionResource,
    @Inject('StrategiesViewsService')
    private strategiesViewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    strategiesBenchmarksService: StrategiesBenchmarksService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('DatesSelectorService')
    datesSelectorService: DatesSelectorService,
    $routeParams: ng.route.IRouteParamsService,
    private $routeSegment: ng.routeSegment.IRouteSegmentService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private ngDialog: ng.dialog.IDialogService,
    $rootScope: ng.IRootScopeService
  ) {
    this.strategyId = $routeParams.id;

    this.subscriptions.push(strategiesViewsService.$views().subscribe(views => this.refreshViews(views)));
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      this.user = user;
    }));
    this.subscriptions.push(strategiesBenchmarksService.$benchmarks().subscribe(benchmarks => {
      this.benchmarks = benchmarks;
      this.toggleViews();
    }));
    this.subscriptions.push(strategiesBenchmarksService.$selected().subscribe(benchmark => {
      this.selectedBenchmark = benchmark;
    }));
    this.subscriptions.push(datesSelectorService.$dates().subscribe(dates => {
      this.timePeriod = dates;
    }));

    this.watchers.push($rootScope.$on('routeSegmentChange', () => {
      this.restrictAccessToCurrentView(strategiesViewsService.views());
      this.toggleViews();
    }));
  }

  ngOnInit() {
    this.refreshViews(this.strategiesViewsService.views());
    this.init();
  }

  ngOnDestroy() {
    this.stopJob();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.watchers.forEach(watcher => watcher());
    this.strategiesViewsService.destroy();
  }

  init() {
    return this.strategyResource.get({
      id: this.strategyId
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  loadSuccess(response: Strategy) {
    this.strategy = response;
    this.strategiesViewsService.strategy(response);
    this.saving = 0;

    if (response.type) {
      this.TYPE = typeFromStrategy(response);

      this.initJob();

      this.strategiesViewsService.setupViews({});
      this.toggleViews();
    }
  }

  /*
   * When we cannot load a strategy, display only the about page with a message rather than
   * redirecting to the home page.
   */
  loadError(error) {
    this.TYPE = typeFromError(error);

    // used in strategies/about.html
    this.$translate(
      'strategies.show.controller.load.error.' + (this.TYPE || '')
    ).then(title => {
      this.errors = {
        load: title
      };
    });

    // Load default routes
    this.strategiesViewsService.setupViews({});
    const views = this.strategiesViewsService.views();
    // Only enable About
    Object.keys(views).forEach(key => {
      if (key !== 'about') {
        views[key].enabled = false;
      }
    });
    this.refreshViews(views);
  }

  // === Job

  reload() {
    this.canReload = false;
    if (this.updateJob) {
      // stop any ongoing update requests
      this.updateJob.clear();
    }

    this.init();
  }

  initJob() {
    // Check if strategy needs to be refreshed
    if (!this.jobInit) {
      this.updateJob = new this.Job({
        id: this.strategyId,
        type: this.TYPE,
        action: JOB_ACTIONS.REFRESH_STATS,
        loading: {
          title: this.$translate.instant('strategies.show.controller.update.loading')
        },
        loaded: {
          title: this.$translate.instant('strategies.show.controller.update.loaded'),
          action: `<a class="underline"><i class="fas fa-sync"></i>&nbsp;<span>
            ${this.$translate.instant('strategies.show.controller.update.refresh')}
          </span></a>`,
          onClick: () => {
            this.reload();
          }
        }
      });

      this.subscriptions.push(this.updateJob!.finished().subscribe(finished => {
        this.canReload = finished;
      }));

      this.updateJob!.start();
    }
    // If reloading after already init, simple restart job to check for updates again
    else if (this.updateJob) {
      this.updateJob.restart();
    }

    this.jobInit = true;
  }

  stopJob() {
    if (this.updateJob !== null) {
      this.updateJob.destroy();
      this.updateJob = null;
    }
  }

  // *** Header

  pdfLink() {
    let params = 'v=1';
    if (this.selectedBenchmark !== null) {
      params += `&benchmarkId=${this.selectedBenchmark.id}`;
    }
    if (this.timePeriod.min) {
      params += `&date.min=${encodeURIComponent(this.timePeriod.min.toISOString())}`;
    }
    if (this.timePeriod.max) {
      params += `&date.max=${encodeURIComponent(this.timePeriod.max.toISOString())}`;
    }
    return `#!/strategy/${this.strategy.id}/pdf?${params}`;
  }

  edit() {
    if (this.TYPE === 'portfolio') {
      this.$location.path(`/strategy/${this.strategyId}/edit/calculation`);
    }
    else {
      this.$location.path(`/strategy/${this.strategyId}/edit`);
    }
  }

  contactCompany() {
    return this.ngDialog.open({
      template: '<page-messages-new></page-messages-new>',
      plain: true,
      className: 'ngdialog-theme-default ngdialog-theme-large',
      data: {
        message: {
          message_type: 'contact',
          subject: this.strategy.name,
          strategy_id: this.strategyId,
          strategy: this.strategy
        }
      }
    });
  }

  addToPortfolio() {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-strategies-new></page-strategies-strategies-new>',
      plain: true,
      data: {
        strategyIds: [this.strategyId]
      }
    });
  }

  addToCustomlist() {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-customlists-new></page-strategies-customlists-new>',
      plain: true,
      data: {
        strategyIds: [this.strategyId],
        ignoreStrategyListIds: (this.strategy.strategy_lists || []).map(customlist => {
          return customlist.id;
        })
      }
    }).then(() => {
      this.init();
    });
  }

  deleteStrategy() {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-delete-confirm></page-strategies-delete-confirm>',
      plain: true,
      className: 'ngdialog-theme-default',
      data: {
        strategy: this.strategy
      }
    }).then(() => {
      this.saving = 1;
      return this.strategyResource.remove({
        id: this.strategyId
      }).$promise.then(() => this.deleteStrategySuccess(), error => this.onError(error));
    });
  }

  deleteStrategySuccess() {
    this.saving = 0;

    this.$translate('strategies.show.controller.delete.success', {
      type: this.strategy.type
    }).then(title => {
      this.toaster.success(title);
    });

    return this.$location.path('/strategies/all');
  }

  editStrategy(strategy?: Strategy) {
    if (strategy == undefined) {
      strategy = this.strategy;
    }

    this.$location.path('/strategy/' + strategy.id + '/edit');
  }

  duplicateStrategy() {
    this.saving = 1;
    return this.strategyResource.duplicate({
      id: this.strategyId
    }).$promise.then(strategy => this.duplicateStrategySuccess(strategy), error => this.onError(error));
  }

  duplicateStrategySuccess(strategy) {
    this.saving = 0;
    this.editStrategy(strategy);

    this.$translate('strategies.show.controller.duplicate.success', {
      type: this.strategy.type
    }).then(title => {
      this.toaster.success(title);
    });
  }

  publishStrategy() {
    let $dialog;

    if (this.strategy.status === variables.STATUSES.DRAFT) {
      $dialog = this.ngDialog.openConfirm({
        template: '<page-strategies-publish-confirm></page-strategies-publish-confirm>',
        plain: true,
        className: 'ngdialog-theme-default',
        data: {
          strategy: this.strategy
        }
      });
    }
    else {
      $dialog = this.ngDialog.openConfirm({
        template: '<page-strategies-versions-publish-confirm></page-strategies-versions-publish-confirm>',
        plain: true,
        className: 'ngdialog-theme-default',
        data: {
          strategy: this.strategy
        }
      });
    }

    return $dialog.then(() => {
      this.saving = 1;
      this._publishStrategy();
    });
  }

  _publishStrategy() {
    return this.strategyVersionResource.publish({
      strategy_id: this.strategyId
    }).$promise.then(() => {
      this.publishStrategySuccess();
      this.reload();
    }, error => this.onError(error));
  }

  publishStrategySuccess() {
    this.saving = 0;

    this.$translate('strategies.show.controller.publish.success', {
      type: this.strategy.type
    }).then(title => {
      this.toaster.success(title);
    });
  }

  onError(response) {
    this.saving = 2;
    Object.keys(response.data).forEach(key => {
      this.toaster.error(key, response.data[key].join(', '));
    });
  }

  toggleViews() {
    const views = this.strategiesViewsService.views();
    if (!this.strategy || !this.benchmarks) {
      return;
    }
    const policies = this.user && this.user.policies ? this.user.policies : [];

    if (!factorsEnabled(this.strategy)) {
      views.factors.enabled = false;
    }

    if (!holdingsEnabled(this.strategy)) {
      views.holdings.enabled = false;
      views.esg.enabled = false;
    }
    else {
      views.holdings.subviews = HOLDINGS_CATEGORIES
        .map(keys => this.holdingSubview(keys[0], keys[1]))
        .filter(subview => subview !== null);
    }

    if (
      !isValid(policies, `strategies/${this.strategy.id}/issuers/esg`, 'show')
    ) {
      views.esg.enabled = false;
    }

    if (
      this.benchmarks.length === 0 ||
      !isValid(policies, `strategies/${this.strategy.id}/benchmarks`, 'index')
    ) {
      views.benchmark.enabled = false;
    }

    if (this.strategy.instrument === 'stock') {
      views.company.enabled = false;
      views.about.enabled = false;
    }

    const portfolio = this.strategy as Portfolio;
    if (portfolio.strategies) {
      views.strategies.subviews = (portfolio.strategies || []).map(value => {
        return this.strategiesSubview(value.id, value.name);
      });
    }
    else {
      views.weights.enabled = false;
      views.strategies.enabled = false;
    }

    Object.keys(views).forEach(key => {
      const view = views[key];
      view.open = this.$location.path().endsWith(key);

      (view.subviews || []).forEach(subview => {
        if (subview.active) {
          view.open = true;
        }
      });
    });

    this.strategiesViewsService.views(views);
  }

  holdingSubview(id: string, categoryValue) {
    if (
      categoryAbsValue(this.strategy, categoryValue, StrategyWeightKeys.long) > 0.01
    ) {
      const path = `/strategy/${this.strategyId}/holdings/${id}`;
      return {
        active: this.$location.path() === path,
        path,
        title: this.$translate.instant(`strategies.show.view.${id}`)
      };
    }
    return null;
  }

  strategiesSubview(id: number, title?: string) {
    const path = `/strategy/${this.strategyId}/strategies/${id}`;
    return {
      active: this.$location.path() === path,
      path,
      title,
      tooltip: title
    };
  }

  restrictAccessToCurrentView(views) {
    const activeViews = Object.keys(views).filter(id => {
      return this.$routeSegment.startsWith('strategies.show.' + id);
    });

    // check if current view should not be enabled
    // (user might enter url manually to try access restricted features)
    if (activeViews && activeViews.length === 1) {
      if (views[activeViews[0]] && !views[activeViews[0]].enabled) {
        // redirect user to about page
        this.$location.path('/strategy/' + this.strategyId + '/about');
      }
    }
  }

  refreshViews(views) {
    this.restrictAccessToCurrentView(views);

    // transform to array to sort
    this.views = Object.keys(views).map(id => {
      return merge({
        id
      }, views[id]);
    });
  }
}
