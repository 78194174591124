import { NgModule } from 'angular-ts-decorators';

import { CustomSearchRequestsService } from './service';

export {
  CustomSearchRequestsService
};
export const LOADING_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  PAGINATING: 'paginating'
};

@NgModule({
  id: 'CustomSearch',
  providers: [
    CustomSearchRequestsService
  ]
})
export class CustomSearchModule {}

export default (CustomSearchModule as NgModule).module!.name;
