import { Component, Inject } from 'angular-ts-decorators';
import './component.scss';
const template = require('./component.html.haml');

import { EnvService } from '../../env';

@Component({
  selector: 'panels-footer',
  template
})
export class PanelsFooterComponent {
  public today: Date = new Date();
  public CMS_URL: string;

  /*@ngInject*/
  constructor(
    @Inject('EnvService')
    envService: EnvService
  ) {
    this.CMS_URL = envService.apiUrl('www');
  }
}
