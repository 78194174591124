import { Directive, Inject, Input, OnChanges, SimpleChanges, OnDestroy, AfterViewInit } from 'angular-ts-decorators';
import { MyUser } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import { MyUserService } from '../../my-user/service';
import { IFilterService } from '../../components/location-starts-with/filter';

import { PanelsSidebarService } from './service';
import { DASHBOARDS_TREE, isInDashboardTree, dashboardSubSection } from './constants';

export abstract class PanelsSidebarChild implements OnDestroy, OnChanges, AfterViewInit {
  @Input('@')
  public dashboardKey: string;

  protected subscriptions: Subscription[] = [];
  public navigationOpen: boolean = true;
  public openedSection: string|undefined;
  public openedSubSection: string|undefined;
  public currentUser: MyUser|null = null;
  public dashboards: {};
  public dashboardId;
  public DASHBOARDS_TREE = DASHBOARDS_TREE;

  /*@ngInject*/
  constructor(
    @Inject('PanelsSidebarService')
    panelsSidebarService: PanelsSidebarService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    protected $routeParams: ng.route.IRouteParamsService,
    protected $location: ng.ILocationService,
    protected $filter: IFilterService,
    protected $routeSegment: ng.routeSegment.IRouteSegmentService,
    $scope?: ng.IScope
  ) {
    this.dashboardId = this.$routeParams.id;
    this.openedSubSection = dashboardSubSection(this.dashboardId);

    this.subscriptions.push(panelsSidebarService.$open().subscribe(open => {
      this.navigationOpen = open;
      // TODO: this is to use navigationOpen directly in pages, fix this
      if ($scope) {
        $scope.navigationOpen = open;
      }
    }));

    this.subscriptions.push(myUserService.$user().subscribe(user => {
      this.currentUser = user;
      return this.refresh();
    }));
  }

  ngAfterViewInit() {
    const sectionSubSection = this.$location.path().split('/', 3);
    this.openedSection = sectionSubSection[1];

    if (!this.openedSubSection) {
      this.openedSubSection = sectionSubSection[2];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('dashboardKey' in changes) {
      this.refresh();
    }
  }

  refresh() {
    this.dashboards = {};

    if (!this.currentUser || !this.currentUser.dashboards || !this.dashboardKey) {
      return ;
    }

    if (this.dashboardKey === 'other') {
      this.currentUser.dashboards.forEach(dashboard => {
        if (dashboard.id && !isInDashboardTree(dashboard.id)) {
          this.dashboards[dashboard.id] = [dashboard];
        }
      });
    } else {
      Object.keys(this.DASHBOARDS_TREE[this.dashboardKey]).forEach(section => {
        this.dashboards[section] = this.DASHBOARDS_TREE[this.dashboardKey][section].map(dashboardId => {
          return (this.currentUser as any).dashboards.find(e => e.id === dashboardId);
        }).filter(dashboard => !!dashboard);
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public goTo(path: string): void {
    this.$location.path(path);
  }

  public openSection(sectionValue: string) {
    this.openedSection = (this.openedSection === sectionValue) ? '' : sectionValue;
  }

  public openSubSection(subSectionValue: string) {
    this.openedSubSection = (this.openedSubSection === subSectionValue) ? '' : subSectionValue;
  }

  /**
   * @return {boolean} True if the current page location starts with `match`
   */
  public locationStartsWith(match: string): boolean {
    return this.$filter('locationStartsWith')(match);
  }

  public isLocation(path): boolean {
    return this.$location.path() === path;
  }

  public segmentStartsWith(segment: string): boolean {
    return this.$routeSegment.startsWith(segment);
  }
}

@Directive({
  selector: 'panels-sidebar-child'
})
export class PanelsSidebarChildDirective extends PanelsSidebarChild {}
