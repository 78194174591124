import { NgModule } from 'angular-ts-decorators';
import { VendorModule } from '../vendor';

import { LevelPermissionsEditorComponent } from './editor/component';

@NgModule({
  id: 'LevelPermissions',
  imports: [
    VendorModule
  ],
  declarations: [
    LevelPermissionsEditorComponent
  ]
})
export class LevelPermissionsModule {}

export default (LevelPermissionsModule as NgModule).module!.name;
