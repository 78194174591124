import { Component, Inject } from 'angular-ts-decorators';
import {
  StrategyUserPermissionResource,
  IStrategyUserPermission as BaseStrategyUserPermission
} from '@quantizr/front-model';
import { variables as userPermissions } from '@quantizr/front-model/dist/user-permissions/variables';

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesEditChildPage } from '../child';

export interface IStrategyUserPermission extends BaseStrategyUserPermission {
  email?: string;
}

@Component({
  selector: 'page-strategies-edit-permissions',
  template
})
export class StrategiesEditPermissionsPage extends StrategiesEditChildPage {
  public newUserPermissionError = {};
  public newUserPermission = {
    strategy_id: undefined,
    email: ''
  };
  public subpanel = 'levelPermisions';
  public STATUSES = userPermissions.STATUSES;

  /*@ngInject*/
  constructor(
    @Inject('StrategyUserPermissionResource')
    private strategyUserPermissionResource: StrategyUserPermissionResource,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $routeParams: ng.route.IRouteParamsService,
    $location: ng.ILocationService,
    ngDialog: ng.dialog.IDialogService,
    $timeout: ng.ITimeoutService,
    $element: ng.IAugmentedJQuery,
    $scope: ng.IScope
  ) {
    super($location, ngDialog, $timeout, $element, $scope);

    // TODO: get the id from this.strategy
    this.newUserPermission.strategy_id = $routeParams.id;
  }

  toggleSubpanel(subpanel) {
    if (this.subpanel === subpanel) {
      this.subpanel = '';
    }
    else {
      this.subpanel = subpanel;
    }
  }

  addUserPermission() {
    return this.strategyUserPermissionResource.save(
      this.newUserPermission
    ).$promise.then(response => {
      return this.addUserPermissionSuccess(response as IStrategyUserPermission);
    }, error => this.addUserPermissionError(error));
  }

  addUserPermissionSuccess(response: IStrategyUserPermission) {
    this.newUserPermission.email = '';
    this.newUserPermissionError = {};
    (this.strategy as any).user_permissions.push(response);

    this.forms.form.$submitted = false;

    this.$translate('strategies.settings.controller.permissions.add.success', {
      type: this.strategy.type,
      user: (response.user as any).email
    }).then(title => this.toaster.success(title));
  }

  addUserPermissionError(error: any) {
    this.newUserPermissionError = error.data;
  }

  updateUserPermission(userPermission: IStrategyUserPermission, status: string) {
    userPermission.status = status;
    userPermission.strategy_id = this.strategy.id;

    return this.strategyUserPermissionResource.update(userPermission).$promise.then(() => {
      this.updateUserPermissionSuccess();
    }, (error) => {
      userPermission.status = userPermissions.STATUSES.PENDING;
      this.updateUserPermissionError(error);
    });
  }

  updateUserPermissionSuccess() {
    this.$translate('strategies.settings.controller.permissions.update.success', {
      type: this.strategy.type
    }).then(title => this.toaster.success(title));
  }

  updateUserPermissionError(error: any) {
    Object.keys(error.data).forEach((key) => {
      this.toaster.error(key, error.data[key].join(', '));
    });
  }
}
