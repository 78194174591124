import { Component, Input, OnInit, OnDestroy, Inject } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import './component.scss';
const template = require('./component.html.haml');

import { firstDateOfYear, DatesSelector, ISelectorDatesSelected } from './model';
import { DatesSelectorService } from './service';

export interface ISelectorDates extends ISelectorDatesSelected {
  maxDate?: Date;
  minDate?: Date;
}

@Component({
  selector: 'dates-selector',
  template,
  transclude: {
    group: '?paneGroup'
  }
})
export class DatesSelectorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  dates: ISelectorDates;
  @Input()
  datesSelector: DatesSelector;

  public minDate: moment.Moment|undefined;
  public yearToDate: Date|undefined;
  public minDateCalOpen = false;
  public maxDateCalOpen = false;

  /*@ngInject*/
  constructor(
    @Inject('DatesSelectorService')
    private datesSelectorService: DatesSelectorService
  ) {}

  ngOnInit() {
    if (!this.datesSelector) {
      return;
    }

    // period (min to max) changed from data on the page
    this.subscriptions.push(this.datesSelector.$defaultPeriod().subscribe(dates => {
      this.dates.min = dates.min;
      this.dates.max = dates.max;
      this.yearToDate = firstDateOfYear(dates.max || new Date());
      if (this.dates.min && this.yearToDate < this.dates.min) {
        this.yearToDate = undefined;
      }

      // default period has changed, make sure user selection doesn't go beyond
      if (!this.dates.minDate || (dates.min && this.dates.minDate < dates.min)) {
        this.dates.minDate = dates.min;
      }
      if (!this.dates.maxDate || (dates.max && this.dates.maxDate > dates.max)) {
        this.dates.maxDate = dates.max;
      }

      this.refreshMinDate();
      this.refresh();
    }));

    // keep dates in sync if other component changes the dates
    this.subscriptions.push(this.datesSelector.$dates().subscribe((dates) => {
      this.dates.minDate = dates.min;
      this.dates.maxDate = dates.max;
      this.refreshMinDate();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refreshMinDate() {
    this.minDate = moment(this.dates.minDate);
  }

  /**
   * User select dates
   */
  refresh() {
    const dates = {
      min: this.dates.minDate,
      max: this.dates.maxDate
    };
    this.datesSelector.dates(dates);
    this.datesSelectorService.dates(dates);
  }

  setPeriod(date: Date) {
    this.dates.minDate = date;
    this.dates.maxDate = this.dates.max;
    this.refresh();
  }

  isSelectedMinDate(date: Date) {
    if (!this.minDate) {
      return false;
    }

    return this.minDate.isSame(date, 'day');
  }
}
