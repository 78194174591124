import { NgModule } from 'angular-ts-decorators';
import StrategyListResourceFactory from '@quantizr/front-model/dist/strategy-lists/resource';

import { ComponentsModule } from '../components';
import { MyUserModule } from '../my-user';
import { PanelsModule } from '../panels';
import { VendorModule } from '../vendor';

import { StrategyListsService } from './service';

import { CustomlistsSidebarFiltersComponent } from './sidebar-filters/component';

import { CustomlistsDeleteConfirmPage } from './delete/confirm/page';
import { CustomlistsEditPage } from './edit/page';
import { CustomlistsNewPage } from './new/page';

export {
  StrategyListsService
};

@NgModule({
  id: 'Customlists',
  imports: [
    ComponentsModule,
    MyUserModule,
    PanelsModule,
    VendorModule
  ],
  declarations: [
    CustomlistsSidebarFiltersComponent,
    CustomlistsDeleteConfirmPage,
    CustomlistsEditPage,
    CustomlistsNewPage
  ],
  providers: [
    StrategyListsService,
    {
      provide: 'StrategyListResource',
      useFactory: ($resource) => StrategyListResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class CustomlistsModule {}

export default (CustomlistsModule as NgModule).module!.name;
