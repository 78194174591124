import { Component, Input, SimpleChanges } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { IStrategyFactorDisplay } from '../page';

@Component({
  selector: 'strategies-show-factors-exposure',
  template
})
export class StrategiesShowFactorsExposureComponent {
  @Input()
  public strategy: Strategy;
  @Input()
  public factorsAnalysis: IStrategyFactorDisplay[];

  public annualisedAlpha: number;

  ngOnChanges(changes: SimpleChanges) {
    if ('factorsAnalysis' in changes) {
      const factorsAnalysis = changes.factorsAnalysis.currentValue;
      if (factorsAnalysis) {
        const values = factorsAnalysis.filter(value => value.factor_type === 'alpha');
        if (values && values.length) {
          this.annualisedAlpha = values[0].analysis.beta * 260.0 / 100;
        }
      }
    }
  }
}
