import { Injectable, Inject } from 'angular-ts-decorators';

import { IEnv } from '../env';

@Injectable('AnalyticsService')
export class AnalyticsService {
  /*@ngInject*/
  constructor(
    @Inject('ENV')
    private Config: IEnv,
    private $window: ng.IWindowService
  ) { }

  public visit() {
    if (this.Config.ENV === 'production') {
      this.$window.ga('send', 'pageview', this.$window.location.hash);
    }
  }
}
