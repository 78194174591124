import { Component, Input, OnInit, ViewParent } from 'angular-ts-decorators';
import { variables as simulations } from '@quantizr/front-model/dist/simulations/variables';

import './component.scss';
const template = require('./component.html.haml');

import { COLORS } from '../../../components/colors';
import { DialogController } from '../../../dialog/controller';
import { formHasErrors } from '../../../forms/has-errors';

@Component({
  selector: 'simulations-edit-windows',
  template
})
export class SimulationsEditWindowsComponent implements OnInit {
  @ViewParent('?^^pageSimulationsEditWindows')
  dialogController?: DialogController;

  @Input()
  windows: string[];
  @Input()
  categories: string[];
  @Input()
  category: string;

  public form: ng.IFormController;
  public unselected = simulations.SimulationIndicators.WINDOWS.slice();
  public sortableOptions = {
    connectWith: '.list-windows',
    cancel: '.unsortable',
    'ui-floating': false // set list as vertical
  };

  ngOnInit() {
    this.windows = this.windows || [];

    this.windows.forEach((window) => {
      const pos = this.unselected.indexOf(window);
      if (pos >= 0) {
        this.unselected.splice(pos, 1);
      }
    });
  }

  isSelected(window: string) {
    return this.windows.indexOf(window) >= 0;
  }

  unselect(window: string) {
    const pos = this.windows.indexOf(window);

    if (pos >= 0) {
      this.windows.splice(pos, 1);
      this.unselected.push(window);
    }
  }

  select(window: string) {
    const pos = this.unselected.indexOf(window);

    if (pos >= 0) {
      this.unselected.splice(pos, 1);
      this.windows.push(window);
    }
  }

  unsortable(element: string) {
    if (this.category === 'return_pa' || this.category === 'volatility') {
      if (element === '1_year') {
        return true;
      }
    }

    return false;
  }

  windowColor(window: string) {
    const pos = simulations.SimulationIndicators.WINDOWS.indexOf(window);
    return COLORS[pos];
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.form);
  }

  validate() {
    this.form.$submitted = true;
    if (this.form.$valid) {
      this.close({
        windows: this.windows,
        category: this.form.category ? this.form.category.$viewValue : null
      });
    }
  }

  close(data?: any) {
    if (this.dialogController) {
      this.dialogController.closeThisDialog(data);
    }
  }
}
