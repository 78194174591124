/**
 * Determines the brightness of a rgb color, in a number between 0 and 255
 * @param r Value between 0 and 255
 * @param g Value between 0 and 255
 * @param b Value between 0 and 255
 */
export const brightness = (r: number, g: number, b: number) => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

/**
 * Determnies wether a rgb color is brighter than a threshold color.
 * @param r Value between 0 and 255
 * @param g Value between 0 and 255
 * @param b Value between 0 and 255
 * @param x Value between 0 and 255
 */
export const brighterThan = (r: number, g: number, b: number, x: number) => {
  return brightness(r, g, b) > x;
};
