import { Component, OnDestroy, Inject, AfterViewInit } from 'angular-ts-decorators';
import {
  CockpitProfile, CockpitPortfolio, TimeSerieWindow,
  StrategyStrategyTimeSerieResource,
  StrategyStrategyTimeSerie, TimeSerieCategory,
  CockpitProfileStrategy,
  GroupedTimeSerie
} from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { CockpitsViewsService } from '../../views/service';
import {
  columnChartDataset, IColumnChartColumnData, IColumnChartLineData
} from '../../../components/column-chart/component';

interface IWindow {
  window: TimeSerieWindow;
  title: string;
}

export const windowDateFormat = (window: TimeSerieWindow) => {
  switch (window) {
    case TimeSerieWindow.one_year:
      return 'y';
    case TimeSerieWindow.one_month:
    case TimeSerieWindow.three_months:
    case TimeSerieWindow.six_months:
      return 'MMM-y';
    default:
      return 'd-MMM-y';
  }
};

export const strategyTimeSerie = (timeSeries: StrategyStrategyTimeSerie[], strategy: CockpitProfileStrategy) => {
  return (timeSeries || []).find(ts => ts.strategy_id === strategy.id);
};

export const strategyValues = (timeSeries: StrategyStrategyTimeSerie[], strategy: CockpitProfileStrategy) => {
  const timeSerie = strategyTimeSerie(timeSeries, strategy);
  return timeSerie ? timeSerie.data.map(value => value[1] || 0) : [];
};

export const groupValues = (children: any[], key = 'values') => {
  const values: number[] = [];
  if (children.length) {
    for (let i = 0; i < children[0][key].length; i++) {
      const value = children.reduce((prev, curr) => {
        const val = curr[key][i];
        if (typeof val === 'object') {
          if ('value' in val) {
            return prev + val.value;
          }
          if ('text' in val) {
            return NaN;
          }
        }
        return prev + val;
      }, 0);
      values.push(value);
    }
  }
  return values;
};

type section = 'table'|'chart';

@Component({
  selector: 'page-cockpits-perf-contribution-show',
  template
})
export class CockpitsPerfContributionShowPage implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public windows: IWindow[] = [];
  public window: IWindow;
  public sections: section[] = ['table', 'chart'];
  public generalSection: section = 'chart';
  public currencySection: section = 'chart';
  public profile: CockpitProfile|null = null;
  public portfolio: CockpitPortfolio|null = null;
  public timeSeries: StrategyStrategyTimeSerie[];

  public currencies: GroupedTimeSerie[] = [];
  public selectedCurrency: GroupedTimeSerie;
  public currencyChartDataset: columnChartDataset[] = [];
  public currencyChartSettings = {
    chart: {
      barGroupsPadding: 0,
      legend: {
        align: 'left',
        enabled: true,
        margin: 5
      },
      tooltip: {
        format() {
          return `${this.seriesName}: ${this.value.toFixed(2)}%`;
        }
      },
      xAxis: {
        enabled: true,
        labels: {
          format() {}
        }
      },
      yMinorGrid: {
        enabled: true
      },
      yScale: {
        stackMode: 'value'
      }
    }
  };

  public windowDateFormat = windowDateFormat;

  /*@ngInject*/
  constructor(
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    @Inject('StrategyStrategyTimeSerieResource')
    private strategyStrategyTimeSerieResource: StrategyStrategyTimeSerieResource,
    private ngDialog: ng.dialog.IDialogService,
    private $rootScope: ng.IRootScopeService,
    private $translate: ng.translate.ITranslateService,
    $filter: ng.IFilterService
  ) {
    this.windows = [
      TimeSerieWindow.one_week,
      TimeSerieWindow.one_month,
      TimeSerieWindow.three_months,
      TimeSerieWindow.one_year
    ].map(window => {
      return {
        window,
        title: $translate.instant(`timeSeries.windows.${window}`)
      };
    });
    this.window = this.windows[2];

    const ctrl = this;
    this.currencyChartSettings.chart.xAxis.labels.format = function() {
      return $filter('date')(this.value, windowDateFormat(ctrl.window.window));
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.cockpitsViewsService.$cockpitProfile().subscribe(profile => {
      this.profile = profile;
    }));
    this.subscriptions.push(this.cockpitsViewsService.$cockpitPortfolio().subscribe(portfolio => {
      this.changePortfolio(portfolio);
    }));
  }

  changePortfolio(portfolio: CockpitPortfolio|null) {
    this.portfolio = portfolio;

    if (portfolio !== null) {
      return Promise.all([
        this.loadTimeSeries(),
        this.loadCurrencies()
      ]);
    }
    return Promise.resolve();
  }

  updateWindow(window: IWindow) {
    this.window = window;
    return Promise.all([
      this.loadTimeSeries(),
      this.loadCurrencies()
    ]);
  }

  loadTimeSeries() {
    return this.strategyStrategyTimeSerieResource.queryBy({
      strategy_id: this.portfolio!.id,
      'category[]': [TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency],
      'window[]': [this.window.window]
    }).$promise.then(response => this.timeSeries = response);
  }

  loadCurrencies() {
    return this.strategyStrategyTimeSerieResource.groupBy({
      strategy_id: this.portfolio!.id,
      'category[]': [
        TimeSerieCategory.perf_contrib_perf_contributions_serie_local_currency,
        TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency
      ],
      'window[]': [this.window.window]
    }).$promise.then(response => {
      this.currencies = response;
      this.updateCurrenciesDataset();
    });
  }

  updateCurrenciesDataset() {
    const category = TimeSerieCategory.perf_contrib_perf_contributions_serie_portfolio_currency;
    let dates: string[] = [];
    if (this.currencies.length) {
      dates = this.currencies[0].categories[category]!.data.map(val => val[0]);
    }
    let minDate: Date|null = null;
    this.currencyChartDataset = [];

    this.currencies.forEach(currency => {
      const dataset: IColumnChartColumnData[] = [];
      if (dates.length) {
        for (let i = 0; i < dates.length; i++) {
          const date = dates[i];
          if (minDate) {
            minDate = new Date(Math.min(minDate.getTime(), new Date(date).getTime()));
          }
          else {
            minDate = new Date(date);
          }
          dataset.push({
            x: date,
            value: (currency.categories[category]!.data[i][1] || 0) * 100
          });
        }
      }
      this.currencyChartDataset.push({
        column: dataset,
        name: currency.currency
      });
    });

    // add sum
    if (dates) {
      const sum = groupValues(this.currencyChartDataset, 'column');
      const dataset: IColumnChartLineData[] = [];
      for (let i = 0; i < dates.length; i++) {
        dataset.push({
          x: dates[i],
          value: sum[i]
        });
      }
      this.currencyChartDataset.push({
        line: dataset,
        name: this.$translate.instant(`timeSeries.categories.${category}`),
        stroke: {
          color: '#e50400',
          thickness: 3
        },
        legend: {
          type: 'circle'
        }
      });
    }
  }

  displayFullTable(section: 'general'|'currencies' = 'general') {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.profile = this.profile;
    newScope.portfolio = this.portfolio;
    newScope.window = this.window.window;
    newScope.timeSeries = this.timeSeries;
    newScope.currencies = this.currencies;
    this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <cockpits-perf-contribution-${section}
          profile="profile" portfolio="portfolio" window="window"
          currencies="currencies" time-series="timeSeries"
        ></cockpits-perf-contribution-${section}>
      `,
      plain: true,
      scope: newScope
    });
  }
}
