import { Component, Inject } from 'angular-ts-decorators';
import { StrategyVersionResource } from '@quantizr/front-model';

const template = require('./page.html.haml');

import { StrategiesEditChildPage } from '../child';

@Component({
  selector: 'page-strategies-edit-calculation',
  template
})
export class StrategiesEditCalculationPage extends StrategiesEditChildPage {
  public subpanel: string = 'weights';

  /*@ngInject*/
  constructor(
    $location: ng.ILocationService,
    ngDialog: ng.dialog.IDialogService,
    $timeout: ng.ITimeoutService,
    $element: ng.IAugmentedJQuery,
    $scope: ng.IScope,
    @Inject('StrategyVersionResource')
    private strategyVersionResource: StrategyVersionResource
  ) {
    super($location, ngDialog, $timeout, $element, $scope);
  }

  saveStrategy() {
    if (this.strategy.id) {
      return this.strategyVersionResource.update(
        this.strategy.draft_version!
      ).$promise.then(response => this.saveSuccess(response), error => this.saveError(error));
    }
    else {
      (this.strategy as any).versions_attributes = [this.strategy.draft_version];
      return super.saveStrategy();
    }
  }
}
