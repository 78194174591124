import {
  CockpitProfileRiskContribution, CockpitProfilePcaMapping, CockpitProfileGroupedKeywords
} from '@quantizr/front-model';

export interface IContributionTable {
  children: { [id: number]: IContributionTable };
  weights: { [id: number]: number };
  absolute: { [id: number]: number };
  relative: { [id: number]: number };
  marginal: { [id: number]: number };
}

export const refreshGroupedTable = (
  rc: CockpitProfileRiskContribution, weights: CockpitProfilePcaMapping, portfolios: Array<{
    id: number;
  }>,
  group: CockpitProfileGroupedKeywords
) => {
  const values: IContributionTable = {
    children: {}, weights: {}, absolute: {}, relative: {}, marginal: {}
  };

  ((group as any).grouped_keywords || []).forEach(child => {
    values.children[child.id || 0] = refreshGroupedTable(rc, weights, portfolios, child);
  });

  (group.children || []).forEach(child => {
    values.children[child.id || 0] = refreshGroupedTable(rc, weights, portfolios, child);
  });

  (group.strategies || []).forEach(strategy => {
    values.children[strategy.id] = refreshStrategy(rc, weights, portfolios, strategy);
  });

  portfolios.forEach(portfolio => {
    ['weights', 'absolute', 'relative'].forEach(tableType => {
      values[tableType][portfolio.id] = 0;
    });

    Object.keys(values.children).forEach(childId => {
      ['weights', 'absolute', 'relative'].forEach(tableType => {
        if (values.children[childId][tableType][portfolio.id]) {
          values[tableType][portfolio.id] += values.children[childId][tableType][portfolio.id];
        }
      });
    });

    if (values.absolute[portfolio.id] && values.weights[portfolio.id] &&
      values.weights[portfolio.id] !== 0) {
      values.marginal[portfolio.id] =
        values.absolute[portfolio.id] / (values.weights[portfolio.id] * 100);
    }
  });

  return values;
};

export const refreshStrategy = (
  rc: CockpitProfileRiskContribution, weights: CockpitProfilePcaMapping,
  portfolios: any, strategy: any
) => {
  const values: IContributionTable = {
    children: {}, weights: {}, absolute: {}, relative: {}, marginal: {}
  };

  portfolios.forEach(portfolio => {
    refreshStrategyForPortfolio(rc, weights, portfolio, strategy, values);
  });

  return values;
};

export const refreshStrategyForPortfolio = (
  rc: CockpitProfileRiskContribution, weights: CockpitProfilePcaMapping,
  portfolio: any, strategy: any, values: IContributionTable
) => {
  if (rc.absolute && rc.absolute[portfolio.id] && !!rc.absolute[portfolio.id][strategy.id]) {
    values.absolute[portfolio.id] = rc.absolute[portfolio.id][strategy.id];
  }

  if (rc.relative && rc.relative[portfolio.id] && !!rc.relative[portfolio.id][strategy.id]) {
    values.relative[portfolio.id] = rc.relative[portfolio.id][strategy.id];
  }

  if (weights[portfolio.id]) {
    values.weights[portfolio.id] = weights[portfolio.id][strategy.id] || 0;
  }
  else {
    values.weights[portfolio.id] = 0;
  }

  if (values.weights[portfolio.id] !== 0) {
    values.marginal[portfolio.id] =
      values.absolute[portfolio.id] / (values.weights[portfolio.id] * 100);
  }
};
