import { ISankeyData } from '../components/sankey/component';

export const COLORS = [
  '#1b1b1b',
  '#373737',
  '#525353',
  '#6e6e6f',
  '#898a8a',
  '#a5a6a6',
  '#c0c1c2',
  '#dcddde'
];

export const translateFor = (
  $translate: ng.translate.ITranslateService, value: string, key: string
) => {
  if (key === 'country_code') {
    return $translate.instant(`countries.${value}.name`);
  }
  else if (key !== 'name') {
    return $translate.instant(`issuers.${key}.${value}`);
  }
  else {
    return value;
  }
};

export const translateData = (
  $translate: ng.translate.ITranslateService,
  data: ISankeyData, sourceKey: string = 'country_code', destKey: string = 'sector'
) => {
  const newSankeyData = {};
  if (data) {
    Object.keys(data).forEach(sourceValue => {
      const newSourceValue = translateFor($translate, sourceValue, sourceKey);
      newSankeyData[newSourceValue] = {};
      Object.keys(data[sourceValue]).forEach(targetValue => {
        const newTargetValue = translateFor($translate, targetValue, destKey);
        newSankeyData[newSourceValue][newTargetValue] = data[sourceValue][targetValue];
      });
    });
  }
  return newSankeyData;
};
