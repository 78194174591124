import { Component, Inject, OnDestroy, OnChanges, SimpleChanges, Input } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  Strategy, StrategyIssuerResource, StrategyGrouped, GroupField, IssuerType,
  filterClosingDate, defaultWeightDisplay, LongShortType
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { COLORS } from '../../../../components/colors';
import { StrategiesViewsService } from '../../../views/service';
import { SECTION_TYPES, LONG_SHORT_TYPES } from '../page';

export const GROUPS: GroupField[] = [
  GroupField.asset_class, GroupField.instrument, GroupField.currency
];
export const COUNTRY_GROUPS: GroupField[] = [
  GroupField.economical_zone, GroupField.country_code, GroupField.continent, GroupField.market_type
];

export const COUNTRY_ISSUER_TYPES: IssuerType[] = [
  IssuerType.corporate, IssuerType.sovereign, IssuerType.credit_basket,
  IssuerType.etf, IssuerType.fund, IssuerType.index, IssuerType.municipal,
  IssuerType.spv, IssuerType.supranational, IssuerType.uncategorized
];

@Component({
  selector: 'page-strategies-show-holdings-general',
  template
})
export class StrategiesShowHoldingsGeneralPage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  public GROUPS = GROUPS;
  public SECTIONS = SECTION_TYPES;
  public LONG_SHORT_TYPES = LONG_SHORT_TYPES;
  public SANKEY_SOURCE_COLORS = COLORS;

  public selectedSection: string = GROUPS[0];
  public groupedData: StrategyGrouped|undefined = undefined;
  public countryGroupedData: StrategyGrouped|undefined = undefined;
  public weightDisplay?: LongShortType = LongShortType.total;
  public closingDate?: Date = undefined;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.updateStrategy(strategy);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.updateStrategy(strategy);
    }
  }

  updateStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.strategy) {
      this.updateGroups(undefined);
      return;
    }

    this.weightDisplay = defaultWeightDisplay(!!this.strategy.is_long_short);

    return Promise.all([
      this.countryQuery(),
      this.query()
    ]);
  }

  query() {
    this.groupedData = undefined;

    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      'fields[]': this.GROUPS,
    }).$promise.then(
      response => this.updateGroups(response),
      () => this.updateGroups(undefined)
    );
  }

  countryQuery() {
    this.countryGroupedData = undefined;

    return this.strategyIssuerResource.grouped({
      strategy_id: this.strategy.id,
      'fields[]': COUNTRY_GROUPS,
      'issuer_type[]': COUNTRY_ISSUER_TYPES
    }).$promise.then(
      response => this.countryGroupedData = response,
      () => this.countryGroupedData = undefined
    );
  }

  updateGroups(groupedData?: StrategyGrouped) {
    this.groupedData = groupedData;
    this.closingDate = filterClosingDate(this.groupedData);
  }
}
