import {
  Strategy as BaseStrategy,
  StrategyVersionVersion as BaseStrategyVersionVersion,
  StrategyVersionVersionResource
} from '@quantizr/front-model';

import { COLORS } from '../../components/colors';
import { IStrategiesCorrelationMatrixSettings } from '../correlation-matrix/component';

export interface IStrategyVersionVersion extends BaseStrategyVersionVersion {
  strategy: IStrategy;
}

export interface IStrategy extends BaseStrategy {
  color?: string;
  strategies?: IStrategy[];
}

export interface IGroupedDataset {
  dataset: any[];
}

export const groups = ['asset_class', 'company_name', 'currency'];

export class StrategiesVersionsModel {
  private strategyVersions: IStrategyVersionVersion[];
  private _strategies: IStrategy[];

  constructor(
    private strategyVersionVersionResource: StrategyVersionVersionResource,
    private $translate: ng.translate.ITranslateService,
    private strategy: IStrategy
  ) {}

  init() {
    return this.strategyVersionVersionResource.query({
      strategy_id: this.strategy.id
    }).$promise.then(response => this.initSuccess(response));
  }

  initSuccess(response: IStrategyVersionVersion[]) {
    this.strategyVersions = response;
    this._strategies = [this.strategy, ...this.strategyVersions.map(version => {
      return version.strategy as IStrategy;
    })];

    for (let i = 0; i < this._strategies.length; i++) {
      this._strategies[i].color = COLORS[i];
    }

    this.strategyVersions.forEach(version => {
      this._strategies.forEach(strategy => {
        if (strategy.id === version.strategy.id) {
          version.strategy.color = strategy.color;
        }
      });
    });

    return response;
  }

  strategies() {
    return this._strategies;
  }

  colors(): IStrategiesCorrelationMatrixSettings {
    let position = 0;
    return this._strategies.reduce((previous, current) => {
      previous[current.id] = {position, color: current.color};
      position += 1;
      return previous;
    }, {});
  }

  groupedData(): {[group: string]: IGroupedDataset} {
    const groupedData: {
      [group: string]: IGroupedDataset
    } = {};

    groups.forEach(group => {
      groupedData[group] = this.groupDataFor(group);
    });

    return groupedData;
  }

  groupDataFor(group: string): IGroupedDataset {
    const data: any[] = [];
    const groupedData: {
      [group: string]: any;
    } = {};

    this.strategyVersions.forEach(version => {
      // create data tables on loaded data
      (this.strategy.strategies || []).forEach(strategy => {
        if (strategy.id === version.strategy.id) {
          let strategyGroup = strategy[group];

          if (group === 'asset_class') {
            strategyGroup = this.$translate.instant(`strategies.asset_classes.${strategyGroup}`);
          }
          else if (group === 'risk_factor') {
            strategyGroup = this.$translate.instant(`strategies.strategy_types.${strategyGroup}`);
          }

          if (!groupedData[strategyGroup]) {
            groupedData[strategyGroup] = 0;
          }

          groupedData[strategyGroup] += version.weight;
        }
      });
    });

    Object.keys(groupedData).forEach(x => {
      data.push({ x, value: groupedData[x] });
    });

    return {
      dataset: data
    };
  }
}
