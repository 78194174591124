import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { MyUserProviderConfig } from '@quantizr/front-model';

const template = require('./component.html.haml');

import { PanelsSidebarChild, PanelsSidebarService } from '../../panels';
import { IFilterService } from '../../components/location-starts-with/filter';
import { MyUserService } from '../../my-user';

@Component({
  selector: 'files-sidebar-filters',
  template
})

export class FilesSidebarFiltersComponent extends PanelsSidebarChild implements OnDestroy {
  private watchers: Function[] = [];

  public selectedProviderConfig: MyUserProviderConfig|undefined = undefined;

  /*@ngInject*/
  constructor(
    @Inject('PanelsSidebarService')
    panelsSidebarService: PanelsSidebarService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    $routeParams: ng.route.IRouteParamsService,
    $location: ng.ILocationService,
    $filter: IFilterService,
    $routeSegment: ng.routeSegment.IRouteSegmentService,
    $rootScope: ng.IRootScopeService
  ) {
    super(panelsSidebarService, myUserService, $routeParams, $location, $filter, $routeSegment);

    this.subscriptions.push(
      myUserService.$user().subscribe((user) => {
          this.currentUser = user;
          this.refreshSelectedProviderConfig();
        }
      )
    );
    this.watchers.push(
      $rootScope.$on('$routeChangeSuccess', () => this.refreshSelectedProviderConfig())
    );
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refreshSelectedProviderConfig() {
    if (!this.currentUser || !this.$routeParams.provider_config_id) {
      return;
    }

    this.selectedProviderConfig = this.currentUser!.provider_configs!.find(providerConfig => {
      return providerConfig.id.toString() === this.$routeParams.provider_config_id;
    });

    if (!this.selectedProviderConfig) {
      this.selectedProviderConfig = this.currentUser.provider_configs![0];
    }
  }
}
