import { NgModule } from 'angular-ts-decorators';
import ForeignExchangeRateResourceFactory from '@quantizr/front-model/dist/foreign-exchange-rates/resource';

import { VendorModule } from '../vendor';

@NgModule({
  id: 'ForeignExchangeRates',
  imports: [
    VendorModule
  ],
  providers: [{
    provide: 'ForeignExchangeRateResource',
    useFactory: ($resource) => ForeignExchangeRateResourceFactory($resource),
    deps: [
      '$resource'
    ]
  }]
})
export class ForeignExchangeRatesModule {}

export default (ForeignExchangeRatesModule as NgModule).module!.name;
