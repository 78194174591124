import { Component, OnDestroy, OnInit, Inject } from 'angular-ts-decorators';
import { variables } from '@quantizr/front-model/dist/strategies/variables';
import { StrategyResource, IStrategy, StrategyVersion } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { formHasErrors } from '../../forms/has-errors';
import { typeFromPath, strategiesType } from '../type';

export type tab = 'strategies'|'portfolios'|'fixings'|'filters'|'weights'|'fees';

export interface IStrategyNew extends IStrategy {
  versions_attributes?: StrategyVersion[];
}

@Component({
  selector: 'page-strategies-new',
  template
})
export class StrategiesNewPage implements OnInit, OnDestroy {
  private watchers: Function[] = [];

  public CURRENCIES = variables.CURRENCIES;
  public TABS: tab[] = [];
  public selectedTab: tab;
  public TYPE: strategiesType|null = null;
  public forms: any = {};
  public errorMessages: any = null;
  public saving = 0;
  public strategy: IStrategyNew|null = null;

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    private $location: ng.ILocationService,
    private ngDialog: ng.dialog.IDialogService,
    $rootScope: ng.IRootScopeService
  ) {
    this.watchers.push($rootScope.$on('$locationChangeStart', event => this.locationChangeStart(event)));

    this.watchers.push($rootScope.$on('$routeChangeSuccess', (_event, route) => {
      // Called when changing from strategies to portfolios
      if (route && route.$$route.segment.indexOf('strategies.new') !== -1) {
        this.init();
      }
    }));
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  init() {
    this.TYPE = typeFromPath(this.$location);
    this.errorMessages = {};
    this.strategy = null;
    this.saving = 0;

    const type = this.TYPE === 'strategies' ? 'Strategy' : 'Portfolio';
    return this.strategyResource.get({
      id: 'new',
      type
    }).$promise.then(response => this.loadSuccess(response as IStrategyNew));
  }

  loadSuccess(response: IStrategyNew) {
    this.strategy = response;

    this.forms = {};

    this.TABS = [];
    if (this.TYPE === 'strategies') {
      this.TABS = ['strategies', 'fixings'];
    }
    else if (this.TYPE === 'portfolios') {
      this.TABS = ['portfolios', 'filters', 'weights', 'fees'];
    }
    this.selectedTab = this.TABS[0];
  }

  /**
   * // TODO: update errors when we submit the form to avoid continuous digests
   * @param form
   * @param fields
   */
  hasError(form, fields) {
    if (typeof fields === 'undefined') {
      return (this.forms.form[form].$submitted && this.forms.form[form].$invalid) ||
        $('[name="' + form + '"]').find('.has-error').length;
    }

    return formHasErrors(fields, this.forms.form[form], this.errorMessages);
  }

  save() {
    this.forms.form.$submitted = true;
    if (this.forms.form.$valid === false) {
      return this.saveError({
        data: {}
      });
    }

    this.saving = 1;
    this.strategy!.versions_attributes = [this.strategy!.draft_version!];
    this.strategy!.$save().then(() => this.saveSuccess(), error => this.saveError(error));
  }

  saveSuccess() {
    this.saving = 0;
    this.errorMessages = null;
    this.forms.form.$dirty = false;

    this.$location.path('/strategy/' + this.strategy!.id);
  }

  saveError(errors) {
    this.saving = 2;
    this.errorMessages = errors.data;
  }

  locationChangeStart(event: any) {
    if (this.forms && this.forms.form) {
      if (this.forms.form.$dirty) {
        const next = this.$location.path();
        event.preventDefault();

        this.ngDialog.openConfirm({
          template: '<page-strategies-cancel-confirm></page-strategies-cancel-confirm>',
          plain: true,
          className: 'ngdialog-theme-default',
          data: {
            strategy: this.strategy
          }
        }).then(() => {
          this.forms.form.$dirty = false;
          this.$location.path(next);
        });
      }
    }

    return;
  }

  // === Tabs

  hasTab(tab: tab) {
    return this.TABS.indexOf(tab) !== -1;
  }

  isFirstTab(tab: tab) {
    const index = this.TABS.indexOf(tab);
    return index === 0;
  }

  isLastTab(tab: tab) {
    const index = this.TABS.indexOf(tab);
    return index === this.TABS.length - 1;
  }

  prevTab() {
    const index = this.TABS.indexOf(this.selectedTab);
    this.selectedTab = this.TABS[index - 1];
  }

  nextTab() {
    const index = this.TABS.indexOf(this.selectedTab);
    this.selectedTab = this.TABS[index + 1];
  }

  validateTab() {
    const form = this.forms.form[this.selectedTab + 'Form'] as ng.IFormController;
    form.$submitted = true;
    return form.$valid;
  }

  selectTab(tab: tab) {
    const form = this.forms.form[tab + 'Form'] as ng.IFormController;
    if (form.$submitted) {
      this.selectedTab = tab;
    }
    else {
      this.validateTab() && this.nextTab();
    }
  }
}
