import {
  Component, Input, Inject, OnChanges, SimpleChanges, OnDestroy, ViewParent
} from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  StrategyListResource, StrategyResource, StrategyUserPermissionResource, MyUser, Strategy
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { StrategiesIndexPage } from '../page';
import { FILTERS } from '../../';
import { TYPES, strategiesType } from '../../type';
import { EnvService } from '../../../env';
import { MyUserService } from '../../../my-user';

@Component({
  selector: 'strategies-index-multi-actions',
  template
})
export class StrategiesIndexMultiActionsComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private user: MyUser;

  @ViewParent('?^pageStrategiesIndex')
  indexPage: StrategiesIndexPage;

  @Input()
  selectedElements: number[];
  @Input()
  strategiesFilter: string;
  @Input()
  strategiesType: strategiesType;

  public FILTERS = FILTERS;
  public TYPES = TYPES;
  public downloadLink: string|null = null;

  /*@ngInject*/
  constructor(
    @Inject('StrategyListResource')
    private strategyListResource: StrategyListResource,
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategyUserPermissionResource')
    private strategyUserPermissionResource: StrategyUserPermissionResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('EnvService')
    private envService: EnvService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private ngDialog: ng.dialog.IDialogService
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      if (user) {
        this.user = user;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedElements' in changes) {
      this.refreshDownloadLink();
    }
  }

  goTo(path: string) {
    (this.$location as any).$$search = {};
    this.$location.path(path);
  }

  clearSelectedElements() {
    this.selectedElements.splice(0, this.selectedElements.length);
    this.indexPage.search();
  }

  comparator() {
    this.goTo(`/comparator/${this.selectedElements}`);
  }

  customlistId() {
    if (this.strategiesType === TYPES.CUSTOMLIST) {
      return parseInt(this.strategiesFilter, 10);
    }
    return null;
  }

  addToCustomlist() {
    const ignoreStrategyListIds: number[] = [];
    const customlistId = this.customlistId();
    if (customlistId !== null) {
      ignoreStrategyListIds.push(customlistId);
    }

    this.ngDialog.openConfirm({
      template: '<page-strategies-customlists-new></page-strategies-customlists-new>',
      plain: true,
      data: {
        strategyIds: this.selectedElements,
        ignoreStrategyListIds
      }
    }).then(() => this.clearSelectedElements());
  }

  addToPortfolio() {
    this.ngDialog.openConfirm({
      template: '<page-strategies-strategies-new></page-strategies-strategies-new>',
      plain: true,
      data: {
        strategyIds: this.selectedElements
      }
    }).then(() => this.clearSelectedElements());
  }

  onError(error = {}) {
    Object.keys(error).forEach(key => {
      this.toaster.error(key, error[key].join(', '));
    });
  }

  requestUserPermission(strategy: Strategy) {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-user-permissions></page-strategies-user-permissions>',
      plain: true,
      data: {
        strategy
      }
    }).then(() => {
      return this.requestUserPermissionConfirm(strategy);
    });
  }

  requestUserPermissionConfirm = (strategy) => {
    return this.strategyUserPermissionResource.save({
      strategy_id: strategy.id,
      user_id: this.user.id,
      status: 'pending'
    }, () => {
      return this.$translate('strategies.index.controller.request_permission.success')
        .then((title) => {
          this.toaster.success(title);
        });
    }, error => this.onError(error.data));
  }

  deleteStrategyCustomlists() {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-customlists-delete-confirm></page-strategies-customlists-delete-confirm>',
      plain: true
    }).then(() => {
      this.strategyListResource.updateAll({
        strategy_lists: [{
          id: this.customlistId()!,
          strategy_ids: this.selectedElements,
          _destroy: true
        }]
      }).$promise.then(response => {
        this.deleteStrategiesSuccess(response);
      }, error => this.onError(error.data));
    });
  }

  deleteStrategies() {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-delete-confirm></page-strategies-delete-confirm>',
      plain: true
    }).then(() => {
      this.strategyResource.removeAll({
        'ids[]': this.selectedElements
      }).$promise.then(response => {
        this.deleteStrategiesSuccess(response);
      }, error => this.onError(error.data));
    });
  }

  deleteStrategiesSuccess = (response) => {
    this.indexPage.search();

    let deleteType = 'multi';

    if (response.count === 0) {
      deleteType = 'zero';
    }
    else if (response.count === 1) {
      deleteType = 'one';
    }

    return this.$translate(`strategies.index.controller.delete.${deleteType}_success`, {
      count: response.count
    }).then(title => {
      return this.toaster.success(title);
    });
  }

  refreshDownloadLink() {
    this.downloadLink = null;
    (this.selectedElements || []).forEach(id => {
      if (this.downloadLink === null) {
        this.downloadLink = `${this.envService.apiUrl('api')}/fixings.csv?ids[]=${id}`;
      }
      else {
        this.downloadLink += `&ids[]=${id}`;
      }
    });
  }
}
