import { Directive, Inject, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';

import { isValid, PolicyType } from '../is-valid';
import { MyUserService } from '../../service';

// https://github.com/angular/angular.js/blob/master/src/ng/directive/ngShowHide.js
const NG_HIDE_CLASS = 'ng-hide';
const NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';

@Directive({
  selector: '[my-user-policies-show]',
  multiElement: true
})
export class MyUserPoliciesShowDirective implements OnChanges {
  @Input()
  myUserPoliciesShow: string;

  /*@ngInject*/
  constructor(
    private $element: ng.IAugmentedJQuery,
    private $animate,
    @Inject('MyUserService')
    private myUserService: MyUserService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('myUserPoliciesShow' in changes) {
      this.updateStyle();
    }
  }

  async updateStyle() {
    const [type, action] = this.myUserPoliciesShow.split('#');
    const user = await this.myUserService.currentUser();
    const accepted = user ? isValid(user.policies, type, action as PolicyType) : false;
    const method = accepted ? 'removeClass' : 'addClass';
    this.$animate[method](this.$element, NG_HIDE_CLASS, {
      tempClasses: NG_HIDE_IN_PROGRESS_CLASS
    });
  }
}
