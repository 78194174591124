import { UserPolicy } from '@quantizr/front-model';

export type PolicyType = 'index'|'new'|'show'|'edit'|'delete';

export const typeToMethod = (type: PolicyType) => {
  switch (type) {
    case 'index':
      return 'GET';
    case 'new':
      return 'POST';
    case 'show':
      return 'GET';
    case 'edit':
      return 'PUT';
    case 'delete':
      return 'DELETE';
  }
};

export const isValid = (policies: UserPolicy[] = [], path: string, action: PolicyType) => {
  const method = typeToMethod(action);
  for (const policy of policies) {
    const methodRegex = new RegExp(policy.policy_method);
    const pathRegexp = new RegExp(`^${policy.path}$`);
    const matchPath = pathRegexp.exec(`/${path}`) !== null;
    const matchMethod = methodRegex.exec(method) !== null;
    if (matchPath && matchMethod) {
      return true;
    }
  }

  return false;
};
