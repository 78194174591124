import { Component, OnInit, Inject, OnDestroy } from 'angular-ts-decorators';
import {
  CockpitAxisResource, CockpitAxis,
  CockpitStrategyResource, CockpitStrategy
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { formHasErrors } from '../../../forms/has-errors';
import { formOnDirty } from '../../../forms/on-dirty';
import { CockpitsViewsService } from '../../views/service';

export const strategyAxisModel = (strategy: CockpitStrategy, axis: CockpitAxis) => {
  let strategyAxis = strategy.strategy_axes!.find(value => value.axis_id === axis.id!);
  if (!strategyAxis) {
    strategyAxis = {
      axis_id: axis.id!
    };
    strategy.strategy_axes!.push(strategyAxis);
  }
  return strategyAxis;
};

@Component({
  selector: 'page-cockpits-edit-strategies',
  template
})
export class CockpitsEditStrategiesPage implements OnInit, OnDestroy {
  private watchers: Function[] = [];

  public form: ng.IFormController;
  public errorMessages: any = null;
  public saving = 0;
  public page = 0;
  public totalResults = 0;
  public strategies: CockpitStrategy[]|null = null;
  public axes: CockpitAxis[] = [];

  public PAGINATION_SIZE = 25;

  public strategyAxisModel = strategyAxisModel;

  /*@ngInject*/
  constructor(
    @Inject('CockpitStrategyResource')
    private cockpitStrategyResource: CockpitStrategyResource,
    @Inject('CockpitAxisResource')
    private cockpitAxisResource: CockpitAxisResource,
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    private $location: ng.ILocationService,
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    public ngDialog: ng.dialog.IDialogService,
    $rootScope: ng.IRootScopeService
  ) {
    this.watchers.push($rootScope.$on('$locationChangeStart', event => this.locationChangeStart(event)));
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  init() {
    this.strategies = null;
    this.saving = 0;

    return this.loadAxes().then(() => {
      this.loadStrategies();
    });
  }

  loadStrategies() {
    return this.cockpitStrategyResource.queryBy({
      page: this.page,
      per_page: this.PAGINATION_SIZE
    }).$promise.then(response => {
      this.strategies = response.strategies;
      if (response.total_results) {
        this.totalResults = response.total_results;
      }
    });
  }

  loadAxes() {
    return this.cockpitAxisResource.queryBy({}).$promise.then(response => this.axes = response.axes);
  }

  cancel() {
    formOnDirty(this.$q, this.form, () => {
      return this.cancelConfirm();
    }).then(() => {
      this.init();
    });
  }

  cancelConfirm() {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-cancel-confirm></page-cockpits-cancel-confirm>',
      plain: true,
      className: 'ngdialog-theme-default'
    });
  }

  canSave(): boolean {
    return this.form.$valid === true;
  }

  public async save() {
    this.form.$submitted = true;

    if (!this.canSave()) {
      return this.saveError({
        data: {}
      });
    }

    await this.saveStrategies();
  }

  saveStrategies() {
    this.saving = 1;

    return this.cockpitStrategyResource.updateAll({
      strategies: this.strategies!
    }).$promise.then(() => this.saveSuccess(), error => this.saveError(error));
  }

  saveSuccess() {
    this.errorMessages = null;
    this.form.$dirty = false;

    this.$translate('cockpits.edit.strategies.controller.saved').then(title => this.toaster.success(title));

    this.cockpitsViewsService.loadCockpit(() => {
      this.saving = 0;
      this.$location.path('/cockpit/monitoring');
    });
  }

  saveError(error: {data: any}) {
    this.errorMessages = error.data;
    this.saving = 2;
  }

  hasError(
    fields: string|string[],
    form: ng.IFormController = this.form,
    errors: any = this.errorMessages
  ) {
    return formHasErrors(fields, form, errors);
  }

  locationChangeStart(event) {
    const next = this.$location.path();
    return formOnDirty(this.$q, this.form, () => {
      event.preventDefault();
      return this.cancelConfirm();
    }).then(() => {
      this.$location.path(next);
    });
  }

  addAxis() {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-axes-new></page-cockpits-axes-new>',
      plain: true
    }).then(() => this.loadAxes());
  }

  editAxis(axis: CockpitAxis) {
    return this.ngDialog.openConfirm({
      template: '<page-cockpits-axes-edit></page-cockpits-axes-edit>',
      plain: true,
      data: axis
    }).then(() => this.loadAxes());
  }

  deleteAxis(axis: CockpitAxis) {
    return this.ngDialog.openConfirm({
      template:
        '<page-cockpits-axes-delete-confirm></page-cockpits-axes-delete-confirm>',
      plain: true,
      data: {axis}
    }).then(() => {
      return this.cockpitAxisResource.delete({
        id: axis.id
      }).$promise;
    }).then(() => this.loadAxes());
  }
}
