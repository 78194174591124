export const transformToNumber = (
  $filter: ng.IFilterService, input: any, category: string | boolean, hideNegative: boolean = true
): string => {
  if (typeof input === 'undefined' || input === null || isNaN(input)) {
    return '-';
  }
  // TODO: should be moved to the appropriate module and use constants
  const percent: boolean = category === true || category === 'return_pa' ||
    category === 'volatility' || category === 'cumulative_return';

  if (percent) {
    input = input * 100;
  }

  if (parseFloat(input) < 0 && hideNegative) {
    return '<0' + (percent ? '%' : '');
  }
  return $filter('number')(input, 2) + (percent ? '%' : '');
};
