import { Component, Inject } from 'angular-ts-decorators';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';

import './page.scss';
const template = require('./page.html.haml');

import { EnvService } from '../../env';
import { MyUserService } from '..';
import { formHasErrors } from '../../forms/has-errors';
import { ILoginMyUser } from '../service';
import {
  CLIENT_APPLICATION, ASSET_CLASS_LIST, INSTRUMENT_LIST, AREA_LIST, ABOUT_US_LIST
} from '../../config/devise';

const SIGNUP_REDIRECT_TIMEOUT = 3000;

@Component({
  selector: 'page-my-user-signup',
  template
})
export class MyUserSignupPage {
  public user: ILoginMyUser = {
    client: CLIENT_APPLICATION
  };
  public signupForm: ng.IFormController;
  public errorMessages: any = null;
  public saving = 0;
  public cmsUrl: string = '';
  public COUNTRY_CODES = settings.COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES = settings.SHORTLISTED_COUNTRY_CODES;
  public SHORTLISTED_COUNTRY_CODES_STRING = settings.SHORTLISTED_COUNTRY_CODES_STRING;
  public ASSET_CLASS_LIST: string[] = ASSET_CLASS_LIST;
  public INSTRUMENT_LIST: string[] = INSTRUMENT_LIST;
  public AREA_LIST: string[] = AREA_LIST;
  public ABOUT_US_LIST: string[] = ABOUT_US_LIST;

  /*@ngInject*/
  constructor(
    @Inject('EnvService')
    envService: EnvService,
    @Inject('MyUserService')
    private myUserService: MyUserService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private gRecaptcha: any
  ) {
    this.cmsUrl = envService.apiUrl('www');
  }

  signup() {
    this.saving = 1;

    return this.gRecaptcha.execute({ action: 'signup' }).then(token => this.signupAfterRecaptcha(token));
  }

  signupAfterRecaptcha(token) {
    this.user['token'] = { signup: token };
    return this.myUserService.register(this.user).then(() => {
      this.signupSuccess();
    }, error => {
      this.signupError(error);
    });
  }

  signupSuccess() {
    this.saving = 0;

    return this.$translate('myUser.signup.controller.signup.success').then((title) => {
      this.toaster.success(title);
      this.$timeout(() => {
        this.$window.location.href = '/';
      }, SIGNUP_REDIRECT_TIMEOUT);
    });
  }

  signupError(error) {
    this.saving = 2;
    this.errorMessages = error.data.errors;
  }

  hasError(fields: string|string[]) {
    return formHasErrors(fields, this.signupForm, this.errorMessages);
  }
}
