import { Component, Input, AfterViewInit } from 'angular-ts-decorators';
const template = require('./component.html.haml');

import { MONTHS } from '../../settings';

export const defaultMaxLines = 100;

// TODO: move to strategies module
@Component({
  selector: 'perf-month-to-month',
  template
})
export class PerfMonthToMonthComponent implements AfterViewInit {
  @Input()
  values: any;
  @Input()
  maxLines: number;

  public MONTHS = MONTHS;

  ngAfterViewInit() {
    if (!this.maxLines) {
      this.maxLines = defaultMaxLines;
    }
  }
}
