import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';

const template = require('./component.html.haml');

@Component({
  selector: 'form-errors',
  template
})
export class FormErrorsComponent implements OnChanges {
  @Input()
  private errors: any;
  @Input('@')
  public translateKey: string;

  public error: string = '';
  public keys: string[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('errors' in changes) {
      const errors = changes.errors.currentValue;
      this.updateError(errors);
    }
  }

  updateError(errors: any) {
    this.error = '';
    this.keys = [];
    if (!errors) {
      return;
    }

    if (Array.isArray(errors)) {
      this.keys = errors;
    }
    else if (typeof errors === 'object') {
      try {
        this.keys = Object.keys(errors);
      }
      catch (error) {
        this.keys = [];
      }
    }
    else if (typeof errors === 'string') {
      this.error = errors;
    }
  }

  value(key: string) {
    let errors = this.errors ? this.errors[key] : [];
    if (!Array.isArray(errors)) {
      errors = [errors];
    }
    return errors;
  }
}
