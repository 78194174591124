import { NgModule } from 'angular-ts-decorators';
import LinkResourceFactory from '@quantizr/front-model/dist/links/resource';

import { VendorModule } from '../vendor';

@NgModule({
  id: 'Links',
  imports: [
    VendorModule
  ],
  providers: [
    {
      provide: 'LinkResource',
      useFactory: ($resource) => LinkResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class LinksModule {}

export default (LinksModule as NgModule).module!.name;
