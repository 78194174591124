export const CLIENT_APPLICATION = 'app';
export const ASSET_CLASS_LIST = [
  'Equity', 'Fixed income', 'Multi asset', 'Commodity', 'Private equity', 'Real estate', 'Other'
];
export const INSTRUMENT_LIST = [
  'Single names', 'Derivatives', 'ETFs', 'Funds', 'Hedge Funds', 'Private Equity', 'Real Estate', 'Indices', 'Other'
];
export const AREA_LIST = [
  'Metrics and analytics', 'Risk factors', 'ESG and impact investing', 'Quantamental research',
  'Clustering', 'Other'
];
export const ABOUT_US_LIST = [
  'Colleague or friend', 'Search engines (Google,…)', 'Social media', 'Other'
];
