export const RED = '#f66a6e';
export const GREEN = '#66bd7d';

export const RED_TO_GREEN = [
  RED,
  '#f67670',
  '#fab57e',
  '#fcd285',
  '#fddd87',
  '#fde388',
  '#feea8a',
  '#f3e78a',
  '#ebe589',
  '#e2e288',
  '#d9df87',
  '#c2d986',
  GREEN
];
