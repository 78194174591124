import { Component, Inject, Input, AfterViewInit } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-map.min';
import 'anychart/dist/geodata/custom/world/world';

import './component.scss';
const template = require('./component.html.haml');

import { BaseChartComponent, tooltipStyle } from '../base-chart/component';
import { BaseChartService } from '../base-chart/service';
import { statisticToPercent } from '../statistics';
import { capitalizeFirstLetter } from '../../app/uib-date-parser';

export const COLORS = [
  '#9e0303', // red for negative values
  '#014377', // blue for positive values
  '#59bf9a' // fill color when hover
];

const scaleSteps = [0, 0.01, 0.025, 0.05, 0.1, 0.25, 1];
const maxStep = scaleSteps[scaleSteps.length - 1];

const scaleSections = () => {
  const sections: Array<{less?: number; greater?: number; from?: number; to?: number}> = [];
  // add below min value to infinity
  sections.push({
    less: maxStep
  });
  // add below 0 inverse scale
  for (let i = scaleSteps.length - 1; i > 0; i--) {
    sections.push({
      from: -scaleSteps[i],
      to: -scaleSteps[i - 1]
    });
  }
  // add above 0
  for (let i = 0; i < scaleSteps.length - 1; i++) {
    sections.push({
      from: scaleSteps[i],
      to: scaleSteps[i + 1]
    });
  }
  // add above max value to infinity
  sections.push({
    greater: maxStep
  });
  return sections;
};

export const colorLabelFormat = (range: {start: number, end: number}) => {
  if (range.end === Number.POSITIVE_INFINITY) {
    return `> ${maxStep * 100}`;
  }
  if (range.start === Number.NEGATIVE_INFINITY) {
    return `< ${maxStep * 100}`;
  }
  return `${range.start * 100} - ${range.end * 100}`;
};

@Component({
  selector: 'map-chart',
  template
})
export class MapChartComponent extends BaseChartComponent<anychart.charts.Map> implements AfterViewInit {
  @Input('@')
  private mappedProperty: string;
  @Input()
  private enableZoom = true;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService
  ) {
    super(baseChartService);
    this.initChart();
  }

  initChart() {
    this.chart = anychart.map();

    this.chart.geoData('anychart.maps.world');
    this.chart.interactivity().selectionMode('none');
    this.chart.padding(0);
    this.chart.credits().enabled(false);
    this.chart.tooltip().background().fill(tooltipStyle.background.fill).stroke(tooltipStyle.background.stroke);
    this.chart.tooltip().padding(tooltipStyle.padding).enabled(true);
    const tooltipPrefix = this.mappedProperty ? `${capitalizeFirstLetter(this.mappedProperty)}: ` : '';
    this.chart.tooltip().format(function() {
      return `${tooltipPrefix}${statisticToPercent(this.value)}`;
    });
  }

  ngAfterViewInit() {
    if (this.enableZoom) {
      // create zoom controls
      const zoomController = anychart.ui.zoom();
      zoomController.render(this.chart);
    }
  }

  refresh() {
    if (!this.chart) {
      return;
    }

    this.chart.removeAllSeries();

    const scale = this.colorScale();
    const dataSet = anychart.data.set(this.dataset);
    const series = this.chart.choropleth(dataSet.mapAs({
      value: this.mappedProperty
    }));

    series.labels(false);
    series.hovered().fill(COLORS[2]).stroke(anychart.color.darken(COLORS[2]) as any);
    series.colorScale(scale);

    const colorRange = this.chart.colorRange();
    colorRange.labels().fontSize(11).padding(3, 0, 0, 0);
    colorRange.labels().format(function() {
      return colorLabelFormat(this.colorRange);
    });
    colorRange.enabled(true).padding([0, 0, 20, 0]);
    colorRange.ticks().enabled(true).stroke('3 #ffffff').position('center').length(7);
    colorRange.colorLineSize(5);
    colorRange.marker().size(7);

    this.onRefreshedDone();
  }

  colorScale() {
    const sections = scaleSections();
    const scale = anychart.scales.ordinalColor(sections);
    const colors = anychart.color.bipolarHueProgression(COLORS[0], COLORS[1], sections.length);
    scale.colors(colors);
    return scale;
  }
}
