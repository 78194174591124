import { Component, Input, Inject, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-scatter.min';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { ScatterChartService } from './service';
import { BaseChartService } from '../../components';
import { BaseChartZoomComponent } from '../base-chart/zoom/component';

export type AxisType = ''|'date'|'float'|'integer';

export const axisLabel = (value: any, valueType: AxisType): string => {
  if (typeof value === 'object') {
    value = value.tickValue;
  }

  switch (valueType) {
    case 'date':
      const date = new Date(value);
      return date.toLocaleDateString('en-au', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    case 'float':
      return value.toFixed(2);
    case 'integer':
     return value.toFixed(0);
   default:
     return value;
  }
};

@Component({
  selector: 'scatter-chart',
  template
})
export class ScatterChartComponent extends BaseChartZoomComponent<anychart.charts.Scatter> implements OnChanges {
  @Input()
  public axisTitle = '';
  @Input()
  public yAxisTitle = '';
  @Input()
  public axisLabel: (value: string) => string = value => value
  @Input()
  public yAxisLabel: (value: string) => string = value => value
  @Input()
  public bubble = false;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService,
    @Inject('ScatterChartService')
    private service: ScatterChartService,
    $window: ng.IWindowService
  ) {
    super(baseChartService, $window);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('settings' in changes) {
      this.removeChart();
      this.initChart();
      this.drawChart();
    }

    super.ngOnChanges(changes);
  }

  public initChart() {
    const settings = merge({
      chart: {
        type: 'scatter',
        credits: {
          text: 'Source: Quantilia',
          url: '',
          logoSrc: '',
          alt: 'Source: Quantilia'
        },
        xGrids: [{
          enabled: true
        }],
        yGrids: [{
          enabled: true
        }],
        xMinorGrids: [{
          enabled: true
        }],
        yMinorGrids: [{
          enabled: true
        }]
      }
    }, this.settings || {});

    this.chart = anychart.fromJson(settings);
    this.chart.listen('pointClick', event => this.pointClick(event));
  }

  pointClick(e) {
    const index = e.iterator.getIndex();
    this.service.selectPoint({
      id: this.dataset[index].id
    });
  }

  public refresh() {
    if (!this.chart) {
      return;
    }

    this.chart.removeAllSeries();

    if (this.bubble) {
      this.chart.bubble(this.dataset);
      this.chart.minBubbleSize(1);
      this.chart.maxBubbleSize(20);
    }
    else {
      this.chart.addSeries(this.dataset);
    }

    const yScale = this.chart.yScale();
    const settings = this.settings || {};
    if ((yScale.maximum() - yScale.minimum()) < 4) {
      const min = yScale.minimum();
      const max = yScale.maximum();
      const diff = (4 - max + min) / 2;

      if (settings.yScale && !settings.yScale.minimum) {
        yScale.minimum(min - diff);
      }
      if (settings.yScale && !settings.yScale.maximum) {
        yScale.maximum(max + diff);
      }
    }

    const ctrl = this;
    const xTitle = this.chart.xAxis().title();
    xTitle.enabled(true);
    xTitle.text(this.axisTitle);
    this.chart.xAxis().labels().format(function() {
      return ctrl.axisLabel(this.value);
    });

    const yTitle = this.chart.yAxis().title();
    yTitle.enabled(true);
    yTitle.text(this.yAxisTitle);
    this.chart.yAxis().labels().format(function() {
      return ctrl.yAxisLabel(this.value);
    });

    this.onRefreshedDone();
  }
}
