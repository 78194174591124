import { Component, Input, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy, StrategyBenchmark } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { dateAtMidnight, ISelectorDatesSelected, DatesSelector } from '../../../components';
import { COLORS } from '../../../components/colors';
import { StrategiesBenchmarksService } from '../../benchmarks/service';
import { StrategiesViewsService } from '../../views/service';

export interface IStrategy extends Strategy {
  color: string;
}

@Component({
  selector: 'page-strategies-show-performance',
  template
})
export class StrategiesShowPerformancePage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: IStrategy|null = null;

  public datesSelector: DatesSelector;
  public dates: ISelectorDatesSelected = {};
  public benchmark: StrategyBenchmark;
  public benchmarks: StrategyBenchmark[] = [];
  public section = 'fixings';

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    private viewsService: StrategiesViewsService,
    @Inject('StrategiesBenchmarksService')
    private benchmarksService: StrategiesBenchmarksService,
    private $timeout: ng.ITimeoutService
  ) {
    this.datesSelector = new DatesSelector();
  }

  ngOnInit() {
    // from @Input()
    if (this.strategy) {
      this.init();
    }
    // no @Input(), load from views
    else {
      this.subscriptions.push(this.viewsService.$strategy().subscribe(strategy => {
        this.strategy = strategy as IStrategy;
        this.strategy && this.init();
      }));
    }

    this.subscriptions.push(this.benchmarksService.$benchmarks().subscribe(benchmarks => {
      this.benchmarks = benchmarks;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  init() {
    // Set Date for selector
    if (this.strategy!.start_date) {
      this.dates.min = dateAtMidnight(this.strategy!.start_date);
    }

    if (this.strategy!.end_date) {
      this.dates.max = dateAtMidnight(this.strategy!.end_date);
    }

    this.$timeout(() => {
      this.datesSelector.defaultPeriod(this.dates.max, this.dates.min);
      this.datesSelector.dates({
        max: this.dates.max
      });
    });

    // Fixings & volatility settings
    this.strategy!.color = COLORS[0];
  }

  changeSection(section) {
    this.section = section;
  }

  onSelectedBenchmark(benchmark) {
    this.benchmark = benchmark;
    this.benchmarksService.select(benchmark);
  }
}
