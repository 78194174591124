import { Component, Input } from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';

const template = require('./component.html.haml');

@Component({
  selector: 'strategies-pdf-header',
  template
})
export class StrategiesPdfHeaderComponent {
  @Input()
  strategy: Strategy;

  public today = new Date();

  logoUrl() {
    if (!this.strategy || !this.strategy.company) {
      return '';
    }

    if (this.strategy.company.pdf_logo && this.strategy.company.pdf_logo.name) {
      return this.strategy.company.pdf_logo.url;
    }

    return this.strategy.company.logo!.url;
  }
}
