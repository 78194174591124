import {
  Component, Input, AfterViewInit, OnDestroy, ViewChild, SimpleChanges, OnChanges, Inject
} from 'angular-ts-decorators';
import {
  StrategyStock, StrategyStockResource,
  TimeSerieCategory, TimeSerieWindow
} from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { statisticToPercent } from '../../../../components';
import { ScatterChartComponent } from '../../../../components/scatter-chart/component';
import { tooltipStyle } from '../../../../components/base-chart/component';

interface IAxisDefinition {
  category: TimeSerieCategory;
  window: TimeSerieWindow;
  title: string;
}

const axisDefinition = (
  $translate: ng.translate.ITranslateService,
  category: TimeSerieCategory,
  window: TimeSerieWindow
) => {
  const categoryTitle = $translate.instant(`timeSeries.categories.${category}`);
  const windowTitle = $translate.instant(`timeSeries.windowsShort.${window}`);
  return {
    category,
    window,
    title: `${categoryTitle} ${windowTitle}`
  };
};

@Component({
  selector: 'strategies-show-holdings-x-y-chart',
  template
})
export class StrategiesShowHoldingsXYChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(ScatterChartComponent)
  public chart: ScatterChartComponent;

  @Input()
  strategyId: number;
  @Input()
  public longShort?: 'long'|'short' = 'long';

  private chartTimeout: ng.IPromise<any>;
  public containerId = 'strategies-show-holdings-x-y-chart';

  public xElements: IAxisDefinition[] = [];
  public xElement: IAxisDefinition;
  public yElements: IAxisDefinition[] = [];
  public yElement: IAxisDefinition;
  public settings: {
    chart: any
  } = {
    chart: {}
  };

  /*@ngInject*/
  constructor(
    @Inject('StrategyStockResource')
    private strategyStockResource: StrategyStockResource,
    private $timeout: ng.ITimeoutService,
    $translate: ng.translate.ITranslateService
  ) {
    this.xElements = [
      TimeSerieWindow.daily,
      TimeSerieWindow.three_months,
      TimeSerieWindow.one_year
    ].map(window => axisDefinition($translate, TimeSerieCategory.cumulative_return, window));
    this.xElement = this.xElements[2];

    this.yElements = [
      TimeSerieWindow.three_months,
      TimeSerieWindow.six_months,
      TimeSerieWindow.one_year,
      TimeSerieWindow.three_years
    ].map(window => axisDefinition($translate, TimeSerieCategory.volatility, window));
    this.yElement = this.yElements[2];
  }

  ngOnDestroy() {
    this.$timeout.cancel(this.chartTimeout);
  }

  ngAfterViewInit() {
    const ctrl = this;
    this.settings = {
      chart: {
        tooltip: {
          useHtml: true,
          titleFormat() {
            const name = this.getData('name');
            const x = this.getData('x');
            const value = this.getData('value');
            return ctrl.tooltipTitleFormat(name, x, value);
          },
          format() {
            const size = this.getData('size');
            return `weight: ${statisticToPercent(size)}`;
          },
          padding: tooltipStyle.padding,
          background: tooltipStyle.background
        }
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategyId' in changes || 'longShort' in changes) {
      this.refresh();
    }
  }

  refresh() {
    return this.strategyStockResource.queryBy({
      strategy_id: this.strategyId,
      fixings: {
        [this.xElement.category]: [this.xElement.window],
        [this.yElement.category]: [this.yElement.window]
      } as any,
      long_short: this.longShort
    }).$promise.then(response => this.refreshGraph(response));
  }

  refreshGraph(stocks: StrategyStock[]) {
    const dataset = stocks.map(strategy => {
      try {
        const x = strategy.fixings[this.xElement.category][this.xElement.window].value;
        const y = strategy.fixings[this.yElement.category][this.yElement.window].value;
        return {
          x,
          value: y,
          size: strategy.weight,
          name: strategy.name
        };
      }
      catch (err) {
        return null;
      }
    }).filter(val => val !== null);

    this.chartTimeout = this.$timeout(() => {
      this.chart.dataset = dataset;
      this.chart.refresh();
    });
  }

  axisLabel(value) {
    return statisticToPercent(value, 0);
  }

  tooltipTitleFormat(name: string, x: any, value: any) {
    const xLabel = `${this.xElement.title}: ${statisticToPercent(x)}`;
    const yLabel = `${this.yElement.title}: ${statisticToPercent(value)}`;
    return `${name}\n${xLabel} VS. ${yLabel}`;
  }
}
