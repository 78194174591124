import { Component, Inject, AfterViewInit } from 'angular-ts-decorators';
import { variables as Settings } from '@quantizr/front-model/dist/settings/variables';
import { StrategyList, StrategyListResource } from '@quantizr/front-model';
const template = require('./component.html.haml');

import { StrategyListsService } from '../service';
import { PanelsSidebarService, PanelsSidebarChild } from '../../panels';
import { MyUserService } from '../../my-user';

@Component({
  selector: 'customlists-sidebar-filters',
  template
})
export class CustomlistsSidebarFiltersComponent extends PanelsSidebarChild implements AfterViewInit {
  public strategyLists: StrategyList[]|null = [];
  public membership: any = Settings.MEMBERSHIP.defaults;

  /*@ngInject*/
  constructor(
    @Inject('StrategyListsService')
    private strategyListsService: StrategyListsService,
    @Inject('StrategyListResource')
    private customlistResource: StrategyListResource,
    @Inject('PanelsSidebarService')
    panelsSidebarService: PanelsSidebarService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    private ngDialog: ng.dialog.IDialogService,
    private toaster: toaster.IToasterService,
    $routeParams: ng.route.IRouteParamsService,
    $location: ng.ILocationService,
    $filter: ng.IFilterService,
    $routeSegment: ng.routeSegment.IRouteSegmentService
  ) {
    super(panelsSidebarService, myUserService, $routeParams, $location, $filter, $routeSegment);

    this.subscriptions.push(strategyListsService.$strategyLists().subscribe(strategyLists => {
      this.strategyLists = strategyLists;
    }));
  }

  newCustomlist() {
    this.ngDialog.openConfirm({
      template: '<page-customlists-new></page-customlists-new>',
      plain: true
    }).then(() => {
      this.openedSection = 'customlists';
    });
  }

  editCustomlist(strategylist: StrategyList) {
    this.ngDialog.openConfirm({
      template: '<page-customlists-edit></page-customlists-edit>',
      plain: true,
      data: strategylist
    });
  }

  deleteCustomlist(strategylist: StrategyList) {
    this.ngDialog.openConfirm({
      template: '<page-customlists-delete-confirm></page-customlists-delete-confirm>',
      plain: true,
      className: 'ngdialog-theme-default',
      data: strategylist
    }).then(() => {
      return this.customlistResource.remove({
        id: strategylist.id
      }, response => this.deleteCustomlistSuccess(response), error => this.onError(error));
    });
  }

  deleteCustomlistSuccess(customlist: StrategyList) {
    this.strategyListsService.reset();
    if (this.locationStartsWith('/strategyLists/' + customlist.id)) {
      this.goTo('/strategies/all');
    }
  }

  onError(error) {
    Object.keys(error.data).forEach(key => {
      this.toaster.error(key, error.data[key].join(', '));
    });
  }
}
