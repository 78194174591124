import { Component, Inject, Input, OnInit, OnDestroy } from 'angular-ts-decorators';
import { Strategy, StrategyEtfStaticData, StrategyEtfStaticDataResource } from '@quantizr/front-model';
import { variables as versions } from '@quantizr/front-model/dist/versions/variables';
import { Subscription } from 'rxjs';

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesViewsService } from '../../views/service';

@Component({
  selector: 'page-strategies-show-about',
  template
})
export class StrategiesShowAboutPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy|null = null;
  public etfStaticData: StrategyEtfStaticData;
  public displayEtfStaticData: boolean = false;
  public REBALANCING_TYPES = versions.REBALANCING_TYPES;
  public FUND_FIELDS = {
    fund: {
      OFST005010: 'string', OFST010500: 'boolean', OFST020560: 'date', OFST010410: 'string',
      OFST010210: 'string', OFST023100: 'string', OFST020400: 'string', OFST011000: 'boolean'
    },
    listing: {
      OFST062040: 'string', OFST010010: 'country_code', OFST062010: 'string', OFST062000: 'date'
    },
    stakeholders: {
      OFST001300: 'string', OFST001500: 'string', OFST001100: 'string', OFST002000: 'string',
      OFST001600: 'string', OFST001900: 'string', OFST011010: 'string', OFST011110: 'string'
    },
    fees: {
      OFST452000: 'percent', OFST452110: 'date', OFST452120: 'percent', OFST452130: 'date',
      OFST452100: 'percent'
    },
    legal: {
      OFST160100: 'string', OFST6030XX: 'string[]', OFST6031XX: 'string[]', OFST6050XX: 'string[]',
      OFST800050: 'string', OFST802000: 'string', OFST801011: 'boolean', OFST808008: 'boolean',
      OFST809015: 'boolean'
    }
  };

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    private strategiesViewsService: StrategiesViewsService,
    @Inject('Strategies.EtfStaticData')
    private strategiesEtfStaticData: StrategyEtfStaticDataResource,
    private $filter: ng.IFilterService,
    private $translate: ng.translate.ITranslateService,
  ) {}

  ngOnInit() {
    // no @Input(), load from views
    if (!this.strategy) {
      this.subscriptions.push(this.strategiesViewsService.$strategy().subscribe(strategy => {
        this.strategy = strategy as Strategy;
        this.refreshEtfStaticData();
      }));
    }
  }

  refreshEtfStaticData() {
    this.displayEtfStaticData = false;
    if (this.strategy === null) {
      return;
    }

    this.strategiesEtfStaticData.get({
      strategy_id: this.strategy.id
    }).$promise.then(
      response => {
        this.etfStaticData = response;
        this.displayEtfStaticData = Object.keys(this.etfStaticData.open_fund_fields).length > 0;
      },
      _error => {
        this.etfStaticData = {};
        this.displayEtfStaticData = false;
      }
    );
  }

  openFundField(key: string, fieldKey: string) {
    const fieldType = this.FUND_FIELDS[key][fieldKey];
    const value = this.etfStaticData.open_fund_fields[fieldKey];

    if (fieldType === 'string[]') {
      const filteredKeys = Object.keys(this.etfStaticData.open_fund_fields).filter((field) => {
        return field.slice(0, 8) === fieldKey.slice(0, 8);
      });

      if (filteredKeys.length === 0) {
        return '-';
      }

      return filteredKeys.map((f) => {
        if (this.etfStaticData.open_fund_fields[f].toLowerCase() === 'yes') {
          return this.$translate.instant(`countries.${f.slice(8, 10).toUpperCase()}.name`);
        }
        else if (this.etfStaticData.open_fund_fields[f].toLowerCase() === 'res') {
          return this.$translate.instant(`countries.${f.slice(8, 10).toUpperCase()}.name`) +
            ` (${this.$translate.instant('views.restricted')})`;
        }
        else {
          return null;
        }
      }).filter((f) => !!f).join(', ');
    }

    if (!value || value === '') {
      return '-';
    }
    else if (fieldType === 'country_code') {
      return this.$translate.instant(`countries.${value}.name`);
    }
    else if (fieldType === 'date') {
      return this.$filter('date')(Date.parse(value), 'dd-MMM-yyyy');
    }
    else if (fieldType === 'percent') {
      return `${value}%`;
    }
    return value;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
