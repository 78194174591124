import { Directive, Input, OnInit, OnDestroy, Inject } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';

import { ITableSettingsProperty } from './model';
import { TableSettingsController } from '../table-settings/controller';
import { TableSettingsService } from '../table-settings/service';

// https://github.com/angular/angular.js/blob/master/src/ng/directive/ngShowHide.js
export const NG_HIDE_CLASS = 'ng-hide';

/**
 * @description
 * Adds a property to a "tableSettings" base component.
 * You must create a `TableSettingsController` in your component to create a bridge with the configuration component.
 * You must register the property in the controller before using it in the view.
 * See examples for more info.
 *
 * See {@link ITableSettingsProperty} for the params of the directive.
 *
 * @example
 * ```ts
 *  contructor() {
 *    this.tableSettingsController = new TableSettingsController('my-component');
 *    this.tableSettingsController.registerProperty({
 *      key: 'property',
 *      group: 'column',
 *      active: false
 *    });
 *  }
 * ```
 *
 * ```html
 *  <some-element
 *    [table-settings-property]="{key: 'property', group: 'column'}"
 *    [table-settings-property-controller]="tableSettingsController"
 *  ></some-element>
 * ```
 */
@Directive({
  selector: '[table-settings-property]',
  multiElement: true
})
export class TableSettingsPropertyDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input()
  tableSettingsProperty: ITableSettingsProperty;
  @Input()
  tableSettingsPropertyController: TableSettingsController;

  /*@ngInject*/
  constructor(
    @Inject('TableSettingsService')
    private tableSettingsService: TableSettingsService,
    private $element: ng.IAugmentedJQuery
  ) {}

  ngOnInit() {
    if (this.tableSettingsPropertyController) {
      this.subscriptions.push(this.tableSettingsPropertyController.$properties().subscribe(properties => {
        return this.onPropertiesChanged(properties);
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async onPropertiesChanged(properties: ITableSettingsProperty[]) {
    const property = properties.find(prop => {
      return prop.key === this.tableSettingsProperty.key &&
        prop.group === this.tableSettingsProperty.group;
    });
    if (property) {
      return this.updateStyle(property.active!);
    }
  }

  async updateStyle(active: boolean) {
    const enabled = await this.tableSettingsService.enabled();
    if (enabled === false) {
      return;
    }

    if (active) {
      this.$element.removeClass(NG_HIDE_CLASS);
    }
    else {
      this.$element.addClass(NG_HIDE_CLASS);
    }
  }
}
