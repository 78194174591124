import { NgModule } from 'angular-ts-decorators';
import KeywordResourceFactory from '@quantizr/front-model/dist/keywords/resource';

import { MyUserModule } from '../my-user';
import { VendorModule } from '../vendor';

import { KeywordsService } from './service';

@NgModule({
  id: 'Keywords',
  imports: [
    MyUserModule,
    VendorModule
  ],
  providers: [
    KeywordsService,
    {
      provide: 'KeywordResource',
      useFactory: ($resource) => KeywordResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class KeywordsModule {}

export default (KeywordsModule as NgModule).module!.name;
