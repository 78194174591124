import { Component, Inject, OnInit } from 'angular-ts-decorators';
import {
  IPortfolio, PortfolioResource,
  StrategyResource, StrategyVersionResource,
  User
} from '@quantizr/front-model';

const template = require('./page.html.haml');

import { DialogController } from '../../../dialog/controller';
import { MyUserService } from '../../../my-user';

export type actionType = '.action_add'|'.action_create'|'.action_add_all';

@Component({
  selector: 'page-strategies-strategies-new',
  template
})
export class StrategiesStrategiesNewPage extends DialogController implements OnInit {
  private currentUser: User|null = null;

  public addCreate: actionType = '.action_add';
  public saving = 0;
  public errorMessages: any = null;
  public strategyIds: number[] = [];
  public portfolios: IPortfolio[] = [];
  public newPortfolio: IPortfolio;
  public selectedPortfolio: IPortfolio;

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('StrategyVersionResource')
    private strategyVersionResource: StrategyVersionResource,
    @Inject('StrategyPortfolioResource')
    private strategyPortfolioResource: PortfolioResource,
    @Inject('MyUserService')
    private myUserService: MyUserService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    $scope: ng.IScope
  ) {
    super($scope);

    this.strategyIds = this.data.strategyIds;
    this.newPortfolio = new strategyResource({
      name: '',
      type: 'Portfolio',
      versions_attributes: [{
        strategy_versions_attributes: []
      }]
    });

    this.strategyIds.forEach(id => {
      (this.newPortfolio as any).versions_attributes[0].strategy_versions_attributes.push({
        strategy_id: id
      });
    });
  }

  async ngOnInit() {
    this.currentUser = await this.myUserService.currentUser();
    this.initPortfolios();
  }

  initPortfolios() {
    if (this.currentUser) {
      this.strategyResource.query({
        type: 'Portfolio',
        user_id: this.currentUser.id,
        per_page: 100
      }, portfolios => this.portfolios = portfolios, () => this.portfolios = []);
    }
  }

  save() {
    this.saving = 1;

    switch (this.addCreate) {
      case '.action_add_all':
        return this.addToAllPortfolios();
      case '.action_create':
        return this.createPortfolio();
      default:
        return this.strategyVersionResource.edit({
          strategy_id: this.selectedPortfolio.id,
        }).$promise.then(response => {
          this.addStrategies(response);
        }, error => this.saveError(error));
    }
  }

  addToAllPortfolios() {
    return this.strategyPortfolioResource.addToAll({
      strategy_id: this.strategyIds[0]
    }).$promise.then(() => this.addToAllPortfoliosSuccess(), error => this.saveError(error));
  }

  addToAllPortfoliosSuccess() {
    this.saving = 0;
    this.$translate('strategies.strategies.new.controller.add_to_all.success').then(title => {
      this.toaster.success(title);
    });
    this.confirm();
  }

  createPortfolio() {
    return this.newPortfolio.$save().then(() => {
      this.addSuccessMessage();
      this.confirm();
    }, error => this.saveError(error));
  }

  addStrategies(version) {
    this.strategyIds.forEach(id => {
      let idInCurrentVersion = false;

      version.strategy_versions_attributes.forEach(strategyVersion => {
        if (strategyVersion.strategy_id ===  id) {
          idInCurrentVersion = true;
        }
      });

      if (idInCurrentVersion === false) {
        version.strategy_versions_attributes.push({
          strategy_id: id
        });
      }
    });

    version.$update({
      strategy_id: this.selectedPortfolio.id
    }, () => {
      this.addSuccessMessage();
      this.confirm();
    }, error => this.saveError(error));
  }

  saveError(error) {
    this.saving = 2;
    this.errorMessages = error.data;
  }

  addSuccessMessage() {
    this.saving = 0;
    this.$translate('strategies.strategies.new.controller.add.success').then(title => {
      this.toaster.success(title);
    });
  }
}
