import { Component } from 'angular-ts-decorators';
import { Company } from '@quantizr/front-model';
const template = require('./page.html.haml');

@Component({
  selector: 'page-companies-show',
  template
})
export class CompaniesShowPage {
  public company: Company;
}
