import { Component, Inject } from 'angular-ts-decorators';
import {
  ICockpitProfile, CockpitProfileResource,
  StrategyResource, Strategy,
  CockpitProfileStrategyResource, CockpitProfileStrategy
} from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');

import { CockpitsEditProfilesChildPage } from '../child';
import { CockpitsViewsService } from '../../../views/service';
import { CustomSearchRequestsService, LOADING_STATES } from '../../../../custom-search';

const PAGINATION_SIZE = 10;
let components = 0;

@Component({
  selector: 'page-cockpits-edit-profiles-strategies',
  template
})
export class CockpitsEditProfilesStrategiesPage extends CockpitsEditProfilesChildPage {
  private namespace = `page-cockpits-profiles-edit-strategies-${++components}`;
  private criteria: {
    text?: string;
    page?: number;
    per_page: number;
    hide_protected: boolean;
  } = {
    hide_protected: true,
    per_page: PAGINATION_SIZE
  };

  public strategies: CockpitProfileStrategy[] = [];

  // search
  public name = '';
  public results: Strategy[] = [];
  public loadingState = LOADING_STATES.LOADING;
  public page = 0;
  public PAGINATION_SIZE = PAGINATION_SIZE;
  public searchVolumes: any = {};

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    @Inject('CockpitProfileStrategyResource')
    private cockpitProfileStrategyResource: CockpitProfileStrategyResource,
    @Inject('CustomSearchRequestsService')
    private customSearchRequestsService: CustomSearchRequestsService,
    // super dependencies
    @Inject('CockpitsViewsService')
    cockpitsViewsService: CockpitsViewsService,
    @Inject('CockpitProfileResource')
    cockpitProfileResource: CockpitProfileResource,
    $location: ng.ILocationService,
    ngDialog: ng.dialog.IDialogService,
    $translate: ng.translate.ITranslateService,
    $q: ng.IQService,
    toaster: toaster.IToasterService,
    $routeParams: ng.route.IRouteParamsService,
    $rootScope: ng.IRootScopeService
  ) {
    super(
      cockpitsViewsService, cockpitProfileResource, $location, ngDialog, $translate, $q, toaster,
      $routeParams, $rootScope
    );
  }

  ngOnInit() {
    return this.search();
  }

  init() {
    return super.init()
      .then(() => {
        return this.cockpitProfileStrategyResource.query({
          profile_id: this.profile!.id
        }).$promise;
      })
      .then(response => {
        this.strategies = response;
      });
  }

  saveProfile() {
    this.profile!.strategy_ids = this.strategies.map(strategy => strategy.id);
    if (this.profile!.id) {
      return this.cockpitProfileResource.update(this.profile!).$promise.then(response => {
        this.saveSuccess(response);
      }, error => this.saveError(error));
    }
    else {
      return (this.profile as ICockpitProfile).$save().then(response => {
        this.saveSuccess(response);
      }, error => this.saveError(error));
    }
  }

  isSelected(strategy: CockpitProfileStrategy) {
    return !!this.strategies.find(value => value.id === strategy.id);
  }

  select(strategy?: CockpitProfileStrategy) {
    if (!strategy || !strategy.id) {
      return;
    }

    this.strategies.push(strategy);
    this.forms.form!.$dirty = true;
  }

  unselect(_strategy: CockpitProfileStrategy, index: number) {
    this.strategies.splice(index, 1);
    this.forms.form!.$dirty = true;
  }

  search() {
    this.criteria.text = this.name;

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    const request = this.strategyResource.search(this.criteria);
    this.loadingState = LOADING_STATES.LOADING;

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise
      .then(response => {
        this.searchVolumes = response.volumes;
        this.query();
      })
      .catch(() => {
        this.loadingState = LOADING_STATES.IDLE;
      })
      .then(() => {
        this.customSearchRequestsService.remove(request);
      });
  }

  query() {
    this.criteria.text = this.name;
    this.criteria.page = this.page;

    this.customSearchRequestsService.cancel(undefined, this.namespace);

    this.loadingState = LOADING_STATES.LOADING;
    const request = this.strategyResource.query(this.criteria);

    this.customSearchRequestsService.add(request, this.namespace);

    return request.$promise
      .catch(() => [])
      .then(response => {
        this.results = response;
        this.loadingState = LOADING_STATES.IDLE;
        this.customSearchRequestsService.remove(request);
      });
  }
}
