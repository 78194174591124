import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { MyUser } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

const template = require('./page.html.haml');

import { MyUserService } from '../../my-user';

@Component({
  selector: 'page-errors-index',
  template
})
export class ErrorsIndexPage implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public user: MyUser;

  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    myUserService: MyUserService
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      if (user) {
        this.user = user;
      }
      else {
        this.user = {
          category: 'visitor',
          status: 'blocked'
        };
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
