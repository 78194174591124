import {
  Component, Input, OnChanges, SimpleChanges
} from 'angular-ts-decorators';

const template = require('./component.html.haml');

import { MONTHS } from '../../../../settings';
import { IColumnChartColumnData } from '../../../../components/column-chart/component';
import { GREEN, RED } from '../../../../components/base-chart/colors';

export const chartData = (values: any[] = []) => {
  const data: IColumnChartColumnData[] = [];

  for (const value of values) {
    for (let j = 11; j >= 0; j--) {
      const month = MONTHS[j];
      const monthValue = value[1][month] * 100;

      if (!!monthValue) {
        let color = GREEN;

        if (monthValue < 0) {
          color = RED;
        }

        data.unshift({
          x: month,
          value: monthValue,
          fill: color,
          stroke: color
        });

        if (data.length >= 12) {
          return data;
        }
      }
    }
  }

  return data;
};

export type displayedSection = 'table'|'chart';

@Component({
  selector: 'strategies-show-performance-month-to-month',
  template
})
export class StrategiesShowPerformanceMonthToMonthComponent implements OnChanges {
  @Input()
  values: any[];
  @Input('<?')
  maxLines: number = 100;
  @Input('<?')
  displayedSections: displayedSection[] = ['table'];
  @Input('<?')
  selectedSection: displayedSection = 'table';

  public chartDataset: IColumnChartColumnData[] = [];
  public chartSettings = {
    chart: {
      yAxis: {
        labels: {
          format() {
            return this.value;
          }
        }
      },
      xMinorGrid: {
        enabled: false
      },
      yMinorGrid: {
        enabled: true
      }
    }
  };

  public MONTHS = MONTHS;
  public MONTH_ALL = 'All';

  /*@ngInject*/
  constructor(
    private ngDialog: ng.dialog.IDialogService,
    private $rootScope: ng.IRootScopeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('displayedSections' in changes) {
      const displayedSections = changes.displayedSections.currentValue;
      this.selectedSection = displayedSections[displayedSections.length - 1];
    }

    if ('values' in changes) {
      const values = changes.values.currentValue;
      if (this.displayedSections.indexOf('chart') !== -1) {
        this.initChart(values);
      }
    }
  }

  initChart(values: any[]) {
    this.chartDataset = chartData(values);
  }

  displayFullMonthToMonth() {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.values = this.values;
    this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <strategies-show-performance-month-to-month values="values">
        </strategies-show-performance-month-to-month>
      `,
      plain: true,
      scope: newScope
    });
  }

  changeSection(section: displayedSection) {
    this.selectedSection = section;
  }
}
