import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { File, FileResource } from '@quantizr/front-model';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';

import './page.scss';
const template = require('./page.html.haml');

import { LOADING_STATES } from '../../custom-search';

export const fileDescriptionFor = (file: File) => {
  if ((new RegExp('sp\.pdf$', 'i')).exec(file.file_file_name) !== null) {
    return 'Spanish';
  }
  else if ((new RegExp('fr\.pdf$', 'i')).exec(file.file_file_name) !== null) {
    return 'French';
  }
  else if ((new RegExp('an\.pdf$', 'i')).exec(file.file_file_name) !== null) {
    return 'English';
  }
  else if ((new RegExp('\.pdf$', 'i')).exec(file.file_file_name) !== null) {
    return 'See';
  }
  else {
    return 'Download';
  }
};

@Component({
  selector: 'page-files-index',
  template
})
export class FilesIndexPage implements OnDestroy {
  public LOADING_STATES = LOADING_STATES;
  public PAGINATION_SIZE = settings.PAGINATION_SIZE;

  public loadingState = this.LOADING_STATES.LOADING;
  public queryParams = {
    title: '',
    owner_id: null,
    date: {
      year: '',
      month: '',
      day: ''
    },
  };
  private criteria = {
    page: 1,
    date: {
      year: '',
      month: '',
      day: ''
    },
    title: '',
    owner_id: null,
    provider_config_id: null
  };
  public totalResults: number = 0;
  public dateList: Date[] = [];
  public files: File[] = [];
  public strategies: any[] = [];
  public years: any[] = [];
  public months: any[] = [];
  public days: any[] = [];
  private watchers: Function[] = [];

  /*@ngInject*/
  constructor(
    @Inject('FileResource')
    private fileResource: FileResource,
    private $routeParams: ng.route.IRouteParamsService
  ) {
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  ngOnInit() {
    this.criteria.provider_config_id = this.$routeParams.provider_config_id;
    this.totalResults = 0;
    this.dateList = [];
    this.strategies = [];
    this.queryParams.date = {
      year: '', month: '', day: ''
    };
    this.queryParams.owner_id = null;
    this.query();
  }

  search() {
    this.criteria.page = 1;
    this.criteria.title = this.queryParams.title;
    this.criteria.date = Object.assign({}, this.queryParams.date);
    this.criteria.owner_id = this.queryParams.owner_id;

    if (this.criteria.date.month !== '') {
      this.criteria.date.month = (parseInt(this.criteria.date.month, 10) + 1).toString();
    }
    this.query();
  }

  clearSearch() {
    this.queryParams.title = '';
    this.queryParams.date = {
      year: '', month: '', day: ''
    };
    this.queryParams.owner_id = null;

    this.search();
  }

  query() {
    this.loadingState = this.LOADING_STATES.LOADING;

    return this.fileResource.queryBy(this.criteria).$promise.then(response => {
      this.querySuccess(
        response.files, response.total_results,
        response.date_list as any, (response as any).strategy_list
      );
    }, () => {
      this.querySuccess([], 0);
    });
  }

  querySuccess(files: File[], totalResults?: number, dateList?: string[], strategies?: any[]) {
    this.loadingState = this.LOADING_STATES.IDLE;
    if (typeof totalResults !== 'undefined') {
      this.totalResults = totalResults;
    }

    if (typeof dateList !== 'undefined' && this.dateList.length === 0) {
      this.dateList = dateList.map( date => new Date(date));
      this.refreshYears();
    }

    if (typeof strategies !== 'undefined' && this.strategies.length === 0) {
      this.strategies = strategies;
    }
    this.files = files || [];
  }

  fileDescriptionFor(file: File) {
    return fileDescriptionFor(file);
  }

  refreshYears() {
    const years = this.dateList.map(date => date.getFullYear().toString());
    const uniqueYears = Array.from(new Set(years)).sort((n1, n2) => n1 > n2 ? -1 : 1);

    this.years = [{ value: '', label: 'All years' }].concat(
      uniqueYears.map(date => {
      return { value: date, label: date };
    }));
    this.months = [];
    this.days = [];
  }

  refreshMonths() {
    this.queryParams.date.month = '';
    this.queryParams.date.day = '';
    this.days = [];

    if (this.queryParams.date.year === '') {
      this.months = [];
    } else {
      const months = this.dateList.filter(date =>
        date.getFullYear().toString() === this.queryParams.date.year
      ).map(date => date.getMonth());
      const uniqueMonths = Array.from(new Set(months)).sort((n1, n2) => n1 > n2 ? -1 : 1);

      this.months = [{ value: '', label: 'all' }].concat(
        uniqueMonths.map(date => {
        return { value: date.toString(), label: date.toString() };
      }));
    }
  }

  refreshDays() {
    this.queryParams.date.day = '';
    if (this.queryParams.date.month === '') {
      this.days = [];
    } else {
      const days = this.dateList.filter(date =>
        date.getFullYear().toString() === this.queryParams.date.year &&
          date.getMonth().toString() === this.queryParams.date.month
      ).map(date => date.getDate()).sort((n1, n2) => n1 > n2 ? -1 : 1);
      this.days = [{ value: '', label: 'All days' }].concat(
        days.map(date => {
        return { value: date.toString(), label: date.toString() };
      }));
    }
  }
}
