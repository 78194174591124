import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { variables as simulations } from '@quantizr/front-model/dist/simulations/variables';

import './component.scss';
const template = require('./component.html.haml');

import { ISimulationNew } from '../page';
import { COLORS } from '../../../components/colors';

@Component({
  selector: 'simulations-new-indicators',
  template
})
export class SimulationsNewIndicatorsComponent implements OnChanges {
  @Input()
  simulation: ISimulationNew;
  @Input()
  errorMessages: any;
  @Input()
  active: boolean;

  public WINDOWS = simulations.SimulationIndicators.WINDOWS;
  public groupedIndicators: {[group: string]: string[]} = {};
  public availableCategories: string[] = [];

  /*@ngInject*/
  constructor(
    private ngDialog: ng.dialog.IDialogService
  ) {}

  ngOnInit() {
    this.errorMessages = this.errorMessages || {};
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('simulation' in changes) {
      this.initSimulation();
    }
  }

  initSimulation() {
    this.simulation.simulation_indicators_attributes.forEach(indicator => {
      this.groupedIndicators[indicator.category] = this.groupedIndicators[indicator.category] || [];
      this.groupedIndicators[indicator.category].push(indicator.window);
    });

    this._refreshAvailableCategories();
  }

  addCategory() {
    return this.ngDialog.open({
      template: '<page-simulations-edit-windows></page-simulations-edit-windows>',
      plain: true,
      className: 'ngdialog-theme-default ngdialog-theme-large',
      data: {
        categories: this.availableCategories
      }
    }).closePromise.then((data) => {
      if (
        data.value &&
        typeof data.value.windows !== 'undefined' &&
        typeof data.value.category !== 'undefined'
      ) {
        this.groupedIndicators[data.value.category] = data.value.windows;
        this._refreshIndicators();
        this._refreshAvailableCategories();
      }
    });
  }

  editCategory(category: string) {
    return this.ngDialog.open({
      template: '<page-simulations-edit-windows></page-simulations-edit-windows>',
      plain: true,
      className: 'ngdialog-theme-default ngdialog-theme-large',
      data: {
        category,
        windows: this.groupedIndicators[category].slice()
      }
    }).closePromise.then((data) => {
      if (data.value && typeof data.value.windows !== 'undefined') {
        this.groupedIndicators[category] = data.value.windows;
        this._refreshIndicators();
        this._refreshAvailableCategories();
      }
    });
  }

  canDeleteCategory(category: string) {
    return category !== 'return_pa' && category !== 'volatility';
  }

  deleteCategory(category: string) {
    if (!this.canDeleteCategory(category)) {
      return;
    }

    delete this.groupedIndicators[category];
    this._refreshIndicators();
    this._refreshAvailableCategories();
  }

  windowColor(window: string) {
    const pos = this.WINDOWS.indexOf(window);
    return COLORS[pos];
  }

  _refreshIndicators() {
    this.simulation.simulation_indicators_attributes = [];
    Object.keys(this.groupedIndicators).forEach(category => {
      this.groupedIndicators[category].forEach(window => {
        this.simulation.simulation_indicators_attributes.push({
          category,
          window
        });
      });
    });
  }

  _refreshAvailableCategories() {
    this.availableCategories = [];
    simulations.SimulationIndicators.CATEGORIES.forEach(category => {
      if (!(category in this.groupedIndicators) || !this.groupedIndicators[category].length) {
        this.availableCategories.push(category);
      }
    });
  }
}
