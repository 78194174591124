import { Component, Input, OnDestroy } from 'angular-ts-decorators';
import * as angular from 'angular';

import './component.scss';
const template = require('./component.html.haml');

import { ANIMATIONS_DURATION } from '../';

@Component({
  selector: 'horizontal-paging',
  template,
  transclude: true
})
export class HorizontalPagingComponent implements OnDestroy {
  private initTimeout: ng.IPromise<void>|null = null;
  private refreshTimeout: ng.IPromise<void>|null = null;
  private $window: ng.IAugmentedJQuery;
  private $content: ng.IAugmentedJQuery;
  private $target: ng.IAugmentedJQuery;
  private $targetContent: ng.IAugmentedJQuery;
  private elementWidth: number;
  private targetWidth: number;
  private targetScrollLeft: number = 0;

  @Input()
  target: string;

  /*@ngInject*/
  constructor(
    $window: ng.IWindowService,
    private $timeout: ng.ITimeoutService,
    private $element: ng.IAugmentedJQuery
  ) {
    this.$window = angular.element($window);

    // wait for transcluded content to be fully loaded
    this.initTimeout = $timeout(() => {
      this.initTimeout = $timeout(() => {
        this.initTimeout = $timeout(() => {
          this.init();
        });
      });
    });
  }

  ngOnDestroy() {
    this.$window.off('resize', this._onResize);
    if (this.$target) {
      this.$target.off('scroll');
    }
    if (this.initTimeout !== null) {
      this.$timeout.cancel(this.initTimeout);
    }
    if (this.refreshTimeout !== null) {
      this.$timeout.cancel(this.refreshTimeout);
    }
  }

  _refreshWidth() {
    if (!this.$content) {
      return;
    }

    const width = this.$content.width();
    if (width !== undefined && width !== 0) {
      this.elementWidth = width;
    }
    const targetWidth = this.$targetContent.width();
    if (targetWidth !== undefined && targetWidth !== 0) {
      this.targetWidth = targetWidth;
    }
  }

  _onResize() {
    this.refresh();
  }

  _onScroll(scrollLeft: number) {
    this.targetScrollLeft = scrollLeft;
    this.refresh();
  }

  init() {
    this.$target = this.$element.find(this.target);

    this.$content = this.$element.find('.content');
    this.$targetContent = this.$target.find('> :first-child');
    this._refreshWidth();

    this.$window.on('resize', this._onResize.bind(this));

    const ctrl = this;
    this.$target.on('scroll', function() {
      ctrl._onScroll(angular.element(this).scrollLeft()!);
    });
  }

  refresh() {
    // avoid digesting multiple times while user scrolls
    if (this.refreshTimeout !== null) {
      this.$timeout.cancel(this.refreshTimeout);
    }
    this.refreshTimeout = this.$timeout(() => {
      this.refreshTimeout = null;
      this._refreshWidth();
    }, ANIMATIONS_DURATION);
  }

  pagingLeftDisabled() {
    return this.targetScrollLeft === 0;
  }

  pagingLeft() {
    // make sure we have correct widths
    this._refreshWidth();
    if (this.pagingLeftDisabled()) {
      return;
    }

    const page = Math.round(this.targetScrollLeft / this.elementWidth);
    this.scroll((page - 1) * this.elementWidth);
  }

  pagingRightDisabled() {
    return this.elementWidth && this.targetScrollLeft >= (this.targetWidth - this.elementWidth);
  }

  pagingRight() {
    // make sure we have correct widths
    this._refreshWidth();
    if (this.pagingRightDisabled()) {
      return;
    }

    const page = Math.ceil(this.targetScrollLeft / this.elementWidth);
    this.scroll((page + 1) * this.elementWidth);
  }

  scroll(scrollLeft: number) {
    this.targetScrollLeft = Math.min(Math.max(scrollLeft, 0), this.targetWidth);
    this.$target.animate({
      scrollLeft: this.targetScrollLeft
    }, ANIMATIONS_DURATION);
  }
}
