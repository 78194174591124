import { NgModule } from 'angular-ts-decorators';
import { ComponentsModule } from '../components';

import { GlossaryIndexPage } from './index/page';

@NgModule({
  id: 'Glossary',
  imports: [
    ComponentsModule
  ],
  declarations: [
    GlossaryIndexPage
  ]
})
export class GlossaryModule {}

export default (GlossaryModule as NgModule).module!.name;
