import { NgModule } from 'angular-ts-decorators';
import CompanyResourceFactory from '@quantizr/front-model/dist/companies/resource';
import { VendorModule } from '../vendor';

import { CompaniesShowPage } from './show/page';

@NgModule({
  id: 'Companies',
  imports: [
    VendorModule
  ],
  declarations: [
    CompaniesShowPage
  ],
  providers: [
    {
      provide: 'CompanyResource',
      useFactory: ($resource) => CompanyResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class CompaniesModule {}

export default (CompaniesModule as NgModule).module!.name;
