import { Component, Input, AfterViewInit } from 'angular-ts-decorators';

const template = require('./component.html.haml');
import './component.scss';

import { TableSettingsController } from './controller';
import { ITableSettingsPropertyDisplay } from '../table-settings-property/model';

export interface ITableSettingsGroup {
  key: string;
  title: string;
}

/**
 * @description
 * Creates a configuration component for a "table" (can be any element composed or rows and columns).
 * This component must be added somewhere in the page in order to control `[table-settings-property]` directives.
 *
 * You must create a `TableSettingsController` in your component to create a bridge with the properties.
 *
 * See {@link TableSettingsPropertyDirective} for details on how to add properties.
 *
 * @example
 * ```ts
 *  contructor() {
 *    this.tableSettingsController = new TableSettingsController('my-component');
 *  }
 * ```
 *
 * ```html
 *  <table-settings
 *    [groups]="[{key: 'my_group', title: 'Columns'}]"
 *    [controller]="tableSettingsController"
 *  ></table-settings>
 * ```
 */
@Component({
  selector: 'table-settings',
  template
})
export class TableSettingsComponent implements AfterViewInit {
  private activeGroup: ITableSettingsGroup|null = null;

  @Input()
  public groups: ITableSettingsGroup[] = [];
  @Input()
  controller: TableSettingsController;

  public properties: ITableSettingsPropertyDisplay[] = [];

  /*@ngInject*/
  constructor(
    private $timeout: ng.ITimeoutService
  ) {}

  ngAfterViewInit() {
    this.$timeout(() => {
      this.controller.load();
      this.changeGroup(this.groups[0]);
    });
  }

  changeGroup(group: ITableSettingsGroup) {
    this.activeGroup = group;
    this.properties = this.controller.properties.filter(property => {
      return property.group === this.activeGroup!.key;
    });
  }

  isActiveGroup(group: ITableSettingsGroup) {
    return this.activeGroup !== null && this.activeGroup.key === group.key;
  }

  toggleProperty(property: ITableSettingsPropertyDisplay) {
    property.active = !property.active;
    this.controller.toggleProperty(property);
  }

  apply() {
    this.controller.save();
  }

  cancel() {
    this.controller.load();
  }

  reset() {
    this.controller.resetProperties();
  }
}
