import { Component, OnDestroy, Inject, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  Strategy, StrategyIssuerResource, StrategyGrouped
} from '@quantizr/front-model';

const template = require('./page.html.haml');

import { COLORS } from '../../../components/colors';
import { COLORS as SANKEY_COLORS, translateData } from '../../sankey';
import { StrategiesViewsService } from '../../views/service';

export type esgSections = 'esg_score'|'esg_controversies_score';

@Component({
  selector: 'page-strategies-show-esg',
  template
})
export class StrategiesShowEsgPage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  public groupedData: StrategyGrouped;
  public sections: {
    esg: esgSections[]
  } = {
    esg: ['esg_score', 'esg_controversies_score']
  };
  public selectedSection: {
    esg: esgSections
  } = {
    esg: 'esg_score'
  };

  public SANKEY_SOURCE_COLORS = COLORS;
  public SANKEY_TARGET_COLORS = SANKEY_COLORS;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService,
    @Inject('StrategyIssuerResource')
    private strategyIssuerResource: StrategyIssuerResource,
    private $translate: ng.translate.ITranslateService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.updateStrategy(strategy);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.updateStrategy(strategy);
    }
  }

  updateStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.strategy) {
      return;
    }

    this.strategyIssuerResource.esg({
      strategy_id: this.strategy.id
    }).$promise.then(response => {
      this.groupedData = response;
      this.groupedData.sankey = translateData(this.$translate, this.groupedData.sankey,
        'country_code', 'esg_score');
    }, () => {
      this.groupedData = {
        country_code: [],
        esg_score: [],
        esg_controversies_score: [],
        currency: [],
        rating: [],
        sankey: {}
      } as any;
    });
  }
}
