export const UnauthorizedRequestInterceptor = 'Users.UnauthorizedRequest.Interceptor';

import { cookieIsLoggedIn } from './service';
import { redirectPath } from './error-codes';

export const redirectUrl = (data: any) => {
  const { error_code } = data;
  return `/#!${redirectPath(error_code)}?error_code=${data.error_code}`;
};

export default function(
  $q: ng.IQService,
  $window: ng.IWindowService,
  localStorageService: ng.local.storage.ILocalStorageService
): ng.IHttpInterceptor {
  return {
    responseError(rejection) {
      const data = rejection.data;
      switch (rejection.status.toString()) {
        case '401':
          if ('redirect_to' in data) {
            $window.location.href = data.redirect_to;
          }
          break;
        case '403':
          if (cookieIsLoggedIn in data) {
            localStorageService.set(cookieIsLoggedIn, data.is_logged_in);
          }
          if ('error_code' in data) {
            $window.location.href = redirectUrl(data);
          }
          break;
      }
      return $q.reject(rejection);
    }
  };
}
