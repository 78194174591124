import {
  Component, Input,
  OnChanges, SimpleChanges, OnDestroy,
  ViewChild
} from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { dateAtMidnight, DatesSelector } from '../../components';
import { TimeSeriesStockChartComponent } from '../../time-series';
import { StrategiesCompositionModel } from '../composition/model';

@Component({
  selector: 'strategies-weights',
  template
})
export class StrategiesWeightsComponent implements OnDestroy, OnChanges {
  @ViewChild('weights')
  public weightsChart: TimeSeriesStockChartComponent;
  // @ViewChild('perf')
  // public perfChart: TimeSeriesStockChartComponent;

  @Input()
  public strategy: Strategy;
  @Input()
  composition: StrategiesCompositionModel;
  @Input()
  public group: string;
  @Input()
  public datesSelector: DatesSelector;
  @Input('<?')
  public dates: any = {};
  @Input()
  public scroller: any;
  @Input()
  chartSettings: any;

  private chartTimeout: ng.IPromise<any>;

  public settings: any = {};
  public dataset: any[] = [];
  // public perfSettings: any = {};
  // public perfDataset: any[] = [];

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService
  ) {
    this.settings = {
      plots: [{
        colors: {},
        yScale: {
          baseHundred: true,
          stackMode: 'percent',
          minimum: 0,
          maximum: 100
        },
        yAxis: {
          labels: {
            format: '{%Value}%'
          }
        },
        area: {},
        legend: { enabled: false },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {
        format() {
          return this.seriesName + ': ' + this.value.toFixed(2) + '%';
        }
      },
      messageLoading: this.$translate.instant('strategies.show.performance.view.fixings.loading'),
      messageEmpty: this.$translate.instant('strategies.show.performance.view.fixings.empty')
    };

    // this.perfSettings = {
    //   grouping: {
    //     maxVisiblePoints: 15
    //   },
    //   plots: [{
    //     colors: {},
    //     column: true,
    //     yScale: {
    //       stackMode: 'value'
    //     },
    //     yAxis: {
    //       labels: {
    //         format: '{%Value}%'
    //       }
    //     },
    //     legend: { enabled: false },
    //     annotations: [{ verticalLine: {} }]
    //   }],
    //   tooltip: {
    //     format: function() {
    //       return this.seriesName + ': ' + this.value.toFixed(2) + '%';
    //     }
    //   },
    //   messageLoading: this.$translate.instant('strategies.show.performance.view.fixings.loading'),
    //   messageEmpty: this.$translate.instant('strategies.show.performance.view.fixings.empty')
    // };
  }

  ngOnDestroy() {
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('chartSettings' in changes) {
      const settings = changes.chartSettings.currentValue || {};
      merge(this.settings, settings);
      // merge(this.perfSettings, settings);
    }

    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      if (strategy) {
        this.initStrategy(strategy);
      }
    }

    if ('composition' in changes || 'group' in changes) {
      this.updateDataset();
    }
  }

  initStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.dates.min && this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }
    if (!this.dates.max && this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    if (this.strategy.live_date) {
      this.settings.plots[0].annotations[0].verticalLine = {
        xAnchor: this.strategy.live_date
      };
    }
  }

  updateDataset() {
    this.dataset = this.dataset || [];
    if (this.composition && this.group) {
      this.dataset = this.composition.dataset(this.group);
      // this.perfDataset = this.composition.perfDataset(this.group);
    }
    this.refresh();
  }

  refresh(): void {
    this.chartTimeout = this.$timeout(() => {
      this.weightsChart.dataset = this.dataset;
      this.weightsChart.refresh();
      // this.perfChart.dataset = this.perfDataset;
      // this.perfChart.refresh();
    });
  }
}
