import { Directive, HostListener } from 'angular-ts-decorators';

@Directive({
  selector: 'dropdown-persist'
})
export class DropdownPersistDirective {
  @HostListener('click')
  onClick(event) {
    if (event.target.type !== 'button') {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
