import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { Cockpit, CockpitProfile } from '@quantizr/front-model';

const template = require('./component.html.haml');

import { CockpitsViewsService } from '../views/service';
import { PanelsSidebarChild, PanelsSidebarService } from '../../panels';
import { IFilterService } from '../../components/location-starts-with/filter';
import { MyUserService } from '../../my-user';

@Component({
  selector: 'cockpits-sidebar-filters',
  template
})
export class CockpitsSidebarFiltersComponent extends PanelsSidebarChild implements OnDestroy {
  private watchers: Function[] = [];

  public cockpit: Cockpit;
  public profile: CockpitProfile|null = null;

  /*@ngInject*/
  constructor(
    @Inject('PanelsSidebarService')
    panelsSidebarService: PanelsSidebarService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService,
    $routeParams: ng.route.IRouteParamsService,
    $location: ng.ILocationService,
    $filter: IFilterService,
    $routeSegment: ng.routeSegment.IRouteSegmentService,
    $rootScope: ng.IRootScopeService
  ) {
    super(panelsSidebarService, myUserService, $routeParams, $location, $filter, $routeSegment);

    this.subscriptions.push(cockpitsViewsService.$cockpit().subscribe(cockpit => {
      if (cockpit !== null) {
        this.loadCockpit(cockpit);
      }
    }));
    this.subscriptions.push(cockpitsViewsService.$cockpitProfile().subscribe(cockpitProfile => {
      this.profile = cockpitProfile;
    }));

    this.watchers.push($rootScope.$on('$routeChangeSuccess', () => this.initCockpitProfile()));
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  editMode() {
    return this.locationStartsWith('/cockpit/edit');
  }

  loadCockpit(cockpit: Cockpit) {
    this.cockpit = cockpit;
    this.initCockpitProfile();
  }

  initCockpitProfile() {
    if (!this.cockpit) {
      return;
    }

    let profileId: number|undefined;
    if ('profileId' in this.$routeParams) {
      profileId = parseInt(this.$routeParams.profileId, 10);
    }

    this.cockpitsViewsService.loadCockpitProfile(this.cockpit, profileId);
  }

  isHoldingsLocation(subview: string): boolean {
    if (!this.profile) {
      return false;
    }

    return this.locationStartsWith(`/cockpit/holdings/${this.profile.id}/${subview}`);
  }

  location(view: string, subview = ''): string {
    if (!this.profile) {
      return `#!/cockpit/${view}`;
    }

    return `#!/cockpit/${view}/${this.profile.id}/${subview}`;
  }
}
