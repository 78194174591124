import { Component, Inject, Input, OnDestroy, AfterViewInit } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { VersionsViewsService } from '../views/service';

export interface IShiftParameter {
  type: string;
  min: number;
  max: number;
  options: any;
  values: any[];
  sliderOptions: ng.RzSlider.RzOptions;
}

@Component({
  selector: 'versions-button-shift',
  template
})
export class VersionsButtonShiftComponent implements AfterViewInit, OnDestroy {
  @Input('@')
  description: string;
  @Input('@')
  path: string;
  @Input()
  defaultValue: any;
  @Input()
  fieldsConfig: any;

  private subscriptions: Subscription[] = [];
  private shiftValues: any = {};

  public key: string;
  public settings: any = null;
  public shiftParameter: IShiftParameter;

  /*@ngInject*/
  constructor(
    @Inject('VersionsViewsService')
    private versionsViewsService: VersionsViewsService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    const parameterCodes = this.path.split('.');
    this.key = parameterCodes[parameterCodes.length - 1];

    this.subscriptions.push(this.versionsViewsService.$shiftSettings().subscribe((settings) => {
      if (settings && this.key in settings) {
        this.settings = settings[this.key];
        this.init();
      }
    }));

    this.subscriptions.push(this.versionsViewsService.$shiftValues().subscribe((shifts) => {
      this.shiftValues = shifts;
      if (shifts && shifts[this.path]) {
        this.init();
      }
    }));
  }

  init() {
    if (!this.settings) {
      return;
    }

    this.shiftParameter = {
      type: this.key,
      min: this.settings.floor,
      max: this.settings.cap,
      options: this.settings.options,
      values: [],
      sliderOptions: merge(this.settings, {
        showSelectionBar: true,
        showSelectionBarEnd: true
      })
    };

    if (this.isSet()) {
      merge(this.shiftParameter, this.defaultShiftParameter() || {});
    }
  }

  defaultShiftParameter() {
    if (!this.shiftValues) {
      return null;
    }
    else {
      return this.shiftValues[this.path];
    }
  }

  isSet() {
    return !!this.defaultShiftParameter();
  }

  isDisabled() {
    return this.fieldsConfig.readonly ||
      (Object.keys(this.shiftValues).length >= 4 && !this.isSet());
  }

  refreshDropdown() {
    if (this.settings && this.settings.type === 'date' && this.shiftParameter.values.length === 0) {
      this.addDate();
    }

    this.$timeout(() => {
      this.$rootScope.$broadcast('rzSliderForceRender');
    });
  }

  toggleOption(element: any) {
    const index = this.shiftParameter.values.indexOf(element);
    if (index > -1) {
      this.shiftParameter.values.splice(index, 1);
    }
    else {
      this.shiftParameter.values.push(element);
    }
  }

  optionChecked(element?: any) {
    if (element) {
      return this.shiftParameter.values.indexOf(element) > -1;
    }
    else {
      return this.shiftParameter.options.length === this.shiftParameter.values.length;
    }
  }

  addDate() {
    if (this.defaultValue) {
      this.shiftParameter.values.push(new Date(this.defaultValue));
    }
  }

  reset() {
    delete this.shiftValues[this.path];
    this.init();
  }

  public apply(event: UIEvent) {
    if (this.shiftParameter.values) {
      for (const value of this.shiftParameter.values) {
        if (!value) {
          event.stopPropagation();
          return;
        }
      }
    }
    this.shiftValues[this.path] = this.shiftParameter;
    this.versionsViewsService.shiftValues(this.shiftValues);
  }
}
