import { Strategy } from '@quantizr/front-model';
import { variables } from '@quantizr/front-model/dist/strategies/variables';

export const badge = (strategy: Strategy): string => {
  let badgeType = '';

  switch (strategy.status) {
    case variables.STATUSES.LISTED:
      badgeType = 'primary';
      break;
    case variables.STATUSES.DRAFT:
      badgeType = 'secondary';
      break;
    case variables.STATUSES.HIDDEN:
      badgeType = 'dark';
      break;
    case variables.STATUSES.WAITING_APPROVAL:
      badgeType = 'warning';
      break;
    default:
      badgeType = 'danger';
      break;
  }

  return `badge-${badgeType}`;
};
