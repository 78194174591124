import { Component, Inject, OnDestroy, OnChanges, SimpleChanges, Input } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Strategy } from '@quantizr/front-model';

import './page.scss';
const template = require('./page.html.haml');
import { StrategiesViewsService } from '../../views/service';

export const LONG_SHORT_TYPES = {
  TOTAL: 'total',
  LONG_SHORT: 'long_short'
};

export const SECTION_TYPES = {
  ASSET_CLASS: 'asset_class',
  COUNTRY_CODE: 'country_code',
  COUPON: 'coupon',
  CURRENCY: 'currency',
  DURATION: 'duration',
  INDUSTRY: 'industry',
  INSTRUMENT: 'instrument',
  SANKEY: 'sankey',
  SECTOR: 'sector',
  RATING: 'rating',
  MATURITY: 'maturity'
};

@Component({
  selector: 'page-strategies-show-holdings',
  template
})
export class StrategiesShowHoldingsPage implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  public strategy: Strategy;

  /*@ngInject*/
  constructor(
    @Inject('StrategiesViewsService')
    strategiesViewsService: StrategiesViewsService
  ) {
    this.subscriptions.push(strategiesViewsService.$strategy().subscribe(strategy => {
      if (strategy !== null) {
        this.strategy = strategy;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      this.strategy = strategy;
    }
  }
}
