import { NgModule } from 'angular-ts-decorators';
import FileResourceFactory from '@quantizr/front-model/dist/files/resource';

import { ComponentsModule } from '../components';
import { PanelsModule } from '../panels';
import { VendorModule } from '../vendor';

import { FilesIndexPage } from './index/page';
import { FilesShowPage } from './show/page';
import { FilesSidebarFiltersComponent } from './sidebar-filters/component';

@NgModule({
  id: 'Files',
  imports: [
    ComponentsModule,
    PanelsModule,
    VendorModule
  ],
  declarations: [
    FilesIndexPage,
    FilesShowPage,
    FilesSidebarFiltersComponent
  ],
  providers: [
    {
      provide: 'FileResource',
      useFactory: ($resource) => FileResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class FilesModule {}

export default (FilesModule as NgModule).module!.name;
