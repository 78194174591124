import { Component, Inject, Input, OnInit, OnDestroy, ViewParent } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import {
  StrategyListResource, StrategyUserPermissionResource, MyUser, Strategy,
  categoriesForTable, isClosingDate, TimeSerieCategory
} from '@quantizr/front-model';
import { variables as userPermissions } from '@quantizr/front-model/dist/user-permissions/variables';
import { variables as settings } from '@quantizr/front-model/dist/settings/variables';
import { variables as strategies } from '@quantizr/front-model/dist/strategies/variables';

import './component.scss';
const template = require('./component.html.haml');

import { LOADING_STATES } from '../../../custom-search';
import { FILTERS } from '../../';
import { MyUserService } from '../../../my-user';
import { TYPES, strategiesType } from '../../type';
import { ISearchSort, StrategiesIndexPage } from '../page';
import { TableSettingsController } from '../../../components';

const CATEGORIES = categoriesForTable([{
  key: TimeSerieCategory.cumulative_return,
  defaultActive: true
}, {
  key: TimeSerieCategory.return_pa,
  defaultActive: true
}, {
  key: TimeSerieCategory.volatility,
  defaultActive: true
}, {
  key: TimeSerieCategory.info_ratio,
  defaultActive: true
}, {
  key: TimeSerieCategory.calmar_ratio,
  defaultActive: false
}]);

@Component({
  selector: 'strategies-index-results',
  template
})
export class StrategiesIndexResultsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private user: MyUser|null;

  @ViewParent('?^pageStrategiesIndex')
  indexPage: StrategiesIndexPage;

  @Input()
  results: Strategy[];
  @Input()
  criteriaVolumes: any;
  @Input()
  loadingState: any;
  @Input()
  sort: ISearchSort;
  @Input()
  selectedElements: number[];
  @Input()
  strategiesFilter: string;
  @Input()
  strategiesType: strategiesType;
  @Input()
  public tableSettingsController: TableSettingsController;

  public membership = settings.MEMBERSHIP.defaults;
  public FILTERS = FILTERS;
  public TYPES = TYPES;
  public LOADING_STATES = LOADING_STATES;
  public STATUSES = strategies.STATUSES;
  public PAGINATION_SIZE = settings.PAGINATION_SIZE;
  public FIELDS = CATEGORIES;

  /*@ngInject*/
  constructor(
    @Inject('StrategyListResource')
    private customlistResource: StrategyListResource,
    @Inject('StrategyUserPermissionResource')
    private strategyUserPermissionResource: StrategyUserPermissionResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService,
    private ngDialog: ng.dialog.IDialogService
  ) {
    this.subscriptions.push(myUserService.$user().subscribe(user => {
      this.user = user;
    }));
  }

  ngOnInit() {
    if (this.tableSettingsController) {
      CATEGORIES.forEach(category => {
        this.tableSettingsController.registerProperty({
          key: category.key,
          group: 'column',
          active: category.defaultActive,
          title: this.$translate.instant(`timeSeries.categories.${category.key}`)
        });
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selected(strategy: Strategy) {
    if (!this.selectedElements) {
      return false;
    }

    return this.selectedElements.indexOf(strategy.id) !== -1;
  }

  toggleSelect(strategy: Strategy) {
    if (!this.selectedElements) {
      return;
    }

    const index = this.selectedElements.indexOf(strategy.id);
    if (index === -1) {
      this.selectedElements.push(strategy.id);
    }
    else {
      this.selectedElements.splice(index, 1);
    }

    this.selectedElements.splice(
      this.membership.versions.max_strategies_count,
      this.selectedElements.length - this.membership.versions.max_strategies_count
    );
  }

  clickSort(fieldName: string) {
    this.sort.sort_type = fieldName;
    this.sort.sort_asc = this.sort.sort_asc === 'asc' ? 'desc' : 'asc';
    this.indexPage.query();
  }

  showSort(fieldName: string, upOrDowm?: 'asc'|'desc') {
    return this.sort.sort_type !== fieldName || upOrDowm === this.sort.sort_asc;
  }

  // *** Actions

  customlistId() {
    if (this.strategiesType === TYPES.CUSTOMLIST) {
      return parseInt(this.strategiesFilter, 10);
    }
    return null;
  }

  customListTooltip() {
    return this.strategiesType === 'customlists' ?
      this.$translate.instant('strategies.index.view.customlists.remove') :
      this.$translate.instant('strategies.index.view.customlists.add');
  }

  customListAction(strategy: Strategy) {
    // inside the custom list, remove strategy from customlist
    if (this.strategiesType === 'customlists') {
      this.ngDialog.openConfirm({
        template: '<page-strategies-customlists-delete-confirm></page-strategies-customlists-delete-confirm>',
        plain: true
      }).then(() => {
        this.customlistResource.updateAll({
          strategy_lists: [{
            id: this.customlistId()!,
            strategy_ids: [strategy.id],
            _destroy: true
          }]
        }).$promise.then(() => {
          this.indexPage.search();
        });
      });
    }
    // add to custom list
    else {
      this.ngDialog.openConfirm({
        template: '<page-strategies-customlists-new></page-strategies-customlists-new>',
        plain: true,
        data: {
          strategyIds: [strategy.id],
          ignoreStrategyListIds: (strategy.strategy_lists || []).map((strategyList) => {
            return strategyList.id;
          })
        }
      }).then(() => {
        this.indexPage.search();
      });
    }
  }

  onError(error = {}) {
    Object.keys(error).forEach(key => {
      this.toaster.error(key, error[key].join(', '));
    });
  }

  goTo(path) {
    (this.$location as any).$$search = {};
    this.$location.path(path);
  }

  detail(strategy: Strategy) {
    if (strategy['show?']) {
      this.goTo('/strategy/' + strategy.id);
    }
    // TODO: still valid?
    else if ((strategy as any).user_permission === userPermissions.STATUSES.PENDING) {
      this.$translate('strategies.index.controller.request_permission.pending').then(title => {
        this.toaster.error(title);
      });
    }
    // TODO: still valid?
    else if ((strategy as any).user_permission === userPermissions.STATUSES.BLOCKED) {
      this.$translate('strategies.index.controller.request_permission.blocked').then(title => {
        this.toaster.error(title);
      });
    }
    else {
      this.requestUserPermission(strategy);
    }
  }

  requestUserPermission(strategy: Strategy) {
    return this.ngDialog.openConfirm({
      template: '<page-strategies-user-permissions></page-strategies-user-permissions>',
      plain: true,
      data: {
        strategy
      }
    }).then(() => {
      return this.requestUserPermissionConfirm(strategy);
    });
  }

  requestUserPermissionConfirm(strategy: Strategy) {
    return this.strategyUserPermissionResource.save({
      strategy_id: strategy.id,
      user_id: this.user!.id,
      status: 'pending'
    }, () => this.requestUserPermissionConfirmSuccess(), error => this.onError(error.data));
  }

  requestUserPermissionConfirmSuccess() {
    this.$translate('strategies.index.controller.request_permission.success').then(title => {
      this.toaster.success(title);
    });
  }

  isClosingDate(strats: Strategy|Strategy[]) {
    const closingDate = new Date(this.criteriaVolumes.deprecation_limit_date);
    if (!closingDate || !strats) {
      return true;
    }

    if (!Array.isArray(strats)) {
      strats = [strats];
    }
    return isClosingDate(strats, closingDate);
  }
}
