import { Component, Input, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import './component.scss';
const template = require('./component.html.haml');

export const max = (values: {[key: string]: number}|number[]): number => {
  let maxValue = 0;
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      maxValue = Math.max(maxValue, values[key]);
    }
  }
  return maxValue;
};

@Component({
  selector: 'histogram-slider',
  transclude: true,
  template
})
export class HistogramSliderComponent implements OnChanges {
  @Input()
  private step: number;
  @Input()
  values: {[key: string]: number}|number[];
  @Input()
  min: number;
  @Input()
  max: number;

  public steps: number[] = [];
  public maxValue: number;

  ngOnChanges(changes: SimpleChanges) {
    if ('values' in changes) {
      const values = changes.values.currentValue;
      if (values) {
        this.updateSteps();
      }
    }
  }

  updateSteps() {
    this.maxValue = max(this.values);

    this.steps = [];
    let i = this.min;
    while (i <= this.max) {
      this.steps.push(this.values[i] || 0);
      i = i + this.step;
    }
  }
}
