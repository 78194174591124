import { Component, AfterViewInit } from 'angular-ts-decorators';

const template = require('./component.html.haml');

import { PanelsSidebarChild } from '../../panels';

@Component({
  selector: 'dashboards-sidebar-filters',
  template
})
export class DashboardsSidebarFiltersComponent extends PanelsSidebarChild implements AfterViewInit {
  hasSection() {
    return !!Object.keys(this.dashboards).some(e => this.dashboards[e].length > 0);
  }
}
