/**
 * Check if a field (or fields) are in error in a form
 * @param fields the field or a collection of fields
 * @param form the form
 * @param errors optional object containing error messages
 */
export const formHasErrors = (
  fields: string|string[], form: ng.IFormController, errors?: any
): boolean => {
  if (!Array.isArray(fields)) {
    fields = [fields];
  }
  for (const field of fields) {
    if (
      ((errors && errors[field]) || (form && form[field] && form[field].$invalid)) &&
      form.$submitted
    ) {
      return true;
    }
  }
  // check the whole form
  if (!fields.length) {
    return form.$invalid && form.$submitted;
  }

  return false;
};
