import {
  Component, Input, OnDestroy,
  OnChanges, SimpleChanges,
  ViewChild
} from 'angular-ts-decorators';
import { Strategy } from '@quantizr/front-model';
import merge = require('lodash/merge');

import './component.scss';
const template = require('./component.html.haml');

import { dateAtMidnight, DatesSelector } from '../../components';
import { TimeSeriesStockChartComponent } from '../../time-series';

const WINDOWS = ['3_months', '6_months', '1_year', '2_years', '3_years'];

@Component({
  selector: 'strategies-volatility',
  template
})
export class StrategiesVolatilityComponent implements OnDestroy, OnChanges {
  @ViewChild(TimeSeriesStockChartComponent)
  public chart: TimeSeriesStockChartComponent;

  @Input()
  public strategy: Strategy;
  @Input()
  public group: string;
  @Input()
  public datesSelector: DatesSelector;
  @Input('<?')
  public dates: any = {};
  @Input()
  public scroller: any;
  @Input()
  chartSettings: any;
  @Input()
  windows = WINDOWS;

  private chartTimeout: ng.IPromise<any>;

  public settings: any = {};

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService
  ) {
    this.settings = {
      plots: [{
        yScale: {
          comparisonMode: 'none',
          baseHundred: false,
          stackMode: null
        },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {},
      messageLoading: this.$translate.instant('strategies.show.performance.view.volatility.loading'),
      messageEmpty: this.$translate.instant('strategies.show.performance.view.volatility.empty')
    };
  }

  ngOnDestroy() {
    this.$timeout.cancel(this.chartTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('chartSettings' in changes) {
      const settings = changes.chartSettings.currentValue || {};
      merge(this.settings, settings);
    }

    if ('strategy' in changes) {
      const strategy = changes.strategy.currentValue;
      if (strategy) {
        this.initStrategy(strategy);
      }
    }
  }

  initStrategy(strategy: Strategy) {
    this.strategy = strategy;

    if (!this.dates.min && this.strategy.start_date) {
      this.dates.min = dateAtMidnight(this.strategy.start_date);
    }
    if (!this.dates.max && this.strategy.end_date) {
      this.dates.max = dateAtMidnight(this.strategy.end_date);
    }

    if (this.strategy.live_date) {
      this.settings.plots[0].annotations[0].verticalLine = {
        xAnchor: this.strategy.live_date
      };
    }

    this.refresh();
  }

  refresh(): void {
    this.chartTimeout = this.$timeout(() => {
      this.chart.requestParams = {
        'ids[]': [this.strategy.id],
        type: 'Strategy',
        qualifier: 'volatility',
        start_date: this.strategy.start_date,
        end_date: this.strategy.end_date,
        'windows[]': this.windows || WINDOWS
      };

      this.chart.refresh();
    });
  }
}
