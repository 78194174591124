import { NgModule } from 'angular-ts-decorators';

import { CustomSearchModule } from '../custom-search';
import { StrategiesModule } from '../strategies';
import { VendorModule } from '../vendor';

import { VersionsViewsService } from './views/service';

import { VersionsEditCalculationComponent } from './edit/calculation/component';
import { VersionsButtonShiftComponent } from './button-shift/component';
import { VersionsEditFeesComponent } from './edit/fees/component';
import { VersionsEditFiltersComponent } from './edit/filters/component';
import { VersionsEditStrategiesComponent } from './edit/strategies/component';
import { VersionsEditWeightsComponent } from './edit/weights/component';

export {
  VersionsViewsService
};

@NgModule({
  id: 'Versions',
  imports: [
    CustomSearchModule,
    StrategiesModule,
    VendorModule
  ],
  declarations: [
    VersionsEditCalculationComponent,
    VersionsButtonShiftComponent,
    VersionsEditFeesComponent,
    VersionsEditFiltersComponent,
    VersionsEditStrategiesComponent,
    VersionsEditWeightsComponent
  ],
  providers: [
    VersionsViewsService
  ]
})
export class VersionsModule {}

export default (VersionsModule as NgModule).module!.name;
