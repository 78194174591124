import {
  Component, Inject, OnDestroy, ViewChild
} from 'angular-ts-decorators';
import { StrategyResource, Strategy } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

const template = require('./page.html.haml');
import './page.scss';

import { StrategiesEditChildPage, childViewId } from './child';
import { typeFromError } from '../type';

@Component({
  selector: 'page-strategies-edit',
  template
})
export class StrategiesEditPage implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private watchers: Function[] = [];

  @ViewChild(childViewId)
  child: StrategiesEditChildPage|null = null;
  private initChildTimeout: ng.IPromise<any>;

  public errors: any = null;
  public strategy: Strategy|null = null;
  public strategyId: number;
  public saving = 0;
  public TABS: string[] = [];

  /*@ngInject*/
  constructor(
    @Inject('StrategyResource')
    private strategyResource: StrategyResource,
    $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    public $timeout: ng.ITimeoutService,
    $rootScope: ng.IRootScopeService,
    $scope: ng.IScope,
  ) {
    this.strategyId = $routeParams.id;

    this.watchers.push($rootScope.$on('routeSegmentChange', () => {
      this.init();

      // wait to be on the new page when chaging page
      if (this.initChildTimeout) {
        this.$timeout.cancel(this.initChildTimeout);
      }
      this.initChildTimeout = this.$timeout(async () => {
        await this.initChild();
        $scope.$digest();
      });
    }));
  }

  ngOnDestroy() {
    if (this.initChildTimeout) {
      this.$timeout.cancel(this.initChildTimeout);
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.watchers.forEach(watcher => watcher());
  }

  init() {
    this.errors = {};
    this.strategy = null;
    this.saving = 0;

    // editing existing strategy
    this.strategyResource.edit({
      id: this.strategyId,
    }).$promise.then(response => this.loadSuccess(response), error => this.loadError(error));
  }

  async initChild() {
    this.child = null;
    // wait for subpage to be loaded
    await this.waitChildLoaded();
    this.onChildLoaded();
  }

  async waitChildLoaded() {
    if (!this.child) {
      (this as any)._updateViewChildren();
      await this.$timeout(() => this.waitChildLoaded());
    }
  }

  onChildLoaded() {
    if (this.child) {
      this.subscriptions.push(this.child.$saving().subscribe(saving => {
        this.saving = saving.error ? 2 : (saving.saving ? 1 : 0);
      }));

      if (this.strategy) {
        this.child.strategy = this.strategy;
      }
    }
  }

  loadSuccess(response) {
    this.strategy = response;

    if (this.strategy) {
      if (this.child) {
        this.child.strategy = this.strategy;
      }

      if (!this.strategy.matrix!.alpha) {
        this.strategy.matrix!.alpha = {};
      }

      if (!this.strategy.matrix!.beta) {
        this.strategy.matrix!.beta = {};
      }

      if (this.strategy.live_date) {
        this.strategy.live_date = new Date(this.strategy.live_date as string);
      }

      if (this.strategy.draft_version && this.strategy.draft_version.start_date) {
        this.strategy.draft_version.start_date = new Date(
          this.strategy.draft_version.start_date as string
        );
      }
    }

    this.enableTabs();
  }

  enableTabs() {
    this.TABS = [];

    if (this.strategy) {
      if (this.strategy.draft_version && this.strategy.draft_version['update?']) {
        this.TABS.push('calculation');
      }
      if (this.strategy['strategy_time_serie_files_new?']) {
        this.TABS.push('fixings');
      }
      if (this.strategy['update?']) {
        this.TABS.push('settings');
        this.TABS.push('permissions');
      }
    }
  }

  loadError(error) {
    const type = typeFromError(error);

    // used in strategies/about.html
    this.$translate(
      `strategies.edit.controller.load.error.${type}`
    ).then(value => {
      this.errors = {
        load: value
      };
    });
  }

  cancel() {
    this.$location.path('/strategy/' + this.strategy!.id);
  }

  save() {
    if (this.child) {
      this.child.save();
    }
  }
}
