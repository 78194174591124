import {
  Directive, Input, OnDestroy, OnChanges, SimpleChanges
} from 'angular-ts-decorators';

@Directive({
  selector: 'focus-on'
})
export class FocusOnDirective implements OnChanges, OnDestroy {
  @Input('@')
  focusOn: string;
  @Input('@')
  focusOnEvent: string;

  /*@ngInject*/
  constructor(private $element: ng.IAugmentedJQuery) {}

  ngOnDestroy() {
    this.$element.off(this.focusOnEvent, this.onEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('focusOn' in changes) {
      const selector = changes.focusOn.currentValue;
      if (!selector || !selector.length) {
        return console.error('focusOn: ' +
          'selector missing. Usage: \'focus-on="selector"\'');
      }
    }

    if ('focusOnEvent' in changes) {
      const event = changes.focusOnEvent.currentValue;
      if (!event) {
        return console.error('focusOn: ' +
          'event missing. Usage: \'focus-on-event="click"\'');
      }
      else if (changes.focusOnEvent.previousValue) {
        this.$element.off(changes.focusOnEvent.previousValue, this.onEvent);
      }

      this.$element.on(this.focusOnEvent, this.onEvent.bind(this));
    }
  }

  onEvent() {
    setTimeout(() => {
      this.$element.find(this.focusOn).focus();
    });
  }
}
