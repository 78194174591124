import { Component, Input, ViewChild } from 'angular-ts-decorators';
import { CockpitProfile } from '@quantizr/front-model';
const template = require('./component.html.haml');

import { PROFILE_WINDOWS, QUALIFIERS } from '../../';
import { TimeSeriesStockChartComponent, IStockChartRequestParams } from '../../../time-series';

@Component({
  selector: 'cockpits-profiles-chart-history',
  template
})
export class CockpitsProfilesChartHistoryComponent {
  private chartsTimeout: ng.IPromise<any>;
  private volatilityChartTimeout: ng.IPromise<any>;

  @ViewChild('fixings')
  public fixingsChart: TimeSeriesStockChartComponent;
  @ViewChild('volatility')
  public volatilityChart: TimeSeriesStockChartComponent;

  @Input()
  profile: CockpitProfile;
  @Input()
  dates: any;
  @Input()
  datesSelector: any;

  public QUALIFIERS = QUALIFIERS;
  public WINDOWS = PROFILE_WINDOWS;

  public currentQualifier = QUALIFIERS[1];
  public currentWindow = PROFILE_WINDOWS[1];

  public fixingsParams: IStockChartRequestParams;
  public volatilityParams: IStockChartRequestParams;

  public perfSettings: any;
  public volatilitySettings: any;

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService
  ) {}

  ngOnDestroy() {
    this.$timeout.cancel(this.chartsTimeout);
    this.$timeout.cancel(this.volatilityChartTimeout);
  }

  ngOnInit() {
    if (!this.profile || !this.profile.portfolios) {
      return;
    }

    const portfolioIds = this.profile.portfolios.map((p) => p.id);
    this.perfSettings = {
      plots: [{
        colors: {},
        yScale: { comparisonMode: 'percent', baseHundred: true, stackMode: null },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      messageLoading: this.$translate.instant('timeSeries.stockChart.loading'),
      messageEmpty: this.$translate.instant('timeSeries.stockChart.empty'),
      tooltip: {}
    };
    this.volatilitySettings = {
      plots: [{
        colors: {},
        yScale: { comparisonMode: 'none', baseHundred: false, stackMode: null },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      messageLoading: this.$translate.instant('timeSeries.stockChart.loading'),
      messageEmpty: this.$translate.instant('cockpits.profiles.chartHistory.controller.empty'),
      tooltip: {}
    };

    this.fixingsParams = {
      'ids[]': portfolioIds,
      type: 'Strategy',
      qualifier: 'fixings'
    };
    this.volatilityParams = {
      'ids[]': portfolioIds,
      type: 'Strategy',
      qualifier: 'volatility',
      'windows[]': [this.currentWindow]
    };

    this.chartsTimeout = this.$timeout(() => {
      (this as any)._updateViewChildren();
      this.fixingsChart.refresh();
      this.volatilityChart.refresh();
    });
  }

  changeWindow(window: string) {
    this.currentWindow = window;
    this.volatilityParams['windows[]'] = [window];
    this.volatilityChartTimeout = this.$timeout(() => {
      this.volatilityChart.refresh();
    });
  }

  changeQualifier(qualifier: string) {
    this.currentQualifier = qualifier;
  }
}
