import { Component, Input, AfterViewInit, OnDestroy, ViewChild } from 'angular-ts-decorators';
import { Simulation, SimulationWidget } from '@quantizr/front-model';
const template = require('./component.html.haml');

import { TimeSeriesStockChartComponent } from '../../../time-series';

@Component({
  selector: 'simulations-widgets-graph-line',
  template
})
export class SimulationsWidgetsGraphLineComponent implements AfterViewInit, OnDestroy {
  @ViewChild(TimeSeriesStockChartComponent)
  private chart: TimeSeriesStockChartComponent;

  @Input()
  simulation: Simulation;
  @Input()
  widget: SimulationWidget;

  private chartTimeout: ng.IPromise<any>;

  public containerId: string;
  public settings: any;

  /*@ngInject*/
  constructor(
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnDestroy() {
    this.$timeout.cancel(this.chartTimeout);
  }

  ngAfterViewInit() {
    this.containerId = 'graph-line-' + this.widget.id;

    this.settings = {
      plots: [{
        colors: {},
        yScale: {
          comparisonMode: 'percent',
          baseHundred: true,
          stackMode: null
        },
        yAxis: { labels: {} },
        line: {},
        legend: { enabled: true },
        annotations: [{ verticalLine: {} }]
      }],
      tooltip: {},
      messageLoading: this.$translate.instant('strategies.show.performance.view.fixings.loading'),
      messageEmpty: this.$translate.instant('strategies.show.performance.view.fixings.empty')
    };

    this.chartTimeout = this.$timeout(() => {
      this.chart.requestParams = {
        qualifier: 'fixings',
        type: 'Strategy',
        'ids[]': [
          this.simulation.version_attributes.strategy_id
        ]
      };
      this.chart.refresh();
    });
  }

  title() {
    const title = this.$translate.instant(
      'simulations.simulation_indicators.categories.' + this.widget.y_category
    );
    if (!this.widget.y_window) {
      return title;
    }
    else {
      return [
        title,
        this.$translate.instant(
          'simulations.simulation_indicators.windows.' + this.widget.y_window
        )
      ].join(' - ');
    }
  }
}
