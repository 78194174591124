import { NgModule } from 'angular-ts-decorators';
import SimulationResourceFactory from '@quantizr/front-model/dist/simulations/resource';
import SimulationTimeSerieResourceFactory from '@quantizr/front-model/dist/simulations/time-series/resource';
import SimulationWidgetResourceFactory from '@quantizr/front-model/dist/simulations/widgets/resource';

import './css-sprite.scss';

import { BenchmarksModule } from '../benchmarks';
import { ComponentsModule } from '../components';
import { MyUserModule } from '../my-user';
import { PanelsModule } from '../panels';
import { StrategiesModule } from '../strategies';
import { TimeSeriesModule } from '../time-series';
import { VendorModule } from '../vendor';
import { VersionsModule } from '../versions';

import { SimulationsViewsService } from './views/service';

import { SimulationsCancelConfirmPage } from './cancel/confirm/page';
import { SimulationsDeleteConfirmPage } from './delete/confirm/page';
import { SimulationsEditWindowsComponent } from './edit/windows/component';
import { SimulationsEditWindowsPage } from './edit/windows/page';
import { SimulationsIndexPage } from './index/page';
import { SimulationsNewIndicatorsComponent } from './new/indicators/component';
import { SimulationsNewParametersComponent } from './new/parameters/component';
import { SimulationsNewScenariosComponent } from './new/scenarios/component';
import { SimulationsNewPage } from './new/page';
import { SimulationsResetConfirmPage } from './reset/confirm/page';
import { SimulationsResultPage } from './result/page';
import { SimulationsShowPage } from './show/page';
import { SimulationsSidebarFiltersComponent } from './sidebar-filters/component';
import { SimulationsStatusComponent } from './status/component';
import { SimulationsWidgetsDeleteConfirmPage } from './widgets/delete/confirm/page';
import { SimulationsWidgetsEditComponent } from './widgets/edit/component';
import { SimulationsWidgetsEditPage } from './widgets/edit/page';
import { SimulationsWidgetsGraphLineComponent } from './widgets/graph-line/component';
import { SimulationsWidgetsXYChartComponent } from './widgets/x-y-chart/component';

@NgModule({
  id: 'Simulations',
  imports: [
    BenchmarksModule,
    ComponentsModule,
    MyUserModule,
    PanelsModule,
    StrategiesModule,
    TimeSeriesModule,
    VendorModule,
    VersionsModule
  ],
  declarations: [
    SimulationsCancelConfirmPage,
    SimulationsDeleteConfirmPage,
    SimulationsEditWindowsComponent,
    SimulationsEditWindowsPage,
    SimulationsIndexPage,
    SimulationsNewIndicatorsComponent,
    SimulationsNewParametersComponent,
    SimulationsNewScenariosComponent,
    SimulationsNewPage,
    SimulationsResetConfirmPage,
    SimulationsResultPage,
    SimulationsSidebarFiltersComponent,
    SimulationsShowPage,
    SimulationsStatusComponent,
    SimulationsWidgetsDeleteConfirmPage,
    SimulationsWidgetsEditComponent,
    SimulationsWidgetsEditPage,
    SimulationsWidgetsGraphLineComponent,
    SimulationsWidgetsXYChartComponent
  ],
  providers: [
    SimulationsViewsService,
    {
      provide: 'SimulationResource',
      useFactory: ($resource) => SimulationResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'SimulationTimeSerieResource',
      useFactory: ($resource) => SimulationTimeSerieResourceFactory($resource),
      deps: [
        '$resource'
      ]
    },
    {
      provide: 'SimulationWidgetResource',
      useFactory: ($resource) => SimulationWidgetResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class SimulationsModule {}

export default (SimulationsModule as NgModule).module!.name;
