import { Component, Inject, OnDestroy } from 'angular-ts-decorators';
import { File, FileResource,  } from '@quantizr/front-model';
import { Subscription } from 'rxjs';

import { MyUserService } from '../../my-user';

import './page.scss';
const template = require('./page.html.haml');

@Component({
  selector: 'page-files-show',
  template
})
export class FilesShowPage implements OnDestroy {
  public fileId: String = '';
  public file: File;
  public safeUrl: string = '';
  private watchers: Function[] = [];
  private subscriptions: Subscription[] = [];

  /*@ngInject*/
  constructor(
    @Inject('FileResource')
    private fileResource: FileResource,
    @Inject('MyUserService')
    myUserService: MyUserService,
    private $location: ng.ILocationService,
    private $routeParams: ng.route.IRouteParamsService,
    private $sce: ng.ISCEService,
    private $translate: ng.translate.ITranslateService,
    private $window: ng.IWindowService
  ) {
    this.subscriptions.push(
      myUserService.$user().subscribe(
        (user) => {
          if (!!user) {
            this.getFile();
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
  }

  getFile() {
    if (!this.$routeParams.id) {
      this.$location.path('/files');
    }
    this.fileResource.get({
      id: this.$routeParams.id
    }).$promise.then(
      response => this.getFileSuccess(response), response => this.getFileError(response)
    );
  }

  getFileSuccess(file) {
    this.file = file;
    if (this.$window.innerWidth < 768) {
      this.file = {
        title: this.$translate.instant('files.show.redirect_file.title'),
        subTitle: this.$translate.instant('files.show.redirect_file.description')
      } as any;
      this.$window.location.href = this.$sce.trustAsResourceUrl(file.file_path);
    }
    else {
      this.safeUrl = this.$sce.trustAsResourceUrl(this.file.file_path);
    }
  }

  getFileError(error) {
    this.file = {
      title: error.data.message[0],
    } as any;
  }
}
