import { Pipe, PipeTransform } from 'angular-ts-decorators';

export interface IFilterService extends ng.IFilterService {
  (name: 'locationStartsWith'): (path: string) => boolean;
}

@Pipe({
  name: 'locationStartsWith'
})
export class LocationStartsWithPipe implements PipeTransform {
  /*@ngInject*/
  constructor(private $location: ng.ILocationService) {}

  transform(path: string): boolean {
    const currentPath = this.$location.path();
    return !!currentPath && currentPath.indexOf(path) === 0;
  }
}
