import { Injectable, Inject } from 'angular-ts-decorators';
import {
  CockpitResource, Cockpit,
  CockpitProfileResource, CockpitProfile, CockpitPortfolio,
  CockpitProfileGroupedKeywords
} from '@quantizr/front-model';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { COLORS } from '../colors';
import { MyUserService } from '../../my-user';

export interface ICockpitProfileGroupedKeywords extends CockpitProfileGroupedKeywords {
  open: boolean;
  color: string;
}

@Injectable('CockpitsViewsService')
export class CockpitsViewsService {
  private _$cockpit: ReplaySubject<Cockpit|null> = new ReplaySubject(1);
  private _cockpitProfile: CockpitProfile|null = null;
  private _$cockpitProfile: ReplaySubject<CockpitProfile|null> = new ReplaySubject(1);
  private _$cockpitPortfolio: ReplaySubject<CockpitPortfolio|null> = new ReplaySubject(1);
  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('CockpitResource')
    private cockpitResource: CockpitResource,
    @Inject('CockpitProfileResource')
    private cockpitProfileResource: CockpitProfileResource
  ) {
    myUserService.$user().subscribe(user => {
      if (user !== null) {
        this.loadCockpit();
      }
    });
  }

  /**
   * Load the cockpit
   * @param onProfileLoaded Function called when the profile is loaded
   */
  loadCockpit(onProfileLoaded?: Function) {
    this.cockpitProfile(null);

    if (onProfileLoaded) {
      const subscription = this.$cockpitProfile().subscribe(profile => {
        if (profile) {
          subscription.unsubscribe();
          onProfileLoaded();
        }
      });
    }

    return this.cockpitResource.get().$promise.then(response => {
      this.loadCockpitSuccess(response);
    }, () => this.loadCockpitError());
  }

  loadCockpitSuccess(cockpit: Cockpit) {
    this.cockpit(cockpit);
  }

  loadCockpitError() {
    this.cockpit(null);
  }

  loadCockpitProfile(cockpit: Cockpit, cockpitProfileId?: number) {
    let cockpitProfile: CockpitProfile|undefined;
    const profiles = cockpit.profiles || [];

    if (cockpitProfileId) {
      cockpitProfile = profiles.find(profile => {
        return profile.id === cockpitProfileId;
      });
    }
    else {
      cockpitProfile = profiles[0];
    }

    // do not reload already loaded profile
    if (this._cockpitProfile && cockpitProfile && this._cockpitProfile.id === cockpitProfile.id) {
      return;
    }
    // make sure we don't load the wrong profile
    this.cockpitProfile(null);

    if (cockpitProfile) {
      this.cockpitProfileResource.get({
        id: cockpitProfile.id
      }).$promise.then(response => this.cockpitProfile(response), () => this.cockpitProfile(null));
    }
    else {
      this.cockpitProfile(null);
    }
  }

  public redirectToProfile($location: ng.ILocationService, $routeParams: ng.route.IRouteParamsService) {
    if (!('profileId' in $routeParams)) {
      const current = $location.path();
      return this.$cockpitProfile().pipe(take(1)).toPromise().then(profile => {
        if (profile !== null) {
          $location.path(`${current}/${profile.id}`);
        }
      });
    }
    return null;
  }

  public cockpit(cockpit: Cockpit|null) {
    this._$cockpit.next(cockpit);
  }

  public $cockpit() {
    return this._$cockpit.asObservable();
  }

  public cockpitProfile(cockpitProfile: CockpitProfile|null) {
    this._cockpitProfile = cockpitProfile;
    this._initColor();
    this._$cockpitProfile.next(cockpitProfile);
  }

  public $cockpitProfile() {
    return this._$cockpitProfile.asObservable();
  }

  public $cockpitPortfolio() {
    return this._$cockpitPortfolio.asObservable();
  }

  public cockpitPortfolio(cockpitPortfolio: CockpitPortfolio|null) {
    this._$cockpitPortfolio.next(cockpitPortfolio);
  }

  private _initColor() {
    if (this._cockpitProfile && this._cockpitProfile.grouped_keywords) {
      const keywords = this._cockpitProfile.grouped_keywords;

      for (let i = 0; i < keywords.length; i++) {
        const group = keywords[i] as ICockpitProfileGroupedKeywords;
        group.open = true;
        group.color = COLORS[i % COLORS.length];
        if (group.children) {
          (group.children as ICockpitProfileGroupedKeywords[]).forEach(child => {
            child.open = false;
            child.color = group.color;
            if (child.strategies) {
              child.strategies.forEach((strategy: any) => strategy.color = group.color);
            }
          });
        }
      }
    }
  }
}
