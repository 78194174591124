import { MyUserService } from '../';

export const isAuthenticated = (
  usersService: MyUserService
) => {
  return usersService.currentUser().then(user => {
    if (!user) {
      usersService.localStorageService.set('redirectAfterSignIn', usersService.$location.url());
      usersService.requestLogin();
    }
  }).catch(() => usersService.requestLogin());
};
