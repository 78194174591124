export const hexToRgbValues = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toLowerCase());
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const hexToRgb = (hex: string) => {
  const values = hexToRgbValues(hex);
  return values ? `rgb(${values.r}, ${values.g}, ${values.b})` : null;
};

export const hexToRgba = (hex: string, alpha: number) => {
  if (!hex) {
    return '';
  }
  const values = hexToRgbValues(hex);
  return values ? `rgba(${values.r}, ${values.g}, ${values.b}, ${alpha})` : null;
};
