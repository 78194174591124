import { Component, Input, AfterViewInit } from 'angular-ts-decorators';
import { Simulation } from '@quantizr/front-model';
const template = require('./component.html.haml');

import { badge } from '../badge';

@Component({
  selector: 'simulations-status',
  template
})
export class SimulationsStatusComponent implements AfterViewInit {
  @Input()
  public simulation: Simulation;

  public badgeClass: string|undefined;

  ngAfterViewInit() {
    this.badgeClass = badge(this.simulation);
  }
}
