import { NgModule, Inject } from 'angular-ts-decorators';

import { EnvModule } from '../env';
import { VendorModule } from '../vendor';

import config from './config';
import { AnalyticsService } from './service';

export {
  AnalyticsService
};

@NgModule({
  id: 'Analytics',
  imports: [
    EnvModule,
    VendorModule
  ],
  providers: [
    AnalyticsService
  ]
})
export class AnalyticsModule {
  static run(
    $rootScope: ng.IRootScopeService,
    @Inject('AnalyticsService')
    analyticsService: AnalyticsService
  ) {
    'ngInject';
    config($rootScope, analyticsService);
  }
}

export default (AnalyticsModule as NgModule).module!.name;
