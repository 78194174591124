import { Component, Input, Inject, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import 'anychart/dist/js/anychart-circular-gauge.min';

import './component.scss';
const template = require('./component.html.haml');

import { BaseChartComponent } from '../base-chart/component';
import { BaseChartService } from '../base-chart/service';
import { COLORS } from '../colors';

const color = '#545f69';

@Component({
  selector: 'gauge-chart',
  template
})
export class GaugeChartComponent extends BaseChartComponent<anychart.charts.CircularGauge> implements OnChanges {
  @Input()
  color: string = COLORS[0];
  @Input()
  value: number;

  /*@ngInject*/
  constructor(
    @Inject('BaseChartService')
    baseChartService: BaseChartService
  ) {
    super(baseChartService);
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('value' in changes) {
      const value = changes.value.currentValue;
      if (value >= 0) {
        this.refresh();
      }
    }
  }

  public initChart() {
    this.chart = anychart.gauges.circular();

    this.chart.credits().enabled(false);
    this.chart.stroke(null as any).padding(0).margin(5);
    this.chart.startAngle(270).sweepAngle(180);
    this.chart.axis().labels().enabled(false);
    this.chart.axis().scale().minimum(0).maximum(100);
    this.chart.axis().ticks({
      interval: 25
    });
    this.chart.axis().minorTicks({
      interval: 5
    });
    this.chart.axis().fill(color).width(1).ticks({
      type: 'line',
      fill: 'white',
      length: 2
    });
    this.chart.needle().stroke(`2 ${color}`);
    this.chart.cap().radius('3%').enabled(true);
    this.chart.cap().fill(color);
  }

  public refresh() {
    if (!this.chart) {
      return;
    }

    this.chart.data([
      this.value
    ]);
    this.chart.range(0, {
      from: 0,
      to: this.value,
      position: 'inside',
      fill: this.color,
      startSize: 50,
      endSize: 50,
      radius: 98
    });

    this.onRefreshedDone();
  }
}
