import { Component, Inject } from 'angular-ts-decorators';
import { MyUser } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { PanelsSidebarService } from './service';
import { Subscription } from 'rxjs';
import { MyUserService } from '../../my-user/service';

let navigationOpen: boolean|null = null;

@Component({
  selector: 'panels-sidebar',
  template,
  transclude: true
})
export class PanelsSidebarComponent {
  protected subscriptions: Subscription[] = [];
  public currentUser: MyUser|null = null;

  /*@ngInject*/
  constructor(
    @Inject('PanelsSidebarService')
    private panelsSidebarService: PanelsSidebarService,
    @Inject('MyUserService')
    myUserService: MyUserService,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService
  ) {
    // collapse sidebar automatically on small screens
    if (navigationOpen === null) {
      this.navigationOpen = window.innerWidth >= 768;
    }

    this.subscriptions.push(
      myUserService.$user().subscribe((user) => {
          this.currentUser = user;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // If panel-body contains slider, make sure they are rendered properly when loaded
  // TODO: use a service instead and re-render in Index
  public rerenderSliders() {
    this.$timeout(() => {
      this.$rootScope.$broadcast('rzSliderForceRender');
    });
  }

  get navigationOpen(): boolean|null {
    return navigationOpen;
  }

  set navigationOpen(open: boolean|null) {
    navigationOpen = open;

    if (open) {
      this.panelsSidebarService.open();
    }
    else {
      this.panelsSidebarService.close();
    }
    this.rerenderSliders();
  }

  public toggleNavigation() {
    this.navigationOpen = !this.navigationOpen;
  }
}
