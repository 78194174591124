import { Component } from 'angular-ts-decorators';
import { variables as strategies } from '@quantizr/front-model/dist/strategies/variables';

import './page.scss';
const template = require('./page.html.haml');

import { StrategiesEditChildPage } from '../child';
import { StrategyMatrix } from '@quantizr/front-model';

export const RETURN_TYPES = Object.keys(strategies.RETURN_TYPES).map(key => {
  return {
    value: strategies.RETURN_TYPES[key]
  };
});

export const OPEN_FUND_COUNTRIES = [
  'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'IS', 'GR', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LI',
  'LT', 'LU', 'HU', 'MT', 'NL', 'NO', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'CH', 'GB'
];

@Component({
  selector: 'page-strategies-edit-settings',
  template
})
export class StrategiesEditSettingsPage extends StrategiesEditChildPage {
  public INSTRUMENTS = strategies.INSTRUMENTS;
  public CURRENCIES = strategies.CURRENCIES;
  public RETURN_TYPES = RETURN_TYPES;
  public OPEN_FUND_COUNTRIES = OPEN_FUND_COUNTRIES;

  managementFeesInludedChanged() {
    if (this.strategy && this.strategy.management_fees_included) {
      this.strategy.management_fees = 0;
    }
  }

  factsheetUploaded(factsheet) {
    this.strategy.factsheet = factsheet;
  }

  updateMatrix(matrix: StrategyMatrix, alphaBeta: 'alpha'|'beta') {
    this.strategy.matrix![alphaBeta] = matrix;
  }
}
