import { Component, Input, OnChanges, SimpleChanges, OnInit } from 'angular-ts-decorators';
import { CockpitProfile } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { colorFor } from './color-for';
import { IContributionTable } from '../show/group-tables';

import {
  ITableGroupedHeader,
  ITableGroupedGroup,
  ITableGroupedValue
} from '../../../components/table-grouped/component';

@Component({
  selector: 'cockpits-risk-contribution-table',
  transclude: {
    group: '?paneGroup'
  },
  template
})
export class CockpitsRiskContributionTableComponent implements OnInit, OnChanges {
  private subGroupTotal: any = {};

  @Input()
  public profile: CockpitProfile;
  @Input()
  public tables: IContributionTable;
  @Input()
  public tableType: 'weights'|'absolute'|'relative'|'marginal';
  @Input()
  public total: any;
  @Input()
  public totalIsPercentage = false;
  @Input()
  public conditionalColor = false;

  public headers: ITableGroupedHeader[] = [];
  public footers: ITableGroupedValue[] = [];
  public groups: ITableGroupedGroup[] = [];

  /*@ngInject*/
  constructor(
    private $translate: ng.translate.ITranslateService
  ) {}

  ngOnInit() {
    this.refreshSubGroupTotal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('profile' in changes) {
      this.loadHeaders();
    }
    if ('total' in changes || 'totalIsPercentage' in changes) {
      this.loadFooters();
    }
    if ('profile' in changes || 'tables' in changes || 'tableType' in changes) {
      this.refreshSubGroupTotal();
      this.updateGroups();
    }
  }

  loadHeaders() {
    return this.$translate([
      'cockpits.profiles.strategiesListing.isin',
      'cockpits.profiles.strategiesListing.name'
    ])
      .then(values => {
        this.headers = Object.keys(values).map(value => {
          return {
            label: values[value],
            colspan: 1
          };
        });
      })
      .then(() => {
        (this.profile.portfolios || []).forEach(portfolio => {
          this.headers.push({
            label: portfolio.name,
            colspan: 1
          });
        });
      });
  }

  loadFooters() {
    if (!this.profile || !this.total) {
      return;
    }

    this.footers = (this.profile.portfolios || []).map(portfolio => {
      return {
        value: this.total[portfolio.id],
        isPercentage: this.totalIsPercentage
      };
    });
  }

  refreshSubGroupTotal() {
    this.subGroupTotal = {};

    if (!this.profile || !this.tables) {
      return;
    }

    (this.profile.portfolios || []).forEach(portfolio => {
      const portfolioValues = {};

      Object.keys(this.tables.children).forEach(childKey => {
        const child: any = this.tables.children[childKey];
        Object.keys(child.children).forEach(subchildKey => {
          const subChild: any = child.children[subchildKey];
          if (subChild[this.tableType][portfolio.id]) {
            portfolioValues[subchildKey] = subChild[this.tableType][portfolio.id];
          }
        });
      });

      this.subGroupTotal[portfolio.id] = portfolioValues;
    });
  }

  public colorFor(value: number) {
    if (this.conditionalColor) {
      return colorFor(this.subGroupTotal, value);
    }
    else {
      return;
    }
  }

  groupValues(table: IContributionTable, setBgColor = false) {
    const values: ITableGroupedValue[] = [];
    (this.profile.portfolios || []).forEach(portfolio => {
      const value = table[this.tableType][portfolio.id] || 0;
      const bgColor = setBgColor ? this.colorFor(value) : undefined;
      values.push({
        value,
        isPercentage: true,
        bgColor
      });
    });
    return values;
  }

  updateGroups() {
    if (!this.profile || !this.tables) {
      return;
    }

    this.groups = (this.profile.grouped_keywords || []).map(group => {
      const color = (group as any).color;
      const groupTable = this.tables.children[group.id || 0];

      const children = (group.children || []).map(subgroup => {
        const subgroupTable = groupTable.children[subgroup.id || 0];

        const strategies = (subgroup.strategies || []).map(strategy => {
          let href = '';
          if (strategy.type === 'Strategy' || strategy.type === 'Portfolio') {
            href = `#!/strategy/${strategy.id}`;
          }
          return {
            id: strategy.isin || '',
            href,
            name: strategy.name || '',
            values: this.groupValues(subgroupTable.children[strategy.id])
          };
        });

        return {
          id: subgroup.id,
          name: subgroup.name,
          open: (subgroup as any).open || false,
          values: this.groupValues(subgroupTable, true),
          children: strategies
        };
      });

      return {
        id: group.id,
        name: group.name,
        open: (group as any).open || false,
        color,
        values: this.groupValues(groupTable),
        children
      };
    });
  }
}
