import { Component, Inject, OnInit, OnDestroy } from 'angular-ts-decorators';
import { SimulationResource, ISimulation, Strategy } from '@quantizr/front-model';
import { variables as versions } from '@quantizr/front-model/dist/versions/variables';
import { variables as dynamicSelections } from '@quantizr/front-model/dist/dynamic-selections/variables';

import './page.scss';
const template = require('./page.html.haml');

import { SimulationsViewsService } from '../views/service';

interface ITab {
  id: string;
  index: number;
}

const TABS: {[key: string]: ITab} = {
  scenarios: {
    id: 'scenario',
    index: 0
  },
  parameters: {
    id: 'parameters',
    index: 1
  },
  indicators: {
    id: 'indicators',
    index: 2
  }
};

export interface ISimulationNewShift {
  name: string;
  version_attributes: any;
}

export interface ISimulationNew extends ISimulation {
  strategy?: Strategy;
  shifts_attributes: ISimulationNewShift[];
  simulation_indicators_attributes: Array<{
    category: string;
    window: string;
  }>;
}

@Component({
  selector: 'page-simulations-new',
  template
})
export class SimulationsNewPage implements OnInit, OnDestroy {
  private watchers: Function[] = [];

  public DYNAMIC_SELECTION_TYPES = dynamicSelections.DYNAMIC_SELECTION_TYPES;
  public ALLOCATION_TYPES = versions.ALLOCATION_TYPES;
  public errorMessages: any = null;
  public TABS = TABS;
  public now = new Date();
  public step = 1;
  public selectedTab: ITab;
  public forms: any = {};
  public saving = 0;
  public simulation: ISimulationNew;

  /*@ngInject*/
  constructor(
    @Inject('SimulationResource')
    private simulationResource: SimulationResource,
    @Inject('SimulationsViewsService')
    private simulationsViewsService: SimulationsViewsService,
    private $filter: ng.IFilterService,
    private $location: ng.ILocationService,
    private $translate: ng.translate.ITranslateService,
    private ngDialog: ng.dialog.IDialogService,
    $rootScope: ng.IRootScopeService
  ) {
    this.watchers.push($rootScope.$on('$locationChangeStart', event => {
      this.locationChangeStart(event);
    }));
  }

  ngOnDestroy() {
    this.watchers.forEach(watcher => watcher());
    this.simulationsViewsService.clear();
  }

  ngOnInit() {
    this.errorMessages = {};
    this.saving = 0;
    this.forms = {};
    this.changeTab(TABS[Object.keys(TABS)[0]]);

    const name = this.$translate.instant('simulations.new.controller.name', {
      currentDate: this.$filter('date')(new Date(), 'dd MMM yyyy - h:mm a')
    });

    this.simulation = new this.simulationResource({
      name,
      simulation_indicators_attributes: [
        { category: 'volatility', window: '1_year' },
        { category: 'return_pa', window: '1_year' },
      ]
    }) as any;
  }

  back() {
    this.$location.path('/simulations');
  }

  shiftParametersCount() {
    if (this.simulation.shift_parameters) {
      return Object.keys(this.simulation.shift_parameters).length;
    }
    return 0;
  }

  // === Tabs

  changeTab(tab: ITab) {
    this.selectedTab = tab;
  }

  _formTab(tab: ITab): ng.IFormController {
    return this.forms.form[tab.id + 'Form'];
  }

  isSelectedTab(tab: ITab) {
    return this.selectedTab && tab.id === this.selectedTab.id;
  }

  isFirstTab(tab: ITab) {
    return tab.index === 0;
  }

  isLastTab(tab: ITab) {
    return tab.index === Object.keys(TABS).length - 1;
  }

  prevTab() {
    const index = this.selectedTab.index;
    const key = Object.keys(TABS)[index - 1];

    this.changeTab(TABS[key]);
  }

  nextTab() {
    const index = this.selectedTab.index;
    const key = Object.keys(TABS)[index + 1];

    this.changeTab(TABS[key]);
  }

  validateTab() {
    const form = this._formTab(this.selectedTab);
    form.$submitted = true;
    return form.$valid;
  }

  selectTab(tab: ITab) {
    if (!this.validateTab()) {
      return;
    }

    const form = this._formTab(tab);
    if (form.$submitted) {
      this.changeTab(tab);
    }
    else {
      this.nextTab();
    }
  }

  // === Form

  // TODO: update errors when we submit the form to avoid continuous digests
  hasError(tab: ITab) {
    const form = this._formTab(tab);
    return (form && form.$submitted && form.$invalid) ||
           $('[name="' + tab.id + 'Form"]').find('.has-error').length;
  }

  save() {
    this.forms.form.$submitted = true;
    if (this.forms.form.$valid === false) {
      return this.saveError({
        data: {}
      });
    }

    this.saving = 1;
    this.simulation.$save().then(simulation => {
      this.saveSuccess(simulation);
    }, error => this.saveError(error));
  }

  saveSuccess(simulation) {
    this.saving = 0;
    this.errorMessages = null;
    this.forms.form.$dirty = false;

    this.$location.path('/simulation/' + simulation.id);
  }

  saveError(errors) {
    this.saving = 2;
    this.errorMessages = errors.data;
  }

  locationChangeStart(event: any) {
    if (this.forms && this.forms.form) {
      if (this.forms.form.$dirty) {
        const next = this.$location.path();
        event.preventDefault();

        return this.ngDialog.openConfirm({
          template: '<page-simulations-cancel-confirm></page-simulations-cancel-confirm>',
          plain: true,
          className: 'ngdialog-theme-default',
          data: {
            simulation: this.simulation
          }
        }).then(() => {
          this.forms.form.$dirty = false;
          this.$location.path(next);
        });
      }
    }

    return;
  }
}
