import { Component } from 'angular-ts-decorators';
const template = require('./page.html.haml');

@Component({
  selector: 'page-glossary-index',
  template
})
export class GlossaryIndexPage {
  public flatdocSrc: string;

  /*@ngInject*/
  constructor($translate: ng.translate.ITranslateService) {
    this.flatdocSrc = `assets/markdowns/glossary/${$translate.use()}.md`;
  }
}
