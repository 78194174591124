import { Injectable } from 'angular-ts-decorators';
import { ReplaySubject, Subject } from 'rxjs';
import { Strategy } from '@quantizr/front-model';
import merge = require('lodash/merge');

import { VIEWS } from './constants';

@Injectable('StrategiesViewsService')
export class StrategiesViewsService {
  private _strategy: Strategy|null = null;
  private _$strategy: ReplaySubject<Strategy|null> = new ReplaySubject(1);
  // define list of available views
  private _views: any = {};
  private _$views: Subject<any> = new Subject();

  views(views?: any): any {
    if (typeof views !== 'undefined') {
      this._views = views;
      this._$views.next(views);
    }

    return this._views;
  }

  $views() {
    return this._$views.asObservable();
  }

  setupViews(views: any): any {
    return this.views(merge({}, VIEWS, views));
  }

  strategy(strategy?: Strategy): Strategy|null {
    if (typeof strategy !== 'undefined') {
      this._strategy = strategy;
      this._$strategy.next(strategy);
    }

    return this._strategy;
  }

  $strategy() {
    return this._$strategy.asObservable();
  }

  destroy(): void {
    this._strategy = null;
    this._$strategy.next(null);
    this._views = {};
    this._$views.next(null);
  }
}
