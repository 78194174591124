import { Pipe, PipeTransform } from 'angular-ts-decorators';

@Pipe({
  name: 'startsWith'
})
export class StartsWithPipe implements PipeTransform {
  transform<T>(array: T[], search: string, searchedField?: string): T[] {
    const matches: T[] = [];

    if (searchedField === undefined) {
      searchedField = 'label';
    }

    for (const value of array) {
      if (
        value[searchedField].toLowerCase().indexOf(search.toLowerCase()) === 0 &&
        search.length <= value[searchedField].length
      ) {
        matches.push(value);
      }
    }
    return matches;
  }
}
