import { Component, Input } from 'angular-ts-decorators';

import './component.scss';
const template = require('./component.html.haml');

@Component({
  selector: 'progress-bar',
  template
})
export class ProgressBarComponent {
  @Input()
  value: number|string = '';
  @Input()
  min = 0;
  @Input()
  max = 100;
  @Input()
  width = 100;
  @Input()
  reverse = false;
  @Input()
  backgroundColorClass = '';
}
