import {
  Component, Inject, Input, OnInit, OnDestroy, OnChanges, SimpleChanges
} from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { StrategyResource, Strategy } from '@quantizr/front-model';

import './component.scss';
const template = require('./component.html.haml');

import { ISimulationNew } from '../page';
import { MyUserService } from '../../../my-user';
import { formHasErrors } from '../../../forms/has-errors';

@Component({
  selector: 'simulations-new-scenarios',
  template
})
export class SimulationsNewScenariosComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  simulation: ISimulationNew;
  @Input()
  errorMessages: any;
  @Input()
  form: ng.IFormController;
  @Input()
  active: boolean;

  public strategyId: number|undefined;
  public preselectedStrategyId: number|undefined;
  public portfolios: Strategy[] = [];

  /*@ngInject*/
  constructor(
    @Inject('MyUserService')
    myUserService: MyUserService,
    @Inject('StrategyResource')
    strategyResource: StrategyResource,
    $location: ng.ILocationService,
    private ngDialog: ng.dialog.IDialogService,
    $routeParams: ng.route.IRouteParamsService,
    private $element: ng.IAugmentedJQuery
  ) {
    this.strategyId = $routeParams.strategyId;
    if (this.strategyId) {
      this.preselectedStrategyId = this.strategyId;
      ($location as any).$$search = {};
    }

    this.subscriptions.push(myUserService.$user().subscribe(user => {
      if (user) {
        strategyResource.query({
          type: 'Portfolio',
          user_id: user.id,
          per_page: 100
        }).$promise.then(portfolios => this.portfolios = portfolios);
      }
    }));
  }

  ngOnInit() {
    this.errorMessages = this.errorMessages || {};

    if (!this.form) {
      const input = this.$element.find('input, textarea, select').eq(0);
      this.form = input && input.controller('form');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('simulation' in changes) {
      this.simulation.strategy_id = this.strategyId;
    }
  }

  changeStrategyId(strategyId: number) {
    return this.confirmChangeValue('strategy_id', strategyId).catch(() => {
      this.strategyId = this.simulation.strategy_id;
    });
  }

  changeScenarioType(scenarioType: string) {
    return this.confirmChangeValue('scenario_type', scenarioType);
  }

  confirmChangeValue(key: string, value: any) {
    return new Promise((resolve, reject) => {
      if (this.simulation.strategy) {
        this.resetConfirmDialog().then(() => {
          delete this.simulation.strategy;
          resolve();
        }).catch(err => reject(err));
      }
      else {
        resolve();
      }
    }).then(() => {
      this.simulation[key] = value;
      return true;
    });
  }

  resetConfirmDialog() {
    return this.ngDialog.openConfirm({
      template: '<page-simulations-reset-confirm></page-simulations-reset-confirm>',
      plain: true,
      data: {
        simulation: this.simulation
      }
    });
  }

  hasError(fields: string[]) {
    return formHasErrors(fields, this.form, this.errorMessages);
  }
}
