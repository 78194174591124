import { Component, Input, Output, OnChanges } from 'angular-ts-decorators';
import * as moment from 'moment';

const template = require('./component.html.haml');

import { dateWithoutYears, dateWithoutMonths, ISelectorDatesSelected } from '../model';

@Component({
  selector: 'dates-selector-button',
  template
})
export class DatesSelectorButtonComponent implements OnChanges {
  @Input()
  dates: ISelectorDatesSelected;
  @Input()
  minDate: moment.Moment|undefined;
  @Input()
  period: number;
  @Input()
  range: 'months'|'years';

  @Output()
  onSelect: (data: {$event: Date}) => void;

  public date: Date;

  ngOnChanges() {
    if (this.dates && this.dates.max && this.range) {
      switch (this.range) {
        case 'months':
          this.date = dateWithoutMonths(this.dates.max, this.period);
          break;
        case 'years':
          this.date = dateWithoutYears(this.dates.max, this.period);
      }
    }
  }

  update() {
    this.onSelect({
      $event: this.date
    });
  }

  selected() {
    if (!this.minDate) {
      return false;
    }

    return this.minDate.isSame(this.date, 'day');
  }

  enabled() {
    if (!this.dates || !this.dates.min || !this.dates.max) {
      return false;
    }

    return this.dates.min && this.dates.max && this.date >= this.dates.min && this.date <= this.dates.max;
  }
}
