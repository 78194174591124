import { Component, Input, Output, Inject, OnDestroy, OnChanges, SimpleChanges } from 'angular-ts-decorators';
import { Subscription } from 'rxjs';
import { Keyword } from '@quantizr/front-model';

const template = require('./component.html.haml');

import { KeywordsService } from '../../keywords/service';

@Component({
  selector: 'strategies-keywords',
  template
})
export class StrategiesKeywordsComponent implements OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  @Input()
  value: string[] = [];
  @Input('@')
  placeholder: string;

  @Output()
  onSelect: (data: {$event: string[]}) => void;

  private _keywords: Keyword[]|null = null;
  public keywords: string[] = [];
  public selected: string[] = [];

  /*@ngInject*/
  constructor(
    @Inject('KeywordsService')
    service: KeywordsService
  ) {
    this.subscriptions.push(service.$keywords().subscribe(keywords => {
      this._keywords = keywords;
      this.keywords = (keywords || []).map(value => value.name);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes) {
      this.selected = changes.value.currentValue || [];
    }
  }

  onKeywordChange() {
    this.onSelect({
      $event: this.selected
    });
  }

  public groupFind = (keyword: string) => {
    for (const value of (this._keywords || [])) {
      if (value.name === keyword) {
        return value.category;
      }
    }
    return null;
  }
}
