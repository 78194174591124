import { Component, Input, Inject } from 'angular-ts-decorators';
import { CockpitProfile, CockpitProfileResource } from '@quantizr/front-model';
import { CockpitsViewsService } from '../views/service';

const template = require('./component.html.haml');

@Component({
  selector: 'cockpits-header',
  template,
  transclude: true
})
export class CockpitsHeaderComponent {
  @Input()
  public title = '';
  @Input()
  public profile: CockpitProfile;

  /*@ngInject*/
  constructor(
    @Inject('CockpitProfileResource')
    private cockpitProfileResource: CockpitProfileResource,
    @Inject('CockpitsViewsService')
    private cockpitsViewsService: CockpitsViewsService
  ) {}

  refresh() {
    return this.cockpitProfileResource.refresh({
      id: this.profile.id
    }).$promise.then(() => this.cockpitsViewsService.loadCockpit());
  }
}
