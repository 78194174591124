import { Component } from 'angular-ts-decorators';

import './page.scss';
import { ILoginMyUser } from '../../service';
import { CLIENT_APPLICATION } from '../../../config/devise';

const template = require('./page.html.haml');

@Component({
  selector: 'page-my-user-password-edit',
  template
})
export class MyUserPasswordEditPage {
  public saving = 0;
  public errorMessages: any = null;
  public user: ILoginMyUser = {
    password: '',
    password_confirmation: '',
    client: CLIENT_APPLICATION
  };
  public token: string|null = null;

  /*@ngInject*/
  constructor(
    private Auth: ng.devise.IAuthProvider<ILoginMyUser>,
    private $location: ng.ILocationService,
    $routeParams: ng.route.IRouteParamsService,
    private $translate: ng.translate.ITranslateService,
    private toaster: toaster.IToasterService
  ) {
    this.token = $routeParams.reset_password_token;
    if (!this.token) {
      $location.path('/');
    }
  }

  submit() {
    this.errorMessages = null;
    this.saving = 1;

    return this.Auth.resetPassword(Object.assign(this.user, {
      reset_password_token: this.token
    })).then(() => this.updateSuccess(), error => this.updateError(error));
  }

  updateSuccess() {
    this.saving = 0;
    this.$translate('myUser.password.edit.controller.success').then(title => {
      this.toaster.success(title);
      this.$location.path('/');
    });
  }

  updateError(error) {
    this.saving = 2;
    this.errorMessages = error.data.errors;
  }
}
