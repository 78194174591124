export const COLORS = [
  '#ffc107',
  '#f44336',
  '#746295',
  '#84bbd4',
  '#83d7c0',
  '#ffdf07',
  '#ff5722',
  '#d61858',
  '#3f51b5',
  '#05a3b7',
  '#64a593',
  '#795548',
  '#833f8e',
  '#2196f3',
  '#009688',
  '#a0c9be',
  '#ff9800',
  '#607d8b',
  '#9e9e9e',
  '#795548',
  '#ff5722',
  '#607d8b'
];
