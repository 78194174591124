import { Component } from 'angular-ts-decorators';

const template = require('./component.html.haml');

@Component({
  selector: 'strategies-pdf-footer',
  template
})
export class StrategiesPdfFooterComponent {
  public today = new Date();
}
