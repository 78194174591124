import { Component, Input, OnDestroy, OnInit, AfterViewInit } from 'angular-ts-decorators';
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';

import './component.scss';
const template = require('./component.html.haml');

export const MAX_COUNT = 30;

export type sankeyDataOrderFunction = (values: {[key: string]: number}, isSource: boolean) => string[];

export const simpleOrderKeys: sankeyDataOrderFunction = (values) => {
  return Object.keys(values).sort((a, b) => values[a] < values[b] ? 1 : -1);
};

export const fillSourceAndTarget = (data: ISankeyData, orderDataKeys: sankeyDataOrderFunction) => {
  const source: {[key: string]: number} = {};
  const target: {[key: string]: number} = {};

  for (const sourceKey of Object.keys(data)) {
    if (!!data[sourceKey]) {
      source[sourceKey] = 0;

      for (const targetKey of Object.keys(data[sourceKey])) {
        const value = data[sourceKey][targetKey];
        if (!!value) {
          if (!target[targetKey]) {
            target[targetKey] = 0;
          }
          target[targetKey] += value;
          source[sourceKey] += value;
        }
      }
    }
  }

  return [orderDataKeys(source, true), orderDataKeys(target, false)];
};

export interface ISankeyData {
  [source: string]: {
    [target: string]: number;
  };
}

interface ISNodeExtra {
  nodeId: number;
  name: string;
  color?: string;
}

interface ISLinkExtra {
  source: number;
  target: number;
  value: number;
  real: number;
}

type SNode = d3Sankey.SankeyNode<ISNodeExtra, ISLinkExtra>;
type SLink = d3Sankey.SankeyLink<ISNodeExtra, ISLinkExtra>;

interface IQSankey {
  nodes: SNode[];
  links: SLink[];
}

@Component({
  selector: 'sankey',
  template
})
export class SankeyComponent implements OnDestroy, OnInit, AfterViewInit {
  private loadingTimeout: any;

  @Input()
  public data: ISankeyData;
  @Input()
  public orderDataKeysFn: sankeyDataOrderFunction = simpleOrderKeys;
  @Input()
  public hideFullSankeyButton = false;
  @Input('@')
  public containerId: string;
  @Input('@')
  public width: number;
  @Input('@')
  public height: number;
  @Input()
  public sourceColors: string[];
  @Input()
  public targetColors: string[];

  sankeyData: IQSankey = {
    links: [],
    nodes: []
  };

  /*@ngInject*/
  constructor(
    private ngDialog: ng.dialog.IDialogService,
    private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit() {
    const [source, target] = fillSourceAndTarget(this.data, this.orderDataKeysFn);
    const sourceLength = Math.min(source.length, MAX_COUNT);
    const targetLength = Math.min(target.length, MAX_COUNT);

    for (let i = 0; i < sourceLength; i++) {
      this.sankeyData.nodes.push({
        nodeId: this.sankeyData.nodes.length,
        name: source[i],
        color: this.sourceColors[i]
      });
    }

    for (let i = 0; i < targetLength; i++) {
      this.sankeyData.nodes.push({
        nodeId: this.sankeyData.nodes.length,
        name: target[i],
        color: this.targetColors[i]
      });
    }

    for (let i = 0; i < sourceLength; i++) {
      const sourceKey = source[i];
      if (!!this.data[sourceKey]) {
        for (let j = 0; j < targetLength; j++) {
          const targetKey = target[j];
          const value = this.data[sourceKey][targetKey];
          if (!!value) {
            this.sankeyData.links.push({
              source: this.sankeyData.nodes[i].nodeId,
              target: this.sankeyData.nodes[sourceLength + j].nodeId,
              value: Math.abs(value * 100),
              real: value * 100
            });
          }
        }
      }
    }
  }

  displaySankey() {
    return Object.keys(this.data).length > 0;
  }

  displayFullSankey() {
    const newScope = this.$rootScope.$new(true) as ng.dialog.IDialogConfirmScope;
    newScope.data = this.data;
    newScope.orderDataKeysFn = this.orderDataKeysFn;
    newScope.sourceColors = this.sourceColors;
    newScope.targetColors = this.targetColors;
    this.ngDialog.open({
      className: 'ngdialog-theme-default ngdialog-theme-xxlarge',
      template: `
        <sankey container-id="sankeyModal" data="data"
          width=900 height=700
          hide-full-sankey-button="true"
          order-data-keys-fn="orderDataKeysFn"
          source-colors="sourceColors" target-colors="targetColors"
        ></sankey>
      `,
      plain: true,
      scope: newScope
    });
  }

  ngAfterViewInit() {
    this.loadingTimeout = setTimeout(() => {
      this._drawChart();
    });
  }

  ngOnDestroy() {
    clearTimeout(this.loadingTimeout);
  }

  private _drawChart() {
    const svg = d3.select(`#${this.containerId}`);
    const formatNumber = d3.format(',.0f');

    const sankey = d3Sankey.sankey().nodeWidth(15).nodePadding(10)
      .extent([[5, 5], [this.width - 1, this.height - 6]]);
    sankey(this.sankeyData);

    let link = svg.append('g')
      .attr('class', 'links')
      .attr('fill', 'none')
      .attr('stroke-opacity', 0.2)
      .selectAll('path');

    link = link.data(this.sankeyData.links).enter().append('path')
      .attr('stroke', d => d.source.color)
      .attr('d', d3Sankey.sankeyLinkHorizontal())
      .attr('stroke-width', d => Math.max(1, d.width));

    link.append('title')
      .text(d => `${d.source.name} → ${d.target.name}\n${formatNumber(d.real)}%`);

    let node = svg.append('g')
      .attr('class', 'nodes')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .selectAll('g');

    node = node.data(this.sankeyData.nodes).enter().append('g');
    node.append('rect')
      .attr('x', d => d.x0)
      .attr('y', d => d.y0)
      .attr('height', d => d.y1 - d.y0)
      .attr('width', d => d.x1 - d.x0)
      .attr('fill', d => d.color)
      .attr('stroke', '#000');

    node.append('text')
      .attr('x', d => d.x0 - 6)
      .attr('y', d => (d.y1 + d.y0) / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'end')
      .text(d => d.name)
      .filter(d => d.x0 < this.width / 2)
      .attr('x', d => d.x1 + 6)
      .attr('text-anchor', 'start');

    node.append('title').text(d => `${d.name}\n${formatNumber(d.value)}%`);
  }
}
