import { NgModule } from 'angular-ts-decorators';
import TimeSerieResourceFactory from '@quantizr/front-model/dist/time-series/resource';

import { ComponentsModule } from '../components';
import { CustomSearchModule } from '../custom-search';
import { VendorModule } from '../vendor';

import { TimeSeriesStockChartComponent, IStockChartRequestParams } from './stock-chart/component';

export {
  TimeSeriesStockChartComponent, IStockChartRequestParams
};

@NgModule({
  id: 'TimeSeries',
  imports: [
    ComponentsModule,
    CustomSearchModule,
    VendorModule
  ],
  declarations: [
    TimeSeriesStockChartComponent
  ],
  providers: [
    {
      provide: 'TimeSerieResource',
      useFactory: ($resource) => TimeSerieResourceFactory($resource),
      deps: [
        '$resource'
      ]
    }
  ]
})
export class TimeSeriesModule {}

export default (TimeSeriesModule as NgModule).module!.name;
