import { NgModule } from 'angular-ts-decorators';

import { EnvModule } from '../env';
import { MyUserModule } from '../my-user';
import { VendorModule } from '../vendor';

import { PanelsSidebarService } from './sidebar/service';

import { PanelsBodyComponent } from './body/component';
import { PanelsFooterComponent } from './footer/component';
import { PanelsHeaderComponent } from './header/component';
import { PanelsSidebarComponent } from './sidebar/component';
import { PanelsSidebarChild, PanelsSidebarChildDirective } from './sidebar/child';

export {
  PanelsSidebarChild,
  PanelsSidebarService
};

@NgModule({
  id: 'Panels',
  imports: [
    EnvModule,
    MyUserModule,
    VendorModule
  ],
  declarations: [
    PanelsBodyComponent,
    PanelsFooterComponent,
    PanelsHeaderComponent,
    PanelsSidebarComponent,
    PanelsSidebarChildDirective
  ],
  providers: [
    PanelsSidebarService
  ]
})
export class PanelsModule {}

export default (PanelsModule as NgModule).module!.name;
